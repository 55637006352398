import React from 'react';
import { Grid } from "@material-ui/core";
import Loader from "react-loader-spinner";
import iconQuickPhone from "../../../Assets/images/icon-quick-phone.svg";
import clinicalEmail from "../../../Assets/images/clinical_email_icon.svg";

function TeamsComponent(props) {
    console.log("teamscomponet", props)
    return (
        <>
            <div className="header-dept">
            <div className="dept_title">Teams</div>
        </div><div className="company_detail_right_content">
                <Grid container spacing={3}>
                    {props.data?.loading ? <div style={{ textAlign: "center", width: '100%' }}><Loader
                        type="ThreeDots"
                        color="#00BFFF"
                        height={50}
                        width={50}
                        visible={props.data?.loading} />
                    </div> :
                        props.data?.company !== undefined && props.data?.company.size === 'large' ? props.data?.company.divisions.map((data) => (
                            <Grid item xs={12} md={6}>
                                <div className="company_detail_right_item">
                                    <h2>Division :<span>{data.divisionName}</span></h2>
                                    <div className="company_detail_right_item_value">
                                        {data.teamLeads.length === 0 && data.territoryManagers.length === 0 ? (
                                            <div style={{ textAlign: "center", width: '100%' }}>
                                                No data found
                                            </div>
                                        ) :
                                            data.teamLeads.map((team) => (
                                                <div className="company_detail_right_item_value_one company_detail_right_item_value_common">
                                                    <div className="company_detail_right_item_values">
                                                        <p>Regional Manager :<span>{team.name !== '' && team.name !== undefined && team.name !== null ? team.name : "-"}</span></p>
                                                    </div>
                                                    <div className="company_detail_right_item_values">
                                                        <p>Phone :<span>{team.phone !== '' && team.phone !== undefined ? team.phone : "-"}</span></p>
                                                        <div className="company_detail_right_item_value_btn"><img src={iconQuickPhone} alt="" /> <a href={"tel:"}> call</a></div>
                                                    </div>
                                                    <div className="company_detail_right_item_values">
                                                        <p>Email :<span>{team.email !== '' && team.email !== undefined ? team.email : "-"}</span></p>
                                                        <div className="company_detail_right_item_value_btn"><img src={clinicalEmail} alt="" /><a href={"mailto:"}> Email</a></div>
                                                    </div>
                                                </div>
                                            ))}
                                        {data.territoryManagers.map((territory) => (
                                            <div className="company_detail_right_item_value_two company_detail_right_item_value_common">
                                                <div className="company_detail_right_item_values">
                                                    <p>Territory Manager :<span>{territory.name !== '' && territory.name !== undefined && territory.name !== null ? territory.name : "-"}</span></p>
                                                </div>
                                                <div className="company_detail_right_item_values">
                                                    <p>Phone :<span>{territory.phone !== '' && territory.name !== undefined ? territory.name : "-"}</span></p>
                                                    <div className="company_detail_right_item_value_btn"><img src={iconQuickPhone} alt="" /> <a href={"tel:"}>call</a></div>
                                                </div>
                                                <div className="company_detail_right_item_values">
                                                    <p>Email :<span>{territory.email !== '' && territory.name !== undefined ? territory.name : "-"}</span></p>
                                                    <div className="company_detail_right_item_value_btn"><img src={clinicalEmail} alt="" /><a href={"tel:"}> Email</a></div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </Grid>
                        )) : <div style={{ textAlign: 'center', width: '100%' }}>No data found</div>}
                </Grid>
            </div></> 
    )
}

export default TeamsComponent
