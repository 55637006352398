import React from "react";
import app_icon from '../../Assets/images/app_icon.png';
import {withRouter} from 'react-router-dom';
import "./PageNotFoundComponent.css";
const PageNotFoundComponent = (props) => {
  const gotoHome=()=>{
    props.history.replace("/dashboard")
  }
  return (
      <div className="error_main">
            <div className="error-page">
                <div className="error-page_wrap">
                <p className="four" width="150" height="150">4</p>
                <img
                    src={app_icon}
                    className="rotate linear infinite"
                    width="150"
                    height="150"
                    alt="app"
                />
                <p className="four" width="150" height="150">4</p>
                </div>
                <div className="error-text">
                    <h2 className="error_head">Something's missing</h2>
                    <p className="error-txt_p">We can't find the page you're looking for!</p>
                    <button className="error_back" onClick={gotoHome}>Back to Home</button>
                </div>
            </div>
        </div>
  );
};

export default withRouter(PageNotFoundComponent);


// import React from "react";
// import { Empty, Button } from "antd";
// import { NavLink } from "react-router-dom";
// import "./PageNotFoundComponent.css";
// const PageNotFoundComponent = () => {
//   return (
//     <Empty
//       className="emptypage"
//       image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
//       imageStyle={{
//         height: 60,
//       }}
//       description={<span style={{ color: "red" }}>Page Not Found</span>}
//     >
//       <NavLink to="/dashboard" className="navbars">
//         Click here
//       </NavLink>{" "}
//       to goto dashboard
//     </Empty>
//   );
// };

// export default PageNotFoundComponent;
