import Slide from '@material-ui/core/Slide';
import Grow from '@material-ui/core/Grow';
import React from "react";

export const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="top" ref={ref} {...props} />;
});
export const TransitionGrow = React.forwardRef(function Transition(props, ref) {
    return <Grow ref={ref} {...props} />;
  });
  

