import React from "react";
import { Select } from "antd";
import { Grid } from "@material-ui/core";
import moment from 'moment';
import momentTimeZone from 'moment-timezone';

const { Option } = Select;

const TimeZoneComponent = (props) => {
  return (
      <div className="notifications-container timezone_container">
        <div className="timezone_top">
            <label className="timezone_title">
              Time Zone    
            </label>
            {/* <h6 className="timezone_content">
             Your Time Zone is : <span>{props.tzSelectName?props.tzSelectName:"No time zone selected"}</span>      
            </h6> */}
        </div>
        <div className="timezone_middle">
            <label className="timezone_date">
              Date & Time   
            </label>
            <h6 className="timezone_date">
            Current time at {props.tzSelectName?props.tzSelectName:""}: {props.tzSelectName?moment().tz(props.tzSelectName).format("DD MMMM YYYY, hh:mm A"):"-" }        
            </h6>
        </div>
      <div className="custom-ant-select timezone_bottom">
        {/* <label className="label bill_label">
          Do you want to change your timezone? */}
          {/* <span className="labelAsterisk">*</span> */}
        {/* </label> */}
        <label className="timezone_date">
          Time Zone   
        </label>
        <div>
        <Select
          placeholder="Search Timezone"
          style={{ width: "200px" }}
          showSearch
          defaultActiveFirstOption={false}
          showArrow={true}
          filterOption={false}
          onSearch={props.handleStateSearch}
          onChange={props.onChangeState}
          onFocus={props.onTzFocus}
          notFoundContent={null}
          value={props.tzSelectName?props.tzSelectName:""}
        >
          {props.tzNameArray &&
            props.tzNameArray.length > 0 &&
            props.tzNameArray.map((item, index) => (
              <Option key={index} value={item}>
                {`(UTC${moment().tz(item).format('Z')}) ${item}`}
              </Option>
            ))}
        </Select>
        <button className="set-time-button" onClick={()=>props.onChangeState(momentTimeZone.tz.guess())}>
        Set timezone same as device : {`(UTC${moment().tz(momentTimeZone.tz.guess()).format('Z')}) ${momentTimeZone.tz.guess()}`}
        </button>
        </div>
      </div>
    </div>
  );
};

export default TimeZoneComponent;
