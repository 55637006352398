import React,{Component,Suspense} from "react";
import { NavLink, withRouter } from "react-router-dom";
import { fade, makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import Badge from "@material-ui/core/Badge";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import AccountCircle from "@material-ui/icons/AccountCircle";
import MailIcon from "@material-ui/icons/Mail";
import logo from "../../Assets/images/Group 1602.svg";
import mobileMenuIcon from "../../Assets/images/Icon feather-menu.svg";
import addTask from "../../Assets/images/add_task.svg";
import Button from "@material-ui/core/Button";
import NotificationsNoneOutlinedIcon from "@material-ui/icons/NotificationsNoneOutlined";
import MoreIcon from "@material-ui/icons/MoreVert";
import notificationIcon from "../../Assets/images/notification-icon.svg";
import userIcon from "../../Assets/images/user-icon.png";
import "./HeaderComponent.css";
import { MobileHeaderModal } from "./MobileHeaderModal";
import { AddTaskModal } from "../../Components/TaskComponent/AddTaskModal";
import settingsIcon from "../../Assets/images/settings-icon.svg";
import closeBtn from "../../Assets/images/close_btn.svg";
import Divider from "@material-ui/core/Divider";
import { ProfileURL } from '../../client-config';
import {Tooltip,Checkbox,withStyles} from '@material-ui/core';
import InfiniteScroll from "react-infinite-scroll-component";
import Loader from "react-loader-spinner";
import ReactTimeAgo from 'react-time-ago'
import io from 'socket.io-client';
import {NOTIFICATION_ENDPOINT,AxiosInstance} from '../../Utilities';
import momentTimeZone from 'moment-timezone';
import { Transition, TransitionGrow } from "../CommonUtilities/CommonUtilities";
var PopConfirmComponent = React.lazy(() => import("../../SharedComponents/PopConfirmComponent/PopConfirmComponent"));

const GreenCheckbox = withStyles({
  root: {
    color: "#424242",
    '&$checked': {
      color: "#376ce2",
    },
  },
  checked: {},
})(Checkbox);
var socket
class HeaderComponent extends Component {
  constructor(props) {
    super(props);
    var userDataInfo = localStorage.getItem("userdata")
    ? JSON.parse(localStorage.getItem("userdata"))
    : "";
    var zoneName= momentTimeZone.tz.guess();
    this.state={
      timeZoneName:localStorage.getItem("timeZone")?localStorage.getItem("timeZone"):zoneName,
      notification_endpoint:NOTIFICATION_ENDPOINT,
      userData:userDataInfo,
      anchorEl:null,
      mobileMoreAnchorEl:null,
      menuMoreAnchorEl:null,
      open:false,
      openTask:false,
      userData: localStorage.getItem("userdata")
      ? JSON.parse(localStorage.getItem("userdata"))
      : "",
      profilePath: localStorage.getItem("userdata") ? JSON.parse(localStorage.getItem("userdata")).profilePath : "",
      hospitalLogo: localStorage.getItem("hospitalLogo") ? JSON.parse(localStorage.getItem("hospitalLogo")).hospitalLogo : "",
      /**
       * @author Benakesh
       * START
       */
      nextpage: 1,
      pagesize: 3,
      endPage: null,
      notifyItems: [],
      notifyMarkCount: null,
      anchorNotifyEl:null,
      onHoverItem: null,
      notifyLoader: false,
      hasMore: true,
      markAsRead: false,
      showdelete: false,
      lengths:"",
      singleClearId:null,
      readId:"",
      disable:false,
      popConfirmTitle: "Notification",
      action: "Clear",

       /**
        * @author Benakesh
        * END
        */
    }
  }
  /**
   * @author Benakesh
   * START
   */
  
  componentDidMount() {
    socket = io.connect(this.state.notification_endpoint);
    socket.on(`notificationCount${this.state.userData.email}`, data => {
      this.setState({
        notifyMarkCount: data.count
      },()=>{
        this.getNotificationItems();
      })
    });
    socket.on(`notificationPopup${this.state.userData.email}`, data => {
      var end_date = momentTimeZone(data.endDate).tz(this.state.timeZoneName);
      var start_date = momentTimeZone(data.startDate).tz(this.state.timeZoneName);
      var diffTime = end_date.diff(start_date, 'hours');
      var diffMinutes = end_date.diff(start_date, 'minutes');
      var diffSeconds = end_date.diff(start_date, 'seconds');
      diffTime = diffTime === 0 ? diffMinutes + " Minutes" : diffMinutes === 0 ? diffSeconds + " Seconds" : diffTime + " Hours";
      this.setState({
        taskAssignModalOpen: true,
        taskAssigneeData: data,
        diffTime
      },()=>{
        this.getNotificationItems();
      })
    })

    AxiosInstance( (process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa")
              ? 4004
              : "clinical-task")
      .post('/notifications/get_notifications',{timeZone:this.state.timeZoneName})
      .then(resp => {
        var response = resp && resp.data;
        if (response && (response.status === '200' || response.status === 200)) {
          this.setState({
            notifyMarkCount: response.count,
          })
        } else {
          this.setState({
            notifyMarkCount: null
          })
        }
      }).catch(err=>{
  			var error=err.response;
            if(error && (error.status === '403' || error.status === 403)){
                localStorage.clear();
                setTimeout(()=>{
                    window.location.href="/";
                },500)
            }else{
                console.log("Internal Server Error")
            }
        this.setState({
          notifyMarkCount: null
        })
      })
  }

  getNotificationItems=()=>{
    this.setState({
      notifyItems:[]
    })
    AxiosInstance( (process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa")
              ? 4004
              : "clinical-task")
    .post('/notifications/get_notifications', {
      nextpage: this.state.nextpage,
      pagesize: this.state.pagesize,
      timeZone:this.state.timeZoneName
    })
    .then(resp => {
      var response = resp && resp.data;
      if (response && (response.status === '200' || response.status === 200)) {
        const newArray = this.state.notifyItems.slice();
        response.data.map(items => {
          var itemObj = {};
          itemObj._id = items._id;
          itemObj.description = items.description;
          itemObj.read = items.read;
          itemObj.refId = items.refId;
          itemObj.type=items.type;
          itemObj.showEvent=items.showEvent;
          itemObj.title = items.title;
          itemObj.showPopup= items.showPopup;
          itemObj.createdAt = new Date(items.createdAt);
          newArray.push(itemObj);
        })
        this.setState({
          notifyLoader: false,
          notifyMarkCount: response.count,
          notifyItems: newArray,
          nextpage: response.pagination.currentPage,
          endPage: response.pagination.endPage
        })
      } else {
        this.setState({
          notifyLoader: false,
          notifyItems: [],
          nextpage: 1,
          endPage: null
        })
      }
    }).catch(err=>{
      var error=err.response;
          if(error && (error.status === '403' || error.status === 403)){
              localStorage.clear();
              setTimeout(()=>{
                  window.location.href="/";
              },500)
          }else{
              console.log("Internal Server Error")
          }
       this.setState({
        notifyLoader: false,
        notifyItems: [],
        nextpage: 1,
        endPage: null
      })
    });
  }

  // getNotificationItemsNew=()=>{
  //   this.setState({
  //     notifyItems:[]
  //   })
  //   AxiosInstance( (process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa")
              // ? 4004
              // : "clinical-task")
  //   .post('/notifications/get_notifications', {
  //     nextpage: this.state.nextpage,
  //     pagesize: this.state.pagesize,
  //     timeZone:this.state.timeZoneName
  //   })
  //   .then(resp => {
  //     var response = resp && resp.data;
  //     if (response && (response.status === '200' || response.status === 200)) {
  //       const newArray = this.state.notifyItems.slice();
  //       response.data.map(items => {
  //         var itemObj = {};
  //         itemObj._id = items._id;
  //         itemObj.description = items.description;
  //         itemObj.read = items.read;
  //         itemObj.refId = items.refId;
  //         itemObj.type=items.type;
  //         itemObj.showEvent=items.showEvent;
  //         itemObj.title = items.title;
  //         itemObj.showPopup= items.showPopup;
  //         itemObj.createdAt = new Date(items.createdAt);
  //         newArray.push(itemObj);
  //       })
  //       this.setState({
  //         notifyLoader: false,
  //         notifyMarkCount: response.count,
  //         notifyItems: newArray,
  //         nextpage: response.pagination.currentPage,
  //         endPage: response.pagination.endPage
  //       })
  //     } else {
  //       this.setState({
  //         notifyLoader: false,
  //         notifyItems: [],
  //         nextpage: 1,
  //         endPage: null
  //       })
  //     }
  //   }).catch(err=>{
  //     var error=err.response;
  //         if(error && (error.status === '403' || error.status === 403)){
  //             localStorage.clear();
  //             setTimeout(()=>{
  //                 window.location.href="/";
  //             },500)
  //         }else{
  //             console.log("Internal Server Error")
  //         }
  //      this.setState({
  //       notifyLoader: false,
  //       notifyItems: [],
  //       nextpage: 1,
  //       endPage: null
  //     })
  //   });
  // }
  /**
  * @author Benakesh
  * @param {target element} event 
  * To open notification dialog
  */
  handleNotificationMenuOpen = (event) => {
    this.setState({
      notifyLoader: true,
      anchorNotifyEl: event.currentTarget,
      nextpage: 1,
      pagesize: 3,
      hasMore: true
    }, () => {
        this.getNotificationItems();
    });
  }
    /**
   * @author Benakesh
   * To close notification dialog
   */
  handleNotificationMenuClose = () => {
    this.setState({
      anchorNotifyEl: null
    })
  };
    /**
   * @author Benakesh
   *  To clear items on exit of dialog
   */
  clearItems = () => {
    this.setState({
      onHoverItem: null,
      anchorNotifyEl: null,
      hasMore: true
    })
  }

    /**
   * @author Benakesh
   *  To fetch list of notification on scroll
   */
  fetchMoreData = () => {
    setTimeout(() => {
      if (this.state.endPage !== this.state.nextpage) {

        AxiosInstance( (process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa")
              ? 4004
              : "clinical-task")
          .post('/notifications/get_notifications', {
            nextpage: this.state.nextpage + 1,
            pagesize: this.state.pagesize,
            timeZone:this.state.timeZoneName
          })
          .then(resp => {
            var response = resp && resp.data;
            if (response && (response.status === '200' || response.status === 200)) {
              const newArray = this.state.notifyItems.slice();
              response.data.map(items => {
                var itemObj = {};
                itemObj._id = items._id;
                itemObj.description = items.description;
                itemObj.read = items.read;
                itemObj.refId = items.refId;
                itemObj.title = items.title;
                itemObj.type=items.type;
                itemObj.showEvent=items.showEvent;
                itemObj.showPopup=items.showPopup;
                itemObj.createdAt = new Date(items.createdAt);
                newArray.push(itemObj);
              })
              this.setState({
                notifyLoader: false,
                notifyMarkCount: response.count,
                notifyItems: newArray,
                nextpage: this.state.nextpage + 1
              })
            } else {
              this.setState({
                notifyLoader: false,
                notifyItems: [],
              })
            }
          }).catch(err=>{
            var error=err.response;
                if(error && (error.status === '403' || error.status === 403)){
                    localStorage.clear();
                    setTimeout(()=>{
                        window.location.href="/";
                    },500)
                }else{
                    console.log("Internal Server Error")
                }
            this.setState({
              notifyLoader: false,
              notifyItems: []
            })
          })
      } else {
        this.setState({
          hasMore: false
        })
      }
    }, 1000);
  };

/**
  * @author Benakesh
  *  To set mark all as read
  */
 mark_all_as_read_handler = () => {
  this.update_all_notification("read")
}

/**
* @author Benakesh
*  To clear all notification
*/
clear_all_handler = () => {
  this.setState({
    showdelete: true,
    lengths:"all"
  });
}

  /**
   * @author Benakesh
   * @param {read/clear} type 
   * @param {notification_id} id
   * To clear/read all notification 
   */
  update_all_notification = (type) => {
    AxiosInstance( (process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa")
              ? 4004
              : "clinical-task")
      .post("/notifications/edit_all_notifications",
        {
          type,
          timeZone:this.state.timeZoneName
        })
      .then(resp => {
        var response = resp && resp.data;
        if (response && (response.status === 200 || response.status === "200")) {
          this.setState({
            notifyItems: response.data,
            notifyMarkCount: response.count,
            disable: false,
            showdelete:false
          })
        }
      }).catch(err=>{
        this.setState({
          disable: true
        })
  			var error=err.response;
            if(error && (error.status === '403' || error.status === 403)){
                localStorage.clear();
                setTimeout(()=>{
                    window.location.href="/";
                },500)
            }else{
                console.log("Internal Server Error")
            }
			});
  }

  /**
   * @author Benakesh
   * @param {notification id} index 
   * To set mark as read on click of switch icon
   */
  on_mark_as_read_handler = (id, read) => {
    var types = read ? "unread" : "read";
    if(types === "read"){
      this.setState({
        readId:id
      });
    }else{
      this.setState({
        readId:''
      });
    }
    
    this.update_notification(types, id)
  }

  /**
   * @author Benakesh
   * @param {notification id} index 
   * Clear specific notification
   */
  on_clear_notification_handler = (id) => {
    this.setState({
      showdelete: true,
      lengths:"single",
      singleClearId:id
    });
  }

  /**
   * @author Benakesh
   * @param {read/clear} type 
   * @param {notification_id} id
   * To clear/read single notification 
   */
  update_notification = (type, id) => {
    AxiosInstance( (process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa")
              ? 4004
              : "clinical-task")
      .post("/notifications/edit_single_notifications",
        {
          type,
          id,
          timeZone:this.state.timeZoneName
        })
      .then(resp => {
        var response = resp && resp.data;
        if (response && (response.status === 200 || response.status === "200")) {
          this.setState({
            notifyItems: response.data,
            notifyMarkCount: response.count,
            disable: false,
            showdelete:false
          })
        }
      }).catch(err=>{
        this.setState({
          disable: true
        })
  			var error=err.response;
            if(error && (error.status === '403' || error.status === 403)){
                localStorage.clear();
                setTimeout(()=>{
                    window.location.href="/";
                },500)
            }else{
                console.log("Internal Server Error")
            }
			});
  }

  /**
  * @author Benakesh
  * @param {Index} index 
  * 
  */
 onMouseOverHandler = (index) => {
  this.setState({ onHoverItem: index })
}

onOpenTaskAssigneeModalHandler=(item)=>{
  this.update_notification('read',item._id);
  // this.getNotificationItems();
  if(window.location.pathname.indexOf("/task") === 0 || window.location.pathname.indexOf("/task") === -1){
    this.props.history.replace(`/task/${item.refId}`);
  }
}

handlePopConfirmClose = () => {
  this.setState({
    showdelete: false
  });
}

onPopConfirm = () => {
  this.setState({
    disable: true
  });
  if(this.state.lengths === "all"){
    this.update_all_notification("clear");
  }else if(this.state.lengths === "single"){
    this.update_notification("clear", this.state.singleClearId);
  }
}
   /**
    * @author Benakesh
    * END
    */


  handleClickOpen = () => {
     this.setState({
      open:true
    })
  };

 handleClickOpenTaskmodal = () => {
   this.setState({
     openTask:true
   })
  };
  handleClose = () => {
    this.setState({
      open:false
    })
  };
 handleCloseTaskmodal = () => {
  this.setState({
    openTask:false
  })
  };
  handleProfileMenuOpen = (event) => {
   this.setState({
     anchorEl:event.currentTarget
   })
  };

   handleMobileMenuClose = () => {
    this.setState({
      mobileMoreAnchorEl:null
    });
  };

   handleMenuClose = () => {
    this.setState({
      anchorEl:null
    })
    this.handleMobileMenuClose();
  };

handleMobileMenuOpen = (event) => {
    this.setState({
      mobileMoreAnchorEl:event.currentTarget
    });
  };

  menuMoreClick = (event) => {
   this.setState({
      menuMoreAnchorEl:event.currentTarget
    })
  };

menuMoreClose = () => {
  this.setState({
    menuMoreAnchorEl:null
  })
};
logoutHandler = () => {
    localStorage.clear();
    window.location.href = "/";
  };



render(){
  const { userData ,profilePath} = this.state;
  const rofilePath = this.props.profile;
 let profileImg = "";
 if(rofilePath !== undefined && rofilePath != ''){
   profileImg = ProfileURL + rofilePath;
 }else if (profilePath !== undefined && profilePath != '') {
   profileImg = ProfileURL + profilePath;
 } else {
   profileImg = userIcon;
 }

      /**
     * @author Benakesh
     * DOM for notification dialog
     */
    const  renderNotificationMenu = (
      <Menu
        onExit={this.clearItems}
        anchorEl={this.state.anchorNotifyEl}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        id="primary-search-account-menu-notify"
        keepMounted
        transformOrigin={{ vertical: "top", horizontal: "center" }}
        open={Boolean(this.state.anchorNotifyEl)}
        onClose={this.handleNotificationMenuClose}
        className="main_notification"
      >
        {this.state.notifyItems.length > 0 ?
          <InfiniteScroll
            height={300}
            dataLength={this.state.notifyItems.length}
            next={this.fetchMoreData}
            hasMore={this.state.hasMore ? true : false}
            initialScrollY={0}
            className="infinite_scroll"
            loader={<Loader
                style={{ textAlign: "center" }}
                type="TailSpin"
                color="#00BFFF"
                height={45}
                width={45}
                visible={true}
              />}
          > 
            {this.state.notifyItems.length > 0 ?
              <div className="notification_main_div">
                <div className="notification_header">
                  <div>
                    <span className="notification_header_name">Notifications </span>
                    <span className="notification_header_count">{this.state.notifyMarkCount}</span>
                  </div>

                  {this.state.notifyMarkCount > 0 ?
                    <span className="mark_all" onClick={this.mark_all_as_read_handler}>Mark all as read</span>
                    :
                    <span className="mark_all" onClick={this.clear_all_handler}>Clear all</span>}

                </div>
              </div>
              : ""}

            {this.state.notifyItems.map(item => (
              <div
                key={item._id}
                className={`notification_card_div 
                  ${this.state.onHoverItem === item._id ? "notification_mouse_hover" : ""}
                   ${item.read ? "" : "read_card"}`}
                onMouseOver={() => this.onMouseOverHandler(item._id)}
              >
                <div className="notification_title">
                  <div className="notification_title_text"   onClick={()=>this.onOpenTaskAssigneeModalHandler(item)}
              >{item.title}</div>
                  <div className="notification_title_btns">

                    {this.state.onHoverItem === item._id ?
                      <Tooltip title="Clear notification">
                        <IconButton
                          onClick={() => this.on_clear_notification_handler(item._id)}
                          className="notification_clear"
                          aria-label="show 17 new notifications"
                          color="inherit"
                          size="small"
                        >
                          <img src={closeBtn} width="13" height="13"></img>
                        </IconButton>
                      </Tooltip> : ""}
                    {this.state.onHoverItem === item._id ?
                      <Tooltip title={item.read ? "Mark as unread" : "Mark as read"}>
                        <GreenCheckbox
                          checked={this.state.readId === item._id || item.read ? true : false}
                          size="small"
                          className="notification_read_check"
                          onChange={() => this.on_mark_as_read_handler(item._id, item.read)}
                          inputProps={{ 'aria-label': 'primary checkbox' }}
                        />
                      </Tooltip> : ""}
                  </div>
                </div>
                <div className="notification_description">{item.description}</div>
                {/* <div className="notification_hrs"><ReactTimeAgo date={item.createdAt} /></div> */}
              </div>
            ))}
          </InfiniteScroll>
          : <div style={{ padding: "15px",marginTop:"30px", textAlign: "center" }}>
            <Loader
              type="TailSpin"
              color="#00BFFF"
              height={45}
              width={45}
              visible={this.state.notifyLoader}
            />
              {/* {this.state.notifyLoader?"":
               <> */}
               <div >No notifications right now</div>
               <span>When your team starts working on things, we’ll show the notifications here.</span>
                {/* </>      
              }    */}
            </div>}
      </Menu>
    );
      
  const renderMenu = (
    <Menu
      anchorEl={this.state.anchorEl}
      getContentAnchorEl={null}
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      id="primary-search-account-menu"
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "center" }}
      open={Boolean(this.state.anchorEl)}
      onClose={this.handleMenuClose}
      className="header-profile"
    >
      <img src={profileImg} className="profile-pic"></img>
      <Typography className="user-name">{this.state.userData.username}</Typography>
      <Typography className="user-email">{this.state.userData.email}</Typography>
      <NavLink to="/profile" onClick={this.handleMenuClose}>
        {" "}
        <img src={settingsIcon} className="accountimg"></img>
        <Typography variant="inherit">Manage Settings</Typography>
      </NavLink>
      <Divider component="li" className="hr-line" />
      <Button
        className="logout-button primary-btn"
        variant="contained"
        onClick={this.logoutHandler}
      >
        Logout
      </Button>
    </Menu>
  );

  const mobileMenuId = "primary-search-account-menu-mobile";
  const renderMobileMenu = (
    <Menu
      anchorEl={this.state.mobileMoreAnchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={Boolean(this.state.mobileMoreAnchorEl)}
      onClose={this.handleMobileMenuClose}
    >
      <MenuItem>
        <IconButton aria-label="show 4 new mails" color="inherit">
          <Badge badgeContent={4} color="secondary">
            <MailIcon />
          </Badge>
        </IconButton>
        <p>Messages</p>
      </MenuItem>
      <MenuItem>
        <IconButton aria-label="show 11 new notifications" color="inherit">
          <Badge badgeContent={11} color="secondary">
            <NotificationsNoneOutlinedIcon />
          </Badge>
        </IconButton>
        <p>Notifications</p>
      </MenuItem>
      <MenuItem onClick={this.handleProfileMenuOpen}>
        <IconButton
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit"
        >
          <AccountCircle />
        </IconButton>
        <p>Profile</p>
      </MenuItem>
    </Menu>
  );

  const renderMoreMenu = (
    <Menu
      id="more-menu"
      anchorEl={this.state.menuMoreAnchorEl}
      keepMounted
      open={Boolean(this.state.menuMoreAnchorEl)}
      onClose={this.menuMoreClose}
    >
      <MenuItem onClick={this.menuMoreClose}>Services</MenuItem>
      <MenuItem onClick={this.menuMoreClose}>Tasks</MenuItem>
    </Menu>
  );

  return (
    <div id="header-navbar" className="grow">
        <Suspense fallback={<div></div>}>
          <PopConfirmComponent
            disable={this.state.disable}
            showdelete={this.state.showdelete}
            title={this.state.popConfirmTitle}
            action={this.state.action}
            onPopConfirm={this.onPopConfirm}
            handlePopConfirmClose={this.handlePopConfirmClose}
          />

        </Suspense>
      <MobileHeaderModal fullScreen open={this.state.open} onClose={this.handleClose} />
      <AddTaskModal open={this.state.openTask} onClose={this.handleCloseTaskmodal} />
      <AppBar position="static">
        <Toolbar>
          <IconButton
            edge="start"
            className="header-logo"
            color="inherit"
            aria-label="open drawer"
          >
            {/* <MenuIcon /> */}
           
            <NavLink to="/dashboard">
           {this.state.hospitalLogo ? 
           <img src={
            "https://refactor-companyservice.s3.amazonaws.com/" +
            this.state.hospitalLogo
          } alt="Sales-Icon" className="company-logo"></img> :
           <img src={logo} alt="Sales-Icon" width="35px" height="auto"></img> }
           </NavLink>
          </IconButton>

          <NavLink to="/dashboard" className="navbars">
            Dashboard
          </NavLink>  
          <NavLink to="/company" className="navbars">
            Companies
          </NavLink>  
          {/* {userData && userData.role.toLowerCase() === "super admin" ? (
            <NavLink to="/company" className="navbars">
              Company
            </NavLink>
          ) : (
            ""
          )} */}
          <NavLink to="/user" className="navbars">
              Users
            </NavLink>
          {this.state.userData &&
          (this.state.userData.role.toLowerCase() === "super admin" 
          // || userData.role.toLowerCase() === "admin"
          )
             ? (
            <NavLink to="/user" className="navbars">
              Users
            </NavLink>
          ) : (""
            // <NavLink to="/user" className="navbars">
            //   Team
            // </NavLink>
          )}
           <NavLink to="/task" className="navbars">
              Task
            </NavLink>
            <NavLink to="/departments" className="navbars">
              Departments
            </NavLink>
          {/* <NavLink to="/account" className="navbars">
            Accounts
          </NavLink> */}
          {this.state.userData &&
          (this.state.userData.role.toLowerCase() === "managers" ||
          this.state.userData.role.toLowerCase() === "manager" ||
          this.state.userData.role.toLowerCase() === "super admin") ? (
            ""
          ) : (""
            // <NavLink to="/services" className="navbars">
            //   Services
            // </NavLink>
          )}
          {this.state.userData && this.state.userData.role.toLowerCase() === "super admin" ? (
            ""
          ) : (
            <React.Fragment>
              {/* <NavLink to="/task" className="navbars">
                Tasks
              </NavLink> */}
            </React.Fragment>
          )}

          {this.state.userData && this.state.userData.role.toLowerCase() === "admin" ? (""
            // <NavLink to="/levels" className="navbars">
            //   Org structure
            // </NavLink>
          ) : (
            ""
          )}
          {this.state.userData &&
          (this.state.userData.role.toLowerCase() === "super admin" ||
          this.state.userData.role.toLowerCase() === "admin") ? (
            ""
          ) : (
            <React.Fragment>
              {/* <NavLink to="/calender" className="navbars">
                Calender
              </NavLink> */}
              {/* <NavLink to="/map" className="navbars">
                Map
              </NavLink> */}
            </React.Fragment>
          )}
          <NavLink to="/reports" className="navbars">
            Report
          </NavLink>
          {/* {userData &&
          (userData.role.toLowerCase() === "managers" ||
            userData.role.toLowerCase() === "manager" ||
            userData.role.toLowerCase() === "super admin") ? (
            ""
          ) : (
            <Button
              className="navbars"
              variant="h6"
              noWrap
              aria-controls="more-menu"
              aria-haspopup="true"
              onClick={menuMoreClick}
            >
              More
            </Button>
          )} */}

          <div className="grow" />
          <div className="sectionDesktop">
            <Button
              className="add_task_btn"
              variant="contained"
              color="primary"
              onClick={this.handleClickOpenTaskmodal}
            >
              Task
            </Button>

            <IconButton className="add_task_btn_mobile" onClick={this.handleClickOpenTaskmodal}>+</IconButton>
            <IconButton 
              aria-haspopup="true"
              aria-label="show 17 new notifications"
              aria-controls="primary-search-account-menu-notify"
              color="inherit"
              onClick={this.handleNotificationMenuOpen}
              >
              <Badge badgeContent={this.state.notifyMarkCount} color="secondary">
                <img src={notificationIcon}></img>
              </Badge>
            </IconButton>
            <IconButton className="mobile-menu-icon" onClick={this.handleClickOpen}>
              <img src={mobileMenuIcon}></img>
            </IconButton>
            <IconButton
              edge="start"
              aria-label="account of current user"
              aria-controls="primary-search-account-menu"
              aria-haspopup="true"
              onClick={this.handleProfileMenuOpen}
              color="inherit"
              className="user_icon user_profile"
            >
              <img src={profileImg} className="profile-pic"></img>
            </IconButton>
          </div>
        </Toolbar>
      </AppBar>
      {renderMobileMenu}
      {renderMenu}
      {renderMoreMenu}
      {renderNotificationMenu}
    </div>
  );
}
}

export default withRouter(HeaderComponent);
