import React from "react";
import notification_img from "../../../Assets/images/notification-icon.png";
import notification_blue_img from "../../../Assets/images/notification-icon-blue.png";
import passoword_img from "../../../Assets/images/password-icon.png";
import passoword_blue_img from "../../../Assets/images/password-icon-blue.png";
import profile_img from "../../../Assets/images/manage-profile.png";
import profile_blue_img from "../../../Assets/images/manage-profile-blue.png";
import TimeZoneBlue from "../../../Assets/images/time-zone.svg";
import TimeZoneGrey from "../../../Assets/images/time-zone-grey.svg";

const SideBarComponent = (props) => {
  return (
    <ul className="billing_list">
      <li className="billing_list_item" onClick={() => props.changeSideBar(1)}>
        <a className={`configure_btn ${props.compNo === 1 ? "active" : "not_active"}`}>
          <span className="manage-profile-images">
            <img src={profile_img} className="grey-img" alt="profile-img"/>{" "}
            <img src={profile_blue_img} className="blue-img" alt="profile-img"/>{" "}
          </span>
          Manage Profile
        </a>
      </li>

      <li className="billing_list_item" onClick={() => props.changeSideBar(2)}>
        <a className={`configure_btn ${props.compNo === 2 ? "active" : ""}`}>
        <span className="passoword-images">
            <img src={passoword_img} className="grey-img" alt="passoword-img"/>{" "}
            <img src={passoword_blue_img} className="blue-img" alt="passoword-img"/>{" "}
          </span>
          Password & Security
        </a>
      </li>
      <li className="billing_list_item" onClick={() => props.changeSideBar(3)}>
        <a className={`configure_btn ${props.compNo === 3 ? "active" : ""}`}>
        <span className="notication-images">
            <img src={notification_img} className="grey-img" alt="notification-img"/>{" "}
            <img src={notification_blue_img} className="blue-img" alt="notification-img"/>{" "}
          </span>
          Notifications
        </a>
      </li>
      <li className="billing_list_item" onClick={() => props.changeSideBar(4)}>
        <a className={`configure_btn ${props.compNo === 4 ? "active" : ""}`}>
        <span className="notication-images">
            <img src={TimeZoneGrey} className="grey-img" alt="notification-img"/>{" "}
            <img src={TimeZoneBlue} className="blue-img" alt="notification-img"/>{" "}
          </span>
          Time Zone
        </a>
      </li>
    </ul>
  );
};

export default SideBarComponent;
