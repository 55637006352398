import React, { Component } from "react";
import {
  Container,
  Grid,
  Card,
  TextField,
  InputLabel,
  Button,
} from "@material-ui/core";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import logo from "../../Assets/images/Sales_C2_logo.svg";
import upload_img from "../../Assets/images/upload_img.png";
// import logo2 from "../../Assets/images/logo_icon_signup.svg";
import logo2 from "../../Assets/images/Group 160.svg";
import dropzoneLogo from "../../Assets/images/dropzone-picture.svg";
import InputAdornment from "@material-ui/core/InputAdornment";
import eyeIcon from "../../Assets/images/eye-icon.svg";
import eyeCloseIcon from "../../Assets/images/eye-close-icon.svg";
import {
  formValidation,
} from "../../SharedComponents/Validation/formValidation";
import {  withRouter } from "react-router-dom";
import { AxiosInstance } from "../../Utilities";
import Dropzone from "react-dropzone";
import { Alert } from "@material-ui/lab";
import {
  createStyles,
  fade,
  Theme,
  withStyles,
  makeStyles,
  createMuiTheme,
} from "@material-ui/core/styles";
import "./SignupComponent.css";
import {Tooltip} from 'antd';
import PageNotFoundComponent from "../PageNotFoundComponent/PageNotFoundComponent";
const CssTextField = withStyles({
  root: {
    backgroundColor: "#F2F2F2",
    borderRadius: "5px",
    marginBottom: "20px",
    "& .MuiInput-underline:after": {
      borderBottomColor: "#14193A",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#E2E5F2",
        borderWidth: 0,
      },

      "&.Mui-focused fieldset": {
        borderColor: "#14193A",
        borderWidth: 0,
      },
    },
  },
})(TextField);

class Signup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      employeeName: "Sharley J",
      userPassword: "",
      notValidUser:false,
      imageFiles: [],
      img_upload:"",
      userLogo: "",
      formError: {
        userPassword: "",
        imageFiles:'',
      },
      responseMsg: "",
      alertType: "",
      hidden: true,
    };
    this.handleChange = this.handleChange.bind(this);
    this.fileHandle = this.fileHandle.bind(this);
  }

  onhandledrop(imageFiles) {
    this.setState({
      imageFiles: imageFiles[0],
    });
  }

  fileHandle = (event) => {
    const {formError} = this.state;
    if(!event.target.files[0].name.match(/\.(jpg|jpeg|png|svg)$/)){
      formError.imageFiles = 'Upload only jpg,jpeg,png,svg images';
      this.setState({
        imageFiles:[],
        formError,
       
      });
    }else{
      formError.imageFiles = '';
      this.setState({
        imageFiles: event.target.files[0],
        formError,
        img_upload:"img_upload"
      });
    }
    
  };
  componentDidMount = () => {
    const { params } = this.props.match;
    let { id } = params;
    AxiosInstance((process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa")
    ? 4002
    : "clinical-login","application/json")
      .get(`/employee/${id}`)
      .then((resp) => {
        var response= resp && resp.data;
        if ( response && (response.status === "200" || response.status === 200)) {
          this.setState({ employeeName: response.data.name });
        }else if(response && (response.status === 400 || response.status === "400" || response.status === 404 || response.status === "404")){
          this.setState({
            notValidUser:true
          })
        }
      }).catch(err=>{
        console.log("Username not found")
      })
  };

  handleChange(event) {
    event.preventDefault();
    const {formError}=this.state;
    const {name,value}=event.target;

    switch(name){
      case 'userPassword': 
        value.trim().length > 0?formError.userPassword = "":formError.userPassword="Please enter a password";
        break;
      default:
        break;
    }
    this.setState({ [name]: value,formError });
  }
  goTologin() {
    window.location.href = "/";
  }

  checksignup = async (e) => {
    e.preventDefault();
    const { formError, userPassword, imageFiles } = this.state;
    if (userPassword === "") {
      formError.userPassword = "Please enter a password";
    } else {
      formError.userPassword = "";
    }
    // if(imageFiles.length === 0){
    //   formError.imageFiles = "Please upload profile image";
    // }else{
    //   formError.imageFiles = "";
    // }
    this.setState({ formError, loginError: "" }, () => {
      console.log("formError", formError);
    });
    if (formValidation(this.state)) {
      let headers = {};
      let FileData = new FormData();
      FileData.append("profilePhoto", this.state.imageFiles);
      FileData.append("password", this.state.userPassword);
      let data = {};
      data.password = this.state.userPassword;
      data.profilePhoto = FileData.entries();
      const { params } = this.props.match;
      let { id } = params;
      AxiosInstance( (process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa")
              ? 4003
              : "clinical-user", "application/json")
        .put(`employee/${id}`, FileData)
        .then((resp) => {
          if (resp.data.status === "204") {
            this.setState(
              { responseMsg: resp.data.msg, alertType: "success" },
              () => {
                setTimeout(() => {
                  window.location.href = "/";
                }, 2000);
              }
            );

            console.log("success");
          } else {
            this.setState({ responseMsg: resp.data.msg, alertType: "error" });
            //this.setState({errorMsg:resp.data.email})
            console.log("error");
            //window.location.href = "/transaction";
          }
        });
    } else {
      console.log("Login Fail", this.state);
    }
  };
  render() {
    let signupForm = (
      <div className="signup-body-bg-color signup-section">
        <div id="header-navbar">
          <AppBar position="static">
            
          </AppBar>
        </div>
        <Container fixed className="signup-body create-password-form">
          <Card className="clinic-signup-root">
            <Grid container>
              <Grid item xs={12} className="card-header">
                <div className="header-content">
                  <div className="signup-logo">
                    <img src={logo2} alt="Sales-Icon" height="auto"></img>
                    <div className="signup-logo-text"> 
                    <h3>ClinicalC2 offers the ultimate Customer Scheduling Management tool for Med Device Sales Teams.</h3>
                    </div>
                  </div>
                </div>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12}>
                <div className="register-form">
              <p className="register-form-header">Create your account to access ClinicalC2</p>
                <form onSubmit={this.checksignup} autoComplete="off">
                  {this.state.responseMsg != "" ? (
                    <Alert severity={this.state.alertType}>
                      {this.state.responseMsg}
                    </Alert>
                  ) : null}
                  <InputLabel className="loginLabel">
                    Upload Profile Image
                  </InputLabel>
                  <Dropzone
                    onDrop={this.onhandledrop.bind(this)}
                    accept={"image/*"}
                  >
                    {({ getRootProps, getInputProps }) => (
                      <section className= {"MuiDropzoneArea-root " + this.state.img_upload }  >
                        <div {...getRootProps()} className="dropzone-icon" tabindex="-1">
                          <input
                            {...getInputProps()}
                            onChange={this.fileHandle}
                          />
                          <img src={dropzoneLogo}></img>
                          <p className="dropzone-paragraph">
                            Drag your image here or <span>Browse</span>
                          </p>
                          <p className="dropzone-inst">
                          <img src={upload_img} alt="upload_img"></img>
                            { this.state.imageFiles.length===0 ? "Supports: JPG,JPEG,PNG & SVG" : this.state.imageFiles.name} 

                          </p>
                        </div>
                      </section>
                    )}
                  </Dropzone>
                  {this.state.formError.imageFiles ? <span style={{color:'red',fontSize:'14px'}}>{this.state.formError.imageFiles}</span> : ''}
                  <div className="form_input_field">
                  <InputLabel className="loginLabel">
                    Create Password <span className="label-star">*</span>
                  </InputLabel>
                  <CssTextField
                    id="outlined-basic-password"
                    type={this.state.hidden ? "password" : "text"}
                    name="userPassword"
                    placeholder="Enter a password"
                    className="inputHeight"
                    variant="outlined"
                    onChange={this.handleChange}
                    value={this.state.userPassword}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment
                          position="end"
                          style={{ cursor: "pointer" }}
                          onClick={() =>
                            this.setState({ hidden: !this.state.hidden })
                          }
                        >
                          <Tooltip title={this.state.hidden?"Show Password":"Hide Password"} placement="bottom">
                            <img src={this.state.hidden ?eyeIcon:eyeCloseIcon } alt="eye"/>
                            </Tooltip>
                        </InputAdornment>
                      ),
                    }}
                  />
                  {this.state.formError.userPassword ? (
                    <span className="error">
                      {this.state.formError.userPassword}
                    </span>
                  ) : (
                    ""
                  )}
                  </div>
                  {/* <div className="buttonGroup"> */}
                  <Grid item xs={12} sm={12}>
                    {/* <Button
                      className="cancelBtn cancel-button"
                      onClick={this.goTologin}
                      variant="contained"
                      color="primary"
                    >
                      Cancel
                    </Button>
                    <Button
                      className="signupBtn success-button"
                      type="submit"
                      variant="contained"
                      color="primary"
                    >
                      Join SalesC2
                    </Button> */}
                    {/* <br/> */}
                    <Button
                      className="LoginBtn"
                      type="submit"
                      variant="contained"
                      color="primary"
                      // onClick={this.onclickBtn}
                    >
                      Register
                    </Button>
                  </Grid>
                </form>
                </div>
              </Grid>
            </Grid>
          </Card>
        </Container>
      </div>
    );
    if(this.state.notValidUser){
      return <PageNotFoundComponent/>;
    }else{
      return <div>{signupForm}</div>;
    }
  }
}

export default withRouter(Signup);
