import * as actionType from "./ActionTypes";
import { AxiosInstance } from "../../Utilities";
/**
 * pagination actions
 */
const paginationRequest = () => {
  return {
    type: actionType.PAGINATION_REQUEST,
  };
};
const paginationSuccess = (pager, pageOfItems,responseData) => {
  return {
    type: actionType.PAGINATION_SUCCESS,
    pager: pager,
    pageOfItems: pageOfItems,
    responseData: responseData,
  };
};
const paginationFailure = (error) => {
  return {
    type: actionType.PAGINATION_FAILURE,
    error: error,
  };
};
/**
 * For Account
 */
export const taskpagination = (pageNo, pageSize) => {
  const pageData = {
    nextpage: pageNo,
    pagesize: pageSize
  };
  // console.log("PAGEDATA", pageData);
  let type = "/taskList";
  return accountapis(pageData, type, (process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa")
              ? 4004
              : "clinical-task");
};
export const onSearchBy = (value) => {
  let type = "/taskList";
  return accountapis(value, type,  (process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa")
              ? 4004
              : "clinical-task");
};

export const companyPagination =(pageNo, pageSize)=>{
  const pageData = {
    nextpage: pageNo,
    pagesize: pageSize
  };
  let type = "/company";
  return accountapis(pageData, type, (process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa")
              ? 4006
              : "clinical-company");
}
export const companySearch = (value) => {
  let type = "/company";
  return accountapis(value, type,  (process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa")
              ? 4006
              : "clinical-company");
};
const accountapis = (value, type, port) => {
  return (dispatch) => {
    dispatch(paginationRequest());
    AxiosInstance(port,"application/x-www-form-urlencoded; charset=UTF-8")
      .post(`${type}`, value)
      .then((response) => {
        // console.log("PAGINATION", response.data.data);
        if (response.status === 200) {
          dispatch(paginationSuccess(response.data.pager, response.data.data));
        } else {
          dispatch(paginationFailure(response.data.message));
        }
      })
      .catch(err => {
        var error = err.response;
        if (error && (error.status === '403' || error.status === 403)) {
          localStorage.clear();
          setTimeout(() => {
            window.location.href = "/";
          }, 500)
        } else {
          console.log("Internal Server Error")
        }
      });
  };
};
/**
 * For User list
 */
export const userPagination =(pageNo, pageSize)=>{
  const pageData = {
    page: pageNo,
    pageSize: pageSize
  };
  let type = "/users";
  return userapis(pageData, type, (process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa")
              ? 4003
              : "clinical-user");
}
export const onuserfilter = (value) => {
  let type = "/users";
  return userapis(value, type,  (process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa")
              ? 4003
              : "clinical-user");
};
const userapis = (value, type, port) => {
  return (dispatch) => {
    dispatch(paginationRequest());
    AxiosInstance(port,"application/x-www-form-urlencoded; charset=UTF-8")
      .post(`${type}`, value)
      .then((response) => {
        // console.log("PAGINATION", response.data.data);
        if (response.status === 200) {
          dispatch(paginationSuccess(response.data.pager, response.data.data,response.data.pager));
        } else {
          dispatch(paginationFailure(response.data.message));
        }
      })
      .catch(err => {
        var error = err.response;
        if (error && (error.status === '403' || error.status === 403)) {
          localStorage.clear();
          setTimeout(() => {
            window.location.href = "/";
          }, 500)
        } else {
          console.log("Internal Server Error")
        }
      });
  };
};
