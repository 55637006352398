import React, { Component, Suspense } from "react";
import HeaderComponent from "../../SharedComponents/HeaderComponent/HeaderComponent";
import { withStyles } from '@material-ui/core/styles';
import * as action from "../../Store/Actions/index";
import {
    Box,
    Typography,
    Button,
    FormControl,
    InputLabel,
    CircularProgress,
    Select, TextField, InputAdornment, Tooltip,
    MenuItem, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableFooter, TablePagination
} from "@material-ui/core";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import jwt from "jsonwebtoken";
import AddUserModel from "./AddUserModel/AddUserModel";
import { AxiosInstance } from "../../Utilities";
import searchIcon from "../../Assets/images/search_icon.svg";
import EditUserModel from "./EditUserModel/EditUserModel";
import Loader from "react-loader-spinner";
import { Autocomplete } from "@material-ui/lab";
import iconNotificationSuccess from "../../Assets/images/icon-notify-success.svg";
import { NotificationComponent } from "../../SharedComponents/NotificationComponent/NotificationComponent";
import iconNotificationError from "../../Assets/images/icon-notify-error.svg";
import "./UsersComponent.css";
import Delete from "../../Assets/images/delete.svg";
import suspended from "../../Assets/images/user (5).svg";
import revsuspended from "../../Assets/images/user 5.svg";
import SuspendedModel from "./SuspendedModel/SuspendedModel";
import Edit from "../../Assets/images/view_edit_icon.svg";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import validator from "validator";
import { upperFirst } from "lodash";
import carret_down from "../../Assets/images/carret_down.svg";
import { TransitionGrow } from "../../SharedComponents/CommonUtilities";
import restoreuser from "../../Assets/images/Exclusion 1.svg";
import CustomPaginationComponent from "../../SharedComponents/CustomPaginationComponent/CustomPaginationComponent";
import { isSafari } from "react-device-detect";
var CustomRoleSelectComponent = React.lazy(() =>
    import("../../SharedComponents/CustomSelectComponent/CustomSelectComponent")
);
var CustomSelectComponent = React.lazy(() =>
    import("../../SharedComponents/CustomSelectComponent/CustomUserSelectComponent")
);
var CustomSelectWithSearchComponent = React.lazy(() =>
    import(
        "../../SharedComponents/CustomSelectWithSearchComponent/CustomSelectWithSearchComponent"
    )
);
const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(even)': {
            backgroundColor: "#F9F9F9",
        },
    },
}))(TableRow);
const CssTextField = withStyles({
    root: {
        '& .MuiInput-underline:after': {
            borderBottomColor: '#14193A',
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: '',
                borderWidth: 0
            },
            '&.Mui-focused fieldset': {
                borderColor: '',
                borderWidth: 0
            },
        },
    },
})(TextField, Select);
const LightTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: 'black',
        color: 'white',
        boxShadow: theme.shadows[1],
        fontSize: 13,
    },
}))(Tooltip);
class UserComponent extends Component {
    constructor(props) {
        super(props);
        let User = JSON.parse(localStorage.getItem("userdata"))
            ? JSON.parse(localStorage.getItem("userdata"))
            : "";
        this.state = {
            openModel: false,
            showeditModal: false,
            loggedInRole: User.role,
            companyList: [],
            filters: {
                company: [],
                area: [],
                division: [],
                region: [],
                territory: [],
            },
            page: '1',
            pageNumber:1,
            rowsPerPage: '10',
            companyId: User.companyId,
            userId: '',
            showaddModal: false,
            companyFilter: '',
            divisionFilter: '',
            description: "",
            descriptioneror: {
                error: ""
            },
            areaFilter: '',
            assignedOption: [],
            delete_id: "",
            regionFilter: '',
            userfilter: "",
            UserStatusFilterName: "",
            UserStatusFilter: "",
            RoleStatusFilterName: "",
            RoleStatusFilter: "",
            territoryFilter: '',
            roleFilter: '',
            assignedId: "",
            assignedName: "",
            searchFilter: '',
            orderBy: 'name',
            searchAssigneeValue: "",
            suspendUser_id: "",
            order: 'asc',
            // showEditModal: false,
            loading: true,
            usersearch: "",
            departmentOption: [],
            rolefilter: "",
            departmentName: "",
            departmentId: "",
            isSubmit: false,
            assigneeConfig: {
                anchorElAssignee: null,
                config: {
                    id: "assignee_filter_select",
                },
            },
            userStatusConfig: {
                config: {
                    id: "status_filter_select",
                },
                anchorElStatus: null,
            },
            roleStatusConfig: {
                config: {
                    id: "status_filter_select",
                },
                anchorElStatus: null,
            },
            editId: "",
      editName: "",
            edituserName: "",
      editdepartmentId: "",
      role: "",
      email: "",
      phone: "",
      userName: "",
      editname: "",
      editemail: "",
      editrole: "",
      editphone: "",
      editdepartmentname: "",
      _id: "",
      responseMsg: "",
      alertType: "",
      formError: {
        companyId: "",
        levelId: "",
        phone: "",
        email: "",
        userName: "",
        role: "",
        department:"",
        level: "",
        createdBy: "",
      },
      tooltipprops:{}
        };
    }
    UNSAFE_componentWillMount(){
        let obj = {}
        if(isSafari){
          obj.open = false;
          this.setState({tooltipprops:obj})
        }
      }
    componentDidMount = () => {
        const { pageNumber, rowsPerPage, companyId, role, userId } = this.state;
        this.props.onPagination(pageNumber, rowsPerPage, companyId, role, userId);
        var data = {
            search: "",
        };
        AxiosInstance( (process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa")
              ? 4004
              : "clinical-task", "application/x-www-form-urlencoded; charset=UTF-8")
            .post("/departmentSearch", data).then((res) => {
                if (res.data.status === 200) {
                    this.setState({
                        departmentOption: res.data.data,
                    });
                } else {
                    this.setState({
                        departmentOption: [
                            {
                                id: "one",
                                name: "No data",
                            },
                        ],

                    });
                }
            });
        setTimeout(() => {
            this.setState({ loading: false });
        }, 2500);
    };
    // handleChangePage = (event, newPage) => {
    //     this.setState({ page: newPage + 1 },
    //         this.filter
    //     );
    // };

    handleCustomPaginagtionChange=(event,value)=>{
        this.setState({ loading: true });
        this.setState({ pageNumber: value },
            this.filter
        )
    }
    handleChangeRowsPerPage = (event) => {
        this.setState({ pageNumber: 1, rowsPerPage: +event.target.value },
            this.filter
        );
    };
    DepartmentFilter = async (e) => {
        var name = e.target.value;
        var data = {
            search: name,
        };
        AxiosInstance( (process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa")
              ? 4004
              : "clinical-task", "application/x-www-form-urlencoded; charset=UTF-8")
            .post("/departmentSearch", data).then((res) => {
                if (res.data.status === 200) {
                    this.setState({
                        departmentOption: res.data.data,
                    });
                } else {
                    this.setState({
                        departmentOption: [
                            {
                                id: "one",
                                name: "No data",
                            },
                        ],

                    });
                }
            });
        var { departmentOption, formError } = this.state;
        var data = await departmentOption.filter((item) => {
            return item.name === name;
        });
        data.map((departmentData) => {
            this.setState({
                departmentId: departmentData.id,
                departmentName: departmentData.name,
            });
        });
    };
    DepartmentSelectHandler = (e) => {
        var name = e.target.value;
        if (!name == "") {
            var { departmentOption } = this.state;
            var data = departmentOption.filter((item) => {
                return item.name === name;
            });
            data.map((departmentData) => {
                this.setState({
                    departmentId: departmentData._id, departmentName: departmentData.name,
                },
                    this.tablefilter
                );
            });
        }
    };
    DepartmentFilterAutoClose = (e) => {
        var name = e.target.value;
        if (typeof name === "undefined") {
            this.setState({ departmentId: "" },
                this.filter
            );
        }
    }
    handleRoleSelect = (event) => {
        this.setState({ [event.target.name]: event.target.value },
            this.tablefilter
        );
    }
    userSearch = (e) => {
        let name = e.target.value
        this.setState({ usersearch: name },
            this.filter
        )
    }
    filter = () => {
        this.setState({ loading: true });
        let data = {
            pageSize: this.state.rowsPerPage,
            page: this.state.pageNumber,
            searchText: this.state.usersearch,
            departmentId: this.state.assignedId,
            role: this.state.rolefilter,
            status: this.state.UserStatusFilter,
        }
        this.props.onFilter(data);
        setTimeout(() => {
            this.setState({ loading: false });
        }, 2000);
    }
    tablefilter = () => {
        this.setState({ loading: true });
        let data = {
            pageSize: this.state.rowsPerPage,
            page: 1,
            searchText: this.state.usersearch,
            departmentId: this.state.assignedId,
            role: this.state.RoleStatusFilter,
            status: this.state.UserStatusFilter,
        }
        this.props.onFilter(data);
        setTimeout(() => {
            this.setState({ loading: false });
        }, 2000);
    }
    onViewHandleClick = (row) => {
        this.setState({ suspendUser_id: row._id, showaddModal: true });
    };
    onCloseModal = () => {
        this.setState({
            showaddModal: false,
            openModel: false,
            showEditModal:false,
            responseMsg: "",
            alertType: "",
            description: "",
            descriptioneror: {
                error: "",
            },
            formError: {
                companyId: "",
                levelId: "",
                phone: "",
                email: "",
                userName: "",
                department:"",
                role: "",
                level: "",
                createdBy: "",
              },
        });
    };
    onDelClick = (row) => {
        this.setState({ delete_id: row._id, showdelete: true });
    };
    onDelete = () => {
        this.setState({ disable: true });
        AxiosInstance( (process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa")
              ? 4003
              : "clinical-user", "application/x-www-form-urlencoded; charset=UTF-8")
            .delete("/user/" + this.state.delete_id)
            .then((resp) => {
                var response = resp && resp.data;
                if (
                    response &&
                    (response.status === "200" || response.status === 200)
                ) {
                    this.setState(
                        {
                            loading: true,
                            showdelete: false,
                            disable: false,
                        },
                        () => {
                            var props = {
                                message: 'User deleted successfully',
                                icon: <img src={iconNotificationSuccess} alt="success" />,
                                type: "success",
                                placement: "bottomRight",
                                duration: 3,
                                top: 200,
                                className: "task_notification update-notify",
                            };
                            NotificationComponent(props);
                        }
                    );
                    this.tablefilter();
                } else {
                    this.setState({ disable: false });
                    var props = {
                        message: response.msg,
                        icon: <img src={iconNotificationError} alt="error" />,
                        type: "error",
                        placement: "bottomRight",
                        duration: 3,
                        top: 200,
                        className: "task_notification error-notify",
                    };
                    NotificationComponent(props);
                    this.tablefilter();
                }
            });
    };
    adddeshandleChange = (e) => {
        var { descriptioneror } = this.state;
        this.setState({ [e.target.name]: e.target.value });
        if (!/\S\w*/.test(e.target.value)) {
            descriptioneror.error =
                "* Please enter note ";
        } else if (e.target.value.length > 250) {
            descriptioneror.error =
                "* note  length should not be max 250 characters";
        } else {
            descriptioneror.error = "";
        }
    };
    onSuspSubmit = (e) => {
        var { descriptioneror } = this.state;
        if (this.state.description === "") {
            descriptioneror.error = "* Please enter note ";
        }
        this.setState(
            {
                descriptioneror,
            },
            () => {
                const isEmpty = Object.values(descriptioneror).every(
                    (x) => x === null || x === ""
                );
                if (isEmpty || descriptioneror.error === "") {
                    this.setState({ isSubmit: true });
                    var data = {
                        userId: this.state.suspendUser_id,
                        notes: this.state.description,
                    };
                    AxiosInstance( (process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa")
              ? 4003
              : "clinical-user", "application/json")
                        .post("/user/suspend", data)
                        .then((res) => {
                            if (res.data.status === "200") {
                                this.setState(
                                    {
                                        isSubmit: false,
                                    },
                                    () => {
                                        var props = {
                                            message: res.data.msg,
                                            icon: <img src={iconNotificationSuccess} alt="success" />,
                                            type: "success",
                                            placement: "bottomRight",
                                            duration: 3,
                                            top: 200,
                                            className: "task_notification update-notify",
                                        };
                                        NotificationComponent(props);
                                    }
                                );
                                this.onCloseModal();
                                this.componentDidMount();
                            } else {
                                this.setState({ isSubmit: false });
                                var props = {
                                    message: res.data.msg,
                                    icon: <img src={iconNotificationError} alt="error" />,
                                    type: "error",
                                    placement: "bottomRight",
                                    duration: 3,
                                    top: 200,
                                    className: "task_notification error-notify",
                                };
                                NotificationComponent(props);
                                this.componentDidMount();
                            }
                        });
                }
            }
        );
    };
    onRevokeHandleClick = (row) => {
        AxiosInstance( (process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa")
              ? 4003
              : "clinical-user", "application/json")
            .get("/user/unsuspend/" + row._id)
            .then((res) => {
                if (res.data.status === "200"||res.data.status === 200) {
                    this.setState(
                        {
                            isSubmit: false,
                        },
                        () => {
                            var props = {
                                message: "The employee is active now!",
                                icon: <img src={iconNotificationSuccess} alt="success" />,
                                type: "success",
                                placement: "bottomRight",
                                duration: 3,
                                top: 200,
                                className: "task_notification update-notify",
                            };
                            NotificationComponent(props);
                        }
                    );
                    this.onCloseModal();
                 this.tablefilter()
                } else {
                    this.setState({ isSubmit: false });
                    var props = {
                        message: res.data.msg,
                        icon: <img src={iconNotificationError} alt="error" />,
                        type: "error",
                        placement: "bottomRight",
                        duration: 3,
                        top: 200,
                        className: "task_notification error-notify",
                    };
                    NotificationComponent(props);
                    this.tablefilter()
                }
            });
    }
    /**
  *
  * TO HANDLE STATUS MENU OPEN
  */
    openStatusFilterMenu = (event) => {
        const { userStatusConfig } = this.state;
        userStatusConfig.anchorElStatus = event.currentTarget;
        this.setState({
            userStatusConfig,
        });
    };
    /**
     *
     * @param {item object} item
     * TO ADD STATUS FILTER VALUE ON SELECT
     */
    handleStatusSelect = (item) => {
        var { UserStatusFilter, UserStatusFilterName, userStatusConfig } = this.state;
        UserStatusFilter = item._id;
        UserStatusFilterName = item.name;
        userStatusConfig.anchorElStatus = null;
        this.setState(
            {
                userStatusConfig,
                UserStatusFilterName,
                UserStatusFilter,
                pageNumber: 1,
            },
            () => {
                this.tablefilter();
            }
        );
    };
    /**
     *
     * TO HANDLE STATUS MENU CLOSE
     */
    closeStatusFilterMenu = () => {
        const { userStatusConfig } = this.state;
        userStatusConfig.anchorElStatus = null;
        this.setState({
            userStatusConfig,
        });
    };
    /**
    *
    * TO HANDLE STATUS MENU OPEN
    */
    openRoleFilterMenu = (event) => {
        const { roleStatusConfig } = this.state;
        roleStatusConfig.anchorElStatus = event.currentTarget;
        this.setState({
            roleStatusConfig,
        });
    };
    /**
     *
     * @param {item object} item
     * TO ADD STATUS FILTER VALUE ON SELECT
     */
    handleRoleStatusSelect = (item) => {
        var { RoleStatusFilter, RoleStatusFilterName, roleStatusConfig } = this.state;
        RoleStatusFilter = item._id;
        RoleStatusFilterName = item.name;
        roleStatusConfig.anchorElStatus = null;
        this.setState(
            {
                roleStatusConfig,
                RoleStatusFilterName,
                RoleStatusFilter,
                pageNumber: 1,

            },
            () => {
                this.tablefilter();
            }
        );
    };
    /**
     *
     * TO HANDLE STATUS MENU CLOSE
     */
    closeStatusRoleFilterMenu = () => {
        const { roleStatusConfig } = this.state;
        roleStatusConfig.anchorElStatus = null;
        this.setState({
            roleStatusConfig,
        });
    };
    /**
   *
   * TO HANDLE ASSIGNEE MENU OPEN
   */
    openAssigneeFilterMenu = (event) => {
        const { assigneeConfig } = this.state;
        assigneeConfig.anchorElAssignee = event.currentTarget;
        this.setState(
            {
                assigneeConfig,
            },
            () => {
                this.employeeFilter("");
            }
        );
    };

    /**
     *
     * @param {value} search
     * TO FETCH THE EMPLOYEE FILTER
     */
    employeeFilter = (search) => {
        const { assignedOption } = this.state;

        AxiosInstance( (process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa")
              ? 4004
              : "clinical-task", "application/x-www-form-urlencoded; charset=UTF-8")
            .post("/departmentSearch", { search })
            .then((res) => {
                var response = res.data;
                if (
                    response &&
                    (response.status === 200 || response.status === "200")
                ) {
                    var data = response.data;
                    var newArray = assignedOption.splice();
                    if (this.state.searchAssigneeValue === "") {
                        newArray.unshift({ _id: "", name: "All" });
                    } else {
                        newArray.shift({ _id: "", name: "All" });
                    }
                    if (data.length > 0) {
                        data.map((item) => {
                            var obj = {};
                            obj._id = item._id;
                            obj.name = item.name;
                            newArray.push(obj);
                        });
                    }
                    this.setState({
                        assignedOption: newArray,
                    });
                } else {
                    this.setState({
                        assignedOption: [
                            {
                                _id: "",
                                name: "All",
                            },
                        ],
                    });
                }
            })
            .catch((err) => {
                var error = err.response;
                if (error && (error.status === "403" || error.status === 403)) {
                    localStorage.clear();
                    setTimeout(() => {
                        window.location.href = "/";
                    }, 500);
                } else {
                    console.log("Internal Server Error");
                }
            });
    };
    /**
     *
     * @param {item object} item
     * TO ADD ASSIGNEE FILTER VALUE ON SELECT
     */
    handleAssigneeSelect = (item) => {
        const { assigneeConfig } = this.state;
        assigneeConfig.anchorElAssignee = null;

        this.setState(
            {
                assignedId: item._id,
                assignedName: item.name === "All" ? "" : item.name,
                assigneeConfig,
                searchAssigneeValue: "",
                pageNumber: 1,
            },
            () => {
                this.tablefilter();
            }
        );
    };
    /**
    *
    * TO HANDLE ASSIGNEE MENU CLOSE
    */
    closeAssigneeFilterMenu = () => {
        const { assigneeConfig } = this.state;
        assigneeConfig.anchorElAssignee = null;
        this.setState({
            assigneeConfig,
        });
    };
    /**
     *
     * @param {item object} item
     * TO HANDLE ASSIGNEE FILTER VALUE ON SEARCH
     */
    onAssigneeSearchFilter = (e) => {
        const { name, value } = e.target;
        this.setState({
            searchAssigneeValue: value,
        });
        this.employeeFilter(value);
    };

  editUserHandler = (e) => {
    e.preventDefault();
    var {
      formError,
      edituserName,
      editrole,
      editemail,
      editphone,
      editdepartmentId
    } = this.state;
    if (editrole === "") {
      formError.role = "Please select role";
    } 
    if (this.state.editrole === "Scheduler") {
    if(editdepartmentId === ""){
        formError.department = "Please select a department";
    }
    }
    if (editemail === "") {
      formError.email = "Please enter an Email address";
    } 
    if (editphone === "") {
      formError.phone = "Please enter phone number";
    } 
    if (edituserName === "") {
      formError.userName = "Please enter the Name";
    } 
    this.setState({ formError }, () => {
        let userData = "";
    if (this.state.editrole === "Scheduler") {
       userData = {
        name: edituserName,
        email: editemail,
        role: editrole,
        phone: editphone,
        userId: this.state._id,
        departmentId: this.state.editdepartmentId,
      } ;
    }else {
        userData = {
            name: edituserName,
            email: editemail,
            role: editrole,
            phone: editphone,
            userId: this.state._id
        };
    }
      const isEmpty = Object.values(formError).every(
        (x) => x === null || x === ""
      );
      if (formError.userName === ""  && formError.phone === "" && formError.role === "" && formError.department === "" ) {
        this.setState({ isLoading: true });
        AxiosInstance( (process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa")
              ? 4003
              : "clinical-user", "application/x-www-form-urlencoded; charset=UTF-8")
          .put("/users", userData)
          .then((res) => {
            var response = res.data;
            if (
              response &&
              (response.status === "403" || response.status === 403)
            ) {
              this.setState({
                responseMsg: response.msg,
                alertType: "error",
                isLoading: false,
                isSubmit: false,
              });
            } else if (
              response &&
              (response.status === "200" || response.status === 200)
            ) {
              this.setState(
                {
                  responseMsg: response.msg,
                  alertType: "success",
                  isLoading: false,
                  isSubmit: false,
                }
                // ,
                // () => {
                //   let data = {
                //     accountId: this.state.accountId,
                //   };
                //   this.fetchAccountDetail(data);
                // }
              );
              setTimeout(() => {
                  this.onCloseModal();
                  this.componentDidMount();
                // window.location.href = "/user";
            }, 1000);
            } else if (
              response &&
              (response.status === "400" || response.status === 400)
            ) {
              this.setState({
                responseMsg: response.msg,
                alertType: "error",
                isLoading: false,
                isSubmit: false,
              });
            }
          })
          .catch((err) => {
            var error = err.response;
            if (error && (error.status === "403" || error.status === 403)) {
              localStorage.clear();
              setTimeout(() => {
                window.location.href = "/";
              }, 500);
            } else {
              console.log("Internal Server Error");
            }
            this.setState({
              isLoading: false,
            });
          });
      }
    });
  };
   onEditViewHandler = (row) => {
    this.setState({ _id: row._id,edituserName:row.name,editphone:row.phone,editemail:row.email,editrole:row.role,editdepartmentId:row.department_details.length === 0 ? "": row.department_details[0]._id,editdepartmentname:row.department_details.length === 0 ? "":row.department_details[0].name, showEditModal: true });
   };
   restoreUser = (row) => {
    var data = {
      userId: row._id,
    };
    AxiosInstance( (process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa")
              ? 4003
              : "clinical-user", "application/json")
      .put("/user/restore", data)
      .then((res) => {
        if (res.data.status === "200") {
          this.setState(
            {
              isSubmit: false,
            },
            () => {
              var props = {
                message: res.data.msg,
                icon: <img src={iconNotificationSuccess} alt="success" />,
                type: "success",
                placement: "bottomRight",
                duration: 3,
                top: 200,
                className: "task_notification update-notify",
              };
              NotificationComponent(props);
            }
          );
          this.tablefilter();
        } else {
          this.setState({ isSubmit: false });
          var props = {
            message: res.data.msg,
            icon: <img src={iconNotificationError} alt="error" />,
            type: "error",
            placement: "bottomRight",
            duration: 3,
            top: 200,
            className: "task_notification error-notify",
          };
          NotificationComponent(props);
          this.tablefilter();
        }
      });
  };

  onEditChangeHandler = (e) => {
    var phoneno = /^(\+\d{1,2}\s?)?1?-?\.?\s?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;
    var { name, value } = e.target;
    this.setState({ [name]: value });
    var { formError } = this.state;
    if (this.state.editrole !== "Scheduler") {
      this.setState({
        editdepartmentId: "",
      });
    }
    switch (name) {
      case "editemail":
        validator.isEmpty(value)
          ? (formError.email = "Please enter an Email address")
          : !validator.isEmail(value)
          ? (formError.email =
              "Please check the email address is in valid format e.g. name@domain.com")
          : (formError.email = "");
        break;
      case "editphone":
        validator.isEmpty(value)
          ? (formError.phone = "Please enter phone number")
          : !phoneno.test(value)
          ? (formError.phone =
              "Please enter the valid phone number e.g.: 800-555-5555 ")
          : (formError.phone = "");
        break;
      case "edituserName":
        validator.isEmpty(value) 
        ? (formError.userName = "Please enter the Name of the person")
        :!/\S\w*/.test(value)
              ? (formError.userName = "Please enter the Name of the person") 
        : !/^[a-zA-Z,'. ]*$/.test(value)
          ? (formError.userName = "Special characters are not allowed")
          : !validator.isLength(value, { min: 2, max: 50 })
          ? (formError.userName =
              "Name length should be min 2 and max of 50 characters")
          : (formError.userName = "");
        break;
      case "editrole":
        validator.isEmpty(value)
          ? (formError.role = "Please select role")
          : (formError.role = "");
        break;
        case "editdepartmentId":
            validator.isEmpty(value)
          ? (formError.department = "Please select a department")
          : (formError.department = "");
        break;
      default:
        break;
    }
    this.setState({
      formError,
    });
  };
    render() {
        let handleClose = () => this.setState({ showdelete: false });
        let { loading } = this.state;
        var { loggedInRole } = this.state;
        var options = [{ _id: "Account Admin", name: "Account Admin" },
        { _id: "Master Scheduler", name: "Master Scheduler" },
        { _id: "Scheduler", name: "Scheduler" },
        ];
        var statusOptions = [{
            _id: "all", name: "All"
        },
        { _id: "inactive ", name: "Suspended" },
        { _id:"deleted", name:"Deleted"}
        ]
        return (
            <div className="body-bg-color">
                <HeaderComponent />
                <Box component="span" className="company-comp">

                    <AddUserModel
                        openModel={this.state.openModel}
                        onClose={this.onCloseModal}
                    />
                    <EditUserModel
                        modaldata={this.state}
                        open={this.state.showEditModal}
                        onClose={this.onCloseModal}
                        editUserHandler={this.editUserHandler}           
                        onEditChangeHandler={this.onEditChangeHandler}
                    />
                    <SuspendedModel
                        onSuspSubmit={this.onSuspSubmit}
                        adddeshandleChange={this.adddeshandleChange}
                        modelData={this.state}
                        open={this.state.showaddModal}
                        handleClose={this.onCloseModal}
                    />
                    <Dialog open={this.state.showdelete} className="deleteServiceDialog" TransitionComponent={TransitionGrow} transitionDuration={600}>
                        <DialogTitle>
                            {" "}
                            <center> Delete User</center>{" "}
                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                <center>
                                    Are you sure you want to delete this User? Your action cannot
                                    be undone
                           </center>
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button className="cancel white-btn" onClick={handleClose} color="primary">
                                Cancel
                          </Button>
                            <Button
                                disabled={this.state.disable}
                                className="delete red-btn"
                                onClick={() => this.onDelete()}
                                style={{ color: "red" }}
                                autoFocus
                            >
                                {this.state.disable ? <CircularProgress size={20} /> : ""}{" "}
                          Delete
                       </Button>
                        </DialogActions>
                    </Dialog>
                    <div className="header-add-btn">
                        <Typography variant="h5" className="tableTitle" component="div">
                            Users
                       </Typography>
                        {loggedInRole.toLowerCase() === 'account admin' ? 
                        <Button
                            variant="contained"
                            className="tableaddBtn"
                            color="default"
                            style={{
                                backgrounColor: "#21BF73",
                                boxShadow: "none",
                                cursor:"pointer",
                            }}
                            
                            onClick={() => {
                                this.setState({ openModel: true });
                            }}
                        >
                            Add User
                         </Button>
                         :''}
                        {/* : ""} */}
                    </div>
                    <div style={{ maxWidth: "100%" }} className="User-Table Acc-Table">
                        <div className="filter-search">
                            <div className="new_custom_filters">
                              
                              {this.state.loggedInRole === "Scheduler" ? "":
                                <Suspense fallback={<div></div>}>
                                    <div
                                        className="new_custom_filter_div"
                                        onClick={this.openAssigneeFilterMenu}
                                    >
                                        {this.state.assignedName !== "" ? (
                                            ""
                                        ) : (
                                                <label className="leftName">Department: </label>
                                            )}
                                        <label
                                            aria-label="assignee_filter"
                                            aria-controls="assignee_filter_select"
                                            area-aria-haspopup="true"
                                            className="rightName"
                                        >
                                            {this.state.assignedName === ""
                                                ? `All`
                                                : upperFirst(this.state.assignedName)}
                                        </label>
                                        <img className="carret" src={carret_down} alt="carret" />
                                    </div>
                                    <CustomSelectWithSearchComponent
                                        filterArray={this.state.assignedOption}
                                        config={this.state.assigneeConfig.config}
                                        anchorEl={this.state.assigneeConfig.anchorElAssignee}
                                        searchValue={this.state.searchAssigneeValue}
                                        handleClick={this.handleAssigneeSelect}
                                        closeCustomPopup={this.closeAssigneeFilterMenu}
                                        onSearchFilter={this.onAssigneeSearchFilter}
                                        placeholder="Search Department"
                                    />
                                </Suspense>
                                        }
                                <Suspense fallback={<div></div>}>
                                    <div
                                        className="new_custom_filter_div"
                                        onClick={this.openRoleFilterMenu}
                                    >
                                        {this.state.RoleStatusFilterName !== "" ? (
                                            ""
                                        ) : (
                                                <label className="leftName">Role: </label>
                                            )}
                                        <label
                                            aria-label="status_filter"
                                            aria-controls="status_filter_select"
                                            area-aria-haspopup="true"
                                            className="rightName"
                                        >
                                            {this.state.RoleStatusFilterName === ""
                                                ? `All`
                                                : upperFirst(this.state.RoleStatusFilterName)}
                                        </label>
                                        <img className="carret" src={carret_down} alt="carret" />
                                    </div>
                                    <CustomRoleSelectComponent
                                        filterArray={options}
                                        config={this.state.roleStatusConfig.config}
                                        anchorEl={this.state.roleStatusConfig.anchorElStatus}
                                        handleClick={this.handleRoleStatusSelect}
                                        closeCustomPopup={this.closeStatusRoleFilterMenu}
                                    />
                                </Suspense>
                                {loggedInRole.toLowerCase() === 'account admin' ? 
                                <Suspense fallback={<div></div>}>

                                    <div
                                        className="new_custom_filter_div"
                                        onClick={this.openStatusFilterMenu}
                                    >
                                        {this.state.UserStatusFilterName !== "" ? (
                                            ""
                                        ) : (
                                                <label className="leftName">Status: </label>
                                            )}
                                        <label
                                            aria-label="status_filter"
                                            aria-controls="status_filter_select"
                                            area-aria-haspopup="true"
                                            className="rightName"
                                        >
                                            {this.state.UserStatusFilterName === ""
                                                ? `Active Users`
                                                : upperFirst(this.state.UserStatusFilterName)}
                                        </label>
                                        <img className="carret" src={carret_down} alt="carret" />
                                    </div>
                                    <CustomSelectComponent
                                        filterArray={statusOptions}
                                        config={this.state.userStatusConfig.config}
                                        anchorEl={this.state.userStatusConfig.anchorElStatus}
                                        handleClick={this.handleStatusSelect}
                                        closeCustomPopup={this.closeStatusFilterMenu}
                                    />
                                </Suspense> : ''}
                            </div>
                            <CssTextField id="outlined-basic" name='serviceName' variant="outlined" type="text" placeholder="Search Here" style={{
                                color: '#303960',
                                fontFamily: 'Roboto',
                                backgroundColor: '#F9F9F9',
                                opacity: 1
                            }} InputProps={{
                                startAdornment: <InputAdornment position="start" style={{ cursor: 'pointer' }}><img src={searchIcon} /></InputAdornment>,
                            }} onChange={this.userSearch} />
                        </div>
                        <div className="table_container">
                            <TableContainer >
                                <Table aria-label="customized table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell style={{ width: '5%' }}>#</TableCell>
                                            <TableCell style={{ width: '20%' }}>NAME</TableCell>
                                            <TableCell >PHONE</TableCell>
                                            <TableCell >EMAIL</TableCell>
                                            <TableCell >ROLE</TableCell>
                                            <TableCell >DEPARTMENT</TableCell>
                                            {loggedInRole.toLowerCase() == "super admin" ? <TableCell style={{ width: '15%' }}>COMPANY</TableCell> : ''}
                                            {loggedInRole.toLowerCase() === 'account admin' ? <TableCell >ACTIONS</TableCell> : ''}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {loading ?
                                            <StyledTableRow>
                                                <TableCell colSpan={9} style={{ textAlign: "center" }}>
                                                    <Loader
                                                        type="ThreeDots"
                                                        color="#00BFFF"
                                                        height={50}
                                                        width={50}
                                                        visible={loading}
                                                    />
                                                </TableCell>
                                            </StyledTableRow> :
                                            !this.props.pageOfItems || this.props.pageOfItems.length === 0 ?
                                                <StyledTableRow><TableCell colSpan={9} style={{ textAlign: 'center' }}>
                                                    No data found
                                             </TableCell></StyledTableRow> : (this.state.rowsPerPage > 0
                                                    ? this.props.pageOfItems.slice() : this.props.pageOfItems).map((row, index) => (
                                                        <StyledTableRow key={row._id}>
                                                            <TableCell>{(this.props.pager.currentPage - 1) * this.state.rowsPerPage + (index + 1)}</TableCell>
                                                             <TableCell >
                                                             <LightTooltip title={row.name ? row.name.charAt(0).toUpperCase() + row.name.slice(1) : 'N/A'} arrow>
                                                                  <span>{row.name ? row.name.charAt(0).toUpperCase() + row.name.slice(1) : 'N/A'}</span>
                                                                </LightTooltip>
                                                                 </TableCell>
                                                          
                                                            <TableCell >
                                                                <LightTooltip title={row.phone} {...this.state.tooltipprops} arrow>
                                                                    <a href={"tel:" + row.phone}>{row.phone}</a>
                                                                </LightTooltip>
                                                            </TableCell>
                                                            <TableCell >
                                                                <LightTooltip title={row.email} {...this.state.tooltipprops} arrow>
                                                                    <a href={"mailto:" + row.email}>{row.email}</a>
                                                                </LightTooltip>
                                                            </TableCell >
                                                            <TableCell >
                                                                
                                                            <LightTooltip title={row.role} {...this.state.tooltipprops} arrow>
                                                                  <span>{row.role}</span>
                                                                </LightTooltip>
                                                                </TableCell>
                                                            <TableCell >
                                                                {row.department_details.length === 0 ?
                                                                    '_ _' :
                                                                    <>
                                                                     <LightTooltip title={row.department_details[0]['name']} {...this.state.tooltipprops} arrow>
                                                                  <span>{row.department_details[0]['name']}</span>
                                                                </LightTooltip>
                                                                        
                                                                    </>
                                                                }
                                                            </TableCell>
                                                            {loggedInRole.toLowerCase() === 'account admin' ? 
                                                            <TableCell ><div className="table_action_btns" style={{ display: "flex", alignItems: "center", justifyContent: "left", }}>
                                                             {row.deleted === true ? (
                                                                        <div>
                                                                            <LightTooltip
                                                                                title="Restore User"
                                                                                {...this.state.tooltipprops}
                                                                                arrow
                                                                            >
                                                                                <a>
                                                                                <img
                                                                                    src={restoreuser}
                                                                                    alt="Restore User"
                                                                                    style={{ cursor: "pointer" }}
                                                                                onClick={() =>
                                                                                  this.restoreUser(row)
                                                                                }
                                                                                ></img>
                                                                                </a>
                                                                            </LightTooltip>
                                                                        </div>
                                                                    ) :
                            
                                                                    <div>
                                                                        {row.suspended === false ? (
                                                                            <LightTooltip
                                                                                title="Suspend User"
                                                                                {...this.state.tooltipprops}
                                                                                arrow
                                                                            >
                                                                                <a>
                                                                                <img
                                                                                    src={suspended}
                                                                                    alt="Suspend User"
                                                                                    style={{ cursor: "pointer" }}
                                                                                    onClick={() =>
                                                                                        this.onViewHandleClick(row)
                                                                                    }
                                                                                ></img>
                                                                                </a>
                                                                            </LightTooltip>

                                                                        ) : (

                                                                                <LightTooltip
                                                                                    title="Revoke Suspension"
                                                                                    {...this.state.tooltipprops}
                                                                                    arrow
                                                                                >
                                                                                    <a>
                                                                                    <img
                                                                                        src={revsuspended}
                                                                                        alt="Revoke Suspension"
                                                                                        style={{ cursor: "pointer" }}
                                                                                        onClick={() =>
                                                                                            this.onRevokeHandleClick(row)
                                                                                        }
                                                                                    ></img>
                                                                                    </a>
                                                                                </LightTooltip>

                                                                            )}
                                                                        <LightTooltip title="Delete User" {...this.state.tooltipprops} arrow>
                                                                           <a>
                                                                            <img
                                                                                src={Delete}
                                                                                alt="openmenu"
                                                                                style={{ cursor: "pointer" }}
                                                                                onClick={() => this.onDelClick(row)}
                                                                            ></img>
                                                                            </a>
                                                                        </LightTooltip>
                                                                        
                                                                        <LightTooltip title="Edit User" {...this.state.tooltipprops} arrow>
                                                                           <a>
                                                                            <img
                                                                                src={Edit}
                                                                                alt="openmenu"
                                                                                style={{ cursor: "pointer" }}
                                                                                onClick={() => this.onEditViewHandler(row)}
                                                                            ></img>
                                                                            </a>
                                                                        </LightTooltip>
                                                                    </div>
                                                             }
                                                            </div></TableCell> : ''}
                                                        </StyledTableRow>
                                                    ))}
                                    </TableBody>
                                    <TableFooter>
                                        {/* <TableRow>
                                            <TablePagination
                                                rowsPerPageOptions={[5, 10, 25]}
                                                colSpan={9}
                                                count={!this.props.pager ? 0 : this.props.pager.totalItems}
                                                rowsPerPage={this.state.rowsPerPage}
                                                page={!this.props.pager ? 0 : this.props.pager.currentPage - 1}
                                                labelDisplayedRows={({ from, to, count }) => `Showing ${!to ? 0 : to} of ${count === undefined ? 0 : count} entries`}
                                                SelectProps={{
                                                    inputProps: { 'aria-label': 'rows per page' },
                                                }}
                                                onChangePage={this.handleChangePage}
                                                onChangeRowsPerPage={this.handleChangeRowsPerPage}
                                            // ActionsComponent={TablePaginationActions}
                                            />
                                        </TableRow> */}

                                    {this.props.pageOfItems && this.props.pageOfItems.length !== 0?
                                        <TableRow>
                                            <td colSpan={20} className="pagination_container">
                                                <CustomPaginationComponent
                                                    count={this.props.pager.endPage}
                                                    rowsPerPage={this.state.rowsPerPage}
                                                    page={this.state.pageNumber}
                                                    onPageChange={this.handleCustomPaginagtionChange}
                                                    onChangeRowsPerPage={this.handleChangeRowsPerPage}
                                                />
                                            </td>
                                        </TableRow>
                                        :null}
                                    </TableFooter>
                                </Table>
                            </TableContainer>
                        </div>
                    </div>
                </Box >
            </div >
        );
    }
}
const mapStateToProps = (state) => {
    console.log("State", state);

    return {
        isAuthenticated: jwt.decode(localStorage.getItem("token")) ? false : true,
        pager: state.pagination.pager,
        pageOfItems: state.pagination.pageOfItems,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        onPagination: (pageNo, pagerole, companyId, role, userId) => dispatch(action.userPagination(pageNo, pagerole, companyId, role, userId)),
        onFilter: (states) => dispatch(action.onuserfilter(states)),
    };
};
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(UserComponent));

