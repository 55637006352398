import React from "react";
import {
    Button,
    TextField,
    Dialog,
    DialogContent,
    DialogTitle,
    CircularProgress,
} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import { withStyles } from "@material-ui/core/styles";
import { Alert } from "@material-ui/lab";
import closeBtn from "../../../Assets/images/close_btn.svg";
import { TransitionGrow } from "../../../SharedComponents/CommonUtilities";
const CssTextField = withStyles({
    root: {
        backgroundColor: "#F2F2F2",
        borderRadius: "5px",
        marginBottom: "15px",
        "& .MuiInput-underline:after": {
            borderBottomColor: "#14193A",
        },
        "& .MuiOutlinedInput-root": {
            "& fieldset": {
                borderColor: "#E2E5F2",
                borderWidth: 0,
            },

            "&.Mui-focused fieldset": {
                borderColor: "#14193A",
                borderWidth: 0,
            },
        },
    },
})(TextField);
const AddDepartmentModel = props => {
    return (
        <div>
            <Dialog {...props.modeldata}
                open={props.openModel}
                onClose={props.onCloseModal}
                aria-labelledby="form-dialog-title"
                maxWidth='md'
                className="add_company_dialog add_account_dialog add_product_category_dialog"
                TransitionComponent={TransitionGrow}
                transitionDuration={600}>
                <DialogTitle id="form-dialog-title" className="add_user_header">
                    <img className="add-company_img"></img>
                    <span> Add Department</span>
                    <IconButton onClick={props.onCloseModal} className="closeBtn" aria-label="show 17 new notifications" color="inherit">
                        <img src={closeBtn}></img>
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <form>
                        {props.modaldata.responseMsg != "" ? (<Alert severity={props.modaldata.alertType}>{props.modaldata.responseMsg}</Alert>) : null}
                        <label className="add_btn_labels label bl-label">Department Name </label>
                        <CssTextField name="deptName" variant="outlined" className="grey-input-field" fullWidth placeholder="Enter department name" onChange={props.handleAddChange} />
                        <div style={{ color: "red", fontSize: "14px" }}>{props.modaldata.formError.deptName !== '' ? <span className="modal-err-msg">{props.modaldata.formError.deptName}</span> : ''}</div>
                        <div className="add_company_action">
                            <Button className="cancel_btn white-btn" onClick={props.onCloseModal} variant="outlined">
                                Cancel
                            </Button>
                            <span style={{ cursor: 'not-allowed' }}>
                                <Button disabled={props.modaldata.deptName === "" ? true : false} className="ok_btn green-btn" variant="contained" onClick={props.addDept}>
                                    {props.modaldata.disable ? <CircularProgress size={20} /> : ''}Add
                                     </Button>
                            </span>
                        </div>
                    </form>
                </DialogContent>
            </Dialog>
        </div>
    );
}
export default AddDepartmentModel;