import { createStore, applyMiddleware, combineReducers } from "redux";
import LoginReducer from "./Reducers/LoginReducers";
import thunk from "redux-thunk";
import TableReducer from "./Reducers/TableReducer";

const logger = ({ getState }) => {
  return (next) => (action) => {
    // console.log("will dispatch", action);
    const returnValue = next(action);
    // console.log("state after dispatch", getState());
    return returnValue;
  };
};
const rootReducer = combineReducers({
  auth: LoginReducer,
  pagination: TableReducer,
});

const Store = createStore(rootReducer, applyMiddleware(logger, thunk));
export default Store;
 