import React, { Component } from 'react';
import { withStyles,TextField} from "@material-ui/core";
import { withRouter } from "react-router-dom";
import HeaderComponent from '../../SharedComponents/HeaderComponent/HeaderComponent';
import { NotificationComponent } from '../../SharedComponents/NotificationComponent/NotificationComponent';
import { passwordReg } from '../../SharedComponents/Validation/formValidation';
import { AxiosInstance } from '../../Utilities';
import PasswordAndSecurityComponent from './PasswordAndSecurityComponent/PasswordAndSecurityComponent';
import SideBarComponent from './SideBarComponent/SideBarComponent';
import UserProfileComponent from './UserProfileComponent/UserProfileComponent';
import iconNotificationError from "../../Assets/images/icon-notify-error.svg";
import iconNotificationSuccess from "../../Assets/images/icon-notify-success.svg";
import "./ManageAccountComponent.css";
import NotificationSettingComponent from './NotificationSettingComponent/NotificationSettingComponent';
import TimeZoneComponent from './TimeZoneComponent/TimeZoneComponent';
import momentTimeZone from 'moment-timezone';

class ManageAccountComponent extends Component {
    constructor(props) {
        super(props);
        var userData = localStorage.getItem("userdata")
        ? JSON.parse(localStorage.getItem("userdata"))
        : "";
        var tzNames = momentTimeZone.tz.names();
        this.state = { 
          tzNameArray:tzNames,
            role: userData.role,
            profileData: {},
            // loading: true,
            image: "",
            mnumber: false,
            eprofile: false,
            formError: "",
            editphone: "",
            hidePro: "",
            hidePhone: "showPhone",
            phone: "",
            isPhone:true,
            disablePhone:true,
            profilePic: "",
            file: null,
            responseMsg: "",
            alertType: "",
            invalidImage: "",
            isValid: !this.props.isValid,
            profilePath: "",
            loading: false,
            department_details:[],


            notificationSetting: {},
            compNo: 1,
            oldPassword:"",
            newPassword:"",
            confirmPassword:"",
            oldHidden:true,
            newHidden:true,
            confirmHidden:true,
            passwordSettingSubmit:false,
            changePasswordError:{
                oldPassword:"",
                newPassword:"",
                confirmPassword:""
            },
            tzList:tzNames,
            tzSelectName:""
         }
    }
    UNSAFE_componentWillMount=()=>{
      this.getTimeZones();
    }
    componentDidMount = () => {
        this.setState({ isValid: false });
        AxiosInstance( (process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa")
          ? 4003
          : "clinical-user", "application/x-www-form-urlencoded; charset=UTF-8")
          .get("/profile")
          .then((resp) => {
            let response = resp.data.data;

            if (response && (resp.data.status === "200" || resp.data.status === 200)) {
              this.setState({
                profileData: resp.data.data,
                phone: resp.data.data.phone,
                profilePath: resp.data.data.profilePath,
              });
              if(this.state.role === "Scheduler"){
                this.setState({
                department_details:resp.data.data.department_details[0].name
               })
              }
            }
          })
          .catch((err) => {
            var error = err.response;
            if (error && (error.status === "403" || error.status === 403)) {
              localStorage.clear();
              setTimeout(() => {
                window.location.href = "/";
              }, 500);
            } else {
            }
          });
      };
      getTimeZones=()=>{
        AxiosInstance((process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa") ? 4003 : "clinical-user","application/json")
        .get("/employee/timezone")
        .then(resp=>{
          var response = resp && resp.data;
          if(response && (response.status === "200" || response.status === 200)){
            this.setState({
              tzSelectName:response.data.timezone
            });
          }else if(response && (response.status === "400" || response.status === 400)){
    
          }
        }).catch(err => {
            var error = err.response;
            if (error && (error.status === '403' || error.status === 403)) {
              localStorage.clear();
              setTimeout(() => {
                window.location.href = "/";
              }, 500)
            } else if (error && (error.status === '500' || error.status === 500)) {
              var propsErr = {
                message: "Something went wrong",
                icon: <img src={iconNotificationError} alt="error" />,
                type: "error",
                placement: "topRight",
                duration: 2,
                top: 200,
                className: "task_notification error-notify",
              };
              NotificationComponent(propsErr);
            }
          });
      }
      onUpdateTimeZones=(value)=>{
        let data={
          timezone:value
        }
        AxiosInstance((process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa") ? 4003 : "clinical-user","application/json")
        .put("/employee/timezone",data)
        .then(resp=>{
          var response = resp && resp.data;
          if(response && (response.status === "200" || response.status === 200)){
            this.getTimeZones();
            this.setState({
              tzSelectName:value
            })
            var propsErr = {
              message: response.msg,
              icon: <img src={iconNotificationSuccess} alt="success" />,
              type: "success",
              placement: "topRight",
              duration: 2,
              top: 200,
              className: "task_notification success-notify",
            };
            NotificationComponent(propsErr);
          }else if(response && (response.status === "400" || response.status === 400)){
    
          }
        }).catch(err => {
            var error = err.response;
            if (error && (error.status === '403' || error.status === 403)) {
              localStorage.clear();
              setTimeout(() => {
                window.location.href = "/";
              }, 500)
            } else if (error && (error.status === '500' || error.status === 500)) {
              var propsErr = {
                message: "Something went wrong",
                icon: <img src={iconNotificationError} alt="error" />,
                type: "error",
                placement: "topRight",
                duration: 2,
                top: 200,
                className: "task_notification error-notify",
              };
              NotificationComponent(propsErr);
            }
          });
      }
      onTzFocus=()=>{
        this.setState({
         tzList:this.state.tzNameArray
       })
     }
     handleTzSearch=(value)=>{
       const {tzNameArray}=this.state;
       let tzNewArray = tzNameArray.filter((item) => {
         return item.toLowerCase().includes(value.toLowerCase());
       });
       if (tzNewArray.length > 0) {
         this.setState({
           tzList:tzNewArray
         });
       } else {
         this.setState({
           tzList:tzNameArray
         });
       }
     }
   
     onChangeTz=(value)=>{
       this.onUpdateTimeZones(value);
     }
      onProSubmit = (e) => {
        const { profilePic } = this.state;
        let data = "";
        if (profilePic !== null) {
          data = new FormData();
          data.append("profilePhoto", profilePic);
        }
        if (this.state.invalidImage === "") {
          AxiosInstance( (process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa")
          ? 4003
          : "clinical-user", "application/x-www-form-urlencoded; charset=UTF-8")
            .put("/profile", data)
            .then((resp) => {
              if (resp.data.status === 200) {
                this.setState({
                  eprofile: false,
                  responseMsg: "Updated successfully",
                  alertType: "success",
                  profilePath: resp.data.profilePath,
                });
                var userData = localStorage.getItem("userdata")
                  ? JSON.parse(localStorage.getItem("userdata"))
                  : "";
                userData.profilePath = resp.data.profilePath;
                var jsonData = JSON.stringify(userData);
                localStorage.setItem("userdata", jsonData);
                setTimeout(() => {
                  this.setState({ responseMsg: "" });
                }, 2000);
              } else {
                this.setState({ responseMsg: resp.data.msg, alertType: "error" });
              }
            })
            .catch((err) => {
              var error = err.response;
              if (error && (error.status === "403" || error.status === 403)) {
                localStorage.clear();
                setTimeout(() => {
                  window.location.href = "/";
                }, 500);
              } else {
              }
            });
        }
        setTimeout(() => {
          this.setState({ loading: false });
        }, 1000);
      };
      uplodProf = (e) => {
        this.setState({ loading: false });
        const proimage = e.target.files[0];
    
        if (!proimage.name.match(/\.(jpg|jpeg|png|gif)$/)) {
          this.setState({
            responseMsg: "Upload valid image in jpg, jpeg, png or gif format",
            alertType: "error",
            loading:false
          });
          return false;
        } else {
          this.setState(
            { profilePic: proimage, responseMsg: "", alertType: "" },
            this.onProSubmit
          );
        }
      };
      handlepPhoneChange = (e) => {
        this.setState({ editphone: e.target.value, disablePhone:false });
        var phoneno = /^(\+\d{1}\s?)?1?\-?\.?\s?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;
        if (e.target.value.length === 0) {
          this.setState({ formError: "*Please enter phone no." });
        } else if (e.target.value.match(phoneno)) {
          this.setState({ formError: "" });
        } else {
          this.setState({
            formError:
              "*Please enter the valid phone number e.g.: 800-555-5555 ",
          });
          return false;
        }
      };
      onSubmit = (e) => {
        e.preventDefault();
        var profile = {
          phone: this.state.editphone,
        };
        if (this.state.formError === "") {
          AxiosInstance( (process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa")
          ? 4003
          : "clinical-user", "application/x-www-form-urlencoded; charset=UTF-8")
            .put("/profile", profile)
            .then((resp) => {
              if (resp.data.status === 200) {
                this.setState({
                  formError: "",
                  responseMsg: "Updated successfully",
                  alertType: "success",
                  phone: this.state.editphone,
                  mnumber: false,
                  isPhone:true,
                  disablePhone:true
                });
                setTimeout(() => {
                  this.setState({ responseMsg: "" });
                }, 2000);
              } else {
                this.setState({
                  formError: "",
                  responseMsg: resp.data.msg,
                  alertType: "error"
                });
              }
            })
            .catch((err) => {
              var error = err.response;
              if (error && (error.status === "403" || error.status === 403)) {
                localStorage.clear();
                setTimeout(() => {
                  window.location.href = "/";
                }, 500);
              } else {
              }
            });
        }
      };
      hideMobEdit = () => {
        this.setState({ isValid: false, mnumber: false, formError: "",isPhone:true });
      };
      showMobEdit = () => {
        this.setState({
          responseMsg: "",
          alertType: "",
          invalidImage: "",
          editphone: this.state.phone,
          mnumber: true,
          isPhone:false,
        });
      };
      hideProEdit = () => {
        this.setState({ invalidImage: "" });
      };
      showProEdit = () => {
        this.setState({
          responseMsg: "",
          alertType: "",
          eprofile: true,
        });
      };
      showButton = (showvalue) => {
        switch (showvalue) {
          case "hidePro":
            this.setState({ hidePro: "avatar_input_file_note" })
            break;
          case "hidePhone":
            this.setState({ hidePhone: "" })
            break;
          default:
            break;
        }
      };
      hideButton = (hidevalue) => {
        switch (hidevalue) {
          case "hidePro":
            this.setState({ hidePro: "" })
            break;
          case "hidePhone":
            this.setState({ hidePhone: "showPhone" })
            break;
          default:
            break;
        }
      }

      /**
       * Benakesh code start
       */
    changeSideBar = (id) => {
       this.setState({
          compNo: id,
        },()=>{
          if(this.state.compNo === 2){
            const {changePasswordError}=this.state;
            changePasswordError.oldPassword="";
            changePasswordError.newPassword="";
            changePasswordError.confirmPassword="";
            this.setState({
              changePasswordError,
              oldPassword:"",
              newPassword:"",
              confirmPassword:"",
              oldHidden:true,
              newHidden:true,
              confirmHidden:true,
              passwordSettingSubmit:false
            })
          }else if(this.state.compNo === 3){
            this.fetchNotificationData();
          }
        });
      };
      fetchNotificationData =()=>{
        AxiosInstance((process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa") ? 4003 : "clinical-user","application/json")
        .get("/employee/notificationSettings")
        .then(resp=>{
          var response = resp && resp.data;
          if(response && (response.status === "200" || response.status === 200)){
            this.setState({
              notificationSetting:response.data
            })
          }
          // else if(response && (response.status === "400" || response.status === 400)){
    
          // }
        }).catch(err=>{
          var error=err.response;
                if(error && (error.status === '403' || error.status === 403)){
                    localStorage.clear();
                    setTimeout(()=>{
                        window.location.href="/";
                    },500)
                } else if(error && (error.status === '500' || error.status === 500)) {
                  var propsErr = {
                    message: "Something went wrong",
                    icon: <img src={iconNotificationError} alt="error" />,
                    type: "error",
                    placement: "topRight",
                    duration: 2,
                    top: 200,
                    className: "task_notification error-notify",
                  };
                  NotificationComponent(propsErr);
                }
        });
      }
      onIconChange=(field)=>{
        this.setState({
          [field]:!this.state[field]
        })
      }
      onPasswordChange=(e)=>{
        const {name,value} = e.target;
        const {changePasswordError}=this.state; 
    
        switch(name){
          case 'oldPassword': 
            value.trim().length>0 && value.trim() !== ""
            ?changePasswordError.oldPassword=""
            :changePasswordError.oldPassword="Please enter current password";
            break;
          case 'newPassword':
            value.trim() === ""
            ?changePasswordError.newPassword="Please enter new password"
            :!passwordReg.test(value.trim())
            ?changePasswordError.newPassword="Please enter valid password"
            :changePasswordError.newPassword="";
            break;
          case 'confirmPassword':
            value.trim() === ""
            ?changePasswordError.confirmPassword="Please confirm the password"
            :!passwordReg.test(value.trim())
            ?changePasswordError.confirmPassword="Please enter valid password"
            :changePasswordError.confirmPassword="";
            break;
          default:
            break;
        }
        this.setState({
          changePasswordError,
          [name]:value
        })
      }
      onPasswordCancelHandler=(e)=>{
        e.preventDefault();
        const {changePasswordError}=this.state;
        changePasswordError.oldPassword="";
        changePasswordError.newPassword="";
        changePasswordError.confirmPassword="";
        this.setState({
          changePasswordError,
          oldPassword:"",
          newPassword:"",
          confirmPassword:"",
          oldHidden:true,
          newHidden:true,
          confirmHidden:true,
        })
      }
      onPasswordSubmitHandler=(e)=>{
        e.preventDefault();
        const {changePasswordError} =this.state;
        if(this.state.oldPassword.trim() === ""){
          changePasswordError.oldPassword="Please enter current password";
        }else{
          changePasswordError.oldPassword="";
        }
        if(this.state.newPassword.trim() === ""){
          changePasswordError.newPassword="Please enter new password";
        }else{
          changePasswordError.newPassword="";
        }
        if(this.state.confirmPassword.trim() === ""){
          changePasswordError.confirmPassword="Please confirm the password";
        }else if(this.state.newPassword.trim() !== this.state.confirmPassword.trim()){
          changePasswordError.confirmPassword="Confirm password should be same as new password";
        }else{
          changePasswordError.confirmPassword="";
        }
        const isEmpty = Object.values(changePasswordError).every(
          (x) => x === null || x === ""
        );
        this.setState({
          changePasswordError
        },()=>{
        if(isEmpty){
          this.setState({
            passwordSettingSubmit:true
          })
          let data={
            oldPassword : this.state.oldPassword,
            newPassword : this.state.newPassword,
            confirmPassword : this.state.confirmPassword
          }
          AxiosInstance(
            (process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa") ? 4003 : "clinical-user",
            "application/json"
          )
            .post("/employee/changePassword", data)
            .then((resp) => {
              var response = resp && resp.data;
              if (response && (response.status === 200 || response.status === "200")) {
                let propserror = {
                  message:response.msg,
                  icon: <img src={iconNotificationSuccess} alt="success" />,
                  type: "success",
                  placement: "topRight",
                  duration: 3,
                  top: 200,
                  className: "task_notification success-notify",
                };
                NotificationComponent(propserror);
                this.setState({
                  oldPassword:"",
                  newPassword:"",
                  confirmPassword:"",
                  oldHidden:true,
                  newHidden:true,
                  confirmHidden:true,
                  passwordSettingSubmit:false
                })
              }else if (response && (response.status === 400 || response.status === "400")) {
                let propserror = {
                  message: response.msg,
                  icon: <img src={iconNotificationError} alt="error" />,
                  type: "error",
                  placement: "topRight",
                  duration: 3,
                  top: 200,
                  className: "task_notification error-notify",
                };
                NotificationComponent(propserror);
                this.setState({
                  passwordSettingSubmit:false
                })
              }
            })
            .catch((err) => {
              var error = err.response;
              if (error && (error.status === "403" || error.status === 403)) {
                localStorage.clear();
                setTimeout(() => {
                  window.location.href = "/";
                }, 500);
              } else if (
                error &&
                (error.status === "500" || error.status === 500)
              ) {
                let propserror = {
                  message: "Something went wrong",
                  icon: <img src={iconNotificationError} alt="success" />,
                  type: "error",
                  placement: "topRight",
                  duration: 3,
                  top: 200,
                  className: "task_notification error-notify",
                };
                NotificationComponent(propserror);
                this.setState({
                  passwordSettingSubmit:false
                })
              }
            });
        }
        })
      }


      onSettingChange=(value,type,to)=>{
        const elementIndex = this.state.notificationSetting[type].findIndex(element=>element.name === value.name);
        let newArray = [...this.state.notificationSetting[type]];
        newArray[elementIndex] =  {...newArray[elementIndex],[to]:!newArray[elementIndex][to]}
        const {notificationSetting}=this.state;
        notificationSetting[type]=newArray;
       this.setState({
          // notificationSetting
        },()=>{
          var data={};
          if(type === 'allNotification' && to === "notification"){
            data.name="allNotification";
            data.notification = newArray[elementIndex][to];
          }else if(type === 'allNotification' && to === "email"){
            data.name="allNotification";
            data.email = newArray[elementIndex][to];
          }else{
           data=newArray[elementIndex];
          }
      
          AxiosInstance((process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa") ? 4003 : "clinical-user","application/json")
          .put("/employee/notificationSettings",data)
          .then(resp=>{
            var response = resp && resp.data;
            if(response && (response.status === "200" || response.status === 200)){
              this.fetchNotificationData();
            }else if(response && (response.status === "400" || response.status === 400)){
              var propsErr = {
                message: response.msg,
                icon: <img src={iconNotificationError} alt="error" />,
                type: "error",
                placement: "topRight",
                duration: 2,
                top: 200,
                className: "task_notification error-notify",
              };
              NotificationComponent(propsErr);
            }
          }).catch(err=>{
            var error=err.response;
                  if(error && (error.status === '403' || error.status === 403)){
                      localStorage.clear();
                      setTimeout(()=>{
                          window.location.href="/";
                      },500)
                  } else if(error && (error.status === '500' || error.status === 500)) {
                    var propsErr = {
                      message: "Something went wrong",
                      icon: <img src={iconNotificationError} alt="error" />,
                      type: "error",
                      placement: "topRight",
                      duration: 2,
                      top: 200,
                      className: "task_notification error-notify",
                    };
                    NotificationComponent(propsErr);
                  }
          });
        });
        
      }

     /**
      * Benakesh code end
      */ 

    render() { 
        let show = "";
        let hidden = "";
        if (this.state.mnumber === true) {
          hidden = "hidden";
        } else if (this.state.mnumber === false) {
          show = "hidden";
        }
        let proshow = "";
        let prohidden = "";
        if (this.state.eprofile === true) {
          prohidden = "hidden";
        } else if (this.state.eprofile === false) {
          proshow = "hidden";
        }
        return (
            <div className="body-bg-color billing-page settings-page">
                <HeaderComponent />
                <div className="billing_container settings_container">
                <div className="billing_sidebar settings_sidebar">
                    <h5 className="sidebar_heading">Account Settings</h5>
                    <SideBarComponent
                    compNo={this.state.compNo}
                    changeSideBar={this.changeSideBar}
                    />
                </div>
                <div className="billing_page settings_page">
                {this.state.compNo === 1 
                ? <UserProfileComponent
                hidePro={this.state.hidePro}
                prohidden={prohidden}
                hidePro={this.state.hidePro}
                invalidImage={this.state.invalidImage}
                loading={this.state.loading}
                hidden={hidden}
                phone={this.state.phone}
                isPhone={this.state.isPhone}
                hidePhone={this.state.hidePhone}
                show={show}
                formError={this.state.formError}
                editphone={this.state.editphone}
                role={this.state.role}
                department_details={this.state.department_details}
                disablePhone={this.state.disablePhone}
                responseMsg={this.state.responseMsg}
                alertType={this.state.alertType}
                profilePath={this.state.profilePath}
                profileData={this.state.profileData}
                uplodProf={this.uplodProf}
                showButton={this.showButton}
                hideButton={this.hideButton}
                showMobEdit={this.showMobEdit}
                hideMobEdit={this.hideMobEdit}
                handlepPhoneChange={this.handlepPhoneChange}
                onSubmit={this.onSubmit}
                />
                : this.state.compNo === 2 
                ? <PasswordAndSecurityComponent
                oldHidden={this.state.oldHidden}
                newHidden={this.state.newHidden}
                confirmHidden={this.state.confirmHidden}

                oldPassword={this.state.oldPassword}
                newPassword={this.state.newPassword}
                confirmPassword={this.state.confirmPassword}
                changePasswordError={this.state.changePasswordError}
                passwordSettingSubmit={this.state.passwordSettingSubmit}
                onIconChange={this.onIconChange}
                onPasswordChange={this.onPasswordChange}
                onPasswordSubmitHandler={this.onPasswordSubmitHandler}
                onPasswordCancelHandler={this.onPasswordCancelHandler}
                />
                : this.state.compNo === 3?
                  <NotificationSettingComponent
                    notificationSetting={this.state.notificationSetting}
                    onSettingChange={this.onSettingChange}
                  /> 
                  : this.state.compNo === 4
                  ?(<TimeZoneComponent
                    tzNameArray={this.state.tzList}
                    tzSelectName={this.state.tzSelectName}
                    handleStateSearch={this.handleTzSearch}
                    onChangeState={this.onChangeTz}
                    onTzFocus={this.onTzFocus}
                  />)           
                    :("")}
                </div>
            </div>
            </div>
        );
    }
}
 
export default withRouter(ManageAccountComponent);