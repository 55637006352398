import React from 'react';
import { Route, Redirect } from 'react-router-dom';

const AuthGuard = ({ component: Component, isLoggedIn, path, ...rest }) => (
    <Route
        {...rest}
        path={path}
        render={props => {
            return isLoggedIn ?
                <Component {...props} /> :
                <Redirect
                    to={{
                        path: '/',
                        state: { from: props.location }
                    }}
                />
        }}
    />
)

export default AuthGuard;