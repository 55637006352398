import axios from "axios";
export const NOTIFICATION_ENDPOINT=(process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa")?"http://3.219.91.255:8014/":"https://socket.salesc2.com:8114/";
export const AxiosInstance = (port, contentType) => {
  const Axios = axios.create({
    // baseURL: `http://3.219.91.255:${port}/api/v1`,
   //baseURL: `http://localhost:${port}/api/v1`,
   baseURL: process.env.REACT_APP_NODE_ENV === "development"
      ?`http://3.219.91.255:${port}/api/v1`
      :process.env.REACT_APP_NODE_ENV === "qa"
      ?`http://34.236.38.97:${port}/api/v1`
      :process.env.REACT_APP_NODE_ENV === "stage"
      ?`https://stage-api.salesc2.com/${port}/api/v1`
      : process.env.REACT_APP_NODE_ENV === "production"
      ?`https://api.clinicalc2.com/${port}/api/v1`
      :""
  });

  Axios.defaults.headers.common["Authorization"] = localStorage.getItem("token")
    ? `Bearer ${localStorage.getItem("token")}`
    : null;
  Axios.defaults.headers.common["Content-Type"] = contentType;
  Axios.interceptors.request.use(
    (request) => {
      return request;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  Axios.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  return Axios;
};