import React, { Component } from "react";
import {Dialog,DialogTitle,DialogContent,Menu,Divider,Button } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import logo from "../../Assets/images/Sales_C2_logo.svg";
import notificationIcon from "../../Assets/images/notification-icon.svg";
import { NavLink } from "react-router-dom";
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import closeBtn from "../../Assets/images/close_btn.svg";
import userIcon from "../../Assets/images/user-icon.png";
import settingsIcon from "../../Assets/images/settings-icon.svg";
import { ProfileURL } from '../../client-config';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Transition } from "../CommonUtilities";
export class MobileHeaderModal extends Component {
  constructor(props) {
    super(props);
    var userData = localStorage.getItem("userdata")
    ? JSON.parse(localStorage.getItem("userdata"))
    : "";
    this.state = {
      anchorEl : null,
    };
    
  }
  handleProfileMenuOpen = (event) => {
    this.setState({
      anchorEl: event.currentTarget
    })
  };
  handleMenuClose = () => {
    this.setState({
      anchorEl: null,
    })
  };
  logoutHandler = () => {
    localStorage.clear();
    window.location.href = "/";
  };
  render() {
    var userData = localStorage.getItem("userdata")
    ? JSON.parse(localStorage.getItem("userdata"))
    : "";
    let profileImg = "";
    if (userData.profilePath !== undefined && userData.profilePath !== "") {
      profileImg = ProfileURL + userData.profilePath;
    } else {
      profileImg = userIcon;
    }
    return (
      <div>
        <Dialog 
          className="mobile_menu_dialog"{...this.props} 
          aria-labelledby="form-dialog-title"
          TransitionComponent={Transition} 
          transitionDuration={600}>
          <div id="header-navbar" className="grow">
          <Menu
        anchorEl={this.state.anchorEl}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        id="primary-search-account-menu"
        keepMounted
        transformOrigin={{ vertical: "top", horizontal: "center" }}
        open={Boolean(this.state.anchorEl)}
        onClose={this.handleMenuClose}
        className="header-profile mobile-profile"
      >
        
        <Typography className="user-name">{userData.username}</Typography>
        <Typography className="user-email">{userData.email}</Typography>
        <NavLink to="/profile" onClick={this.handleMenuClose}>
          <img src={settingsIcon} className="accountimg"></img>
          <Typography variant="inherit">Manage Settings</Typography>
        </NavLink>
        <Divider component="li" className="hr-line" />
        {localStorage.getItem('switchedToSuperAdmin') || localStorage.getItem('switchedToSuperAdminFromUser') ? <Button
          className="logout-button"
          variant="contained"
          onClick={this.switchBackToSuperAdminHandler}
        >
          Switch to Super Admin
        </Button> : <Button
            className="logout-button primary-btn"
            variant="contained"
            onClick={this.logoutHandler}
          >
            Logout
        </Button>}
      </Menu>
        <AppBar position="static">
        <Toolbar>
          <IconButton
            edge="start"
            className="header-logo"
            color="inherit"
            aria-label="open drawer"
          >
            {/* <MenuIcon /> */}
            <img src={logo} alt="Sales-Icon" width="35px" height="auto"></img>
          </IconButton>
          
         
          <div className="grow" />
          <div className="sectionDesktop">
         
         
            <IconButton className="add_task_btn_mobile">
             +
            </IconButton>
            <IconButton aria-label="show 17 new notifications" color="inherit">
             <img src={notificationIcon}></img>
            </IconButton>
            <IconButton onClick={this.props.onClose} className="mobile-menu-icon" >
             <img src={closeBtn} ></img>
            </IconButton>
           
          </div>
         
          
        </Toolbar>
      </AppBar>
      </div>
      <div className="mobile-menu">
      <List component="nav"  aria-label="menu list">
      <ListItem className="mobile-menu-list" button>
      <NavLink to="/dashboard" className="navbars">
            Dashboard
          </NavLink>  
          </ListItem>
          <ListItem className="mobile-menu-list" button>
          <NavLink to="/company" className="navbars">
            Company
          </NavLink>
        </ListItem>
          {userData &&
          (userData.role.toLowerCase() === "super admin" 
          // || userData.role.toLowerCase() === "admin"
          )
             ? (
              <ListItem className="mobile-menu-list" button>
            <NavLink to="/user" className="navbars">
              Users
            </NavLink>
            </ListItem>
          ) : (""
            // <NavLink to="/user" className="navbars">
            //   Team
            // </NavLink>
          )}
          <ListItem className="mobile-menu-list" button>
           <NavLink to="/task" className="navbars">
              Task
            </NavLink>
            </ListItem>
            <ListItem className="mobile-menu-list" button>
            <NavLink to="/departments" className="navbars">
              Departments
            </NavLink>
            </ListItem>
            <ListItem className="mobile-menu-list" button>
            <NavLink to="/reports" className="navbars">
            Report
            </NavLink>
            </ListItem>
          {/* <NavLink to="/account" className="navbars">
            Accounts
          </NavLink> */}
          {userData &&
          (userData.role.toLowerCase() === "managers" ||
            userData.role.toLowerCase() === "manager" ||
            userData.role.toLowerCase() === "super admin") ? (
            ""
          ) : (""
            // <NavLink to="/services" className="navbars">
            //   Services
            // </NavLink>
          )}
          {userData && userData.role.toLowerCase() === "super admin" ? (
            ""
          ) : (
            <React.Fragment>
              {/* <NavLink to="/task" className="navbars">
                Tasks
              </NavLink> */}
            </React.Fragment>
          )}

          {userData && userData.role.toLowerCase() === "admin" ? (""
            // <NavLink to="/levels" className="navbars">
            //   Org structure
            // </NavLink>
          ) : (
            ""
          )}
          {userData &&
          (userData.role.toLowerCase() === "super admin" ||
            userData.role.toLowerCase() === "admin") ? (
            ""
          ) : (
            <React.Fragment>
              {/* <NavLink to="/calender" className="navbars">
                Calender
              </NavLink> */}
              {/* <NavLink to="/map" className="navbars">
                Map
              </NavLink> */}
            </React.Fragment>
          )}
        
        
        {/* <ExpansionPanel className="mobile-menu-expansion">
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className="">More</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <Typography>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex,
            sit amet blandit leo lobortis eget.
          </Typography>
        </ExpansionPanelDetails>
      </ExpansionPanel> */}
      
      <ListItem className="mobile-menu-list" button onClick={this.handleProfileMenuOpen}>
                <ListItemIcon className="user_icon" >
                  <img src={profileImg} className="profile-pic" alt="profile"/> 
                </ListItemIcon>
                <ListItemText primary={userData.username} />
              </ListItem>
    </List>
      </div>
        </Dialog>
      </div>
    );
  }
}
