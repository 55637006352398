import React, { useEffect } from "react";

const GoogleRecaptchaComponent = ({ onCallback }) => {
  const handleLoaded = (_) => {
    window.grecaptcha.ready((_) => {
      window.grecaptcha
        .execute(process.env.REACT_APP_RECAPTCHA_SITE_KEY)
        .then((token) => {
          // call form submit
          if (token && token !== null) {
            onCallback(token);
          }
        });
    });
  };

  useEffect(() => {
    // Add reCaptcha
    const script = document.createElement("script");
    script.src = `https://www.google.com/recaptcha/api.js?render=${process.env.REACT_APP_RECAPTCHA_SITE_KEY}`;
    script.addEventListener("load", handleLoaded);
    document.body.appendChild(script);
  }, []);

  return (
    <div
      className="g-recaptcha"
      data-sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
      data-size="invisible"
    ></div>
  );
};

export default GoogleRecaptchaComponent;
