import React, { Component } from "react";
import {
    Button,
    TextField,
    Dialog,
    DialogContent,
    MenuItem,
    DialogTitle,
    Select,
    FormControl,
    CircularProgress,
} from "@material-ui/core";
import { withRouter } from "react-router-dom";
import IconButton from "@material-ui/core/IconButton";
import { withStyles } from "@material-ui/core/styles";
import { Autocomplete, Alert } from "@material-ui/lab";
// import "./AddUserModel.css";
import { AxiosInstance } from "../../Utilities";
import clinicalInviteIcon from "../../Assets/images/clinical_invite_icon.svg";
import closeBtn from "../../Assets/images/close_btn.svg";
import { TransitionGrow } from "../../SharedComponents/CommonUtilities";
const CssTextField = withStyles({
    root: {
        backgroundColor: "#F2F2F2",
        borderRadius: "5px",
        marginBottom: "15px",
        "& .MuiInput-underline:after": {
            borderBottomColor: "#14193A",
        },
        "& .MuiOutlinedInput-root": {
            "& fieldset": {
                borderColor: "#E2E5F2",
                borderWidth: 0,
            },

            "&.Mui-focused fieldset": {
                borderColor: "#14193A",
                borderWidth: 0,
            },
        },
    },
})(TextField);

const CssAutocomplete = withStyles({
    root: {
        backgroundColor: "#F2F2F2",
        borderRadius: "5px",
        marginBottom: "15px",
        "& .MuiInput-underline:after": {
            borderBottomColor: "#14193A",
        },
        "& .MuiOutlinedInput-root": {
            "& fieldset": {
                borderColor: "#E2E5F2",
                borderWidth: 0,
            },

            "&.Mui-focused fieldset": {
                borderColor: "#14193A",
                borderWidth: 0,
            },
        },
    },
})(Autocomplete);

class InviteCompanyModal extends Component {
    constructor(props) {
        super(props);
        var userData = localStorage.getItem("userdata")
            ? JSON.parse(localStorage.getItem("userdata"))
            : "";
        this.state = {
            responseMsg: "",
            alertType: "",
            createdBy: userData.username,
            companyName: "",
            companyId: "",
            email: "",
            phone: "",
            firstName: "",
            lastName: "",
            isLoading: false,
            companyOption: [
                {
                    id: "",
                    name: "",
                },
            ],
            formError: {
                companyId: "",
                phone: "",
                email: "",
                compName: "",
                firstname: "",
                lastname: "",
                createdBy: "",
                divisionError: "",
                divError: ""
            },
            companyNameError: "",
            dialog: false,
            mainDialog: this.props.openModel,
            division: [],
            division_id: "",
            divisionName: "",
            divisioninvite: "",
            companyList: [],
            isSubmit: false,
        };
    }
    componentDidMount = () => {
        AxiosInstance( (process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa")
              ? 4006
              : "clinical-company", "application/x-www-form-urlencoded; charset=UTF-8")
            .post("/companylist")
            .then((resp) => {
                if (resp.data.status == "200") {
                    this.setState({ companyOption: resp.finalCompany })
                } else {
                    this.setState({
                        companyOption: [
                            {
                                id: "one",
                                name: "No data",
                            },
                        ],

                    });
                }
            });
    }
    clearForm = () => {
        var error = {
            companyId: "",
            phone: "",
            email: "",
            compName: "",
            firstname: "",
            lastname: "",
            departmentId: "",
            createdBy: "",
        };
        this.setState(
            {
                role: "",
                compName: "",
                firstname: "",
                lastname: "",
                email: "",
                departmentId: "",
                phone: "",
                responseMsg: "",
                formError: error,
                division: [],
                isSubmit: false,
                alertType: "",
            }
        );
    };
    render() {
        var { formError, companyNameError } = this.props.modaldata;
        const inputProps = {
            step: 500,
        };
        return (
            <div>
                <Dialog
                    onExit={this.clearForm}
                    open={this.props.openModel}
                    {...this.props}
                    aria-labelledby="form-dialog-title"
                    maxWidth="md"
                    className="add_company_dialog add_company_modal"
                    TransitionComponent={TransitionGrow}
                    transitionDuration={600}
                >
                    <DialogTitle id="form-dialog-title" className="add_user_header">
                        <img className="add-company_img" src={clinicalInviteIcon}></img>
                        <span>Invite Company</span>
                        <IconButton
                            onClick={this.props.onClose}
                            className="closeBtn"
                            aria-label="show 17 new notifications"
                            color="inherit"
                        >
                            <img src={closeBtn}></img>
                        </IconButton>
                    </DialogTitle>
                    <DialogContent>
                        {this.props.modaldata.responseMsg !== "" ? (

                            <Alert severity={this.props.modaldata.alertType}>
                                {this.props.modaldata.responseMsg}
                            </Alert>
                        ) : null}
                        <form className="adduserform">
                            <div style={{ display: this.state.isLoading ? "block" : "none" }}>
                                <CircularProgress
                                    variant={this.state.isLoading ? "indeterminate" : "static"}
                                />
                            </div>
                            <div className="form_input_field company_form_field">
                                <label className="label">Company Name<span className="labelAsterisk"> *</span></label>
                                <CssAutocomplete
                                    id="companyName"
                                    debug
                                    className="cusautocomplete"
                                    onInputChange={this.props.compAutoClose}
                                    options={this.props.modaldata.companyOption}
                                    getOptionLabel={(option) => option.name}
                                    getOptionSelected={(option, value) => true}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            variant="outlined"
                                            name="companyName"
                                            placeholder="Select Company"
                                            onChange={this.props.companyListHandler}
                                            onSelect={this.props.onSelectHandler}
                                        />
                                    )}
                                />
                                <span className="error">{companyNameError}</span>

                            </div>

                            <div className="form_input_field company_form_field">
                                {this.props.modaldata.companySize === "large" ? (
                                    <>
                                        <label className="label">Division<span className="labelAsterisk"> *</span></label>
                                        <CssAutocomplete
                                            id="divisionName"
                                            className="cusautocomplete"
                                            disabled={this.props.modaldata.disable}
                                            onInputChange={this.props.divisionAutoClose}
                                            options={this.props.modaldata.divisionOption}
                                            getOptionLabel={(option) => option.name}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    variant="outlined"
                                                    name="divisionName"
                                                    placeholder="Select Division"
                                                    onChange={this.props.divisionListHandler}
                                                    onSelect={this.props.onDivisionSelectHandler}
                                                />
                                            )}
                                        />
                                        <span className="error"> {formError.divisionError}</span>
                                    </>
                                ) : ""}
                            </div>
                            {this.props.modaldata.ishide === false ? (
                                <>
                                    {this.props.modaldata.companyId === "" ? (
                                        <>
                                            <div className="invite_contact_header">
                                                Contact
                                            </div>
                                            <div className="form_input_field">
                                                <label className="label">First Name<span className="labelAsterisk"> *</span></label>
                                                <CssTextField
                                                    id="outlined-basic"
                                                    name="firstName"
                                                    variant="outlined"

                                                    type="text"
                                                    fullWidth
                                                    placeholder="Enter first name"
                                                    onChange={this.props.onChangeHandler}
                                                />
                                                <span className="error">{formError.firstname}</span>
                                            </div>
                                            <div className="form_input_field">
                                                <label className="label">Last Name<span className="labelAsterisk"> *</span></label>
                                                <CssTextField
                                                    id="outlined-basic"
                                                    name="lastName"
                                                    variant="outlined"

                                                    type="text"
                                                    fullWidth
                                                    placeholder="Enter last name"
                                                    onChange={this.props.onChangeHandler}
                                                />
                                                <span className="error">{formError.lastname}</span>
                                            </div>
                                            <div className="form_input_field">
                                                <label className="label">Email<span className="labelAsterisk"> *</span></label>
                                                <CssTextField
                                                    id="outlined-basic"
                                                    name="email"
                                                    variant="outlined"

                                                    type="text"
                                                    fullWidth
                                                    placeholder="Enter email"
                                                    onChange={this.props.onChangeHandler}
                                                />
                                                <span className="error"> {formError.email}</span>
                                            </div>
                                            <div className="form_input_field">
                                                <label className="label">Division</label>
                                                <CssTextField
                                                    id="outlined-basic"
                                                    name="divisioninvite"
                                                    variant="outlined"

                                                    type="text"
                                                    fullWidth
                                                    placeholder="Enter Division"
                                                    onChange={this.props.onChangeHandler}
                                                />
                                                <span className="error"> {formError.divError}</span>
                                            </div>
                                            <div className="form_input_field">
                                                <label className="label">Phone</label>
                                                <CssTextField
                                                    id="outlined-basic"
                                                    name="phone"
                                                    variant="outlined"

                                                    type="text"
                                                    fullWidth
                                                    placeholder="Please enter phone number"
                                                    onChange={this.props.onChangeHandler}
                                                />
                                                <span className="error"> {formError.phone}</span>
                                            </div>
                                        </>
                                    ) : ""}
                                </>
                            ) : ""}
                            <div className="add_company_action">

                                <Button
                                    className="cancel_btn"
                                    onClick={this.props.onClose}
                                    variant="outlined"
                                >
                                    Cancel
                                </Button>
                                {this.props.modaldata.companyId !== "" ? (

                                    <Button
                                        className="ok_btn"
                                        onClick={this.props.newInviteHandler}
                                        variant="contained"
                                    >
                                        Invite
                                    </Button>
                                ) : (
                                        <Button
                                            className="ok_btn"
                                            onClick={this.props.inviteHandler}
                                            variant="contained"
                                        >
                                            Invite
                                        </Button>
                                    )}
                            </div>
                        </form>
                    </DialogContent>
                </Dialog>
            </div>
        );
    }
}
export default withRouter(InviteCompanyModal);
