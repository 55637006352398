import React, { Component } from "react";
import { Route, Router, withRouter, Switch, Redirect } from "react-router-dom";
import AuthGuard from "./AuthGuard";
import LoginComponent from "./Components/LoginComponent/LoginComponent";
import RegisterComponent from "./Components/RegisterComponent/RegisterComponent";
import ResetPasswordComponent from "./Components/LoginComponent/ResetPasswordComponent/ResetPasswordComponent"
import * as action from "./Store/Actions";
import { connect } from "react-redux";
import jwt from "jsonwebtoken";
import PageNotFoundComponent from "./Components/PageNotFoundComponent/PageNotFoundComponent";
import UserComponent from "./Components/UsersComponent/UserComponent";
import TaskComponent from "./Components/TaskComponent/TaskComponent";
import TaskDetailsComponent from "./Components/TaskComponent/TaskDetailsComponent";
import DashboardComponent from "./Components/DashboardComponent/DashboardComponent";
import DepartmentsComponent from "./Components/DepartmentsComponent/DepartmentsComponent";
import CompanyComponent from "./Components/CompanyComponent/CompanyComponent";
import CompanyDetailsComponent from "./Components/CompanyComponent/CompanyDetailsComponent";
import SignupComponent from "./Components/SignupComponent/SignupComponent";
import ReportsGraphComponent from "./Components/ReportsGraphComponent/ReportsGraphComponent";
import ProfileComponent from "./Components/ProfileComponent/ProfileComponent";

// fonts
import "../src/Assets/fonts/stylesheet.css";
import ManageAccountComponent from "./Components/ManageAccountComponent/ManageAccountComponent";
class App extends Component {
  constructor(props) {
   
    super(props);
    this.state = {
      userData: localStorage.getItem("userdata")
        ? JSON.parse(localStorage.getItem("userdata"))
        : "",    
    };
    
  }

  componentDidMount() {
    this.props.authCheck();
  }
  render() {
    const { userData } = this.state;
    return (
      <Router history={this.props.history}>
        <Switch>
          <Route path="/" exact component={LoginComponent} />
          <Route path="/register" exact component={RegisterComponent} />
          <Route path="/signup/:id" component={SignupComponent} />
          <Route path="/resetpassword/:id" component={ResetPasswordComponent} />
            <AuthGuard
              path="/dashboard"
              isLoggedIn={this.props.isAuthenticated}
              component={DashboardComponent}
            />
            <AuthGuard
              path="/user"
              isLoggedIn={this.props.isAuthenticated}
              component={UserComponent}
            />
            <AuthGuard
            exact
              path="/task"
              isLoggedIn={this.props.isAuthenticated}
              component={TaskComponent}
            />
            <AuthGuard
              path="/task/:id"
              isLoggedIn={this.props.isAuthenticated}
              component={TaskDetailsComponent}
            />
            <AuthGuard
              path="/departments"
              isLoggedIn={this.props.isAuthenticated}
              component={DepartmentsComponent}
            />
            <AuthGuard
            path="/reports"
            isLoggedIn={this.props.isAuthenticated}
            component={ReportsGraphComponent}
          />
            <AuthGuard
            exact
              path="/company"
              isLoggedIn={this.props.isAuthenticated}
              component={CompanyComponent}
            />
            <AuthGuard
              path="/company/:id"
              isLoggedIn={this.props.isAuthenticated}
              component={CompanyDetailsComponent}
            />
             {/* <AuthGuard
            path="/profile"
            isLoggedIn={this.props.isAuthenticated}
            component={ProfileComponent}
          /> */}
          <AuthGuard
            path="/profile"
            isLoggedIn={this.props.isAuthenticated}
            component={ManageAccountComponent}
          />
          {/* {userData && userData.role.toLowerCase() !== "super admin" ? (
            ""
          ) : (
            <AuthGuard
              path="/user"
              isLoggedIn={this.props.isAuthenticated}
              component={UserComponent}
            />
          )} */}
          <Route component={PageNotFoundComponent} />
        </Switch>
      </Router>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    isAuthenticated: jwt.decode(localStorage.getItem("token")) ? true : false,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    authCheck: () => dispatch(action.authCheckState()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(App));
