import React, { Component, useCallback, Suspense } from "react";
import {
  Container,
  Grid,
  Card,
  TextField,
  Button,
  Checkbox,
  FormControlLabel,
  CircularProgress
} from "@material-ui/core";
import AppBar from "@material-ui/core/AppBar";
import logo2 from "../../Assets/images/Group 160.svg";
import { withRouter } from "react-router-dom";
import { Alert } from "@material-ui/lab";
import { AxiosInstance } from "../../Utilities";
import {
  withStyles,
  makeStyles,
  createMuiTheme,
} from "@material-ui/core/styles";
import { Autocomplete } from "@material-ui/lab";
import "./RegisterComponent.css";
import { formValidation, emailRegx, phoneRegx } from "../../SharedComponents/Validation/formValidation";
import validator, * as Validator from "validator";
import { Scrollbars } from 'react-custom-scrollbars';
import {
  getGooglePlaceObjectValues, 
  getCity,
  getArea,
  getState,
  getZipcode,
} from "../../SharedComponents/CommonUtilities/CommonUtilities";
import GoogleRecaptchaComponent from "../../SharedComponents/GoogleRecaptchaComponent/GoogleRecaptchaComponent";

var AutoCompleteMapComponent = React.lazy(() =>
  import(
    "../../SharedComponents/AutoCompleteMapComponent/AutoCompleteMapComponent"
  )
);
const BlueCheckbox = withStyles({
  root: {
    color: "#4d7eec",
    "&$checked": {
      color: "#4d7eec",
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);
const CssTextField = withStyles({
  root: {
    backgroundColor: "#F2F2F2",
    borderRadius: "5px",
    marginBottom: "20px",
    "& .MuiInput-underline:after": {
      borderBottomColor: "#14193A",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#E2E5F2",
        borderWidth: 0,
      },

      "&.Mui-focused fieldset": {
        borderColor: "#14193A",
        borderWidth: 0,
      },
    },
  },
})(TextField);


const CssAutocomplete = withStyles({
  root: {
    backgroundColor: "#F2F2F2",
    borderRadius: "5px",
    marginBottom: "20px",
    "& .MuiInput-underline:after": {
      borderBottomColor: "#14193A",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#E2E5F2",
        borderWidth: 0,
      },

      "&.Mui-focused fieldset": {
        borderColor: "#14193A",
        borderWidth: 0,
      },
    },
  },
})(Autocomplete);

class RegisterComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      employeeName: "Sharley J",
      userPassword: "",
      imageFiles: [],
      userLogo: "",
      formError: {
        userPassword: "",
        imageFiles: "",
        phone: "",
        name: "",
        email: "",
        address: "",
      },
      hospitalError: "",
      responseMsg: "",
      alertType: "",
      hidden: true,
      isValid: !this.props.isValid,
      selecthospital: "",
      phone: "",
      address: "",
      name: "",
      termCondition: false,
      email: "",
      files: "",
      hospitalList: [
        {
          _id: "",
          accountName: "",
        },
      ],
      hospitalId: "",
      hospitalName: "",
      responseMsg: "",
      alertType: '',
      invalidImage: "",
      phoneError: "",
      addressError: "",
      nameError: "",
      emailError: "",
      img_upload:"",
      formIsValid: false,
      isSubmit: false,
      reCaptchaResponseToken: ""
    };
    this.handleChange = this.handleChange.bind(this);
    this.fileHandle = this.fileHandle.bind(this);
  }
  componentDidMount = () => {
    this.setState({ isValid: false });
  };
  fileHandle = (e) => {
    const files = e.target.files[0];
    this.setState({ files: files })
    if (!files.name.match(/\.(doc|docx|jpg|jpeg|pdf)$/)) {
      this.setState({ invalidImage: 'Please select a pdf, .doc,.jpg,.docx format.', img_upload:"" });
      var x = document.getElementsByClassName("custom-upload-doc-container");
      x[0].innerHTML = e.target.files[0].name;
      return false;
    } else {

      this.setState({ files: files, invalidImage: "", img_upload:"img_upload" });
      var x = document.getElementsByClassName("custom-upload-doc-container");
      x[0].innerHTML = e.target.files[0].name;
    }
  };
  onHospitalChange = async (e) => {
    if (e.target.value.length > 100) {
      this.setState({ hospitalError: "* Select Hospital should not exceed 100 characters" })
    } else {
      this.setState({ hospitalError: "" })
    }
    var name = e.target.value;
    this.setState({ hospitalName: name })
    var data = {
      search: name,
    };
    AxiosInstance( (process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa")
              ? 4001
              : "clinical-registration", "application/x-www-form-urlencoded; charset=UTF-8")
      .post("/accountlist", data).then((res) => {
        if (res.data.status === 200) {
          if (res.data.data.length !== 0) {
            this.setState({
              hospitalList: res.data.data,
            });
          } else {
            this.setState({
              hospitalList: [
                {
                  _id: "one",
                  accountName: name,
                },
              ],
            });
          }
        }
        else {
          this.setState({
            hospitalList: [
              {
                _id: "",
                accountName: name,
              },
            ],

          });
        }
      });
  };
  onSelectHospital = (e) => {
    var name = e.target.value;
    if (name === '') {
      this.onHospitalChange(e);
    } else {
      var { hospitalList } = this.state;
      var data = hospitalList.filter((item) => {
        return item.accountName === name;
      });
      data.map((hospitalData) => {
        this.setState({
          hospitalId: hospitalData._id, hospitalName: hospitalData.accountName,
        },
        );
      });
    }
  };
  onHospitalAutoClose = (e) => {
    var name = e.target.value;
    if (typeof name === "undefined") {
      this.setState({ hospitalId: "", hospitalName: "" },
        this.filter
      );
    }
  }
  handleChange(event) {
    var { name, value } = event.target
    var { formError } = this.state

    this.setState({ isSubmit: false, [name]: value });
    switch (name) {
      case "phone":
        !phoneRegx.test(value)
          ? (formError.phone = "* Please enter the valid phone number e.g.: 800-555-5555 ")
          : (formError.phone = "");
        break;
      case "name":
        !/^(?=.{2,50}$)[a-zA-Z]+(?:[-'\s][a-zA-Z]+)*$/.test(value)
          ? (formError.name = "* Please enter a valid name")
          : (formError.name = "");
        break;
      case "email":
        !validator.isLength(value, { min: 5, max: 100 })
          ? (formError.email = "Email length should be less than 100 character")
          : !emailRegx.test(value)
            ? (formError.email = "* Please check the email address is in valid format e.g. name@domain.com")

            : (formError.email = "");
        break;
      case "address":
        (value.length > 100)
          ? (formError.address = "* Address should not exceed 100 characters")
          : (formError.address = "");
        break;
      default:
        break;
    }
    this.setState({
      formError,
    });
  }
  onSubmit = (e) => {
    e.preventDefault();
    var { formError, hospitalError, termCondition, reCaptchaResponseToken } = this.state
    let profile1 = new FormData();
    profile1.append('name', this.state.name);
    profile1.append('email', this.state.email);
    profile1.append('phone', this.state.phone);
    profile1.append('address', this.state.address);
    profile1.append('accountName', this.state.hospitalName);
    if (this.state.files) profile1.append('filePath', this.state.files);
    profile1.append('token', reCaptchaResponseToken);

    let profile2 = new FormData();
    profile2.append('name', this.state.name);
    profile2.append('email', this.state.email);
    profile2.append('phone', this.state.phone);
    profile2.append('address', this.state.address);
    profile2.append('accountId', this.state.hospitalId);
    if (this.state.files) profile2.append('filePath', this.state.files);
    profile2.append('token', reCaptchaResponseToken);

    if (!this.state.isValid &&
      formError.phone === "" &&
      formError.email === "" &&
      formError.address === "" &&
      formError.name === "" &&
      hospitalError === "" &&
      reCaptchaResponseToken !== "" &&
      termCondition === true
    ) {
      this.setState({ isSubmit: true })
      if (this.state.hospitalId === "") {

        AxiosInstance( (process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa")
              ? 4001
              : "clinical-registration", "application/x-www-form-urlencoded; charset=UTF-8")
          .post("/register", profile1).then((res) => {
            if (res.data.status === 200) {
              this.setState({ isSubmit: false, responseMsg: 'Registation  successfully', alertType: 'success' },)
              setTimeout(() => {
                window.location.href = "/dashboard";
              }, 500);
            } else {
              this.setState({ isSubmit: false, responseMsg: res.data.msg, alertType: 'error' },)
            }
          });
      } else {
        AxiosInstance( (process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa")
              ? 4001
              : "clinical-registration", "application/x-www-form-urlencoded; charset=UTF-8")
          .post("/register", profile2).then((res) => {
            if (res.data.status === 200) {
              this.setState({ isSubmit: false, responseMsg: 'Registation  successfully', alertType: 'success' },)
              setTimeout(() => {
                window.location.href = "/dashboard";
              }, 4000);
            } else {
              this.setState({ isSubmit: false, responseMsg: res.data.msg, alertType: 'error' },)
            }
          });
      }
    }
  }
  onclickBtn = (e) => {
    const { hospitalName, phone, address, name, email, termCondition } = this.state;
    if (hospitalName === '' || phone === '' || address === '' || name === '' || email === '' || termCondition === false) {
      this.setState({ isValid: true });
    } else {
      this.setState({ isValid: false });
    }
  }
  goTologin() {
    window.location.href = "/";
  }
  onCheck = (e) => {
    var name = e.target.checked;
    this.setState({ termCondition: name })
  }
  onGooglePlaceSelect = (place) => {
    const { addEventFormError } = this.state;
    var addressArray = place.address_components;
    var formattedPlace = {
      ...getGooglePlaceObjectValues(place),
      city: getCity(addressArray),
      area: getArea(addressArray),
      state: getState(addressArray),
      zipcode: getZipcode(addressArray),
    };
    this.setState({
      addEventFormError,
      address: formattedPlace.address,
      eventLocation: [formattedPlace.lng, formattedPlace.lat],
    });
  };
  onGoogleRecaptchaResponse = (token) => {
    this.setState({ reCaptchaResponseToken : token})
  }

  render() {
    var { formError, hospitalError } = this.state
    let signupForm = (
      <div className="signup-body-bg-color signup-section registration-section">
        <div id="header-navbar">
          <AppBar position="static"></AppBar>
        </div>
        <Container className="signup-body">
          <Card className="clinic-signup-root">
            <Grid container>
              <Grid item xs={12} className="card-header">
                <div className="header-content">
                  <div className="signup-logo register-signup-logo">
                    <img src={logo2} alt="Sales-Icon" height="auto"></img>
                    <div className="signup-logo-text">
                       {/* <h3>ClinicalC2 offers the ultimate Customer Scheduling Management tool for Med Device Sales Teams.</h3> */}
                       <h3>The First Vendor Scheduling Platform Designed for Healthcare Providers.</h3>
                       </div>
                  </div>
                </div>
              </Grid>
            </Grid>
            <div className="register-form">
              {this.state.responseMsg != "" ? (<Alert severity={this.state.alertType}>{this.state.responseMsg}</Alert>) : null}
              <p className="register-form-header">Please register to use ClinicalC2.</p>
              <form onSubmit={this.onSubmit}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6} className="register-form-left">
                    <div className="hospital_field form_input_field">
                      <label className="label">Select Hospital<span className="labelAsterisk"> *</span></label>
                      <CssAutocomplete
                        id="accountName"
                        className="cusautocomplete"
                        onInputChange={this.onHospitalAutoClose}
                        options={this.state.hospitalList}
                        getOptionLabel={(option) => option.accountName}
                        getOptionSelected={(option, value) => true}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            name="accountName"
                            placeholder="Select Hospital"
                            onChange={this.onHospitalChange}
                            onSelect={this.onSelectHospital}
                            error={
                              this.state.hospitalName === "" && this.state.isValid
                                ? true
                                : false
                            }
                          />
                        )}
                      />
                      {/* <CssAutocomplete
                        id="companyName"
                        className="cusautocomplete"
                        // onInputChange={this.props.compAutoClose}
                        // options={this.props.modaldata.companyOption}
                        getOptionLabel={(option) => option.name}
                        getOptionSelected={(option, value) => true}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            name="companyName"
                            placeholder="Select Company"
                            // onChange={this.props.companyListHandler}
                            // onSelect={this.props.onSelectHandler}
                          />
                        )}
                      /> */}
                      {this.state.hospitalName === "" && this.state.isValid ?
                        <span className="error">* Please select a Hospital <br></br></span> : <>
                          {this.state.hospitalError === '' ?
                            '' : <span className="error">{this.state.hospitalError} </span>}
                        </>}
                    </div>
                    <div className="phone_field form_input_field">
                      <label className="label">Phone number<span className="labelAsterisk"> *</span></label>
                      <CssTextField
                        id="outlined-basic"
                        name="phone"
                        variant="outlined"
                        type="text"
                        fullWidth
                        placeholder="Please enter phone number"
                        onChange={this.handleChange}
                        error={
                          this.state.phone === "" && this.state.isValid
                            ? true
                            : false
                        }
                      />
                      {this.state.phone === '' && this.state.isValid ?
                        <span className="error">* Please enter phone number <br></br></span> :
                        <>
                          {formError.phone === '' ?
                            '' :
                            <span className="error">{formError.phone} </span>}
                        </>
                      }
                    </div>
                    <div className="address_field form_input_field">
                      <label className="label">Address<span className="labelAsterisk"> *</span></label>
                      <Suspense fallback={<div></div>}>
                      
                        <AutoCompleteMapComponent
                          onPlaceSelect={this.onGooglePlaceSelect}
                        />
                      </Suspense>
                      {/* <CssTextField
                        id="outlined-basic"
                        name="address"
                        variant="outlined"
                        type="text"
                        fullWidth
                        placeholder="Enter Address "
                        onChange={this.handleChange}
                        error={
                          this.state.address === "" && this.state.isValid
                            ? true
                            : false
                        }
                      /> */}
                      {this.state.address === '' && this.state.isValid ?
                        <span className="error">* Please enter an address</span> :
                        <>
                          {formError.address === '' ?
                            '' : <span className="error">{formError.address} </span>}
                        </>
                      }
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={6} className="register-form-right">
                    <div className="name_field form_input_field">
                      <label className="label">Name<span className="labelAsterisk"> *</span></label>
                      <CssTextField
                        id="outlined-basic"
                        name="name"
                        variant="outlined"
                        type="text"
                        fullWidth
                        placeholder="Enter Name"
                        onChange={this.handleChange}
                        error={
                          this.state.name === "" && this.state.isValid
                            ? true
                            : false
                        }
                      />
                      {this.state.name === '' && this.state.isValid ?
                        <span className="error">* Please enter a Name <br></br></span> :
                        <>
                          {formError.name === '' ?
                            '' : <span className="error">{formError.name} </span>}
                        </>
                      }
                    </div>
                    <div className="email_field form_input_field">
                      <label className="label">Email<span className="labelAsterisk"> *</span></label>
                      <CssTextField
                        id="outlined-basic"
                        name="email"
                        variant="outlined"
                        type="text"
                        fullWidth
                        placeholder="Enter Email address"
                        onChange={this.handleChange}
                        error={
                          this.state.email === "" && this.state.isValid
                            ? true
                            : false
                        }
                      />
                      {this.state.email === '' && this.state.isValid ?
                        <span className="error">* Please enter an Email address <br></br></span> :
                        <>
                          {formError.email === '' ?
                            '' : <span className="error">{formError.email} </span>}
                        </>
                      }
                    </div>
                    <div className="document_field form_input_field">
                      <label className="label upload-doc-label">
                        Upload Document{" "}
                        <span>(Licence document, Tax certificate)</span> 
                        {/* <span className="labelAsterisk"> *</span> */}
                      
                      </label>
                      <div className="custom-upload-doc ">
                        <CssTextField
                          id="outlined-basic"
                          name="files"
                          variant="outlined"
                          type="file"
                          fullWidth
                          placeholder="Upload your Document "
                          onChange={this.fileHandle}
                          error={
                            this.state.files === "" && this.state.isValid
                              ? true
                              : false
                          }
                        />
                        <div className={"custom-upload-doc-container " +  this.state.img_upload}>Upload your Document here <span className="">Upload</span></div>
                      </div>
                      {/* {this.state.files === '' && this.state.isValid ?
                        <span style={{ marginTop: "20px" }} className="error">* Please upload a Document <br></br></span> :
                        <>
                          {this.state.invalidImage === '' ?
                            '' : <span className="error">{this.state.invalidImage} </span>}
                        </>
                      } */}
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormControlLabel
                      className="checkbox-label"
                      control={<BlueCheckbox name="checkedG" />}
                      onChange={this.onCheck}
                      label={
                        <div>
                          <span>I agree to the ClinicalC2 </span>
                          <a className="under_line" href=""> Terms of Service </a>
                          <span> and </span>
                          <a className="under_line" href=""> Privacy Policy</a>
                        </div>
                      }
                    />
                    {this.state.termCondition === false && this.state.isValid ?
                      <span className="error">* Please accept to continue. <br></br></span> : ''}
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Button disabled={this.state.isSubmit}
                      className="LoginBtn RegisterBtn"
                      type="submit"
                      variant="contained"
                      color="primary"
                      onClick={this.onclickBtn}
                    >
                      {this.state.isSubmit ? <CircularProgress size={20} /> : ''}  Register
                    </Button>
                    <div className="register-link">
                      <p>
                        Already have an account? <a href="/">Login</a>
                      </p>
                    </div>
                  </Grid>
                </Grid>
              </form>
            </div>
          </Card>
        </Container>
        <GoogleRecaptchaComponent onCallback={this.onGoogleRecaptchaResponse}/>
      </div>
    );
    return <div>{signupForm}</div>;
  }
}
export default withRouter(RegisterComponent);
