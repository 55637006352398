import React ,{Suspense} from 'react';
import {withStyles,TextField,Select,Button} from '@material-ui/core';
import { Tabs , Tooltip } from 'antd';
import TeamsComponent from './TeamsComponent/TeamsComponent';
import './TabComponent.css';
import ServiceComponent from './ServiceComponent/ServiceComponent';
import CMSComponent from './CMSComponent/CMSComponent';

const { TabPane } = Tabs;

const TabComponent = (props) => {
  console.log("props.data?.activeTab",typeof props.data?.activeTab)
    return (
        <div className="tab-card-container">
            <Tabs type="card" activeKey={props.data?.activeTab} onChange={props.onActiveTabChange}>
                <TabPane tab="Teams" key="1">
                       <TeamsComponent
                       data={props.data}
                       />    
                </TabPane>
                {props.data && props.data?.type ==="clinical" && 
                <>
                <TabPane tab="Services" key="2">
                  <ServiceComponent 
                   data={props.data}
                   updateMenu={props.updateMenu}
                   onDeleteHandleClick={props.onDeleteHandleClick}
                   showdelete={props.showdelete}
                   handleClose={props.handleClose}
                   deleteService={props.deleteService}
                   hideButton={props.hideButton}
                   handleMenuClose={props.handleMenuClose}
                   setMenu={props.setMenu}
                   addService={props.addService}
                   editService={props.editService}
                   handleChange={props.handleChange}
                   selectServiceType={props.selectServiceType}
                  />
                </TabPane>
                <TabPane tab="CMS" key="3">
                     <Suspense fallback={<div></div>}>
                        <CMSComponent
                         data={props.data}
                         cmsEditHandler={props.cmsEditHandler}
                         cmsHandleChecked={props.cmsHandleChecked}
                         onCloseCms={props.onCloseCms}
                         cmsHandler={props.cmsHandler}
                        /> 
                     </Suspense>
                </TabPane>
                </>
              }
            </Tabs>
        </div>
    );
}
 
export default TabComponent;