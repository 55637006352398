import React, { useState } from 'react';
import Grid from "@material-ui/core/Grid";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import { withStyles } from "@material-ui/core/styles";
import iconlyLightEdit from "../../../Assets/images/Iconly-Light-Edit-Square.svg";
import './CMSComponent.css'
import {
    Button,
    CircularProgress,
  } from "@material-ui/core";

const AntSwitch = withStyles((theme) => ({
    root: {
      width: 48,
      height: 24,
      padding: 0,
      display: "flex",
      overflow: "initial",
    },
    switchBase: {
      padding: 2,
      color: theme.palette.grey[500],
      "&$checked": {
        transform: "translateX(23px)",
        color: theme.palette.common.white,
        "& + $track": {
          opacity: 1,
          backgroundColor: "#21BF73",
          border: `1px solid #21BF73`,
        },
      },
    },
    thumb: {
      width: 20,
      height: 20,
      boxShadow: "none",
    },
    track: {
      border: `1px solid ${theme.palette.grey[500]}`,
      borderRadius: 24 / 2,
      opacity: 1,
      backgroundColor: theme.palette.common.white,
    },
    checked: {},
  }))(Switch);

function CMSComponent(props) {
    return (
      <>
      <div className="header-dept">
            <div className="dept_title">CMS</div>
        </div>
      <Grid container spacing={2} className="cms_container">
        {props.data?.cmsData &&
          props.data?.cmsData.length > 0 &&
          props.data?.cmsData.map((item, index) => (
            <Grid item xs={12} sm={6} key={index}>
              <div className="form-group">
                {(item.value==="patientName"|| item.value==="caseNumber" ) ?
                <div>
                  <label variant="h6" className="cms-label">{Object.keys(item)[0]}</label>
                  <FormControlLabel
                    className="formControlLabel"
                    name={item?.value}
                    style ={{cursor:"pointer"}}
                    control={<AntSwitch color="primary" />}
                    onChange={(e)=>props.cmsHandleChecked(e)}
                    margin="dense"
                    labelPlacement="start"
                    checked={item.checked==true?true:false}
                  />
                </div>:
                <label variant="h6">{Object.keys(item)[0]}</label>}
                <div className="module_detail">
                  <input
                    type="text"
                    value={Object.values(item)[0]}
                    className={
                      Object.keys(item)[1] === ""
                        ? "text_input"
                        : "text_input_value"
                    }
                    placeholder={`Enter an alternate label for '${
                      Object.keys(item)[0]
                    }'`}
                    maxLength={ item.value === "price"|| item.value === "quantity" || item.value === "amount" ? 15: item.value === "product" ||  item.value === "item" ?  20 :  50}
                    onChange={(e) =>
                      props.cmsEditHandler(e, Object.keys(item)[0], index)
                    }
                    disabled={item.checked==false?true:false}
                  />
                  {item.isEdited && item.value && Object.values(item.value)[0] !== "" && (
                    <img src={iconlyLightEdit} alt="Edit" />
                  )}
                </div>
                
              </div>
            </Grid>
          ))}
        <div className="add_company_action">
                  <Button
                    className="cancel_btn"
                    onClick={()=>props.onCloseCms()}
                    variant="outlined"
                  >
                    Clear
                </Button>
                  <Button
                    className="ok_btn"
                    disabled={props.data?.isSubmit}
                    type="submit"
                    variant="contained"
                    onClick={()=>props.cmsHandler()}
                  >
                    {props.data?.isSubmit ? <CircularProgress size={20} /> : ''}Save
                </Button>
        </div>
      </Grid>
    </>
    )
}

export default CMSComponent
