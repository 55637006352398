import React, { Component, Suspense } from "react";
import "./ReportsGraphComponent.css";
import * as action from "../../Store/Actions/index";
import { withStyles } from "@material-ui/core/styles";
import {
  Box,
  Button,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableFooter,
  TablePagination,
  Tooltip,
  Divider,
} from "@material-ui/core";
import { Select, Menu, Dropdown, DatePicker, Empty, Tabs } from "antd";
import { connect } from "react-redux";
import { Redirect, withRouter } from "react-router-dom";
import carret_down from "../../Assets/images/carret_down.svg";
import jwt from "jsonwebtoken";
import GetAppIcon from '@material-ui/icons/GetApp';
import momentTimeZone from "moment-timezone";
import moment from 'moment';
import { AxiosInstance } from "../../Utilities";
import { upperFirst } from "lodash";
import { exportToCSV } from "../../SharedComponents/CommonUtilities/CommonUtilities";
import { NotificationComponent } from "../../SharedComponents/NotificationComponent/NotificationComponent";
import iconNotificationError from "../../Assets/images/icon-notify-error.svg";
var HeaderComponent = React.lazy(() =>
  import("../../SharedComponents/HeaderComponent/HeaderComponent")
);
var CommonPieChartComponent = React.lazy(() =>
  import("../../SharedComponents/ChartsComponent/CommonPieChartComponent/CommonPieChartComponent")
);
var CommonBarGraphComponent = React.lazy(() =>
  import(
    "../../SharedComponents/ChartsComponent/CommonBarGraphComponent/CommonBarGraphComponent"
  )
);
var CustomSelectWithSearchComponent = React.lazy(() =>
  import(
    "../../SharedComponents/CustomSelectWithSearchComponent/CustomSelectWithSearchComponent"
  )
);
var CustomSelectComponent = React.lazy(() =>
  import("../../SharedComponents/CustomSelectComponent/CustomRepoSelectComponent")
);
const { RangePicker } = DatePicker;
const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(even)": {
      backgroundColor: "#F9F9F9",
    },
  },
}))(TableRow);
const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
  },
}))(Tooltip);
class ReportsGraphComponent extends Component {
  constructor(props) {
    super(props);
    let userData = JSON.parse(localStorage.getItem("userdata"))
      ? JSON.parse(localStorage.getItem("userdata"))
      : "";
    var zoneName = momentTimeZone.tz.guess();
    this.state = {
      timeZoneName:zoneName,
      userData: userData,
      loggedInRole: userData.role,
      role: userData.role.toLowerCase(),
      page: 0,
      rowsPerPage: 10,
      dateFilter: {
        startDate: momentTimeZone(momentTimeZone().tz(localStorage.getItem("timeZone") ? localStorage.getItem("timeZone") : zoneName).format("YYYY-MM-DD 00:00:00")).utc(),
        endDate: momentTimeZone(momentTimeZone().tz(localStorage.getItem("timeZone") ? localStorage.getItem("timeZone") : zoneName).format("YYYY-MM-DD 23:59:59")).utc(),
      },
      companyOption: [],
      filters: {
        company: [],
        area: [],
        division: [],
        region: [],
        territory: [],
      },
      companySize: '',
      haveParentId:false,
      assignedOption: [
        {
          id: "",
          name: "",
        },
      ],
      accountOption: [
        {
          id: "",
          name: "",
        },
      ],
      accountsOption:[
        {
          _id:"",
          name:""
        }
      ],
      accountsConfig:{
        anchorElAccounts: null,
        config: {
          id: "accounts_filter_select",
        },
      },
      assigneeConfig: {
        anchorElAssignee: null,
        config: {
          id: "assignee_filter_select",
        },
      },
      accountConfig: {
        anchorElAccount: null,
        config: {
          id: "account_filter_select",
        },
      },
      companyId: userData.companyId,
      departmentId: "",
      userId: "",
      accountid: "",
      accountNewId:"",
      companySizeFilter: "large",
      accountName: "",
      companyFilter: "",
      searchAccountsValue:"",
      divisionFilter: "",
      areaFilter: "",
      regionFilter: "",
      territoryFilter: "",
      roleFilter: "",
      roleFiltername: "",
      searchFilter: "",
      orderBy: "name",
      departmentName: "",
      accountFilterName:"",
      order: "asc",
      loading: true,
      compFilter: false,
      regionName: "",
      territoryName: "",
      divisionName: "",
      level: "",
      levelId: "",
      StatusFilterName: "",
      statusFilter: "department",
      StatusConfig: {
        config: {
          id: "status_filter_select",
        },
        anchorElStatus: null,
      },
      statusConfig: {
        config: {
          id: "status_filter_select",
        },
        anchorElStatus: null,
      },
      descriptioneror: {
        error: "",
      },
      companyConfig: {
        anchorElCompany: null,
        config: {
          id: "company_filter_select",
        },
      },
      searchCompanyValue: "",
      divisionConfig: {
        anchorElDivision: null,
        config: {
          id: "division_filter_select",
        },
      },
      areaConfig: {
        anchorElArea: null,
        config: {
          id: "area_filter_select",
        },
      },
      regionConfig: {
        anchorElRegion: null,
        config: {
          id: "region_filter_select",
        },
      },
      territoryConfig: {
        anchorElTerritory: null,
        config: {
          id: "territory_filter_select",
        },
      },
      roleConfig: {
        anchorElRole: null,
        config: {
          id: "role_filter_select",
        },
      },
      RepChartData: {
        xAxis: [],
        yAxis: [],
        plotOptions: {
          bar: {
            stacking: "percent",
            pointWidth: 25,
            minPointLength: 2,
          },
        },
        config: {},
        tooltip: {
          pointFormat: `{series.name}: <b>{point.y:.2f} hr</b>`,
        },
        startDate: momentTimeZone(momentTimeZone()
          .startOf("week")
          .tz(localStorage.getItem("timeZone") ? localStorage.getItem("timeZone") : zoneName)
          .format("YYYY-MM-DD 00:00:00")).utc(),
        endDate: momentTimeZone(momentTimeZone()
          .endOf("week")
          .tz(localStorage.getItem("timeZone") ? localStorage.getItem("timeZone") : zoneName)
          .format("YYYY-MM-DD 23:59:59")).utc(),
      },
      AccSatisfactionData: {
        xAxis: [],
        yAxis: [],
        xAxisName: "",
        yAxisName: "",
        plotOptions: {
          bar: {
            dataLabels: {
              enabled: true
            }
          }
        },
        config: {},
        tooltip: {
          pointFormat: `{series.name}: <b>{point.y}</b>`,
        },
        startDate: momentTimeZone(momentTimeZone()
          .startOf("week")
          .tz(localStorage.getItem("timeZone") ? localStorage.getItem("timeZone") : zoneName)
          .format("YYYY-MM-DD 00:00:00")).utc(),
        endDate: momentTimeZone(momentTimeZone()
          .endOf("week")
          .tz(localStorage.getItem("timeZone") ? localStorage.getItem("timeZone") : zoneName)
          .format("YYYY-MM-DD 23:59:59")).utc(),
      },
      AccSatisfactionDatas: {
        xAxis: [],
        yAxis: [],
        xAxisName: "",
        yAxisName: "",
        plotOptions: {
          bar: {

            pointWidth: 25,
            minPointLength: 2,
          },
          column: {
            color: '#7283e2',
            stacking: "percent",
            pointPadding: 0.2,
            borderWidth: 0,
            pointWidth: 25,
            minPointLength: 2,
          }
        },
        config: {},
        tooltip: {
          pointFormat: `{series.name}: <b>{point.y} %</b>`,
        },
        startDate: momentTimeZone(momentTimeZone()
          .startOf("week")
          .tz(localStorage.getItem("timeZone") ? localStorage.getItem("timeZone") : zoneName)
          .format("YYYY-MM-DD 00:00:00")).utc(),
        endDate: momentTimeZone(momentTimeZone()
          .endOf("week")
          .tz(localStorage.getItem("timeZone") ? localStorage.getItem("timeZone") : zoneName)
          .format("YYYY-MM-DD 23:59:59")).utc(),
      },
      AccSatisfactionData: {
        xAxis: [],
        yAxis: [],
        xAxisName: "",
        yAxisName: "",
        plotOptions: {
          bar: {

            pointWidth: 25,
            minPointLength: 2,
          },
          column: {
            color: '#7283e2',
            stacking: "percent",
            pointPadding: 0.2,
            borderWidth: 0,
            pointWidth: 25,
            minPointLength: 2,
          }
        },
        config: {},
        tooltip: {
          pointFormat: `{series.name}: <b>{point.y}</b>`,
        },
        startDate: momentTimeZone(momentTimeZone()
          .startOf("week")
          .tz(localStorage.getItem("timeZone") ? localStorage.getItem("timeZone") : zoneName)
          .format("YYYY-MM-DD 00:00:00")).utc(),
        endDate: momentTimeZone(momentTimeZone()
          .endOf("week")
          .tz(localStorage.getItem("timeZone") ? localStorage.getItem("timeZone") : zoneName)
          .format("YYYY-MM-DD 23:59:59")).utc(),
      },
      activeReportData: {
        xAxis: [],
        yAxis: [],
        xAxisName: "",
        yAxisName: "",
        plotOptions: {
          bar: {
            stacking: "normal",
            pointWidth: 25,
            minPointLength: 2,
            color: "#A93BAC",
          },
        },
        config: {},
        tooltip: {
          pointFormat: `{series.name}: <b>{point.y}</b>`,
        },
        startDate: momentTimeZone(momentTimeZone()
          .startOf("week")
          .tz(localStorage.getItem("timeZone") ? localStorage.getItem("timeZone") : zoneName)
          .format("YYYY-MM-DD 00:00:00")).utc(),
        endDate: momentTimeZone(momentTimeZone()
          .endOf("week")
          .tz(localStorage.getItem("timeZone") ? localStorage.getItem("timeZone") : zoneName)
          .format("YYYY-MM-DD 23:59:59")).utc(),
      },
      activeAccountData: {
        xAxis: [],
        yAxis: [],
        xAxisName: "",
        yAxisName: "",
        plotOptions: {
          bar: {
            stacking: "normal",
            pointWidth: 25,
            minPointLength: 2,
            color: "#CF9339",
          },
        },
        config: {},
        tooltip: {
          pointFormat: `{series.name}: <b>{point.y}</b>`,
        },
        startDate: momentTimeZone(momentTimeZone()
          .startOf("week")
          .tz(localStorage.getItem("timeZone") ? localStorage.getItem("timeZone") : zoneName)
          .format("YYYY-MM-DD 00:00:00")).utc(),
        endDate: momentTimeZone(momentTimeZone()
          .endOf("week")
          .tz(localStorage.getItem("timeZone") ? localStorage.getItem("timeZone") : zoneName)
          .format("YYYY-MM-DD 23:59:59")).utc(),
      },

      PipelineReportData: {
        xAxis: [],
        yAxis: [],
        plotOptions: {
          pie: {
            allowPointSelect: true,
            cursor: 'pointer',
            dataLabels: {
              enabled: true,
            }, showInLegend: true
          },
        },
        config: {
          accessibility: {
            point: {
              valueSuffix: '%'
            }
          },
        },    
        tooltip: {
          pointFormat: `{series.name}: <b>{point.y:.2f} hr</b>`
        },
        startDate: momentTimeZone(momentTimeZone()
          .startOf("week")
          .tz(localStorage.getItem("timeZone") ? localStorage.getItem("timeZone") : zoneName)
          .format("YYYY-MM-DD 00:00:00")).utc(),
        endDate: momentTimeZone(momentTimeZone()
          .endOf("week")
          .tz(localStorage.getItem("timeZone") ? localStorage.getItem("timeZone") : zoneName)
          .format("YYYY-MM-DD 23:59:59")).utc(),
      },
      reportType: "Activity Report",
      tableData: [],
      tableHeaders: [],
      accountRep: [],
      anchorEl: null,
    };
  }

  /**
   * @author Murali
   * To get events and tasks for the current month
   */
  componentWillMount = () => {
    const { role, dateFilter } = this.state;

    this.companyListHandler("");
    let data = {
      groupBy: this.state.statusFilter,
      startDate: dateFilter.startDate,
      endDate: dateFilter.endDate,
    };
    this.fetchAccSatisfactionData(data, "/task/uncovered");
  };
  componentDidMount = () => {
    window.scrollTo(0, 0);
    this.checkParentId();
    const { page, rowsPerPage, role } = this.state;

    let data = {
      roles: role,
    };
  };
  disabledDate(current) {
    // Can not select days after today and today
    return current > momentTimeZone().endOf('day');
  }
  fetchRepUtilizationData = (data) => {
    // AxiosInstance(8016, "application/json")
    //   .post("/report/repUtilizationChart", data)
    //   .then((resp) => {
    //     var response = resp && resp.data;
    //     if (
    //       response &&
    //       (response.status === "200" || response.status === 200)
    //     ) {
    //       const { RepChartData } = this.state;
    //       RepChartData.xAxis = response.data.xaxis;
    //       RepChartData.yAxis = response.data.yaxis;
    //       this.setState({
    //         RepChartData,
    //         tableData: response.tableData,
    //         tableHeaders: response.tableHeaders,
    //       });
    //     } else if (
    //       response &&
    //       (response.status === "400" || response.status === 400)
    //     ) {
    //       let props = {
    //         message: response.msg,
    //         icon: <img src={iconNotificationError} alt="success" />,
    //         type: "error",
    //         placement: "bottomRight",
    //         duration: 3,
    //         top: 200,
    //         className: "task_notification error-notify",
    //       };
    //       NotificationComponent(props);
    //     }
    //   })
    //   .catch((err) => {
    //     var error = err.response;
    //     if (error && (error.status === "403" || error.status === 403)) {
    //       localStorage.clear();
    //       setTimeout(() => {
    //         window.location.href = "/";
    //       }, 500);
    //     } else if (error && (error.status === "500" || error.status === 500)) {
    //       let propserror = {
    //         message: "Something went wrong",
    //         icon: <img src={iconNotificationError} alt="success" />,
    //         type: "error",
    //         placement: "bottomRight",
    //         duration: 3,
    //         top: 200,
    //         className: "task_notification error-notify",
    //       };
    //       NotificationComponent(propserror);
    //     }
    //   });
  };
  fetchAccSatisfactionData = (data, type) => {
    AxiosInstance((process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa")
      ? 4007
      : "clinical-report", "application/json")
      .post("/report" + type, data)
      .then((resp) => {
        var response = resp && resp.data;
        if (
          response &&
          (response.status === "200" || response.status === 200)
        ) {
          const { AccSatisfactionData , AccSatisfactionDatas} = this.state;
          AccSatisfactionData.xAxis = response.data.xaxis;
          AccSatisfactionData.yAxis = response.data.yaxis;
          AccSatisfactionDatas.xAxis = response.data.xaxis;
          AccSatisfactionDatas.yAxis = response.data.yaxis;
          if (type === "completed") {
            AccSatisfactionData.yAxisName = "Account Satisfaction";
            AccSatisfactionData.xAxisName = "";
          } else if (type === "uncovered") {
            AccSatisfactionData.yAxisName = "Uncovered Tasks";
            AccSatisfactionData.xAxisName = "";
          } else if (type === "cancelled") {
            AccSatisfactionData.yAxisName = "Cancellations by Account";
            AccSatisfactionData.xAxisName = "";
          }
          this.setState({
            AccSatisfactionData,
            AccSatisfactionDatas,
            tableData: response.tableData,
            tableHeaders: response.tableHeaders,
          });
        } else if (
          response &&
          (response.status === "400" || response.status === 400)
        ) {
          let props = {
            message: response.msg,
            icon: <img src={iconNotificationError} alt="success" />,
            type: "error",
            placement: "bottomRight",
            duration: 3,
            top: 200,
            className: "task_notification error-notify",
          };
          NotificationComponent(props);
        } 
      })
      .catch((err) => {
        var error = err.response;
        if (error && (error.status === "403" || error.status === 403)) {
          localStorage.clear();
          setTimeout(() => {
            window.location.href = "/";
          }, 500);
        } else if (error && (error.status === "500" || error.status === 500)) {
          let propserror = {
            message: "Something went wrong",
            icon: <img src={iconNotificationError} alt="success" />,
            type: "error",
            placement: "bottomRight",
            duration: 3,
            top: 200,
            className: "task_notification error-notify",
          };
          NotificationComponent(propserror);
        }
      });
  };
  fetchPipelinePiChartData = (data, url) => {
    AxiosInstance((process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa")
      ? 4007
      : "clinical-report", "application/json")
      .post(url, data)
      .then((resp) => {
        var response = resp && resp.data;
        if (
          response &&
          (response.status === "200" || response.status === 200)
        ) {
          const { PipelineReportData, RepChartData } = this.state;
          //  RepChartData.xAxis = response.data.xaxis;
          PipelineReportData.yAxis = response.data;
          this.setState({
            PipelineReportData, tableData: response.tableData,
            tableHeaders: response.tableHeaders,
          });
        } else if (
          response &&
          (response.status === "400" || response.status === 400)
        ) {
          let props = {
            message: response.msg,
            icon: <img src={iconNotificationError} alt="success" />,
            type: "error",
            placement: "bottomRight",
            duration: 3,
            top: 200,
            className: "task_notification error-notify",
          };
          NotificationComponent(props);
        }
      })
      .catch((err) => {
        var error = err.response;
        if (error && (error.status === "403" || error.status === 403)) {
          localStorage.clear();
          setTimeout(() => {
            window.location.href = "/";
          }, 500);
        } else if (error && (error.status === "500" || error.status === 500)) {
          let props = {
            message: 'Something went wrong',
            icon: <img src={iconNotificationError} alt="success" />,
            type: "error",
            placement: "bottomRight",
            duration: 3,
            top: 200,
            className: "task_notification error-notify",
          };
          NotificationComponent(props);
        } else if (error && (error.status === "404" || error.status === 404)) {
          let props = {
            message: 'Something went wrong',
            icon: <img src={iconNotificationError} alt="success" />,
            type: "error",
            placement: "bottomRight",
            duration: 3,
            top: 200,
            className: "task_notification error-notify",
          };
          NotificationComponent(props);
        }
      });

  }
  fetchactiveReportData = (data, type) => {
    AxiosInstance((process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa")
      ? 4007
      : "clinical-report", "application/json")
      .post("/report/mostActive/" + type, data)
      .then((resp) => {
        var response = resp && resp.data;
        if (
          response &&
          (response.status === "200" || response.status === 200)
        ) {
          const { activeReportData, activeAccountData } = this.state;
          activeReportData.xAxis = response.data.xaxis;
          activeReportData.yAxis = response.data.yaxis;
          activeAccountData.xAxis = response.data.xaxis;
          activeAccountData.yAxis = response.data.yaxis;
          if (type === "account") {
            activeAccountData.yAxisName = "Most Active Accounts";
            activeAccountData.xAxisName = "";
          } else if (type === "rep") {
            activeReportData.yAxisName = "Most Active Reps";
            activeReportData.xAxisName = "";
          }
          this.setState({
            activeReportData,
            tableData: response.tableData,
            tableHeaders: response.tableHeaders,
          });
        } else if (
          response &&
          (response.status === "400" || response.status === 400)
        ) {
          let props = {
            message: response.msg,
            icon: <img src={iconNotificationError} alt="success" />,
            type: "error",
            placement: "bottomRight",
            duration: 3,
            top: 200,
            className: "task_notification error-notify",
          };
          NotificationComponent(props);
        }
      })
      .catch((err) => {
        var error = err.response;
        if (error && (error.status === "403" || error.status === 403)) {
          localStorage.clear();
          setTimeout(() => {
            window.location.href = "/";
          }, 500);
        } else if (error && (error.status === "500" || error.status === 500)) {
          let propserror = {
            message: "Something went wrong",
            icon: <img src={iconNotificationError} alt="success" />,
            type: "error",
            placement: "bottomRight",
            duration: 3,
            top: 200,
            className: "task_notification error-notify",
          };
          NotificationComponent(propserror);
        }
      });
  };
  onDateChanage = (date, dateString) => {
    const {
      reportType,
    } = this.state;
    if (dateString[0] !== "" && dateString[1] !== "") {
      this.setState(
        {
          dateFilter: {
            startDate:dateString[0],// momentTimeZone(dateString[0]).format("YYYY-MM-DD"),
            endDate: dateString[1]// momentTimeZone(dateString[1]).format("YYYY-MM-DD"),
          },
        },
        () => {

          let ActiveCompany = {
            departmentId: this.state.departmentId ? this.state.departmentId : null,

            startDate:moment(moment(date[0]._d).tz(this.state.timeZoneName).format("YYYY-MM-DD 00:00:00")).utc(),// momentTimeZone(dateString[0]).format("YYYY-MM-DD"),
            endDate: moment(moment(date[1]._d).tz(this.state.timeZoneName).format("YYYY-MM-DD 23:59:59")).utc(),//momentTimeZone(dateString[1]).format("YYYY-MM-DD"),
          };
          let ActiveReps = {
            startDate:moment(moment(date[0]._d).tz(this.state.timeZoneName).format("YYYY-MM-DD 00:00:00")).utc(),// momentTimeZone(dateString[0]).format("YYYY-MM-DD"),
            endDate: moment(moment(date[1]._d).tz(this.state.timeZoneName).format("YYYY-MM-DD 23:59:59")).utc(),//momentTimeZone(dateString[1]).format("YYYY-MM-DD"),
            departmentId: this.state.departmentId ? this.state.departmentId : null,
            companyId: this.state.accountid ? this.state.accountid : null,
          };

          let data = {
            companyId: this.state.statusFilter === "department" && this.state.accountid ? this.state.accountid : null,
            departmentId: this.state.statusFilter === "company" && this.state.departmentId ? this.state.departmentId : null,
            groupBy: this.state.statusFilter,
            startDate: moment(moment(date[0]._d).tz(this.state.timeZoneName).format("YYYY-MM-DD 00:00:00")).utc(),//momentTimeZone(dateString[0]).format("YYYY-MM-DD"),
            endDate: moment(moment(date[1]._d).tz(this.state.timeZoneName).format("YYYY-MM-DD 23:59:59")).utc(),//momentTimeZone(dateString[1]).format("YYYY-MM-DD"),
          };
          if(this.state.accountNewId !== "" && (reportType  === "Incomplete task reports" || reportType === "Activity Report" || reportType === "Procedure Report")){
            data.accountId=this.state.accountNewId;
          }
      
          if (reportType === "Sales Rep Utilization") {
            this.fetchRepUtilizationData(data);
          } else if (reportType === "Account Satisfaction") {
            this.fetchAccSatisfactionData(data, "/task/completed");
          } else if (reportType === "Incomplete task reports") {
            this.fetchAccSatisfactionData(data, "/incompleteTasks");
          }else if (reportType === "Activity Report") {
            this.fetchAccSatisfactionData(data, "/task/completed");
          } else if (reportType === "Procedure Report") {
            this.fetchAccSatisfactionData(data, "/procedureReport");
          } 
          else if (reportType === "Uncovered Tasks") {
            this.fetchAccSatisfactionData(data, "/task/uncovered");
          } else if (reportType === "Products Breakdown") {
            this.fetchPipelinePiChartData(ActiveReps, "/report/productDetails");
          } else if (reportType === "Cancellations by Account") {
            this.fetchAccSatisfactionData(data, "/task/cancelled");
          } else if (reportType === "Pipeline Report") {
            this.fetchPipelinePiChartData(data);
          } else if (reportType === "Most Active Accounts") {
            this.fetchactiveReportData(ActiveCompany, "company");
          } else if (reportType === "Most Active Reps") {
            this.fetchactiveReportData(ActiveReps, "employee");
          }
        }
      );
    } else {
      var zoneName = momentTimeZone.tz.guess();
      this.setState(
        {
          dateFilter: {
            startDate: momentTimeZone().tz(localStorage.getItem("timeZone") ? localStorage.getItem("timeZone") : zoneName).format("YYYY-MM-DD 00:00:00"),
            endDate: momentTimeZone().tz(localStorage.getItem("timeZone") ? localStorage.getItem("timeZone") : zoneName).format("YYYY-MM-DD 23:59:59"),

          },
        },
        () => {
          let ActiveCompany = {
            departmentId: this.state.departmentId ? this.state.departmentId : null,

            startDate: momentTimeZone().tz(localStorage.getItem("timeZone") ? localStorage.getItem("timeZone") : zoneName).format("YYYY-MM-DD 00:00:00"),
            endDate: momentTimeZone().tz(localStorage.getItem("timeZone") ? localStorage.getItem("timeZone") : zoneName).format("YYYY-MM-DD 23:59:59"),
          };
          let ActiveReps = {
            startDate: momentTimeZone().tz(localStorage.getItem("timeZone") ? localStorage.getItem("timeZone") : zoneName).format("YYYY-MM-DD 00:00:00"),
            endDate: momentTimeZone().tz(localStorage.getItem("timeZone") ? localStorage.getItem("timeZone") : zoneName).format("YYYY-MM-DD 23:59:59"),
            departmentId: this.state.departmentId ? this.state.departmentId : null,
            companyId: this.state.accountid ? this.state.accountid : null,
          };

          let data = {
            companyId: this.state.statusFilter === "department" && this.state.accountid ? this.state.accountid : null,
            departmentId: this.state.statusFilter === "company" && this.state.departmentId ? this.state.departmentId : null,
            groupBy: this.state.statusFilter,
            startDate: momentTimeZone().tz(localStorage.getItem("timeZone") ? localStorage.getItem("timeZone") : zoneName).format("YYYY-MM-DD 00:00:00"),
            endDate: momentTimeZone().tz(localStorage.getItem("timeZone") ? localStorage.getItem("timeZone") : zoneName).format("YYYY-MM-DD 23:59:59"),
          };
          if(this.state.accountNewId !== "" && (reportType  === "Incomplete task reports" || reportType === "Activity Report" || reportType === "Procedure Report")){
              data.accountId=this.state.accountNewId;
            }
          if (reportType === "Sales Rep Utilization") {
            this.fetchRepUtilizationData(data);
          } else if (reportType === "Account Satisfaction") {
            this.fetchAccSatisfactionData(data, "/task/completed");
          } else if (reportType === "Incomplete task reports") {
            this.fetchAccSatisfactionData(data, "/incompleteTasks");
          }else if (reportType === "Activity Report") {
            this.fetchAccSatisfactionData(data, "/task/completed");
          }else if (reportType === "Procedure Report") {
            this.fetchAccSatisfactionData(data, "/procedureReport");
          } 
          else if (reportType === "Uncovered Tasks") {
            this.fetchAccSatisfactionData(data, "/task/uncovered");
          } else if (reportType === "Cancellations by Account") {
            this.fetchAccSatisfactionData(data, "/task/cancelled");
          } else if (reportType === "Pipeline Report") {
            this.fetchPipelinePiChartData(data);
          } else if (reportType === "Most Active Accounts") {
            this.fetchactiveReportData(data, "account");
          } else if (reportType === "Most Active Reps") {
            this.fetchactiveReportData(data, "employee");
          }
        }
      );
    }
  };
  getReports = (value) => {
    const {
      dateFilter,
    } = this.state;
    this.setState({ reportType: value });
    let ActiveCompany = {
      departmentId: this.state.departmentId ? this.state.departmentId : null,
      startDate: dateFilter.startDate,
      endDate: dateFilter.endDate,
    };
    let ActiveReps = {
      startDate: dateFilter.startDate,
      endDate: dateFilter.endDate,
      departmentId: this.state.departmentId ? this.state.departmentId : null,
      companyId: this.state.accountid ? this.state.accountid : null,
    };

    let data = {
      companyId: this.state.statusFilter === "department" && this.state.accountid ? this.state.accountid : null,
      departmentId: this.state.statusFilter === "company" && this.state.departmentId ? this.state.departmentId : null,
      groupBy: this.state.statusFilter,
      startDate: dateFilter.startDate,
      endDate: dateFilter.endDate,
    };
    if(this.state.accountNewId !== "" && (value  === "Incomplete task reports" || value === "Activity Report" || value === "Procedure Report")){
      data.accountId=this.state.accountNewId;
    }
    if (value === "Sales Rep Utilization") {
      this.fetchRepUtilizationData(data);
    } else if (value === "Account Satisfaction") {
      this.fetchAccSatisfactionData(data, "/task/completed");
    } else if (value === "Incomplete task reports") {
      this.fetchAccSatisfactionData(data, "/incompleteTasks");
    }else if (value === "Activity Report") {
      this.fetchAccSatisfactionData(data, "/task/completed");
    }else if (value === "Procedure Report") {
      this.fetchAccSatisfactionData(data, "/procedureReport");
    } 
    else if (value === "Uncovered Tasks") {
      this.fetchAccSatisfactionData(data, "/task/uncovered");
    } else if (value === "Cancellations by Account") {
      this.fetchAccSatisfactionData(data, "/task/cancelled");
    } else if (value === "Products Breakdown") {
      this.fetchPipelinePiChartData(ActiveReps, "/report/productDetails");
    } else if (value === "Pipeline Report") {
      this.fetchPipelinePiChartData(data, "/report/pipeLine");
    } else if (value === "Most Active Accounts") {
      this.fetchactiveReportData(ActiveCompany, "company");
    } else if (value === "Most Active Reps") {
      this.fetchactiveReportData(ActiveReps, "employee");
    }
  };

  /**
   * @author Murali
   * To open territory filter menu
   */
  openCompanyFilterMenu = (event) => {
    const { companyConfig } = this.state;
    companyConfig.anchorElCompany = event.currentTarget;
    this.setState(
      {
        companyConfig,
      },
      () => { }
    );
  };
  /**
   * @author Murali
   * To close territory filter menu
   */
  closeCompanyFilterMenu = () => {
    const { companyConfig } = this.state;
    companyConfig.anchorElCompany = null;
    this.setState({
      companyConfig,
    });
  };
  /**
   * @author Murali
   * To display territory based on search
   */
  onCompanySearchFilter = (e) => {
    const { value } = e.target;
    this.setState({
      searchCompanyValue: value,
    });
    this.companyListHandler(value);
  };
  /**
   * @author Murali
   * To display data based on selected territory
   */
  handleCompanySelect = (item) => {
    const { companyConfig, dateFilter, reportType } = this.state;
    companyConfig.anchorElCompany = null;
    this.setState(
      {
        companyFilter: item._id,
        companyName: item.name === "All" ? "" : item.name,
        companySize: item.size,
        companySizeFilter: item.size,
        companyConfig,
        searchCompanyValue: "",
        salesrepName: "",
        compFilter: true,
        loading: true,
        level: "",
        levelId: "",
        salesrepOption: [
          {
            _id: "",
            name: "All",
          },
        ],
      },

    );
  };
  /**
   * @author Murali
   * To get territory names in filter
   */
  companyListHandler = (search) => {
    const { companyOption, dateFilter, reportType } = this.state;

    let data = {
      search: search,
    };
    AxiosInstance((process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa")
      ? 4004
      : "clinical-task", "application/x-www-form-urlencoded; charset=UTF-8")
      .post("/companyFilter", data)
      .then((res) => {
        var response = res.data;
        if (
          response &&
          (response.status === 200 || response.status === "200")
        ) {
          var data = response.data;
          this.setState(
            {
              companyFilter: data[0]._id,
              companySize: data[0].size,
              companyName: data[0].name,
            },

          );
          this.setState({
            companyOption: res.data.data,
          })
          var newArray = companyOption.splice();
          // if (this.state.searchCompanyValue === "") {
          //   newArray.unshift({ _id: "", name: "All" });
          // } else {
          //   newArray.shift({ _id: "", name: "All" });
          // }
          if (data.length > 0) {
            data.map((item) => {
              var obj = {};
              obj._id = item._id;
              obj.name = item.name;
              obj.size = item.size;
              newArray.push(obj);
            });
          }
          // this.setState({
          //   companyOption: newArray,
          // });
        } else {
          this.setState({
            companyOption: [
              {
                _id: "",
                name: "All",
              },
            ],
          });
        }
      })
      .catch((err) => {
        var error = err.response;
        if (error && (error.status === "403" || error.status === 403)) {
          localStorage.clear();
          setTimeout(() => {
            window.location.href = "/";
          }, 500);
        } else {
          console.log("Internal Server Error");
        }
      });
  };
  openDivisionFilterMenu = (event) => {
    const { divisionConfig } = this.state;
    divisionConfig.anchorElDivision = event.currentTarget;
    this.setState({
      divisionConfig,
    });
  };
  /**
   *
   * @param {item object} item
   * TO ADD STATUS FILTER VALUE ON SELECT
   */
  handleDivisionSelect = (item) => {
    this.setState({ loading: true });
    var {
      divisionFilter,
      divisionName,
      divisionConfig,
      role,
      companyId,
      companyFilter,
      companySizeFilter,
      companySize,
      reportType,
      dateFilter,
    } = this.state;
    divisionFilter = item._id;
    divisionName = item.name;
    divisionConfig.anchorElDivision = null;
    this.setState(
      {
        divisionConfig,
        divisionName,
        divisionFilter,
        areaFilter: "",
        regionFilter: "",
        territoryFilter: "",
        level: "division",
        levelId: divisionFilter,
      },
      () => {
        let data = "";
        if (divisionFilter !== "") {
          data = {
            companyId: role === "super admin" ? companyFilter : companyId,
            companySize:
              role === "super admin" ? companySizeFilter : companySize,
            level: "division",
            levelId: divisionFilter,
            startDate: dateFilter.startDate,
            endDate: dateFilter.endDate,
          };
        } else {
          data = {
            companyId: role === "super admin" ? companyFilter : companyId,
            companySize:
              role === "super admin" ? companySizeFilter : companySize,
            startDate: dateFilter.startDate,
            endDate: dateFilter.endDate,
          };
        }

        if (reportType === "Sales Rep Utilization") {
          this.fetchRepUtilizationData(data);
        } else if (reportType === "Account Satisfaction") {
          this.fetchAccSatisfactionData(data, "/task/completed");
        } else if (reportType === "Incomplete task reports") {
          this.fetchAccSatisfactionData(data, "/incompleteTasks");
        }else if (reportType === "Activity Report") {
          this.fetchAccSatisfactionData(data, "/task/completed");
        }else if (reportType === "Procedure Report") {
          this.fetchAccSatisfactionData(data, "/procedureReport");
        } 
        else if (reportType === "Uncovered Tasks") {
          this.fetchAccSatisfactionData(data, "/task/uncovered");
        } else if (reportType === "Cancellations by Account") {
          this.fetchAccSatisfactionData(data, "/task/cancelled");
        } else if (reportType === "Pipeline Report") {
          this.fetchPipelinePiChartData(data);
        } else if (reportType === "Most Active Accounts") {
          this.fetchactiveReportData(data, "account");
        } else if (reportType === "Most Active Reps") {
          this.fetchactiveReportData(data, "employee");
        }
        this.getFilters("divisionFilter");
      },
      setTimeout(() => {
        this.setState({ loading: false });
      }, 1000)
    );
  };
  /**
   *
   * TO HANDLE STATUS MENU CLOSE
   */
  closeDivisionFilterMenu = () => {
    const { divisionConfig } = this.state;
    divisionConfig.anchorElDivision = null;
    this.setState({
      divisionConfig,
    });
  };
  openAreaFilterMenu = (event) => {
    const { areaConfig } = this.state;
    areaConfig.anchorElArea = event.currentTarget;
    this.setState({
      areaConfig,
    });
  };
  /**
   *
   * @param {item object} item
   * TO ADD STATUS FILTER VALUE ON SELECT
   */
  handleAreaSelect = (item) => {
    this.setState({ loading: true });
    var {
      areaFilter,
      areaName,
      areaConfig,
      role,
      companyId,
      companyFilter,
      companySizeFilter,
      companySize,
      dateFilter,
      divisionFilter,
      reportType,
    } = this.state;
    areaFilter = item._id;
    areaName = item.name;
    areaConfig.anchorElArea = null;
    this.setState(
      {
        areaConfig,
        areaName,
        areaFilter,
        regionFilter: "",
        territoryFilter: "",
        level: "area",
        levelId: areaFilter,
      },
      () => {
        let size = role === "super admin" ? companySizeFilter : companySize;
        let data = "";
        if (size === "medium" && areaFilter === "") {
          data = {
            companyId: role === "super admin" ? companyFilter : companyId,
            companySize:
              role === "super admin" ? companySizeFilter : companySize,
            startDate: dateFilter.startDate,
            endDate: dateFilter.endDate,
          };
        } else {
          data = {
            companyId: role === "super admin" ? companyFilter : companyId,
            companySize:
              role === "super admin" ? companySizeFilter : companySize,
            level: areaFilter !== "" ? "area" : "division",
            levelId: areaFilter !== "" ? areaFilter : divisionFilter,
            startDate: dateFilter.startDate,
            endDate: dateFilter.endDate,
          };
        }

        if (reportType === "Sales Rep Utilization") {
          this.fetchRepUtilizationData(data);
        } else if (reportType === "Account Satisfaction") {
          this.fetchAccSatisfactionData(data, "/task/completed");
        } else if (reportType === "Incomplete task reports") {
          this.fetchAccSatisfactionData(data, "/incompleteTasks");
        }else if (reportType === "Activity Report") {
          this.fetchAccSatisfactionData(data, "/task/completed");
        }else if (reportType === "Procedure Report") {
          this.fetchAccSatisfactionData(data, "/procedureReport");
        } 
        else if (reportType === "Uncovered Tasks") {
          this.fetchAccSatisfactionData(data, "/task/uncovered");
        } else if (reportType === "Cancellations by Account") {
          this.fetchAccSatisfactionData(data, "/task/cancelled");
        } else if (reportType === "Pipeline Report") {
          this.fetchPipelinePiChartData(data);
        } else if (reportType === "Most Active Accounts") {
          this.fetchactiveReportData(data, "account");
        } else if (reportType === "Most Active Reps") {
          this.fetchactiveReportData(data, "employee");
        }
        this.getFilters("areaFilter");
      },
      setTimeout(() => {
        this.setState({ loading: false });
      }, 1000)
    );
  };
  /**
   *
   * TO HANDLE STATUS MENU CLOSE
   */
  closeAreaFilterMenu = () => {
    const { areaConfig } = this.state;
    areaConfig.anchorElArea = null;
    this.setState({
      areaConfig,
    });
  };
  openRegionFilterMenu = (event) => {
    const { regionConfig } = this.state;
    regionConfig.anchorElRegion = event.currentTarget;
    this.setState({
      regionConfig,
    });
  };
  /**
   *
   * @param {item object} item
   * TO ADD STATUS FILTER VALUE ON SELECT
   */
  handleRegionSelect = (item) => {
    this.setState({ loading: true });
    var {
      regionFilter,
      regionName,
      regionConfig,
      role,
      companyId,
      companyFilter,
      companySizeFilter,
      companySize,
      dateFilter,
      areaFilter,
      reportType,
    } = this.state;
    regionFilter = item._id;
    regionName = item.name;
    regionConfig.anchorElRegion = null;
    this.setState(
      {
        regionConfig,
        regionName,
        regionFilter,
        territoryFilter: "",
        level: "region",
        levelId: regionFilter,
      },
      () => {
        let size = role === "super admin" ? companySizeFilter : companySize;
        let data = "";
        if (size === "small" && regionFilter === "") {
          data = {
            companyId: role === "super admin" ? companyFilter : companyId,
            companySize:
              role === "super admin" ? companySizeFilter : companySize,
            startDate: dateFilter.startDate,
            endDate: dateFilter.endDate,
          };
        } else {
          data = {
            companyId: role === "super admin" ? companyFilter : companyId,
            companySize:
              role === "super admin" ? companySizeFilter : companySize,
            level: regionFilter !== "" ? "region" : "area",
            levelId: regionFilter !== "" ? regionFilter : areaFilter,
            startDate: dateFilter.startDate,
            endDate: dateFilter.endDate,
          };
        }

        if (reportType === "Sales Rep Utilization") {
          this.fetchRepUtilizationData(data);
        } else if (reportType === "Account Satisfaction") {
          this.fetchAccSatisfactionData(data, "/task/completed");
        } else if (reportType === "Incomplete task reports") {
          this.fetchAccSatisfactionData(data, "/incompleteTasks");
        }else if (reportType === "Activity Report") {
          this.fetchAccSatisfactionData(data, "/task/completed");
        }else if (reportType === "Procedure Report") {
          this.fetchAccSatisfactionData(data, "/procedureReport");
        } 
        else if (reportType === "Uncovered Tasks") {
          this.fetchAccSatisfactionData(data, "/task/uncovered");
        } else if (reportType === "Cancellations by Account") {
          this.fetchAccSatisfactionData(data, "/task/cancelled");
        } else if (reportType === "Pipeline Report") {
          this.fetchPipelinePiChartData(data);
        } else if (reportType === "Most Active Accounts") {
          this.fetchactiveReportData(data, "account");
        } else if (reportType === "Most Active Reps") {
          this.fetchactiveReportData(data, "employee");
        }
        this.getFilters("regionFilter");
      },
      setTimeout(() => {
        this.setState({ loading: false });
      }, 1000)
    );
  };
  /**
   *
   * TO HANDLE STATUS MENU CLOSE
   */
  closeRegionFilterMenu = () => {
    const { regionConfig } = this.state;
    regionConfig.anchorElRegion = null;
    this.setState({
      regionConfig,
    });
  };
  openTerritoryFilterMenu = (event) => {
    const { territoryConfig } = this.state;
    territoryConfig.anchorElTerritory = event.currentTarget;
    this.setState({
      territoryConfig,
    });
  };
  /**
   *
   * @param {item object} item
   * TO ADD STATUS FILTER VALUE ON SELECT
   */
  handleTerritorySelect = (item) => {
    this.setState({ loading: true });
    var {
      territoryFilter,
      territoryName,
      territoryConfig,
      role,
      companyId,
      companyFilter,
      companySizeFilter,
      companySize,
      dateFilter,
      reportType,
      regionFilter,
    } = this.state;
    territoryFilter = item._id;
    territoryName = item.name;
    territoryConfig.anchorElTerritory = null;
    this.setState(
      {
        territoryConfig,
        territoryName,
        territoryFilter,
        level: "territory",
        levelId: territoryFilter,
      },
      () => {
        let data = {
          companyId: role === "super admin" ? companyFilter : companyId,
          companySize: role === "super admin" ? companySizeFilter : companySize,
          level: territoryFilter !== "" ? "territory" : "region",
          levelId: territoryFilter !== "" ? territoryFilter : regionFilter,
          startDate: dateFilter.startDate,
          endDate: dateFilter.endDate,
        };
        if (reportType === "Sales Rep Utilization") {
          this.fetchRepUtilizationData(data);
        } else if (reportType === "Account Satisfaction") {
          this.fetchAccSatisfactionData(data, "/task/completed");
        } else if (reportType === "Incomplete task reports") {
          this.fetchAccSatisfactionData(data, "/incompleteTasks");
        }else if (reportType === "Activity Report") {
          this.fetchAccSatisfactionData(data, "/task/completed");
        }else if (reportType === "Procedure Report") {
          this.fetchAccSatisfactionData(data, "/procedureReport");
        } 
        else if (reportType === "Uncovered Tasks") {
          this.fetchAccSatisfactionData(data, "/task/uncovered");
        } else if (reportType === "Cancellations by Account") {
          this.fetchAccSatisfactionData(data, "/task/cancelled");
        } else if (reportType === "Pipeline Report") {
          this.fetchPipelinePiChartData(data);
        } else if (reportType === "Most Active Accounts") {
          this.fetchactiveReportData(data, "account");
        } else if (reportType === "Most Active Reps") {
          this.fetchactiveReportData(data, "employee");
        }
      },
      setTimeout(() => {
        this.setState({ loading: false });
      }, 1000)
    );
  };
  /**
   *
   * TO HANDLE STATUS MENU CLOSE
   */
  closeTerritoryFilterMenu = () => {
    const { territoryConfig } = this.state;
    territoryConfig.anchorElTerritory = null;
    this.setState({
      territoryConfig,
    });
  };


  /**
 *
 * TO HANDLE ASSIGNEE MENU OPEN
 */
  openAssigneeFilterMenu = (event) => {
    const { assigneeConfig } = this.state;
    assigneeConfig.anchorElAssignee = event.currentTarget;
    this.setState(
      {
        assigneeConfig,
      },
      () => {
        this.employeeFilter("");
      }
    );
  };

  openAccountNewFilterMenu = (event) => {
    const { accountsConfig } = this.state;
    accountsConfig.anchorElAccounts = event.currentTarget;
    this.setState(
      {
        accountsConfig,
      },
      () => {
        this.accountsNewFilter("");
      }
    );
  };

  onAccountNewSearchFilter = (e) => {
    const { name, value } = e.target;
    this.setState({
      searchAccountsValue: value,
    });
    this.accountsNewFilter(value);
  };

  checkParentId = () => {
    AxiosInstance((process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa")
    ? 4007
    : "clinical-report", "application/json")
      .get("/checkParent")
      .then((res) => {
        var response = res && res.data;
        if (
          response &&
          (response.status === 200 || response.status === "200")
        ) {
          this.setState({
            haveParentId: response.data.parentAccount,
          });
        } else {
          this.setState({
            haveParentId:false
          });
        }
      })
      .catch((err) => {
        var error = err.response;
        if (error && (error.status === "403" || error.status === 403)) {
          localStorage.clear();
          setTimeout(() => {
            window.location.href = "/";
          }, 500);
        } else {
          console.log("Internal Server Error");
        }
      });
  };

  accountsNewFilter = (search) => {
    const { accountsOption } = this.state;
    AxiosInstance((process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa")
    ? 4007
    : "clinical-report", "application/json")
      .post("/listAccounts", { search })
      .then((res) => {
        var response = res && res.data;
        if (
          response &&
          (response.status === 200 || response.status === "200")
        ) {
          var data = response.data;
          var newArray = accountsOption.splice();
          if (this.state.searchAccountsValue === "") {
            newArray.unshift({ _id: "", name: "All" });
          } else {
            newArray.shift({ _id: "", name: "All" });
          }
          if (data.length > 0) {
            data.map((item) => {
              var obj = {};
              obj._id = item._id;
              obj.name = item.accountName;
              newArray.push(obj);
            });
          }
          this.setState({
            accountsOption: newArray,
          });
        } else {
          this.setState({
            accountsOption: [
              {
                _id: "",
                name: "All",
              },
            ],
          });
        }
      })
      .catch((err) => {
        var error = err.response;
        if (error && (error.status === "403" || error.status === 403)) {
          localStorage.clear();
          setTimeout(() => {
            window.location.href = "/";
          }, 500);
        } else {
          console.log("Internal Server Error");
        }
      });
  };

  /**
   *
   * @param {value} search
   * TO FETCH THE EMPLOYEE FILTER
   */
  employeeFilter = (search) => {
    const { assignedOption } = this.state;

    AxiosInstance((process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa")
      ? 4004
      : "clinical-task", "application/x-www-form-urlencoded; charset=UTF-8")
      .post("/departmentSearch", { search })
      .then((res) => {
        var response = res.data;
        if (
          response &&
          (response.status === 200 || response.status === "200")
        ) {
          var data = response.data;
          var newArray = assignedOption.splice();
          if (this.state.searchAssigneeValue === "") {
            newArray.unshift({ _id: "", name: "All" });
          } else {
            newArray.shift({ _id: "", name: "All" });
          }
          if (data.length > 0) {
            data.map((item) => {
              var obj = {};
              obj._id = item._id;
              obj.name = item.name;
              newArray.push(obj);
            });
          }
          this.setState({
            assignedOption: newArray,
          });
        } else {
          this.setState({
            assignedOption: [
              {
                _id: "",
                name: "All",
              },
            ],
          });
        }
      })
      .catch((err) => {
        var error = err.response;
        if (error && (error.status === "403" || error.status === 403)) {
          localStorage.clear();
          setTimeout(() => {
            window.location.href = "/";
          }, 500);
        } else {
          console.log("Internal Server Error");
        }
      });
  };
  /**
   *
   * @param {item object} item
   * TO ADD ASSIGNEE FILTER VALUE ON SELECT
   */
  handleAssigneeSelect = (item) => {
    const { assigneeConfig, reportType, dateFilter, departmentId, accountid } = this.state;
    assigneeConfig.anchorElAssignee = null;

    this.setState(
      {
        departmentId: item._id,
        departmentName: item.name === "All" ? "" : item.name,
        assigneeConfig,
        searchAssigneeValue: "",
      },

      () => {

        let ActiveCompany = {
          departmentId: this.state.departmentId ? this.state.departmentId : null,
          startDate: dateFilter.startDate,
          endDate: dateFilter.endDate,
        };
        let ActiveReps = {
          startDate: dateFilter.startDate,
          endDate: dateFilter.endDate,
          departmentId: this.state.departmentId ? this.state.departmentId : null,
          companyId: this.state.accountid ? this.state.accountid : null,
        };

        let data = {
          companyId: this.state.statusFilter === "department" && this.state.accountid ? this.state.accountid : null,
          departmentId: this.state.statusFilter === "company" && this.state.departmentId ? this.state.departmentId : null,
          groupBy: this.state.statusFilter,
          startDate: dateFilter.startDate,
          endDate: dateFilter.endDate,
        };
        if(this.state.accountNewId !== "" && (reportType  === "Incomplete task reports" || reportType === "Activity Report" || reportType === "Procedure Report")){
          data.accountId=this.state.accountNewId;
        }
        if (reportType === "Sales Rep Utilization") {
          this.fetchRepUtilizationData(data);
        } else if (reportType === "Account Satisfaction") {
          this.fetchAccSatisfactionData(data, "/task/completed");
        } else if (reportType === "Incomplete task reports") {
          this.fetchAccSatisfactionData(data, "/incompleteTasks");
        }else if (reportType === "Activity Report") {
          this.fetchAccSatisfactionData(data, "/task/completed");
        }else if (reportType === "Procedure Report") {
          this.fetchAccSatisfactionData(data, "/procedureReport");
        } 
        else if (reportType === "Uncovered Tasks") {
          this.fetchAccSatisfactionData(data, "/task/uncovered");
        } else if (reportType === "Products Breakdown") {
          this.fetchPipelinePiChartData(ActiveReps, "/report/productDetails");
        } else if (reportType === "Cancellations by Account") {
          this.fetchAccSatisfactionData(data, "/task/cancelled");
        } else if (reportType === "Pipeline Report") {
          this.fetchPipelinePiChartData(data);
        } else if (reportType === "Most Active Accounts") {
          this.fetchactiveReportData(ActiveCompany, "company");
        } else if (reportType === "Most Active Reps") {
          this.fetchactiveReportData(ActiveReps, "employee");
        }
      }
    );
  };
  /**
  *
  * TO HANDLE ASSIGNEE MENU CLOSE
  */
  closeAssigneeFilterMenu = () => {
    const { assigneeConfig } = this.state;
    assigneeConfig.anchorElAssignee = null;
    this.setState({
      assigneeConfig,
    });
  };
  closeAccountNewFilterMenu = () => {
    const { accountsConfig } = this.state;
    accountsConfig.anchorElAccounts = null;
    this.setState({
      accountsConfig,
    });
  };
  /**
   *
   * @param {item object} item
   * TO HANDLE ASSIGNEE FILTER VALUE ON SEARCH
   */
  onAssigneeSearchFilter = (e) => {
    const { name, value } = e.target;
    this.setState({
      searchAssigneeValue: value,
    });
    this.employeeFilter(value);
  };


  /**
*
* TO ACCOUNT STATUS MENU OPEN
*/
  openAccountFilterMenu = (event) => {
    const { accountConfig } = this.state;
    accountConfig.anchorElAccount = event.currentTarget;
    this.setState(
      {
        accountConfig,
      },
      () => {
        this.accountFilter("");
      }
    );
  };

  handleAssigneeNewSelect=(item)=>{
    const {accountsConfig,reportType,dateFilter} =this.state;
    accountsConfig.anchorElAccounts=null;
    this.setState({
      accountNewId:item._id,
      accountFilterName:item.name === "All"?"":item.name,
      accountsConfig,
      searchAccountsValue:""
    },()=>{
      let data = {
        companyId: this.state.statusFilter === "department" && this.state.accountid ? this.state.accountid : null,
        departmentId: this.state.statusFilter === "company" && this.state.departmentId ? this.state.departmentId : null,
        groupBy: this.state.statusFilter,
        startDate: dateFilter.startDate,
        endDate: dateFilter.endDate,
      };

      if(this.state.accountNewId !== "" && (reportType  === "Incomplete task reports" || reportType === "Activity Report" || reportType === "Procedure Report")){
        data.accountId=this.state.accountNewId;
      }

      if (reportType === "Incomplete task reports") {
        this.fetchAccSatisfactionData(data, "/incompleteTasks");
      }else if (reportType === "Activity Report") {
        this.fetchAccSatisfactionData(data, "/task/completed");
      }else if (reportType === "Procedure Report") {
        this.fetchAccSatisfactionData(data, "/procedureReport");
      }

    })
  }
  /**
      *
      * @param {item object} item
      * TO ADD ACCOUNT FILTER VALUE ON SELECT
      */
  handleAccountSelect = (item) => {
    const { accountConfig, reportType, dateFilter, accountid, departmentId } = this.state;
    accountConfig.anchorElAccount = null;
    this.setState(
      {
        accountid: item._id,
        accountName: item.name === "All" ? "" : item.name,
        accountConfig,
        searchAccountValue: "",
      },
      () => {

        let ActiveReps = {
          startDate: dateFilter.startDate,
          endDate: dateFilter.endDate,
          departmentId: this.state.departmentId ? this.state.departmentId : null,
          companyId: this.state.accountid ? this.state.accountid : null,
        };

        let data = {
          companyId: this.state.statusFilter === "department" && this.state.accountid ? this.state.accountid : null,
          departmentId: this.state.statusFilter === "company" && this.state.departmentId ? this.state.departmentId : null,
          groupBy: this.state.statusFilter,
          startDate: dateFilter.startDate,
          endDate: dateFilter.endDate,
        };

        if(this.state.accountNewId !== "" && (reportType  === "Incomplete task reports" || reportType === "Activity Report" || reportType === "Procedure Report")){
          data.accountId=this.state.accountNewId;
        }
        if (reportType === "Sales Rep Utilization") {
          this.fetchRepUtilizationData(data);
        } else if (reportType === "Account Satisfaction") {
          this.fetchAccSatisfactionData(data, "/task/completed");
        } else if (reportType === "Incomplete task reports") {
          this.fetchAccSatisfactionData(data, "/incompleteTasks");
        }else if (reportType === "Activity Report") {
          this.fetchAccSatisfactionData(data, "/task/completed");
        }else if (reportType === "Procedure Report") {
          this.fetchAccSatisfactionData(data, "/procedureReport");
        } 
        else if (reportType === "Uncovered Tasks") {
          this.fetchAccSatisfactionData(data, "/task/uncovered");
        } else if (reportType === "Products Breakdown") {
          this.fetchPipelinePiChartData(ActiveReps, "/report/productDetails");
        } else if (reportType === "Cancellations by Account") {
          this.fetchAccSatisfactionData(data, "/task/cancelled");
        } else if (reportType === "Pipeline Report") {
          this.fetchPipelinePiChartData(data);
        } else if (reportType === "Most Active Reps") {
          this.fetchactiveReportData(ActiveReps, "employee");
        }
      }
    );
  };
  /**
   *
   * TO HANDLE ACCOUNT MENU CLOSE
   */
  closeAccountFilterMenu = () => {
    const { accountConfig } = this.state;
    accountConfig.anchorElAccount = null;
    this.setState({
      accountConfig,
    });
  };
  /**
   *
   * @param {item object} item
   * TO HANDLE ACCOUNT FILTER VALUE ON SEARCH
   */
  onAccountSearchFilter = (e) => {
    const { name, value } = e.target;
    this.setState({
      searchAccountValue: value,
    });
    this.accountFilter(value);
  };
  /**
      *
      * @param {value} search
      * TO FETCH THE ACCOUNT FILTER
      */
  accountFilter = (search) => {
    const { accountOption } = this.state;
    AxiosInstance((process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa")
      ? 4004
      : "clinical-task", "application/x-www-form-urlencoded; charset=UTF-8")
      .post("/companyFilter", { search })
      .then((resp) => {
        var response = resp && resp.data;
        if (
          response &&
          (response.status === 200 || response.status === "200")
        ) {
          var data = response.data;
          var newArray = accountOption.splice();
          if (this.state.searchAccountValue === "") {
            newArray.unshift({ _id: "", name: "All" });
          } else {
            newArray.shift({ _id: "", name: "All" });
          }
          if (data.length > 0) {
            data.map((item) => {
              var obj = {};
              obj._id = item._id;
              obj.name = item.name;
              newArray.push(obj);
            });
          }
          this.setState({
            accountOption: newArray,
          });
        } else {
          this.setState({
            accountOption: [
              {
                _id: "",
                name: "All",
              },
            ],
          });
        }
      })
      .catch((err) => {
        var error = err.response;
        if (error && (error.status === "403" || error.status === 403)) {
          localStorage.clear();
          setTimeout(() => {
            window.location.href = "/";
          }, 500);
        } else {
          console.log("Internal Server Error");
        }
      });
  }

  /**
     *
     * TO HANDLE STATUS MENU OPEN
     */
  openRoleFilterMenu = (event) => {
    const { StatusConfig } = this.state;
    StatusConfig.anchorElStatus = event.currentTarget;
    this.setState({
      StatusConfig,
    });
  };

  /**
     *
     * @param {item object} item
     * TO ADD STATUS FILTER VALUE ON SELECT
     */
  handleRoleStatusSelect = (item) => {
    var { statusFilter, StatusFilterName, StatusConfig, reportType, dateFilter } = this.state;
    statusFilter = item._id;
    StatusFilterName = item.name;
    StatusConfig.anchorElStatus = null;
    this.setState(
      {
        StatusConfig,
        StatusFilterName,
        statusFilter,
      },
      () => {
        let data = {
          companyId: this.state.statusFilter === "department" && this.state.accountid ? this.state.accountid : null,
          departmentId: this.state.statusFilter === "company" && this.state.departmentId ? this.state.departmentId : null,
          groupBy: this.state.statusFilter,
          startDate: dateFilter.startDate,
          endDate: dateFilter.endDate,
        };
        if(this.state.accountNewId !== "" && (reportType  === "Incomplete task reports" || reportType === "Activity Report" || reportType === "Procedure Report")){
          data.accountId=this.state.accountNewId;
        }
        if (reportType === "Sales Rep Utilization") {
          this.fetchRepUtilizationData(data);
        } else if (reportType === "Account Satisfaction") {
          this.fetchAccSatisfactionData(data, "/task/completed");
        } else if (reportType === "Incomplete task reports") {
          this.fetchAccSatisfactionData(data, "/incompleteTasks");
        }else if (reportType === "Activity Report") {
          this.fetchAccSatisfactionData(data, "/task/completed");
        }else if (reportType === "Procedure Report") {
          this.fetchAccSatisfactionData(data, "/procedureReport");
        } 
        else if (reportType === "Uncovered Tasks") {
          this.fetchAccSatisfactionData(data, "/task/uncovered");
        } else if (reportType === "Cancellations by Account") {
          this.fetchAccSatisfactionData(data, "/task/cancelled");

        }
      }
    );
  };
  /**
   *
   * TO HANDLE STATUS MENU CLOSE
   */
  closeStatusRoleFilterMenu = () => {
    const { StatusConfig } = this.state;
    StatusConfig.anchorElStatus = null;
    this.setState({
      StatusConfig,
    });
  };
  getTeamReport = () => {
    const { role, companyId, companyFilter, departmentId, companySizeFilter, companySize, level, levelId, dateFilter, employeeFilter, accountFilter } = this.state;
    let data = {
      companyId: companyFilter,
      companySize: companySize,
      level: level ? level : null,
      levelId: levelId ? levelId : null,
      startDate: dateFilter.startDate,
      endDate: dateFilter.endDate,
      employeeId: employeeFilter ? employeeFilter : null,
      departmentId: departmentId ? departmentId : null
    };
    AxiosInstance((process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa")
      ? 4007
      : "clinical-report", "application/json")
      .post("/report/teamReport", data)
      .then((resp) => {
        var response = resp && resp.data;
        if (
          response &&
          (response.status === "200" || response.status === 200)
        ) {
          exportToCSV(response.data, 'Team Summary Report ' + Date.now())
        } else if (
          response &&
          (response.status === "400" || response.status === 400)
        ) {
          let props = {
            message: response.msg,
            icon: <img src={iconNotificationError} alt="success" />,
            type: "error",
            placement: "bottomRight",
            duration: 3,
            top: 200,
            className: "task_notification error-notify",
          };
          NotificationComponent(props);
        }
      })
      .catch((err) => {
        var error = err.response;
        if (error && (error.status === "403" || error.status === 403)) {
          localStorage.clear();
          setTimeout(() => {
            window.location.href = "/";
          }, 500);
        } else if (error && (error.status === "500" || error.status === 500)) {
          let propserror = {
            message: "Something went wrong",
            icon: <img src={iconNotificationError} alt="success" />,
            type: "error",
            placement: "bottomRight",
            duration: 3,
            top: 200,
            className: "task_notification error-notify",
          };
          NotificationComponent(propserror);
        }
      });
  }
  render() {
    var statusOptions = [
      { name: "Company", _id: "company" },
    ];
    const {
      loggedInRole,
      role,
      companySizeFilter,
      tableData,
      tableHeaders,
      accountRep,
    } = this.state;
    const { Option } = Select;
    const { TabPane } = Tabs;
    let CompanyPage = (
      <div className="body-bg-color">
        <Suspense fallback={<div></div>}>
          <HeaderComponent />
        </Suspense>
        <Box component="span" className="company-comp">
          <div className="header-add-btn report_header_btn">
            <Typography variant="h5" className="tableTitle" component="div">
              Reports
            </Typography>
          </div>
          <div className="report_filter">
            <div className="new_custom_filters">
              <Select
                className="report_ant_select selectBoi"
                placeholder="Select a report"
                defaultValue="Activity Report"
                name="reportName"
                suffixIcon={
                  <img className="carret" src={carret_down} alt="carret" />
                }
                onChange={this.getReports}
              >
                {/* <Option value="Uncovered Tasks">Uncovered Tasks</Option> */}
                {/* <Option value="Account Satisfaction">
                  Account Satisfaction
                </Option> */}
                
                <Option value="Activity Report">
                Activity Report
                </Option>
                {/* <Option value="Procedure Report">
                Procedure Report
                </Option> */}

                {/* <Option value="Products Breakdown">
                  Products Breakdown by Account
                </Option> */}
                <Option value="Incomplete task reports">
                  Incomplete task reports
                </Option>
                {/* <Option value="Cancellations by Account">
                  Cancellations by Account
                </Option> */}
{/* 
                <Option value="Most Active Accounts">
                  Most Active Vendors
                </Option>
                <Option value="Most Active Reps">Most Active Reps</Option> */}
                <Option value="Team Summary Report">Task Summary Report</Option>
              </Select>

              <div className="new_custom_filter_div">
                <label className="leftName">Date Range: </label>
                <RangePicker
                  className="filter_date_range"
                  name="datepicker"
                  format="MM-DD-YYYY"
                  separator="-"
                  placeholder={["MM-DD-YYYY", "MM-DD-YYYY"]}
                  suffixIcon={
                    <img className="carret" alt="carret" src={carret_down} />
                  }
                  // disabledDate={this.disabledDate}
                  onChange={(date, dateString) =>
                    this.onDateChanage(date, dateString)
                  }
                />
              </div>

              {this.state.reportType === "Uncovered Tasks" || this.state.reportType === "Account Satisfaction" || this.state.reportType === "Cancellations by Account"
              || this.state.reportType === "Activity Report" ?
                <Suspense fallback={<div></div>}>
                  <div
                    className="new_custom_filter_div"
                    onClick={this.openRoleFilterMenu}
                  >
                    {this.state.StatusFilterName !== "" ? (
                      ""
                    ) : (
                        <label className="leftName">Group By: </label>
                      )}
                    <label
                      aria-label="status_filter"
                      aria-controls="status_filter_select"
                      area-aria-haspopup="true"
                      className="rightName"
                    >
                      {this.state.StatusFilterName === ""
                        ? `Department`
                        : upperFirst(this.state.StatusFilterName)}
                    </label>
                    <img className="carret" src={carret_down} alt="carret" />
                  </div>
                  <CustomSelectComponent
                    filterArray={statusOptions}
                    config={this.state.StatusConfig.config}
                    anchorEl={this.state.StatusConfig.anchorElStatus}
                    handleClick={this.handleRoleStatusSelect}
                    closeCustomPopup={this.closeStatusRoleFilterMenu}
                  />
                </Suspense>
                : ""}
              {this.state.reportType === "Account Satisfaction" && this.state.statusFilter === "department"
                || this.state.reportType === "Cancellations by Account" && this.state.statusFilter === "department"
                || this.state.reportType === "Uncovered Tasks" && this.state.statusFilter === "department" 
                || this.state.reportType === "Activity Report" && this.state.statusFilter === "department"
                ? "" :
                <Suspense fallback={<div></div>}>
                  <div
                    className="new_custom_filter_div"
                    onClick={this.openAssigneeFilterMenu}
                  >
                    {this.state.departmentName !== "" ? (
                      ""
                    ) : (
                        <label className="leftName">Department: </label>
                      )}
                    <label
                      aria-label="assignee_filter"
                      aria-controls="assignee_filter_select"
                      area-aria-haspopup="true"
                      className="rightName"
                    >
                      {this.state.departmentName === ""
                        ? `All`
                        : upperFirst(this.state.departmentName)}
                    </label>
                    <img className="carret" src={carret_down} alt="carret" />
                  </div>
                  <CustomSelectWithSearchComponent
                    filterArray={this.state.assignedOption}
                    config={this.state.assigneeConfig.config}
                    anchorEl={this.state.assigneeConfig.anchorElAssignee}
                    searchValue={this.state.searchAssigneeValue}
                    handleClick={this.handleAssigneeSelect}
                    closeCustomPopup={this.closeAssigneeFilterMenu}
                    onSearchFilter={this.onAssigneeSearchFilter}
                    placeholder="Search Department"
                  />
                </Suspense>
              }

              {this.state.reportType === "Account Satisfaction" && this.state.statusFilter === "company"
                || this.state.reportType === "Cancellations by Account" && this.state.statusFilter === "company"
                || this.state.reportType === "Uncovered Tasks" && this.state.statusFilter === "company"
                || this.state.reportType === "Activity Report" && this.state.statusFilter === "company"
                ? "" :
                <>
                  {this.state.reportType === "Most Active Accounts" ? "" :
                    <>

                      {this.state.reportType === "Team Summary Report" ?
                        <Suspense fallback={<div></div>}>
                          <div
                            className="new_custom_filter_div"
                            onClick={this.openCompanyFilterMenu}
                          >
                            {this.state.companyFilter !== "" ? (
                              ""
                            ) : (
                                <label className="leftName">Company: </label>
                              )}
                            <label
                              aria-label="company_filter"
                              aria-controls="company_filter_select"
                              area-aria-haspopup="true"
                              className="rightName"
                            >
                              {this.state.companyFilter === ""
                                ? ``
                                : upperFirst(this.state.companyName)}
                            </label>
                            <img className="carret" src={carret_down} alt="carret" />
                          </div>
                          <CustomSelectWithSearchComponent
                            filterArray={this.state.companyOption}
                            config={this.state.companyConfig.config}
                            anchorEl={this.state.companyConfig.anchorElCompany}
                            searchValue={this.state.searchCompanyValue}
                            handleClick={this.handleCompanySelect}
                            closeCustomPopup={this.closeCompanyFilterMenu}
                            onSearchFilter={this.onCompanySearchFilter}
                            placeholder="Search Company"
                          />
                        </Suspense>

                        :
                        <Suspense fallback={<div></div>}>
                          <div
                            className="new_custom_filter_div"
                            onClick={this.openAccountFilterMenu}
                          >
                            {this.state.accountName !== "" ? (
                              ""
                            ) : (
                                <label className="leftName">Company: </label>
                              )}
                            <label
                              aria-label="account_filter"
                              aria-controls="account_filter_select"
                              area-aria-haspopup="true"
                              className="rightName"
                            >
                              {this.state.accountName === ""
                                ? `All`
                                : upperFirst(this.state.accountName)}
                            </label>
                            <img className="carret" src={carret_down} alt="carret" />
                          </div>
                          <CustomSelectWithSearchComponent
                            filterArray={this.state.accountOption}
                            config={this.state.accountConfig.config}
                            anchorEl={this.state.accountConfig.anchorElAccount}
                            searchValue={this.state.searchAccountValue}
                            handleClick={this.handleAccountSelect}
                            closeCustomPopup={this.closeAccountFilterMenu}
                            onSearchFilter={this.onAccountSearchFilter}
                            placeholder="Search Company"
                          />
                        </Suspense>
                      }
                    </>
                  }
                </>
              }

              {/* benakesh code  */}
              {
             this.state.haveParentId && (this.state.reportType  === "Incomplete task reports" || this.state.reportType === "Activity Report" || this.state.reportType === "Procedure Report") ?
              <Suspense fallback={<div></div>}>
                  <div
                    className="new_custom_filter_div"
                    onClick={this.openAccountNewFilterMenu}
                  >
                    {this.state.accountFilterName !== "" ? (
                      ""
                    ) : (
                        <label className="leftName">Account: </label>
                      )}
                    <label
                      aria-label="accounts_filter"
                      aria-controls="accounts_filter_select"
                      area-aria-haspopup="true"
                      className="rightName"
                    >
                      {this.state.accountFilterName === ""
                        ? `All`
                        : upperFirst(this.state.accountFilterName)}
                    </label>
                    <img className="carret" src={carret_down} alt="carret" />
                  </div>
                  <CustomSelectWithSearchComponent
                    filterArray={this.state.accountsOption}
                    config={this.state.accountsConfig.config}
                    anchorEl={this.state.accountsConfig.anchorElAccounts}
                    searchValue={this.state.searchAssigneeValue}
                    handleClick={this.handleAssigneeNewSelect}
                    closeCustomPopup={this.closeAccountNewFilterMenu}
                    onSearchFilter={this.onAccountNewSearchFilter}
                    placeholder="Search Accounts"
                  />
                </Suspense>
                :""}
                {/* benakesh code end */}
            </div>
          </div>
          {this.state.reportType === 'Team Summary Report' ?
            <div
              className="Comp-Table Acc-Table reports_down_tab"
            >
              <Button
                className="report_header_btn_down green-btn"
                variant="contained"
                color="default"
                onClick={this.getTeamReport}
              >
                <GetAppIcon />
                Download Report
              </Button>
            </div> :

            <div
              style={{ maxWidth: "100%" }}
              className="Comp-Table Acc-Table reports_tab"
            >
              <Tabs defaultActiveKey="1">
                <TabPane tab="Graphs" key="1">
                  <Typography color="inherit" className="graph-table-header graph-header">
                    {this.state.reportType}
                  </Typography>
                  {this.state.reportType === "Sales Rep Utilization" ? (
                    this.state.RepChartData.yAxis.length > 0 &&
                      this.state.RepChartData.xAxis.length > 0 ? (
                        <Suspense fallback={<div></div>}>
                          <span className="vertical-barchart">
                            <CommonBarGraphComponent
                              className="rep-utilisation"
                              configuration={this.state.RepChartData.config}
                              plotOptions={this.state.RepChartData.plotOptions}
                              chartType="column"
                              xAxis={this.state.RepChartData.xAxis}
                              yAxisTitle="Rep Utilization (hr)"
                              xAxisTitle={
                                this.state.userData.role.toLowerCase() ===
                                  "division manager"
                                  ? `Area`
                                  : this.state.userData.role.toLowerCase() === "admin"
                                    ? this.state.userData.companySize.toLowerCase() ===
                                      "medium"
                                      ? `Area`
                                      : this.state.userData.companySize.toLowerCase() ===
                                        "small"
                                        ? `Region`
                                        : `Division`
                                    : `Region`
                              }

                              tooltip={this.state.RepChartData.tooltip}
                              gridLineWidth={true}
                              yAxisLabelRotate={-90}
                              seriesData={this.state.RepChartData.yAxis}
                            />
                          </span>
                        </Suspense>
                      ) : (
                        <Empty
                          description={
                            <span
                              style={{
                                color: "#303960",
                              }}
                            >
                              No data found
                        </span>
                          }
                        />
                      )
                  ) : (
                      ""
                    )}
                  {this.state.reportType === "Account Satisfaction" ||
                    this.state.reportType === "Uncovered Tasks" ||
                    this.state.reportType === "Cancellations by Account" ? (
                      this.state.AccSatisfactionData.yAxis.length > 0 &&
                        this.state.AccSatisfactionData.xAxis.length > 0 ? (
                          <Suspense fallback={<div></div>}>
                            <div className={this.state.reportType === "Account Satisfaction" ? "report-graph account-satisfaction" : this.state.reportType === "Uncovered Tasks" ? "report-graph uncovered" : "report-graph cancelled"}>
                              <span >
                                <CommonBarGraphComponent
                                  configuration={this.state.AccSatisfactionData.config}
                                  plotOptions={this.state.AccSatisfactionData.plotOptions}
                                  chartType="bar"
                                  xAxis={this.state.AccSatisfactionData.xAxis}
                                  yAxisTitle={this.state.AccSatisfactionData.yAxisName}
                                  xAxisTitle={this.state.AccSatisfactionData.xAxisName}
                                  labelJustify="justify"
                                  tooltip={this.state.AccSatisfactionData.tooltip}
                                  xAxisLabelRotate={-60}
                                  gridLineWidth={true}
                                  seriesData={this.state.AccSatisfactionData.yAxis}
                                />
                              </span>
                            </div>
                          </Suspense>
                        ) : (
                          <Empty
                            description={
                              <span
                                style={{
                                  color: "#303960",
                                }}
                              >
                                No data found
                        </span>
                            }
                          />
                        )
                    ) : (
                      ""
                    )}

                  {this.state.reportType === "Incomplete task reports" || this.state.reportType === "Activity Report"
                    ? (
                      this.state.AccSatisfactionDatas.yAxis.length > 0 &&
                        this.state.AccSatisfactionDatas.xAxis.length > 0 ? (
                          <Suspense fallback={<div></div>}>
                            <div className={this.state.reportType === "Account Satisfaction" ? "report-graph account-satisfaction" : this.state.reportType === "Uncovered Tasks" ? "report-graph uncovered" : "report-graph cancelled"}>
                              <span >
                                <CommonBarGraphComponent
                                  configuration={this.state.AccSatisfactionDatas.config}
                                  plotOptions={this.state.AccSatisfactionDatas.plotOptions}
                                  chartType="column"
                                  xAxis={this.state.AccSatisfactionDatas.xAxis}
                                  yAxisTitle={this.state.AccSatisfactionDatas.yAxisName}
                                  xAxisTitle={this.state.AccSatisfactionDatas.xAxisName}
                                  labelJustify="justify"
                                  tooltip={this.state.AccSatisfactionDatas.tooltip}
                                  xAxisLabelRotate={-60}
                                  gridLineWidth={true}
                                  seriesData={this.state.AccSatisfactionDatas.yAxis}
                                />
                              </span>
                            </div>
                          </Suspense>
                        ) : (
                          <Empty
                            description={
                              <span
                                style={{
                                  color: "#303960",
                                }}
                              >
                                No data found
                        </span>
                            }
                          />
                        )
                    ) : (
                      ""
                    )}

                   { this.state.reportType === "Procedure Report"
                    ? (
                      this.state.AccSatisfactionData.yAxis.length > 0 &&
                        this.state.AccSatisfactionData.xAxis.length > 0 ? (
                          <Suspense fallback={<div></div>}>
                            <div className={this.state.reportType === "Account Satisfaction" ? "report-graph account-satisfaction" : this.state.reportType === "Uncovered Tasks" ? "report-graph uncovered" : "report-graph cancelled"}>
                              <span >
                                <CommonBarGraphComponent
                                  configuration={this.state.AccSatisfactionData.config}
                                  plotOptions={this.state.AccSatisfactionData.plotOptions}
                                  chartType="column"
                                  xAxis={this.state.AccSatisfactionData.xAxis}
                                  yAxisTitle={this.state.AccSatisfactionData.yAxisName}
                                  xAxisTitle={this.state.AccSatisfactionData.xAxisName}
                                  labelJustify="justify"
                                  tooltip={this.state.AccSatisfactionData.tooltip}
                                  xAxisLabelRotate={-60}
                                  gridLineWidth={true}
                                  seriesData={this.state.AccSatisfactionData.yAxis}
                                />
                              </span>
                            </div>
                          </Suspense>
                        ) : (
                          <Empty
                            description={
                              <span
                                style={{
                                  color: "#303960",
                                }}
                              >
                                No data found
                        </span>
                            }
                          />
                        )
                    ) : (
                      ""
                    )}















                  {this.state.reportType === "Most Active Reps" ? (
                    this.state.activeReportData.yAxis.length > 0 &&
                      this.state.activeReportData.xAxis.length > 0 ? (
                        <Suspense fallback={<div></div>}>
                          <span className="vertical-barchart">
                            <CommonBarGraphComponent
                              configuration={this.state.activeReportData.config}
                              plotOptions={this.state.activeReportData.plotOptions}
                              chartType="bar"
                              xAxis={this.state.activeReportData.xAxis}
                              yAxisTitle={this.state.activeReportData.yAxisName}
                              xAxisTitle={this.state.activeReportData.xAxisName}
                              labelJustify="justify"
                              tooltip={this.state.activeReportData.tooltip}
                              xAxisLabelRotate={-90}
                              gridLineWidth={true}
                              seriesData={this.state.activeReportData.yAxis}
                            />
                          </span>
                        </Suspense>
                      ) : (
                        <Empty
                          description={
                            <span
                              style={{
                                color: "#303960",
                              }}
                            >
                              No data found
                        </span>
                          }
                        />
                      )
                  ) : (
                      ""
                    )}
                  {this.state.reportType === "Most Active Accounts" ? (
                    this.state.activeAccountData.yAxis.length > 0 &&
                      this.state.activeAccountData.xAxis.length > 0 ? (
                        <Suspense fallback={<div></div>}>
                          <span className="vertical-barchart">
                            <CommonBarGraphComponent
                              configuration={this.state.activeAccountData.config}
                              plotOptions={this.state.activeAccountData.plotOptions}
                              chartType="bar"
                              xAxis={this.state.activeAccountData.xAxis}
                              yAxisTitle={this.state.activeAccountData.yAxisName}
                              xAxisTitle={this.state.activeAccountData.xAxisName}
                              labelJustify="justify"
                              tooltip={this.state.activeAccountData.tooltip}
                              xAxisLabelRotate={-90}
                              gridLineWidth={true}
                              seriesData={this.state.activeAccountData.yAxis}
                            />
                          </span>
                        </Suspense>
                      ) : (
                        <Empty
                          description={
                            <span
                              style={{
                                color: "#303960",
                              }}
                            >
                              No data found
                        </span>
                          }
                        />
                      )
                  ) : (
                      ""
                    )}

                  {this.state.reportType === "Pipeline Report" || this.state.reportType === "Products Breakdown" ? (
                    this.state.PipelineReportData.yAxis.length > 0 ? (
                      <Suspense fallback={<div></div>}>
                        <CommonPieChartComponent
                          title={""}
                          // from='reports'
                          type={'pie'}
                          pointFormat={'{series.name}: <b>{point.percentage:.1f}%</b>'}
                          valueSuffix={'%'}
                          dataLabels={'<b>{point.name}</b>: {point.percentage:.1f} %'}
                          legend={true}
                          seriesName={'Company'}
                          seriesData={this.state.PipelineReportData.yAxis}
                        />
                      </Suspense>
                    ) : (
                        <Empty
                          description={
                            <span
                              style={{
                                color: "#303960",
                              }}
                            >
                              No data found
                        </span>
                          }
                        />
                      )
                  ) : (
                      ""
                    )}

                 
                </TabPane>
                <TabPane tab="Table" key="2">
                  <Typography color="inherit" className="graph-table-header table-header">
                    {this.state.reportType}
                  </Typography>
                  <div className="">
                    <TableContainer>
                      <Table aria-label="customized table">
                        <TableHead>
                          <TableRow>
                            <TableCell>NO</TableCell>
                            {tableHeaders.map((header, i) => (
                              <TableCell>{header}</TableCell>
                            ))}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {!tableData || tableData.length === 0 ? (
                            <StyledTableRow>
                              <TableCell
                                colSpan={9}
                                style={{ textAlign: "center" }}
                              >
                                No data Found
                            </TableCell>
                            </StyledTableRow>
                          ) : (
                              tableData.map((row, index) => (
                                <StyledTableRow>
                                  <TableCell>{index + 1}</TableCell>
                                  {tableHeaders.map((header, i) =>
                                    header === "Account" ? (
                                      <TableCell
                                        onMouseEnter={(event) =>
                                          this.getSalesrep(row._id, event)
                                        }
                                      >
                                        <Dropdown
                                          overlay={
                                            <Menu className="report-popup-menu">
                                              <Menu.Item className="popup-header">
                                                Sales Reps
                                          </Menu.Item>
                                              <Divider className="popup-divider" />
                                              {accountRep
                                                ? accountRep.map((assign) => (
                                                  <Menu.Item key={assign._id} className="popup-list">
                                                    {assign.name}
                                                  </Menu.Item>
                                                ))
                                                : ""}
                                            </Menu>
                                          }
                                          placement="bottomLeft"
                                          arrow
                                        >
                                          <span>{row[header]}</span>
                                        </Dropdown>
                                      </TableCell>
                                    ) : (
                                        <TableCell>{row[header]}</TableCell>
                                      )
                                  )}
                                </StyledTableRow>
                              ))
                            )}
                        </TableBody>
                        <TableFooter>
                          <TableRow>
                            {/* <TablePagination
                              rowsPerPageOptions={[5, 10, 25]}
                              colSpan={9}
                              count={
                                !this.props.pager
                                  ? 0
                                  : this.props.pager.totalItems
                              }
                              rowsPerPage={this.state.rowsPerPage}
                              page={
                                !this.props.pager
                                  ? 0
                                  : this.props.pager.currentPage - 1
                              }
                              labelDisplayedRows={({ from, to, count }) =>
                                `Showing ${!to ? 0 : to} of ${count === undefined ? 0 : count
                                } entries`
                              }
                              SelectProps={{
                                inputProps: { "aria-label": "rows per page" },
                              }}
                              onChangePage={this.handleChangePage}
                              onChangeRowsPerPage={this.handleChangeRowsPerPage}
                            /> */}
                          </TableRow>
                        </TableFooter>
                      </Table>
                    </TableContainer>
                  </div>
                </TabPane>
              </Tabs>
            </div>
          }
        </Box>
      </div>
    );

    if (this.props.isAuthenticated) {
      return <Redirect to="/dashboard" />;
    } else {
      return <div>{CompanyPage}</div>;
    }
  }
}
const mapStateToProps = (state) => {
  return {
    isAuthenticated: jwt.decode(localStorage.getItem("token")) ? false : true,
    loginError: state.auth.error,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onPagination: (pageNo, pagerole) =>
      dispatch(action.companyPagination(pageNo, pagerole)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ReportsGraphComponent));
