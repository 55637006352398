import React, { Suspense } from 'react'
import { ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails, Typography, Button, TextField, Select, withStyles } from "@material-ui/core";
import ExpandMoreIcon from "../../Assets/images/expansion_dd.svg";
import editIcon from "../../Assets/images/view_edit_icon.svg";
import close_icon_red from "../../Assets/img/close_icon_red.svg";
import { upperFirst } from "lodash";
import { Tooltip } from "antd";

const CssTextField = withStyles({
    root: {
        "& .MuiInput-underline:after": {
            borderBottomColor: "#14193A",
        },
        "& .MuiOutlinedInput-root": {
            "& fieldset": {
                borderColor: "#E2E5F2",
                borderWidth: 1,
            },

            "&.Mui-focused fieldset": {
                borderColor: "#14193A",
                borderWidth: 1,
            },
        },
    },
})(TextField, Select);

function PatientInfoComponent(props) {
    return (
        <ExpansionPanel className="account-expansion">
            <ExpansionPanelSummary
                expandIcon={<img src={ExpandMoreIcon} alt="expand" />}
                style={{ borderBottom: "1px solid #0000000F" }}
            >
                <Typography
                    className="account-expansion-text"
                    variant="h6"
                    component="span"
                >
                    Patient Information
                </Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
                <Suspense fallback={<div></div>}>
                    <div className="info-card">
                        <p className="info-card-sub-name">Patient Name:</p>
                        <div className="info-card-detail info-card-detail-last">
                            <div className="info-card-detail-left">
                                {props.isPatientName ? <span className="account-des-span custom-span">
                                    {props.patientName ? upperFirst(props.patientName) : "-"}
                                </span> :
                                    <span className="edit-input">
                                        <CssTextField
                                            id="outlined-basic"
                                            name="patientName"
                                            variant="outlined"
                                            type="text"
                                            placeholder="Enter Patient Name"
                                            defaultValue={props.patientName ? upperFirst(props.patientName) : "-"}
                                            onChange={props.onChangeHandler}
                                        />

                                        <Button
                                            className="add_more_btn"
                                            onClick={() => props.updateHandler("isPatientName")}
                                        >
                                            Save
                                        </Button>
                                    </span>
                                }
                            </div>

                            {props.isPatientName ?
                                <img
                                        src={editIcon}
                                        style={{ cursor: "pointer" }}
                                        onClick={() => props.editInput("isPatientName")}
                                        alt="edit"
                                    /> :
                                <img
                                    src={close_icon_red}
                                    alt="correct_icon"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => props.editInput("isPatientName")} />
                            }
                        </div>

                        <p className="info-card-sub-name">Case Number:</p>
                        <div className="info-card-detail info-card-detail-last">
                            <div className="info-card-detail-left">
                                {props.isCaseNumber ? <span className="account-des-span custom-span">
                                    {props.caseNumber ? upperFirst(props.caseNumber) : "-"}
                                </span> :
                                    <span className="edit-input">
                                        <CssTextField
                                            id="outlined-basic"
                                            name="caseNumber"
                                            variant="outlined"
                                            type="text"
                                            placeholder="Enter Case Number"
                                            defaultValue={props.caseNumber ? upperFirst(props.caseNumber) : "-"}
                                            onChange={props.onChangeHandler}
                                        />

                                        <Button
                                            className="add_more_btn"
                                            onClick={() => props.updateHandler("isCaseNumber")}
                                        >
                                            Save
                                        </Button>
                                    </span>
                                }
                            </div>

                            {props.isCaseNumber ?
                                     <img
                                        src={editIcon}
                                        style={{ cursor: "pointer" }}
                                        onClick={() => props.editInput("isCaseNumber")}
                                        alt="edit"
                                    /> :
                                    <img
                                    src={close_icon_red}
                                    alt="correct_icon"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => props.editInput("isCaseNumber")} />
                            }
                        </div>

                    </div>
                </Suspense>
            </ExpansionPanelDetails>
        </ExpansionPanel>

    )
}

export default PatientInfoComponent