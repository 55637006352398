
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import momentTimeZone from "moment-timezone";
import Slide from '@material-ui/core/Slide';
import Grow from '@material-ui/core/Grow';
import React from "react";
export const getGooglePlaceObjectValues=(place)=>{
    var placeObj={
        address :place.formatted_address,
        lat : place.geometry.location.lat(),
        lng : place.geometry.location.lng()
      }
      return placeObj;
}
export const getCity = ( addressArray ) => {
    let city = '';
    for( let i = 0; i < addressArray.length; i++ ) {
        if ( addressArray[ i ].types[0] && 'administrative_area_level_2' === addressArray[ i ].types[0] ) {
            city = addressArray[ i ].short_name;
            return city;
        }
    }
};
export const getZipcode = ( addressArray ) => {
    let zipcode = '';
    for( let i = 0; i < addressArray.length; i++ ) {
        if ( addressArray[ i ].types[0] && 'postal_code' === addressArray[ i ].types[0] ) {
            zipcode = addressArray[ i ].short_name;
            return zipcode;
        }
    }
};

export const getArea = ( addressArray ) => {
    let area = '';
    for( let i = 0; i < addressArray.length; i++ ) {
        if ( addressArray[ i ].types[0]  ) {
            for ( let j = 0; j < addressArray[ i ].types.length; j++ ) {
                if ( 'sublocality_level_1' === addressArray[ i ].types[j] || 'locality' === addressArray[ i ].types[j] ) {
                    area = addressArray[ i ].short_name;
                    return area;
                }
            }
        }
    }
};

export const getState = ( addressArray ) => {
  let state = '';
  for( let i = 0; i < addressArray.length; i++ ) {
      for( let i = 0; i < addressArray.length; i++ ) {
          if ( addressArray[ i ].types[0] && 'administrative_area_level_1' === addressArray[ i ].types[0] ) {
              state = addressArray[ i ].short_name;
              return state;
          }
      }
  }
};

export const onGetDirectionHandler = (geomettry) => {
    window.open("https://www.google.com/maps/dir/?api=1&destination="+geomettry.lat+","+geomettry.lng);
};

export const exportToCSV = (csvData, fileName) => {
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';
    const ws = XLSX.utils.json_to_sheet(csvData);
    const wb = { Sheets: { 'Team Data': ws }, SheetNames: ['Team Data'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array'});
    const data = new Blob([excelBuffer], {type: fileType});
    FileSaver.saveAs(data, fileName + fileExtension);
  }
export const fetchDateFilterData = (item,timeZoneName) => {
    var data = {};
    if (item._id === "") {
      data.startDate = momentTimeZone()
        .startOf("week")
        .tz(timeZoneName)
        .format("YYYY-MM-DD");
      data.endDate = momentTimeZone()
        .endOf("week")
        .tz(timeZoneName)
        .format("YYYY-MM-DD");
    } else if (item._id === "last7days") {
      data.startDate = momentTimeZone(new Date())
        .subtract(7, "d")
        .tz(timeZoneName)
        .format("YYYY-MM-DD");
      data.endDate = momentTimeZone(new Date())
        .tz(timeZoneName)
        .format("YYYY-MM-DD");
    } else if (item._id === "month") {
      data.startDate = momentTimeZone(new Date())
        .subtract(30, "d")
        .tz(timeZoneName)
        .format("YYYY-MM-01");
      data.endDate = momentTimeZone(new Date())
        .tz(timeZoneName)
        .format("YYYY-MM-DD");
    } else if (item._id === "quarter") {
      data.startDate = momentTimeZone(new Date())
        .tz(timeZoneName)
        .quarter(4)._i;
      data.endDate = momentTimeZone(new Date())
        .tz(timeZoneName)
        .quarter(4)._d;
    } else if (item._id === "year") {
      data.startDate = momentTimeZone(new Date())
        .tz(timeZoneName)
        .format("YYYY-01-01");
      data.endDate = momentTimeZone(new Date())
        .tz(timeZoneName)
        .endOf("year")._d;
    }
    return data;
  };


  export const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="top" ref={ref} {...props} />;
  });
  export const TransitionGrow = React.forwardRef(function Transition(props, ref) {
      return <Grow ref={ref} {...props} />;
    });