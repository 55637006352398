import React from 'react';
import { Container, Card, TextField, Button } from "@material-ui/core";
import email from "../../../Assets/images/Icon simple-email.svg";
import phone from "../../../Assets/images/Icon awesome-phone-alt.svg";
// import division from "../../Assets/images/Icon material-device-hub1.svg";
import editprofile from "../../../Assets/images/profile-pic-edit.svg";
import editIcon from "../../../Assets/images/view_edit_icon.svg";
import DefaultProfile from "../../../Assets/images/dummy-profile-image.png";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { AxiosInstance } from "../../../Utilities";
import { Alert } from "@material-ui/lab";
// import "./ProfileComponent.css";
import { withStyles } from "@material-ui/core/styles";
import close_icon_red from "../../../Assets/img/close_icon_red.svg";
import Loader from "react-loader-spinner";
const CssTextField = withStyles({
    root: {
      "& .MuiInput-underline:after": {
        borderBottomColor: "#14193A",
      },
      "& .MuiOutlinedInput-root": {
        "& fieldset": {
          borderColor: "#E2E5F2",
          borderWidth: 1,
        },
  
        "&.Mui-focused fieldset": {
          borderColor: "#14193A",
          borderWidth: 1,
        },
      },
    },
  })(TextField);
const UserProfileComponent = (props) => {
    return (
        <Card className="signup-root">
        <div className="card-header pro_box_ht">
          <div className="profile-header-content ">
            <div className={props.prohidden}>
              {props.profilePath === "" ||
                props.profilePath === undefined ? (
                  <div className="prof_avtar">
                    <img
                      className="avatar_img"
                      alt={props.profileData.name}
                      src={DefaultProfile}
                    />
                     <span className={"avatar_input_file_container " + props.hidePro}>
                    <input  onMouseEnter={() => props.showButton("hidePro")} onMouseLeave={() => props.hideButton("hidePro")}
                      className="avatar_edit_img avatar_input_file"
                      type="file"
                      name="profilePic"
                      onChange={props.uplodProf}
                    />
                    </span>
                    <img
                     className="avatar_edit_img"
                      src={editprofile}
                      style={{ cursor: "pointer" }}
                    />
                    <p style={{ color: "red", fontSize: "14px" }}>
                      {props.invalidImage}{" "}
                    </p>
                  </div>
                ) : (
                    <div className="prof_avtar" >
                    <img
                      className="avatar_img"
                      alt={props.profileData.name}
                      src={
                        "https://salesc2userprofile.s3.amazonaws.com/" +
                        props.profilePath
                      }
                    />
                     <span className={"avatar_input_file_container " + props.hidePro}>
                    <input  onMouseEnter={() => props.showButton("hidePro")} onMouseLeave={() => props.hideButton("hidePro")}
                      className="avatar_edit_img avatar_input_file"
                      type="file"
                      name="profilePic"
                      onChange={props.uplodProf}
                    />
                    </span>
                    <img
                      className=" avatar_edit_img"
                      src={editprofile}
                      style={{ cursor: "pointer" }}
                    />
                    <p style={{ color: "red", fontSize: "14px" }}>
                      {props.invalidImage}{" "}
                    </p>
                  </div>
                )}{" "}
                  <Loader
                className="pro_loding"
                type="ThreeDots"
                color="#00BFFF"
                height={50}
                width={50}
                visible={props.loading}
              />
            </div>
            <div className="prof_name">
              {" "}
              <h2>{props.profileData.name}</h2>{" "}
              <p>
                {props.profileData.role
                  ? props.profileData.role.charAt(0).toUpperCase() +
                  props.profileData.role.slice(1)
                  : "-"}
              </p>
            </div>
          </div>
        </div>
        {props.responseMsg != "" ? (
          <Alert severity={props.alertType}>
            {props.responseMsg}
          </Alert>
        ) : null}
        <div className="profile-header-content profile-header-content-two">
          <div className="key_name">Email</div>
          <div className="profile_value">
            <img src={email} />
            <span className="profile_value_span">
              {" "}
              {props.profileData.email}
            </span>
          </div>
          <div className="key_name">Phone Number</div>
           <div className="profile_value" onMouseEnter={() => props.showButton("hidePhone")} onMouseLeave={() => props.hideButton("hidePhone")} >
            <img src={phone} />
            {props.isPhone && (
            <div className={props.hidden}>
              <span className="profile_value_span">
                {" "}
                {props.phone}
              </span>
              <img
                className={props.hidePhone + " edit_icon"}
                src={editIcon}
                style={{ cursor: "pointer" }}
                onClick={props.showMobEdit}
              />
            </div>
            )}
            {!props.isPhone && (
            <div className="info-card-detail-left " className={props.show}>
              <span className="edit-input add_company_dialog">
                  <CssTextField
                    id="outlined-basic"
                    name="editphone"
                    variant="outlined"
                    type="text"
                    placeholder="Please enter phone number"
                    value={props.editphone}
                    onChange={props.handlepPhoneChange}
                  />
                  <Button
                    className="add_more_btn"
                    disabled={props.disablePhone}
                    onClick={props.onSubmit}
                  >
                    Save
                  </Button>
                <img
                  className={props.show}
                  src={close_icon_red}
                  style={{ cursor: "pointer" }}
                  onClick={props.hideMobEdit}
                />
              </span>
            </div>
             )}
          </div>
          <div className="profile_ErrorMassage">
            <span className="modal-err-msg">{props.formError}</span>
            </div>
            {props.role !== "Scheduler" ? (
              ""
            ) : (
              <>
                <div className="key_name">Team</div>
                <div className="profile_value">
                  {/* <img className="division_img" src={division} /> */}
                  <div>
                    {props.department_details === null || props.department_details === "" ? (
                      ""
                    ) : (
                        <div className="division_value">
                          <span className="profile_value_span profile_value_span_key">
                            Department
                      </span>
                          <span className="profile_value_seperator profile_value_span">
                            :
                      </span>
                          <span className="profile_value_two profile_value_span">
                            {props.department_details}
                          </span>
                        </div>
                      )}
                    </div>
                </div>
              </>
            )}
        </div>
      </Card>
    );
}
 
export default UserProfileComponent;