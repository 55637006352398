import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import InputLabel from "@material-ui/core/InputLabel";
import "./LoginComponent.css";
import logoIcon from "../../Assets/images/Group 159.svg";
import iconFacebook from "../../Assets/images/Icon-awesome-facebook-square.svg";
import iconInstagram from "../../Assets/images/Icon-awesome-instagram.svg";
import iconLinkedin from "../../Assets/images/Icon-awesome-linkedin.svg";
import iconTwitter from "../../Assets/images/Icon-awesome-twitter.svg";
import logo from "../../Assets/images/Group 1602.svg";
import emailIcon from "../../Assets/images/email-@.svg";
import eyeIcon from "../../Assets/images/eye-icon.svg";
import eyeCloseIcon from "../../Assets/images/eye-close-icon.svg";
import appStore from "../../Assets/images/app_store.svg";
import googlePlay from "../../Assets/images/google-play.png";
import repeatGrid from "../../Assets/images/repeat_grid_3.svg";
import Checkbox from "@material-ui/core/Checkbox";
import {Tooltip} from 'antd';
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Button from "@material-ui/core/Button";
import InputAdornment from "@material-ui/core/InputAdornment";
import {
  createStyles,
  fade,
  Theme,
  withStyles,
  makeStyles,
  createMuiTheme,
} from "@material-ui/core/styles";
import {
  formValidation,
  emailRegx,
} from "../../SharedComponents/Validation/formValidation";
import * as action from "../../Store/Actions/index";
import { connect } from "react-redux";
import { Redirect, withRouter } from "react-router-dom";
import jwt from "jsonwebtoken";
import ForgotPasswordModal from "./ForgotPasswordModal/ForgotPasswordModal";
import validator from "validator";
import { AxiosInstance } from "../../Utilities";
import GoogleRecaptchaComponent from "../../SharedComponents/GoogleRecaptchaComponent/GoogleRecaptchaComponent";

const GreenCheckbox = withStyles({
  root: {
    color: "#424242",
    "&$checked": {
      color: "#424242",
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

const CssTextField = withStyles({
  root: {
    backgroundColor: "#F2F2F2",
    borderRadius: "5px",
    marginBottom: "20px",
    "& .MuiInput-underline:after": {
      borderBottomColor: "#14193A",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#E2E5F2",
        borderWidth: 0,
      },

      "&.Mui-focused fieldset": {
        borderColor: "#14193A",
        borderWidth: 0,
      },
    },
  },
})(TextField);

class LoginComponent extends Component {
  constructor(props) {
    super(props);
    var check = (sessionStorage.getItem('isChecked') && sessionStorage.getItem('userEmail') && sessionStorage.getItem('userPassword'))
      && (sessionStorage.getItem('isChecked') === 'true' && sessionStorage.getItem('userEmail') !== "" && sessionStorage.getItem('userPassword') !== "") ? true : false
    this.state = {
      userEmail: sessionStorage.getItem('userEmail') ? sessionStorage.getItem('userEmail') : "",
      userPassword: sessionStorage.getItem('userPassword') ? sessionStorage.getItem('userPassword') : "",
      formError: {
        userEmail: "",
        userPassword: "",
      },
      loginError: "",
      hidden: true,
      forgotPassword: false,
      forgotEmail: "",
      forgotEmailError: "",
      responseError: "",
      alertType: "",
      isChecked: check,
      reCaptchaResponseToken: ""
    };
    this.handleChange = this.handleChange.bind(this);
  }

  onForgotModalOpen = () => {
    this.setState({
      forgotPassword: true,
    });
  };

  onForgotModalClose = () => {
    this.setState({
      forgotPassword: !this.state.forgotPassword,
    });
  };

  onChangeForgotPassword = (e) => {
    var { name, value } = e.target;
    this.setState({
      [name]: value,
    });
  };

  onForgotPasswordHandler = (e) => {
    e.preventDefault();
    var { forgotEmail, forgotEmailError } = this.state;

    if (validator.isEmpty(forgotEmail)) {
      forgotEmailError = "Please enter an email id";
    } else if (!validator.isEmail(forgotEmail)) {
      forgotEmailError = "Please enter valid email format";
    } else {
      forgotEmailError = "";
    }
    this.setState(
      {
        forgotEmailError,
      },
      () => {
        var {
          responseError,
          forgotEmail,
          alertType,
          forgotPassword,
        } = this.state;
        if (validator.isEmpty(this.state.forgotEmailError)) {
          AxiosInstance( (process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa")
              ? 4002
              : "clinical-login", "application/json")
            .post("resetpassword", { email: forgotEmail })
            .then((resp) => {
              var response = resp && resp.data;
              if (
                response &&
                (response.status === "201" || response.status === 201)
              ) {
                this.setState(
                  {
                    responseError: response.msg,
                    alertType: "success",
                  },
                  () => {
                    setTimeout(() => {
                      this.setState({
                        responseError: "",
                        alertType: "",
                        forgotPassword: false,
                      });
                    }, 2000);
                  }
                );
              } else {
                this.setState(
                  {
                    responseError: response.msg,
                    alertType: "error",
                  },
                  () => {
                    setTimeout(() => {
                      this.setState({
                        responseError: "",
                        alertType: "",
                      });
                    }, 9000);
                  }
                );
              }
            })
            .catch((err) => {
              this.setState(
                {
                  responseError: "Internal server error",
                  alertType: "error",
                },
                () => {
                  setTimeout(() => {
                    this.setState({
                      responseError: "",
                      alertType: "",
                    });
                  }, 2000);
                }
              );
            });
        }
      }
    );
  };

  clearForgotDialog = () => {
    var { forgotEmail, forgotEmailError, responseError } = this.state;
    this.setState({
      forgotEmail: "",
      forgotEmailError: "",
      responseError: "",
    });
  };

  handleChange = (event) => {
    event.preventDefault();
    const { formError } = this.state;
    let identifier = event.target.name;
    let value = event.target.value;
    switch (identifier) {
      case "userEmail":
        if (!emailRegx.test(value) && value.trim().length > 0) {
          formError.userEmail = "";
        } else {
          formError.userEmail = "";
        }
        break;
      case "userPassword":
        if (value.trim().length < 0) {
          formError.userPassword = "*Please enter password";
        } else {
          formError.userPassword = "";
        }
        break;
      default:
        break;
    }
    this.setState({
      [event.target.name]: event.target.value,
      formError,
      loginError: "loginError",
    });
  };

  checkLogin = async (e) => {
    e.preventDefault();
    const { formError, userEmail, userPassword } = this.state;
    if (userEmail === "" && userPassword === "") {
      formError.userEmail = "*Please enter your email address";
      formError.userPassword = "*Please enter your Password";
      this.setState({ loginError: "loginError" }, () => { });
    } else if (userEmail === "" && userPassword !== "") {
      formError.userEmail = "*Please enter your email address";
      formError.userPassword = "";
      this.setState({ loginError: "loginError" }, () => { });
    } else if (userEmail !== "" && userPassword === "") {
      if (!emailRegx.test(userEmail)) {
        formError.userEmail = "*Please enter valid email format";
      } else {
        formError.userEmail = "";
      }
      formError.userPassword = "*Please enter your Password";
      this.setState({ loginError: "loginError" }, () => { });
    } else if (userEmail !== "" && !emailRegx.test(userEmail)) {
      formError.userEmail = "*Please enter valid email format";
      formError.userPassword = "";
      this.setState({ loginError: "loginError" }, () => { });
    } else {
      formError.userEmail = "";
      formError.userPassword = "";
    }
    this.setState({ formError, loginError: "" }, () => {
      console.log("formError", formError);
    });
    if (formValidation(this.state)) {
      console.log("Login Success", this.state);
      const userData = {
        email: this.state.userEmail,
        password: this.state.userPassword,
        token: this.state.reCaptchaResponseToken
      };
      this.props.onAuth(userData);
    }
  };
  onRememberMe = (e) => {
    this.setState({
      isChecked: e.target.checked
    }, () => {
      if (this.state.isChecked) {
        sessionStorage.setItem('userEmail', this.state.userEmail);
        sessionStorage.setItem('userPassword', this.state.userPassword);
        sessionStorage.setItem('isChecked', true)
      } else {
        sessionStorage.setItem('userEmail', '');
        sessionStorage.setItem('userPassword', '');
        sessionStorage.setItem('isChecked', false);
      }
    })
  }
  onGoogleRecaptchaResponse = (token) => {
    this.setState({ reCaptchaResponseToken : token})
  }
  render() {
    let LoginForm = (
      <div>
        <ForgotPasswordModal
          clearForgotDialog={this.clearForgotDialog}
          onForgotPassword={this.onForgotPasswordHandler}
          onForgotModalClose={this.onForgotModalClose}
          onChangeForgotPassword={this.onChangeForgotPassword}
          forgotPassword={this.state.forgotPassword}
          forgotEmailError={this.state.forgotEmailError}
          responseMsg={this.state.responseError}
          alertType={this.state.alertType}
        />
        <Grid container className="login_form_container">
          <Grid className="login_form" item xs={12} sm={12} md={5}>
            <div className="login_form_div">
              <img className="logo" src={logo}></img>
              <p className="login-header">Please login to your account</p>
              <form onSubmit={this.checkLogin} autoComplete="off">
                {this.props.loginError ? (
                  <p
                    style={{
                      color: "red",
                      fontSize: "16px",
                      background: "antiquewhite",
                      textAlign: "center",
                    }}
                  >
                    {this.props.loginError}
                  </p>
                ) : (
                    ""
                  )}
                <div className="form_input_field">
                  <InputLabel className="loginLabel" data-test="loginLabel">
                    Email
                </InputLabel>
                  <CssTextField
                    id="outlined-basic-email"
                    name="userEmail"
                    type="text"
                    placeholder="Enter an email address"
                    className="inputHeight"
                    variant="outlined"
                    onChange={this.handleChange}
                    value={this.state.userEmail}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <img src={emailIcon} />
                        </InputAdornment>
                      ),
                    }}
                  // helperText={
                  //   this.state.formError.userEmail ? (
                  //     <span
                  //       className={this.state.loginError + " error"}
                  //     >
                  //       {this.state.formError.userEmail}
                  //     </span>
                  //   ) : (
                  //       ""
                  //     )
                  // }
                  // error={
                  //   this.state.formError.userEmail ? (
                  //     <span
                  //       className={this.state.loginError + " error"}
                  //     >
                  //       {this.state.formError.userEmail}
                  //     </span>
                  //   ) : (
                  //       ""
                  //     )
                  // }
                  />

                  <span className="error">
                    {this.state.formError.userEmail}
                  </span>
                </div>
                <div className="form_input_field">
                  <InputLabel className="loginLabel" data-test="loginLabel">
                    Password
                </InputLabel>
                  <CssTextField
                    id="outlined-basic-password"
                    type={this.state.hidden ? "password" : "text"}
                    name="userPassword"
                    placeholder="Enter a password"
                    className="inputHeight"
                    variant="outlined"
                    onChange={this.handleChange}
                    value={this.state.userPassword}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment
                          position="end"
                          style={{ cursor: "pointer" }}
                          onClick={() =>
                            this.setState({ hidden: !this.state.hidden })
                          }
                        >
                        <Tooltip title={this.state.hidden?"Show Password":"Hide Password"} placement="bottom">
                          <img src={this.state.hidden ? eyeIcon:eyeCloseIcon } alt="eye"/>
                        </Tooltip>
                        </InputAdornment>
                      ),
                    }}
                    error={
                      <span
                        className={this.state.loginError + " error"}
                      >
                        {this.state.formError.userPassword}
                      </span>
                    }
                  />
                  <span className="error">
                    {this.state.formError.userPassword}
                  </span>
                </div>
                <Grid
                  container
                  direction="row"
                  justify="space-between"
                  alignItems="flex-start"
                >
                  <FormControlLabel
                    className="loginCheckbox"
                    control={<GreenCheckbox name="checkedG" checked={
                      this.state.isChecked} onChange={this.onRememberMe} />}
                    label="Remember me" />
                   <span
                    style={{ cursor: "pointer" }}
                    className="forget_password"
                    onClick={this.onForgotModalOpen}
                    >
                    Forgot Password?
                   </span>
                </Grid>
                <Button
                  className="LoginBtn"
                  type="submit"
                  variant="contained"
                  color="primary"
                >
                  Login
                </Button>
              </form>
              <div className="register-link">
                <p>
                  Don't have an account? <a href="/register">Register Now!</a>
                </p>
              </div>
            </div>
            <Grid
              className="social-link"
              container
              direction="row"
              justify="space-between"
              alignItems="flex-start"
            >
              <Grid item></Grid>
              <Grid item>
                <div className="social_links">
                  <a href="https://www.facebook.com/salesc2" target="_blank">
                    <img src={iconFacebook} />
                  </a>{" "}
                  <a href="https://www.instagram.com/salesc2" target="_blank">
                    <img src={iconInstagram} />
                  </a>{" "}
                  <a href="http://linkedin.com/company/salesc2" target="_blank">
                    <img src={iconLinkedin} />
                  </a>{" "}
                  <a href="https://www.twitter.com/sales_c2" target="_blank">
                    <img src={iconTwitter} />
                  </a>
                </div>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} md={7}>
            <div className="login_banner">
              <p>
              The First Vendor Scheduling Platform Designed for Healthcare Providers.
              </p>
              <img className="icon_small" src={logoIcon}></img>
              <span>
                ©{new Date().getFullYear()} All Rights Reserved. ClinicalC2. Cookie Preferences.
                Privacy, and Terms
              </span>
              <img className="repeat_grid_bottom" src={repeatGrid}></img>
              <img className="repeat_grid_right" src={repeatGrid}></img>
            </div>
          </Grid>
        </Grid>
        <GoogleRecaptchaComponent onCallback={this.onGoogleRecaptchaResponse} />
      </div>
    );

    return <div>{LoginForm}</div>;
  }
}

const mapStateToProps = (state) => {
  return {
    isAuthenticated: jwt.decode(localStorage.getItem("token")) ? true : false,
    loginError: state.auth.error,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onAuth: (userData) => dispatch(action.LoginActions(userData)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(LoginComponent));
