import * as actionTypes from "./ActionTypes";
import { AxiosInstance } from "../../Utilities";
import jwt from "jsonwebtoken";

export const loginRequest = () => {
  return {
    type: actionTypes.LOGIN_REQUEST,
  };
};
export const loginSuccess = (token, data) => {
  return {
    type: actionTypes.LOGIN_SUCCESS,
    token: token,
    data: data,
  };
};
export const loginFailure = (error) => {
  return {
    type: actionTypes.LOGIN_FAILURE,
    error: error,
  };
};

export const logout = () => {
  // Auth.signOut();
  console.log("logout");
  localStorage.clear();
  return {
    type: actionTypes.LOGOUT,
  };
};

export const checkAuthTimeout = (expirationTime) => {
  return (dispatch) => {
    setTimeout(() => {
      console.log("logout");
      //dispatch(logout());
    }, expirationTime * 1000);
  };
};

export const LoginActions = (formData) => {
  return (dispatch) => {
    console.log("process.env.REACT_APP_NODE_ENV.........",process.env.REACT_APP_NODE_ENV, process.env.REACT_APP_RECAPTCHA_SITE_KEY)
    dispatch(loginRequest());
    AxiosInstance( (process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa")
              ? 4002
              : "clinical-login","application/x-www-form-urlencoded; charset=UTF-8")
      .post("/login", formData)
      .then((response) => {
        var loginResponse = response.data;
        if (loginResponse.status === "200") {
          let token = loginResponse.token.split(" ");
          let decode = jwt.decode(token[1]);
          // let expirationTime = new Date(
          //   new Date().getTime() + decode.exp * 1000
          // );
          //localStorage.setItem("expirationDate", expirationTime);

          localStorage.setItem("token", token[1]);
          localStorage.setItem("userdata", JSON.stringify(loginResponse.data));
          localStorage.setItem("hospitalLogo", JSON.stringify(loginResponse.data.hospitalLogo));
          localStorage.setItem("cms", JSON.stringify(loginResponse.data.cmsDetail));
          dispatch(loginSuccess(token[1], JSON.stringify(decode)));
          window.location.href = "/dashboard";
        } else {
          console.log("not valid user");
          dispatch(loginFailure(response.data.msg));
        }
      })
      .catch((e) => {
        dispatch(loginFailure("Username / Password is incorrect"));
      });
  };
};

export const authCheckState = () => {
  return (dispatch) => {
    const token = localStorage.getItem("token");
    let decode = jwt.decode(token);
    if (!jwt.decode(token)) {
      dispatch(logout());
    } else {
      // let dec = parseInt(decode.exp);
      // console.log("dec--", dec, (Date.now() / 1000).toFixed(0));
      // if (dec <= (Date.now() / 1000).toFixed(0)) {
      //   dispatch(logout());
      // } else {
      const data = localStorage.getItem("data");
      dispatch(loginSuccess(token, data));
      // }
    }
  };
};
