import React, {Component} from 'react';
import { AxiosInstance } from "../../../Utilities";
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import PlaylistAddIcon from '@material-ui/icons/PlaylistAdd';
import { withStyles } from '@material-ui/core/styles';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import addAccountIcon from "../../../Assets/images/add_company_icon.svg";
import closeBtn from "../../../Assets/images/close_btn.svg";
import addUserIcon from "../../../Assets/images/add_user_icon.svg";
import add_dept_icon from "../../../Assets/images/add_dept_icon.svg";
import IconButton from '@material-ui/core/IconButton';
import { Alert } from "@material-ui/lab";
import {
    Button,
    TextField,
    Dialog,
    DialogActions,
    DialogContent,
    MenuItem,
    DialogTitle,
    Select,
    InputLabel,
    FormControl,
    CircularProgress,
    DialogContentText
} from "@material-ui/core";
import { render } from 'enzyme';
import { Transition} from '../../../SharedComponents/CommonUtilities';
// import Slide from '@material-ui/core/Slide';

// const Transition = React.forwardRef(function Transition(props, ref) {
//   return <Slide direction="top" ref={ref} {...props} />;
// });

const CssTextField = withStyles({
    root: {
        backgroundColor: "#F2F2F2",
        borderRadius: "5px",
        marginBottom: "15px",
        "& .MuiInput-underline:after": {
            borderBottomColor: "#14193A",
        },
        "& .MuiOutlinedInput-root": {
            "& fieldset": {
                borderColor: "#E2E5F2",
                borderWidth: 0,
            },

            "&.Mui-focused fieldset": {
                borderColor: "#14193A",
                borderWidth: 0,
            },
        },
    },
})(TextField);
const CssAutocomplete = withStyles({
    root: {
        backgroundColor: "#F2F2F2",
        borderRadius: "5px",
        marginBottom: "15px",
        "& .MuiInput-underline:after": {
            borderBottomColor: "#14193A",
        },
        "& .MuiOutlinedInput-root": {
            "& fieldset": {
                borderColor: "#E2E5F2",
                borderWidth: 0,
            },

            "&.Mui-focused fieldset": {
                borderColor: "#14193A",
                borderWidth: 0,
            },
        },
    },
})(FormControl);
export class EditUserModel extends Component {
    constructor(props) {
        super(props);
        var userData = localStorage.getItem("userdata")
            ? JSON.parse(localStorage.getItem("userdata"))
            : "";
        var roles = userData.role;
        this.state = {
            responseMsg: "",
            alertType: "",
            loggedInRole: userData.role,
            createdBy: userData.username,
            companyName: "a",
            companyId:
                roles.toLowerCase() !== "super admin" && userData.companyId
                     ? userData.companyId
                    : "",
            role: "",
            level: "",
            levelId: "",
            levelName: "",
            email: "",
            phone: "",
            userName: "",
            accountId: "",
            isLoading: false,
            selectedRoleSuperAdmin: false,
            formError: {
                companyId: "",
                levelId: "",
                phone: "",
                email: "",
                userName: "",
                department:"",
                role: "",
                level: "",
                createdBy: "",
            },
            dialog: false,
            mainDialog: this.props.showEditModal,
            division: [],
            area: [],
            region: [],
            territory: [],

            division_id: "",
            area_id: "",
            region_id: "",
            territory_id: "",
            levelError: "",
            departmentId: "",
            departmentList: [],
        };
    }
    componentDidMount = () => {
        AxiosInstance( (process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa")
              ? 4003
              : "clinical-user", "application/x-www-form-urlencoded; charset=UTF-8")
          .get("/departmentList")
          .then((resp) => {
              if (resp.data.status === 200) {
                  this.setState({ departmentList: resp.data.data })
              } else {
              }
          });
  }
    clearForm = () => {
        var error = {
            companyId: "",
            levelId: "",
            phone: "",
            email: "",
            userName: "",
            role: "",
            level: "",
            createdBy: "",
        };
        this.setState(
            {
                role: "",
                level: "",
                levelError: "",
                levelId: "",
                responseMsg: "",
                formError: error,
                area: [],
                region: [],
                division: [],
                territory: [],
                userName: "",
                email: "",
                phone: ""
            }
        );
    };
    render(){
        var { formError } = this.props.modaldata;

    var options = [{ key: "1", value: "Select role" }];
    options.push(
        { key: "2", value: "Account Admin" },
        { key: "3", value: "Master Scheduler" },
        { key: "4", value: "Scheduler" },
    );
    return (
        <div>
             <Dialog
                     onExit={this.clearForm}
                     open={this.props.showEditModal}
                      {...this.props}
                    // onClose={props.handleClose}
                    aria-labelledby="form-dialog-title" 
                    maxWidth='md' 
                    className="add_company_dialog edit_user_modal"
                    TransitionComponent={Transition} 
                    transitionDuration={600}
                    >
                    <DialogTitle id="form-dialog-title" className="add_user_header">
                        <img className="add-company_img" src={addUserIcon}></img>
                        <span>Edit User</span>
                        <IconButton onClick={this.props.onClose} 
                        className="closeBtn" 
                        aria-label="show 17 new notifications" 
                        color="inherit"
                        >
                            <img src={closeBtn}></img>
                        </IconButton>
                    </DialogTitle>
                    <DialogContent>
                    {this.props.modaldata.responseMsg != "" ? (
                        <Alert severity={this.props.modaldata.alertType}>
                            {this.props.modaldata.responseMsg}
                        </Alert>
                         ) : null} 
                    <form className="adduserform">
                            <div style={{ display: this.state.isLoading ? "block" : "none" }}>
                                <CircularProgress
                                    variant={this.state.isLoading ? "indeterminate" : "static"}
                                />
                            </div>
                            <div className="form_input_field">
                            <label className="label">Name<span className="labelAsterisk"> *</span></label>
                            <CssTextField
                                id="outlined-basic"
                                name="edituserName"
                                variant="outlined"
                               
                                type="text"
                                fullWidth
                                placeholder="Enter name"
                                onChange={this.props.onEditChangeHandler}
                                defaultValue={this.props.modaldata.edituserName}

                            />
                            {formError.userName != "" ? (
                                <span className="error">* {formError.userName}</span>
                            ) : null}
                            </div>
                            <div className="form_input_field">
                            <label className="label">Role<span className="labelAsterisk"> *</span></label>
                            <CssAutocomplete variant="outlined" fullWidth>
                                <Select
                                    
                                    className="customSelect"
                                    name="editrole"
                                    fullWidth
                                    onChange={this.props.onEditChangeHandler}
                                    defaultValue={this.props.modaldata.editrole}

                                >
                                    {options.map((mitem) => (
                                        <MenuItem
                                            key={mitem.key}
                                            value={
                                                mitem.value.toLowerCase() === "select role"
                                                    ? ""
                                                    : mitem.value
                                            }
                                        >
                                            {mitem.value}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </CssAutocomplete>
                            {formError.role != "" ? (
                                <span className="error">* {formError.role}</span>
                            ) : null}
                            </div>
                            {this.props.modaldata.editrole === "Scheduler" ?
                                <div className="form_input_field">
                                    <label className="label">Select department<span className="labelAsterisk"> *</span></label>
                                    <CssAutocomplete variant="outlined" fullWidth>
                                        <Select
                                            
                                            className="customSelect"
                                            name="editdepartmentId"
                                            fullWidth
                                            onChange={this.props.onEditChangeHandler}
                                            defaultValue={this.props.modaldata.editdepartmentId}

                                        >
                                            {this.state.departmentList.map((mitem) => (
                                                <MenuItem key={mitem._id} value={mitem._id}>
                                                    {mitem.name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </CssAutocomplete>
                                    {formError.department != "" ? (
                                <span className="error">* {formError.department}</span>
                            ) : null}
                                </div>
                                : ''}
                            <div className="form_input_field">
                            <label className="label">Email<span className="labelAsterisk"> *</span></label>
                            <CssTextField
                                id="outlined-basic"
                                disabled
                                name="editemail"
                                variant="outlined"
                               
                                type="text"
                                fullWidth
                                placeholder="Enter email"
                                onChange={this.props.onEditChangeHandler}
                                defaultValue={this.props.modaldata.editemail}

                            />
                            {formError.email != "" ? (
                                <span className="error">* {formError.email}</span>
                            ) : null}
                            </div>
                            <div className="form_input_field">
                            <label className="label">Phone<span className="labelAsterisk"> *</span></label>
                            <CssTextField
                                id="outlined-basic"
                                name="editphone"
                                variant="outlined"
                                
                                type="text"
                                fullWidth
                                placeholder="Please enter phone number"
                                onChange={this.props.onEditChangeHandler}
                                defaultValue={this.props.modaldata.editphone}

                            />
                            {formError.phone !== "" ? (
                                <span className="error">* {formError.phone}</span>
                            ) : null}
                            </div>
                            <div className="add_company_action">
                                <Button
                                    className="cancel_btn"
                                    onClick={this.props.onClose}
                                    variant="outlined"
                                >
                                    Cancel
                                </Button>
                                <Button
                                    className="ok_btn"
                                    onClick={this.props.editUserHandler}
                                    variant="contained"
                                    disabled={this.props.modaldata.isSubmit}

                                >
                                    Update
                                </Button>
                            </div>
                        </form>

                    </DialogContent>
                </Dialog>
        </div>
    );
 }
};
export default EditUserModel;