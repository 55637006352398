import React from 'react'
import { withStyles } from '@material-ui/core/styles';
import closeBtn from "../../../Assets/images/close_btn.svg";
import IconButton from '@material-ui/core/IconButton';
import {
    Button,
    TextField,
    Dialog,
    DialogContent,
    DialogTitle,
    Select,
    Tooltip,
    CircularProgress,
    DialogContentText,
} from "@material-ui/core";
import { TransitionGrow} from '../../../SharedComponents/CommonUtilities';
// import Grow from '@material-ui/core/Grow';

// const Transition = React.forwardRef(function Transition(props, ref) {
//   return <Grow ref={ref} {...props} />;
// });

const { Option, OptGroup } = Select;
const CssTextField = withStyles({
    root: {
      backgroundColor: "#F2F2F2",
      borderRadius: "5px",
      marginBottom: "15px",
      "& .MuiInput-underline:after": {
        borderBottomColor: "#14193A",
      },
      "& .MuiOutlinedInput-root": {
        "& fieldset": {
          borderColor: "#E2E5F2",
          borderWidth: 0,
        },
  
        "&.Mui-focused fieldset": {
          borderColor: "#14193A",
          borderWidth: 0,
        },
      },
    },
  })(TextField);  
const LightTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: "black",
        color: "white",
        boxShadow: theme.shadows[1],
        fontSize: 13,
    },
}))(Tooltip);
var i = 0;
/**
   * @author Shashi
   */
const SuspendedModel = props => {
    let show = "";
    let hidden = "";
    return (
        <div >
            <div>
                <Dialog  {...props.modelData}
                    open={props.open}
                    onClose={props.handleClose}
                    aria-labelledby="form-dialog-title" 
                    maxWidth='md' 
                    className=" add_company_dialog suspend_modal"
                    TransitionComponent={TransitionGrow} 
                    transitionDuration={600}
                    >
                    <DialogTitle id="form-dialog-title" className="add_user_header">
                        <span>Suspend User</span>
                        <IconButton onClick={props.handleClose} className="closeBtn" aria-label="show 17 new notifications" color="inherit">
                            <img src={closeBtn}></img>
                        </IconButton>
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description" className="suspend_text">
                        Restrict the user from logging in and accessing data in ClinicalC2.
                        </DialogContentText>
                        <label className="label bl-label">Note: <span className="labelAsterisk">*</span></label>
                        <CssTextField
                            id="outlined-basic"
                            name='description'
                            variant="outlined"
                            className="multiline-text"
                            multiline
                            rowsMax={4}
                            type="text"
                            style={{margin:"2px 0 4px"}}
                            fullWidth
                            placeholder="Add a note here"
                            onChange={props.adddeshandleChange}
                        />
                        {props.modelData.descriptioneror.error !== "" ?
                            <span className="error"> {props.modelData.descriptioneror.error}</span> : ""}
                        <div className="add_company_action">
                            <Button className="cancel_btn white-btn" onClick={props.handleClose} variant="outlined">
                                Cancel
                            </Button>
                            <Button disabled={props.modelData.isSubmit} className="ok_btn green-btn" variant="contained" onClick={props.onSuspSubmit} >
                                {props.modelData.isSubmit ? <CircularProgress size={20} /> : ''}  Suspend User
                             </Button>
                        </div>
                    </DialogContent>
                </Dialog>
            </div>
        </div >
    );
};
export default SuspendedModel;