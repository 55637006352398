import React, { Component, Suspense } from "react";
import "./TaskComponent.css";
import * as action from "../../Store/Actions/index";
import { withStyles } from '@material-ui/core/styles';
import HeaderComponent from "../../SharedComponents/HeaderComponent/HeaderComponent";
import emergency from "../../Assets/images/Icon.png";
import searchIconWhite from "../../Assets/images/search_icon_grey.png"
import momentTimeZone from "moment-timezone";
import {
    Typography,
    Select,
    TextField,
    InputAdornment, Tooltip, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TableFooter, TablePagination,
    FormControlLabel,
    Checkbox
} from "@material-ui/core";
import searchIcon from "../../Assets/images/search_icon.svg";
import { connect } from "react-redux";
import { withRouter, NavLink } from "react-router-dom";
import jwt from "jsonwebtoken";
import OpenMenu from "../../Assets/images/open-menu.svg";
import { AxiosInstance } from "../../Utilities";
import { Autocomplete, Alert } from "@material-ui/lab";
import Loader from "react-loader-spinner";
import 'antd/dist/antd.css';
import { DatePicker } from 'antd';
import { upperFirst } from "lodash";
import carret_down from "../../Assets/images/carret_down.svg";
import CustomPaginationComponent from "../../SharedComponents/CustomPaginationComponent/CustomPaginationComponent";
import { isSafari } from "react-device-detect";
var CustomSelectComponent = React.lazy(() =>
    import("../../SharedComponents/CustomSelectComponent/CustomSelectComponent")
);
const { RangePicker } = DatePicker;
const moment = require("moment");
var CustomSelectWithSearchComponent = React.lazy(() =>
    import(
        "../../SharedComponents/CustomSelectWithSearchComponent/CustomSelectWithSearchComponent"
    )
);
const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(even)': {
            backgroundColor: "#F9F9F9",
        },
    },
}))(TableRow);
const CssTextField = withStyles({
    root: {
        '& .MuiInput-underline:after': {
            borderBottomColor: '#14193A',
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: '',
                borderWidth: 0
            },
            '&.Mui-focused fieldset': {
                borderColor: '',
                borderWidth: 0
            },
        },
    },
})(TextField, Select);
const LightTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: 'black',
        color: 'white',
        boxShadow: theme.shadows[1],
        fontSize: 13,
    },
}))(Tooltip);
class TaskComponent extends Component {
    constructor(props) {
        super(props);
        var zoneName = momentTimeZone.tz.guess();
        var userData = localStorage.getItem("userdata")
            ? JSON.parse(localStorage.getItem("userdata"))
            : "";
        var roles = userData.role;
        this.state = {
            timeZoneName: localStorage.getItem("timeZone") ? localStorage.getItem("timeZone") : zoneName,
            auth_username: userData.username,
            role: userData.role,
            auth_email: userData.email,
            auth_companyId: userData.companyId,
            showaddModal: false,
            showEditModal: false,
            modalRecord: {},
            deleteRecord: {},
            dateFilter: { from: "", to: "" },
            rowsPerPage: '10',
            open: "",
            StatusConfig: {
                config: {
                    id: "status_filter_select",
                },
                anchorElStatus: null,
            },
            StatusFilterName: "",
            assigneeConfig: {
                anchorElAssignee: null,
                config: {
                    id: "assignee_filter_select",
                },
            },
            accountConfig: {
                anchorElAccount: null,
                config: {
                    id: "account_filter_select",
                },
            },
            searchAccountValue: "",
            statusFilter: "",
            assigendFilter: "",
            accountFilter: "",
            companyFilter: "",
            loading: true,
            nextpage: 1,
            pageNumber: 1,
            pagesize: 10,
            emergencyConfig: {
                config: {
                    id: "status_filter_select",
                },
                anchorElStatus: null,
            },
            companyOption: [
                {
                    id: "",
                    name: "",
                },
            ],
            assignedOption: [
                {
                    id: "",
                    name: "",
                },
            ],
            accountOption: [
                {
                    id: "",
                    name: "",
                },
            ],
            departmentId: "",
            departmentName: "",
            accountId: "",
            accountName: "",
            companyId: "",
            companyName: "",
            taskSerch: "",
            emergencyFilterName: "",
            emergencyFilter: "",
            tooltipprops: {},
            searchPatientInfo: false,
            patientNameChecked: false,
            caseNumberChecked: false,
            searchPatientInfoType : ""
        };
    }
    UNSAFE_componentWillMount() {
        let obj = {}
        if (isSafari) {
            obj.open = false;
            this.setState({ tooltipprops: obj })
        }
    }
    componentWillMount = () => {
        this.props.onPagination(this.state.pageNumber, this.state.pagesize);

        const cms = JSON.parse(localStorage.getItem('cms'));
        cms.map((cmsItem) => {
            if(cmsItem.value === "patientName") {
                this.setState({patientNameChecked: cmsItem.checked})
            } else if (cmsItem.value === "caseNumber") {
                this.setState({caseNumberChecked: cmsItem.checked})
            }
        })


        setTimeout(() => {
            this.setState({ loading: false });
        }, 2500);
    }
    onTaskDetailHandler = (taskId) => {
        this.props.history.push(`task/${taskId}`)
    }
    // handleChangePage = (event, nextpage) => {
    //     this.setState({ nextpage: nextpage + 1 },
    //         this.filter
    //     )
    // };
    handleCustomPaginagtionChange = (event, value) => {
        this.setState({ pageNumber: value },
            this.filter
        )
    }
    handleChangeRowsPerPage = (event) => {
        this.setState({ pageNumber: 1, pagesize: +event.target.value },
            this.filter
        );
    };
    onDateChanage = (date, dateString) => {
        this.setState({ pageNumber: 1, dateFilter: { from: dateString[0], to: dateString[1], } },
            this.filter
        );
    }
    handleStatusSelect = (event) => {
        this.setState({ pageNumber: 1, [event.target.name]: event.target.value },
            this.filter
        );
    }

    departmentListHandler = async (e) => {
        var name = e.target.value;
        var data = {
            search: name,
        };
        AxiosInstance((process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa")
            ? 4004
            : "clinical-task", "application/x-www-form-urlencoded; charset=UTF-8")
            .post("/departmentSearch", data).then((res) => {
                if (res.data.status === 200) {
                    this.setState({
                        accountOption: res.data.data,
                    });
                } else {
                    this.setState({
                        accountOption: [
                            {
                                id: "one",
                                name: "No data",
                            },
                        ],
                    });
                }
            });
    };
    onDepartmentSelectHandler = (e) => {
        var name = e.target.value;
        if (!name == "") {
            var { accountOption, formError } = this.state;
            var data = accountOption.filter((item) => {
                return item.name === name;
            });
            data.map((accountData) => {
                this.setState({
                    accountId: accountData._id,
                    accountName: accountData.name,
                    pageNumber: 1,
                },
                    this.filter
                );
            });
        }
    };
    departmentAutoClose = (e) => {
        var name = e.target.value;
        if (typeof name === "undefined") {
            this.setState({ accountId: "" },
                this.filter
            );
        }

    }
    companyListHandler = async (e) => {
        var name = e.target.value;
        var data = {
            companyName: name,
        };
        AxiosInstance((process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa")
            ? 4004
            : "clinical-task", "application/x-www-form-urlencoded; charset=UTF-8")
            .post("/companyFilter", data).then((res) => {
                if (res.data.status == "200") {
                    this.setState({
                        companyOption: res.data.data,
                    });
                } else {
                    this.setState({
                        companyOption: [
                            {
                                id: "one",
                                name: "No data",
                            },
                        ],

                    });
                }
            });
        var { companyOption, formError } = this.state;
        var data = await companyOption.filter((item) => {
            return item.name === name;
        });
        data.map((companyData) => {
            this.setState({
                companyId: companyData._id,
                companyName: companyData.name,
                [formError.companyId]: "",
            });
        });
    };

    onSelectHandler = (e) => {

        var name = e.target.value;
        if (!name == "") {
            var { companyOption } = this.state;
            var data = companyOption.filter((item) => {
                return item.name === name;
            });
            data.map((companyData) => {
                this.setState({
                    companyId: companyData._id, companyName: companyData.name, pageNumber: 1,
                },
                    this.filter
                );
            });
        }
    };
    compAutoClose = (e) => {
        var name = e.target.value;
        if (typeof name === "undefined") {
            this.setState({ companyId: "" },
                this.filter
            );
        }
    }
    taskSearch = (e) => {
        let name = e.target.value
        this.setState({ pageNumber: 1, taskSerch: name }, () => {
            if(!this.state.searchPatientInfo) this.filter();
        });
    }
    handlePatientInfoCheck = (e) => {
        const checked = e.target.checked
        this.setState({ searchPatientInfo: !this.state.searchPatientInfo }, () => {
          if(!checked) {
            this.filter()
          }
        },)
    }
    filter = () => {
        this.setState({ loading: true });
        var { pagesize, pageNumber, accountId, departmentId, taskSerch, statusFilter, emergencyFilter } = this.state
        let data = {
            pagesize: pagesize,
            nextpage: pageNumber,
            companyId: accountId,
            departmentId: departmentId,
            // startDate: this.state.dateFilter.from,
            // endDate: this.state.dateFilter.to,
            search: taskSerch,
            taskStatus: statusFilter,
            emergency: emergencyFilter,
            searchPatientInfo: this.state.searchPatientInfo,
            searchPatientInfoType : (this.state.patientNameChecked && this.state.caseNumberChecked) ? 0
            : (this.state.patientNameChecked && !this.state.caseNumberChecked) ? 1 
            : (!this.state.patientNameChecked && this.state.caseNumberChecked) ? 2 : ""
        }
        if (this.state.dateFilter.from && this.state.dateFilter.to) {
            data.startDate = momentTimeZone(momentTimeZone(this.state.dateFilter.from)
                .tz(this.state.dateFilter.from).format('YYYY-MM-DD 00:00:01')).utc();
            data.endDate = momentTimeZone(momentTimeZone(this.state.dateFilter.to)
                .tz(this.state.dateFilter.to).format('YYYY-MM-DD 23:59:59')).utc();

        } else {
            data.startDate = "";
            data.endDate = "";
        }
        this.props.onSearchBy(data);
        setTimeout(() => {
            this.setState({ loading: false });
        }, 2500);
    }

    /**
       *
       * TO HANDLE STATUS MENU OPEN
       */
    openRoleFilterMenu = (event) => {
        const { StatusConfig } = this.state;
        StatusConfig.anchorElStatus = event.currentTarget;
        this.setState({
            StatusConfig,
        });
    };

    /**
       *
       * @param {item object} item
       * TO ADD STATUS FILTER VALUE ON SELECT
       */
    handleRoleStatusSelect = (item) => {
        var { statusFilter, StatusFilterName, StatusConfig } = this.state;
        statusFilter = item._id;
        StatusFilterName = item.name;
        StatusConfig.anchorElStatus = null;
        this.setState(
            {
                StatusConfig,
                StatusFilterName,
                statusFilter,
                pageNumber: 1
            },
            () => {
                this.filter();
            }
        );
    };
    /**
     *
     * TO HANDLE STATUS MENU CLOSE
     */
    closeStatusRoleFilterMenu = () => {
        const { StatusConfig } = this.state;
        StatusConfig.anchorElStatus = null;
        this.setState({
            StatusConfig,
        });
    };

    /**
   *
   * TO HANDLE ASSIGNEE MENU OPEN
   */
    openAssigneeFilterMenu = (event) => {
        const { assigneeConfig } = this.state;
        assigneeConfig.anchorElAssignee = event.currentTarget;
        this.setState(
            {
                assigneeConfig,
            },
            () => {
                this.employeeFilter("");
            }
        );
    };

    /**
     *
     * @param {value} search
     * TO FETCH THE EMPLOYEE FILTER
     */
    employeeFilter = (search) => {
        const { assignedOption } = this.state;

        AxiosInstance((process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa")
            ? 4004
            : "clinical-task", "application/x-www-form-urlencoded; charset=UTF-8")
            .post("/departmentSearch", { search })
            .then((res) => {
                var response = res.data;
                if (
                    response &&
                    (response.status === 200 || response.status === "200")
                ) {
                    var data = response.data;
                    var newArray = assignedOption.splice();
                    if (this.state.searchAssigneeValue === "") {
                        newArray.unshift({ _id: "", name: "All" });
                    } else {
                        newArray.shift({ _id: "", name: "All" });
                    }
                    if (data.length > 0) {
                        data.map((item) => {
                            var obj = {};
                            obj._id = item._id;
                            obj.name = item.name;
                            newArray.push(obj);
                        });
                    }
                    this.setState({
                        assignedOption: newArray,
                    });
                } else {
                    this.setState({
                        assignedOption: [
                            {
                                _id: "",
                                name: "All",
                            },
                        ],
                    });
                }
            })
            .catch((err) => {
                var error = err.response;
                if (error && (error.status === "403" || error.status === 403)) {
                    localStorage.clear();
                    setTimeout(() => {
                        window.location.href = "/";
                    }, 500);
                } else {
                    console.log("Internal Server Error");
                }
            });
    };
    /**
     *
     * @param {item object} item
     * TO ADD ASSIGNEE FILTER VALUE ON SELECT
     */
    handleAssigneeSelect = (item) => {
        const { assigneeConfig } = this.state;
        assigneeConfig.anchorElAssignee = null;

        this.setState(
            {
                departmentId: item._id,
                departmentName: item.name === "All" ? "" : item.name,
                assigneeConfig,
                searchAssigneeValue: "",
                pageNumber: 1,
            },
            () => {
                this.filter();
            }
        );
    };
    /**
    *
    * TO HANDLE ASSIGNEE MENU CLOSE
    */
    closeAssigneeFilterMenu = () => {
        const { assigneeConfig } = this.state;
        assigneeConfig.anchorElAssignee = null;
        this.setState({
            assigneeConfig,
        });
    };
    /**
     *
     * @param {item object} item
     * TO HANDLE ASSIGNEE FILTER VALUE ON SEARCH
     */
    onAssigneeSearchFilter = (e) => {
        const { name, value } = e.target;
        this.setState({
            searchAssigneeValue: value,
        });
        this.employeeFilter(value);
    };

    /**
  *
  * TO ACCOUNT STATUS MENU OPEN
  */
    openAccountFilterMenu = (event) => {
        const { accountConfig } = this.state;
        accountConfig.anchorElAccount = event.currentTarget;
        this.setState(
            {
                accountConfig,
            },
            () => {
                this.accountFilter("");
            }
        );
    };

    /**
     *
     * @param {value} search
     * TO FETCH THE ACCOUNT FILTER
     */
    accountFilter = (search) => {
        const { accountOption } = this.state;
        AxiosInstance((process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa")
            ? 4004
            : "clinical-task", "application/x-www-form-urlencoded; charset=UTF-8")
            .post("/companyFilter", { search })
            .then((resp) => {
                var response = resp && resp.data;
                if (
                    response &&
                    (response.status === 200 || response.status === "200")
                ) {
                    var data = response.data;
                    var newArray = accountOption.splice();
                    if (this.state.searchAccountValue === "") {
                        newArray.unshift({ _id: "", name: "All" });
                    } else {
                        newArray.shift({ _id: "", name: "All" });
                    }
                    if (data.length > 0) {
                        data.map((item) => {
                            var obj = {};
                            obj._id = item._id;
                            obj.name = item.name;
                            newArray.push(obj);
                        });
                    }
                    this.setState({
                        accountOption: newArray,
                    });
                } else {
                    this.setState({
                        accountOption: [
                            {
                                _id: "",
                                name: "All",
                            },
                        ],
                    });
                }
            })
            .catch((err) => {
                var error = err.response;
                if (error && (error.status === "403" || error.status === 403)) {
                    localStorage.clear();
                    setTimeout(() => {
                        window.location.href = "/";
                    }, 500);
                } else {
                    console.log("Internal Server Error");
                }
            });
    }
    /**
      *
      * @param {item object} item
      * TO ADD ACCOUNT FILTER VALUE ON SELECT
      */
    handleAccountSelect = (item) => {
        const { accountConfig } = this.state;
        accountConfig.anchorElAccount = null;
        this.setState(
            {
                accountId: item._id,
                accountName: item.name === "All" ? "" : item.name,
                accountConfig,
                searchAccountValue: "",
                pageNumber: 1,
            },
            () => {
                this.filter();
            }
        );
    };

    /**
     *
     * TO HANDLE ACCOUNT MENU CLOSE
     */
    closeAccountFilterMenu = () => {
        const { accountConfig } = this.state;
        accountConfig.anchorElAccount = null;
        this.setState({
            accountConfig,
        });
    };
    /**
     *
     * @param {item object} item
     * TO HANDLE ACCOUNT FILTER VALUE ON SEARCH
     */
    onAccountSearchFilter = (e) => {
        const { name, value } = e.target;
        this.setState({
            searchAccountValue: value,
        });
        this.accountFilter(value);
    };
    /**
   *
   * TO HANDLE Emergency MENU OPEN
   */
    openEmergencyFilterMenu = (event) => {
        const { emergencyConfig } = this.state;
        emergencyConfig.anchorElStatus = event.currentTarget;
        this.setState({
            emergencyConfig,
        });
    };
    /**
      *
      * @param {item object} item
      * TO ADD Emergency FILTER VALUE ON SELECT
      */
    handleEmergencySelect = (item) => {
        var { emergencyFilter, emergencyFilterName, emergencyConfig } = this.state;
        emergencyFilter = item._id;
        emergencyFilterName = item.name;
        emergencyConfig.anchorElStatus = null;
        this.setState(
            {
                emergencyConfig,
                emergencyFilterName,
                emergencyFilter,
                pageNumber: 1,
            },
            () => {
                this.filter();
            }
        );
    };
    /**
      *
      * TO HANDLE Emergency MENU CLOSE
      */
    closeEmergencyFilterMenu = () => {
        const { emergencyConfig } = this.state;
        emergencyConfig.anchorElStatus = null;
        this.setState({
            emergencyConfig,
        });
    };
    navigateDetail = (row) => {
        this.props.history.push('task/' + row._id);
    }
    render() {
        let { loading } = this.state;
        var statusOptions = [{ name: "Unassigned", _id: "Unassigned" },
        { name: "Assigned", _id: "assigned" },
        { name: "In Progress", _id: "inprogress" },
        { name: "Completed", _id: "completed" },
        { name: "Cancelled", _id: "cancelled" },
        { name: "Uncovered", _id: "uncovered" },
        ];
        var emergencyOptions = [
            { _id: "emergency", name: "Emergency" },
            { _id: "normal", name: "Normal" },
        ];
        let page = 0;
        let TaskPage = (
            <div className="body-bg-color">
                <HeaderComponent />
                <div className="header-add-btn">
                    <Typography variant="h5" className="tableTitle" component="div">Tasks</Typography>
                    {/* <Button variant="contained" className="tableaddBtn" color="default" style={{ boxShadow: 'none' }} onClick={() => this.setState({ showaddModal: true })}>Task Scheduler</Button> */}
                </div>

                <div className='Acc-Table' >
                    <div className="filter-search">

                        <div className="new_custom_filters">
                            <Suspense fallback={<div></div>}>
                                <div
                                    className="new_custom_filter_div"
                                    onClick={this.openRoleFilterMenu}
                                >
                                    {this.state.StatusFilterName !== "" ? (
                                        ""
                                    ) : (
                                            <label className="leftName">Status: </label>
                                        )}
                                    <label
                                        aria-label="status_filter"
                                        aria-controls="status_filter_select"
                                        area-aria-haspopup="true"
                                        className="rightName"
                                    >
                                        {this.state.StatusFilterName === ""
                                            ? `All`
                                            : upperFirst(this.state.StatusFilterName)}
                                    </label>
                                    <img className="carret" src={carret_down} alt="carret" />
                                </div>
                                <CustomSelectComponent
                                    filterArray={statusOptions}
                                    config={this.state.StatusConfig.config}
                                    anchorEl={this.state.StatusConfig.anchorElStatus}
                                    handleClick={this.handleRoleStatusSelect}
                                    closeCustomPopup={this.closeStatusRoleFilterMenu}
                                />
                            </Suspense>
                            <Suspense fallback={<div></div>}>
                                <div
                                    className="new_custom_filter_div"
                                    onClick={this.openAssigneeFilterMenu}
                                >
                                    {this.state.departmentName !== "" ? (
                                        ""
                                    ) : (
                                            <label className="leftName">Department: </label>
                                        )}
                                    <label
                                        aria-label="assignee_filter"
                                        aria-controls="assignee_filter_select"
                                        area-aria-haspopup="true"
                                        className="rightName"
                                    >
                                        {this.state.departmentName === ""
                                            ? `All`
                                            : upperFirst(this.state.departmentName)}
                                    </label>
                                    <img className="carret" src={carret_down} alt="carret" />
                                </div>
                                <CustomSelectWithSearchComponent
                                    filterArray={this.state.assignedOption}
                                    config={this.state.assigneeConfig.config}
                                    anchorEl={this.state.assigneeConfig.anchorElAssignee}
                                    searchValue={this.state.searchAssigneeValue}
                                    handleClick={this.handleAssigneeSelect}
                                    closeCustomPopup={this.closeAssigneeFilterMenu}
                                    onSearchFilter={this.onAssigneeSearchFilter}
                                    placeholder="Search Department"
                                />
                            </Suspense>
                            <Suspense fallback={<div></div>}>
                                <div
                                    className="new_custom_filter_div"
                                    onClick={this.openAccountFilterMenu}
                                >
                                    {this.state.accountName !== "" ? (
                                        ""
                                    ) : (
                                            <label className="leftName">Company: </label>
                                        )}
                                    <label
                                        aria-label="account_filter"
                                        aria-controls="account_filter_select"
                                        area-aria-haspopup="true"
                                        className="rightName"
                                    >
                                        {this.state.accountName === ""
                                            ? `All`
                                            : upperFirst(this.state.accountName)}
                                    </label>
                                    <img className="carret" src={carret_down} alt="carret" />
                                </div>
                                <CustomSelectWithSearchComponent
                                    filterArray={this.state.accountOption}
                                    config={this.state.accountConfig.config}
                                    anchorEl={this.state.accountConfig.anchorElAccount}
                                    searchValue={this.state.searchAccountValue}
                                    handleClick={this.handleAccountSelect}
                                    closeCustomPopup={this.closeAccountFilterMenu}
                                    onSearchFilter={this.onAccountSearchFilter}
                                    placeholder="Search Company"
                                />
                                <div
                                    className="new_custom_filter_div"
                                    onClick={this.openEmergencyFilterMenu}
                                >
                                    {this.state.emergencyFilterName !== "" ? (
                                        ""
                                    ) : (
                                            <label className="leftName">Priority: </label>
                                        )}
                                    <label
                                        aria-label="status_filter"
                                        aria-controls="status_filter_select"
                                        area-aria-haspopup="true"
                                        className="rightName"
                                    >
                                        {this.state.emergencyFilterName === ""
                                            ? `All`
                                            : upperFirst(this.state.emergencyFilterName)}
                                    </label>
                                    <img className="carret" src={carret_down} alt="carret" />
                                </div>
                                <CustomSelectComponent
                                    filterArray={emergencyOptions}
                                    config={this.state.emergencyConfig.config}
                                    anchorEl={this.state.emergencyConfig.anchorElStatus}
                                    handleClick={this.handleEmergencySelect}
                                    closeCustomPopup={this.closeEmergencyFilterMenu}
                                />
                            </Suspense>
                            <div className="new_custom_filter_div task_date_rage">
                                <label className="leftName">Date Range: </label>
                                <RangePicker
                                    className="filter_date_range "
                                    name="datepicker"
                                    format="MM-DD-YYYY"
                                    separator="-"
                                    placeholder={["MM-DD-YYYY", "MM-DD-YYYY"]}
                                    suffixIcon={
                                        <img
                                            className="carret"
                                            alt="carret"
                                            src={carret_down}
                                        />
                                    }
                                    onChange={(date, dateString) =>
                                        this.onDateChanage(date, dateString)
                                    }
                                />
                            </div>
                        </div>
                        {(this.state.patientNameChecked || this.state.caseNumberChecked) ?
                        <div className={`search-container${this.state.searchPatientInfo ? " search-back-width" : ""}`}>
                        <LightTooltip
                        placement="top"
                        title="Enable the checkbox to Search for Patient Information"
                        {...this.state.tooltipprops}
                        arrow
                      >
                        <FormControlLabel
                          control={
                          <Checkbox
                          name="Patient info"
                          onChange={this.handlePatientInfoCheck}
                          checked={this.state.searchPatientInfo}
                          sx={{
                            '&.Mui-checked': {
                              color: "#21BF73",
                            },
                          }}
                          />}
                          label="Patient Information"
                          className="mr-0 checked-color"
                        />
                        </LightTooltip>
                        <CssTextField
                          id="outlined-basic"
                          name="taskSerch"
                          variant="outlined"
                          type="text"
                          placeholder="Search Here"
                          style={{
                            color: "#303960",
                            fontFamily: "Roboto",
                            backgroundColor: "#FFFFFF",
                            opacity: 1,
                          }}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment
                                position="start"
                                style={{ cursor: "pointer" }}
                              >
                                <img src={searchIcon} alt="searchIcon" />
                              </InputAdornment>
                            ),
                          }}
                          value={this.state.taskSerch}
                          onChange={this.taskSearch}
                        />
                        {this.state.searchPatientInfo && 
                        <LightTooltip
                        placement="top"
                        title={(this.state.patientNameChecked && this.state.caseNumberChecked) 
                        ? "Please enter the full patient name or case number" 
                        : this.state.patientNameChecked ? "Please enter the full patient name" 
                        : this.state.caseNumberChecked ? "Please enter the full case number" 
                        : ""}
                        {...this.state.tooltipprops}
                        arrow
                      >
                        <div className="search-btn">
                          <img src={searchIconWhite} style={{height:"18px", width:"18px"}} onClick={this.filter}></img>
                        </div>
                        </LightTooltip>
                        }
                        </div>
                        :
                        <CssTextField id="outlined-basic" name='taskSerch' variant="outlined" type="text" placeholder="Search Here" style={{ color: '#303960', fontFamily: 'Roboto', backgroundColor: '#F9F9F9', opacity: 1 }} InputProps={{ startAdornment: <InputAdornment position="start" style={{ cursor: 'pointer' }}><img src={searchIcon} /></InputAdornment> }} onChange={this.taskSearch} />
                        }
                    </div>
                    <div className="table_container">
                        <TableContainer >
                            <Table aria-label="customized table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell >#</TableCell>
                                        <TableCell >TASK</TableCell>
                                        <TableCell  >STATUS</TableCell>
                                        <TableCell  >COMPANY</TableCell>
                                        <TableCell >REP</TableCell>
                                        <TableCell >DEPARTMENT</TableCell>
                                        <TableCell >START DATE & TIME</TableCell>
                                        <TableCell  >END TIME</TableCell>
                                        <TableCell  >ACTIONS</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody className="tablecell_container">
                                    {loading ?
                                        <StyledTableRow>
                                            <TableCell colSpan={9} style={{ textAlign: "center" }}>
                                                <Loader
                                                    type="ThreeDots"
                                                    color="#00BFFF"
                                                    height={50}
                                                    width={50}
                                                    visible={loading}
                                                />
                                            </TableCell>
                                        </StyledTableRow> :
                                        !this.props.pageOfItems || this.props.pageOfItems.length === 0 ?
                                            <StyledTableRow><TableCell colSpan={9} style={{ textAlign: 'center' }}>
                                                No data found
                                             </TableCell></StyledTableRow> : (this.state.rowsPerPage > 0
                                                ? this.props.pageOfItems.slice() : this.props.pageOfItems).map((row, index) => (
                                                    <StyledTableRow key={row._id}>
                                                        <TableCell onClick={() => this.navigateDetail(row)}>{!this.props.pager ? 0 : (this.props.pager.currentPage - 1) * this.state.pagesize + (index + 1)}</TableCell>

                                                        { row.isEmergency === true ? (

                                                            <TableCell onClick={() => this.navigateDetail(row)} className="priority_icon_wrapper">
                                                                <img
                                                                    className="priority_icon"
                                                                    src={emergency} alt="emergency"
                                                                ></img>
                                                                <LightTooltip title={row.taskName ? row.taskName.charAt(0).toUpperCase() + row.taskName.slice(1) : '-'} {...this.state.tooltipprops} arrow>
                                                                    <span >{row.taskName ? row.taskName.charAt(0).toUpperCase() + row.taskName.slice(1) : '-'}</span>
                                                                </LightTooltip>

                                                            </TableCell>
                                                        ) : (
                                                                <TableCell onClick={() => this.navigateDetail(row)}><LightTooltip title={row.taskName ? row.taskName.charAt(0).toUpperCase() + row.taskName.slice(1) : '-'} {...this.state.tooltipprops} arrow>
                                                                    <span >{row.taskName ? row.taskName.charAt(0).toUpperCase() + row.taskName.slice(1) : '-'}</span>
                                                                </LightTooltip></TableCell>

                                                            )}
                                                        {row.taskStatus ?
                                                            <>
                                                                {row.taskStatus.toLowerCase() === "unassigned" ? (
                                                                    <TableCell onClick={() => this.navigateDetail(row)}>
                                                                        <span style={{ color: 'hsla(228, 100%, 69%, 1)' }}>

                                                                            <LightTooltip {...this.state.tooltipprops} title={row.taskStatus ? row.taskStatus.charAt(0).toUpperCase() + row.taskStatus.slice(1) : '-'} arrow>
                                                                                <span >{row.taskStatus ? row.taskStatus.charAt(0).toUpperCase() + row.taskStatus.slice(1) : '-'}</span>
                                                                            </LightTooltip>
                                                                        </span></TableCell>
                                                                ) : row.taskStatus.toLowerCase() === "pending" ? (
                                                                    <TableCell onClick={() => this.navigateDetail(row)}> <span style={{ color: 'hsla(0, 100%, 61%, 1)' }}><LightTooltip {...this.state.tooltipprops} title={row.taskStatus ? row.taskStatus.charAt(0).toUpperCase() + row.taskStatus.slice(1) : '-'} arrow>
                                                                        <span >{row.taskStatus ? row.taskStatus.charAt(0).toUpperCase() + row.taskStatus.slice(1) : '-'}</span>
                                                                    </LightTooltip></span></TableCell>
                                                                ) : row.taskStatus.toLowerCase() === "assigned" ? (
                                                                    <TableCell onClick={() => this.navigateDetail(row)}> <span style={{ color: '#617FFF' }}><LightTooltip {...this.state.tooltipprops} title={row.taskStatus ? row.taskStatus.charAt(0).toUpperCase() + row.taskStatus.slice(1) : '-'} arrow>
                                                                        <span >{row.taskStatus ? row.taskStatus.charAt(0).toUpperCase() + row.taskStatus.slice(1) : '-'}</span>
                                                                    </LightTooltip></span></TableCell>
                                                                ) : row.taskStatus.toLowerCase() === "in progress" ? (
                                                                    <TableCell onClick={() => this.navigateDetail(row)}> <span style={{ color: '#0022FF' }}><LightTooltip {...this.state.tooltipprops} title={row.taskStatus ? row.taskStatus.charAt(0).toUpperCase() + row.taskStatus.slice(1) : '-'} arrow>
                                                                        <span >{row.taskStatus ? row.taskStatus.charAt(0).toUpperCase() + row.taskStatus.slice(1) : '-'}</span>
                                                                    </LightTooltip></span></TableCell>
                                                                ) : row.taskStatus.toLowerCase() === "completed" ? (
                                                                    <TableCell onClick={() => this.navigateDetail(row)}> <span style={{ color: '#21BF73' }}><LightTooltip {...this.state.tooltipprops} title={row.taskStatus ? row.taskStatus.charAt(0).toUpperCase() + row.taskStatus.slice(1) : '-'} arrow>
                                                                        <span >{row.taskStatus ? row.taskStatus.charAt(0).toUpperCase() + row.taskStatus.slice(1) : '-'}</span>
                                                                    </LightTooltip></span></TableCell>
                                                                ) : row.taskStatus.toLowerCase() === "cancelled" ? (
                                                                    <TableCell onClick={() => this.navigateDetail(row)}> <span style={{ color: '#979797' }}><LightTooltip {...this.state.tooltipprops} title={row.taskStatus ? row.taskStatus.charAt(0).toUpperCase() + row.taskStatus.slice(1) : '-'} arrow>
                                                                        <span >{row.taskStatus ? row.taskStatus.charAt(0).toUpperCase() + row.taskStatus.slice(1) : '-'}</span>
                                                                    </LightTooltip></span></TableCell>
                                                                ) : row.taskStatus.toLowerCase() === "uncovered" ? (
                                                                    <TableCell onClick={() => this.navigateDetail(row)}> <span style={{ color: '#617FFF' }}><LightTooltip {...this.state.tooltipprops} title={row.taskStatus ? row.taskStatus.charAt(0).toUpperCase() + row.taskStatus.slice(1) : '-'} arrow>
                                                                        <span >{row.taskStatus ? row.taskStatus.charAt(0).toUpperCase() + row.taskStatus.slice(1) : '-'}</span>
                                                                    </LightTooltip></span></TableCell>
                                                                ) : ("_")}
                                                            </>
                                                            : ''}
                                                        <TableCell onClick={() => this.navigateDetail(row)}> <LightTooltip {...this.state.tooltipprops} title={row.companyName ? row.companyName.charAt(0).toUpperCase() + row.companyName.slice(1) : '-'} arrow>
                                                            <span >{row.companyName ? row.companyName.charAt(0).toUpperCase() + row.companyName.slice(1) : '-'}</span>
                                                        </LightTooltip>               </TableCell>
                                                        <TableCell onClick={() => this.navigateDetail(row)}>
                                                            <LightTooltip {...this.state.tooltipprops} title={row.salesRep?.name ? row.salesRep?.name.charAt(0).toUpperCase() + row.salesRep?.name.slice(1) : '-'} arrow>
                                                                <span >{row.salesRep?.name ? row.salesRep?.name.charAt(0).toUpperCase() + row.salesRep?.name.slice(1) : '-'}</span>
                                                            </LightTooltip>
                                                        </TableCell>
                                                        <TableCell>
                                                            <LightTooltip {...this.state.tooltipprops} title={row.department ? row.department.name.charAt(0).toUpperCase() + row.department.name.slice(1) : '-'} arrow>
                                                                <span >{row.department ? row.department.name.charAt(0).toUpperCase() + row.department.name.slice(1) : '-'}</span>
                                                            </LightTooltip>

                                                        </TableCell>
                                                        <TableCell onClick={() => this.navigateDetail(row)}>

                                                            <LightTooltip {...this.state.tooltipprops} title={moment(row.startDate).format("MM-DD-YYYY-hh:mm A")} arrow>
                                                                <span >{moment(row.startDate).format("MM-DD-YYYY-hh:mm A")}</span>
                                                            </LightTooltip>

                                                        </TableCell>
                                                        <TableCell onClick={() => this.navigateDetail(row)}>
                                                            <LightTooltip {...this.state.tooltipprops} title={moment(row.endDate).format("MM-DD-YYYY-hh:mm A")} arrow>
                                                                <span >{moment(row.endDate).format("MM-DD-YYYY-hh:mm A")}</span>
                                                            </LightTooltip>

                                                        </TableCell>
                                                        <TableCell ><div className="table_action_btns" style={{ display: "flex", alignItems: "center", justifyContent: "left", }}>
                                                            <LightTooltip {...this.state.tooltipprops} title="View Details" arrow>
                                                                {/* <div onClick={() => this.onTaskDetailHandler(row._id)}> */}
                                                                <NavLink to={'/task/' + row._id} className="navbars">
                                                                    <img style={{ cursor: 'pointer' }} src={OpenMenu} alt="openmenu"></img>
                                                                </NavLink>
                                                                {/* </div> */}
                                                            </LightTooltip>
                                                        </div></TableCell>
                                                    </StyledTableRow>
                                                ))}
                                </TableBody>
                                <TableFooter>
                                    {this.props.pageOfItems && this.props.pageOfItems.length !== 0 ?
                                        <TableRow>
                                            <td colSpan={20} className="pagination_container">
                                                <CustomPaginationComponent
                                                    count={this.props.pager.endPage}
                                                    rowsPerPage={this.state.pagesize}
                                                    page={this.state.pageNumber}
                                                    onPageChange={this.handleCustomPaginagtionChange}
                                                    onChangeRowsPerPage={this.handleChangeRowsPerPage}
                                                />
                                            </td>
                                        </TableRow>
                                        : null}
                                </TableFooter>
                            </Table>
                        </TableContainer>
                    </div>
                </div>
            </div >
        )
        return <div>{TaskPage}</div>;
    }
}
const mapStateToProps = (state) => {
    return {
        loginError: state.auth.error,
        pager: state.pagination.pager,
        pageOfItems: state.pagination.pageOfItems,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        onPagination: (pageNo, pagerole,) => dispatch(action.taskpagination(pageNo, pagerole)),
        onSearchBy: (value) => dispatch(action.onSearchBy(value)),
    };
};
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(TaskComponent));
