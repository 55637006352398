import React from 'react';
import { Button,TextField, Dialog, InputLabel, DialogContent, DialogTitle, Select, IconButton } from "@material-ui/core";
import { withStyles} from "@material-ui/core/styles";
import { Alert } from "@material-ui/lab";
import closeBtn from "../../../Assets/images/close_btn.svg";
import { TransitionGrow } from '../../../SharedComponents/CommonUtilities';

const CssTextField = withStyles({
  root: {
    backgroundColor: "#F2F2F2",
    borderRadius: "5px",
    marginBottom: "15px",
    "& .MuiInput-underline:after": {
      borderBottomColor: "#14193A",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#E2E5F2",
        borderWidth: 0,
      },

      "&.Mui-focused fieldset": {
        borderColor: "#14193A",
        borderWidth: 0,
      },
    },
  },
})(TextField); 
  
const ForgotPasswordModal = (props) => {
    return (
        <div>
        <Dialog
          onExit={props.clearForgotDialog}
          open={props.forgotPassword}
          aria-labelledby="form-dialog-title"
          maxWidth="md"
          className="add_company_dialog"
          TransitionComponent={TransitionGrow} 
          transitionDuration={600}
        >
            <DialogTitle id="form-dialog-title" className="add_user_header">
                <span>Forgot Password</span>
                <IconButton
                onClick={props.onForgotModalClose}
                className="closeBtn"
                aria-label="show 17 new notifications"
                color="inherit"
                >
                <img src={closeBtn}></img>
                </IconButton>
            </DialogTitle>
            <DialogContent>
                {props.responseMsg?
                    <Alert severity={props.alertType}>
                        {props.responseMsg}
                    </Alert>
                :""}
                <div className="form_input_field forget_password_field">
               <InputLabel  className="loginLabel">
                    Enter email id to recover your password
                </InputLabel>
              <CssTextField
                id="outlined-basic"
                name="forgotEmail"
                variant="outlined"
                type="text"
                fullWidth
                placeholder="Enter your email"
                onChange={props.onChangeForgotPassword}
              />
                {props.forgotEmailError?
                    <span className="error">
                        *{props.forgotEmailError}
                    </span>
                :""}
                </div>
            <div className="add_company_action">
                <Button
                    className="cancel_btn"
                    onClick={props.onForgotModalClose}
                    variant="outlined"
                >
                    Cancel
                </Button>
                <Button
                    // disabled={this.state.levelError === "" ? true : false}
                    className="ok_btn"
                    onClick={props.onForgotPassword}
                    variant="contained"
                >
                    Confirm
                </Button>
            </div>
            </DialogContent>
        </Dialog>
    </div>
    );
}
 
export default ForgotPasswordModal;