import React,{Component} from 'react';
import validator from 'validator';
import PropTypes from 'prop-types';
import logo3 from "../../../Assets/images/Group 160.svg";
import { Container, Grid, Card, TextField,InputLabel, Button,AppBar,
        Toolbar,IconButton,InputAdornment,withStyles } from '@material-ui/core';
import {  withRouter} from "react-router-dom";
import { AxiosInstance } from "../../../Utilities";
import { NotificationComponent } from "../../../SharedComponents/NotificationComponent/NotificationComponent";
import iconNotificationError from "../../../Assets/images/icon-notify-error.svg";
import iconNotificationUpdate from "../../../Assets/images/icon-notify-update.svg";
import logo from "../../../Assets/images/Sales_C2_logo.svg";
import logo2 from "../../../Assets/images/logo_icon_signup.svg";
import eyeIcon from "../../../Assets/images/eye-icon.svg";
import eyeCloseIcon from "../../../Assets/images/eye-close-icon.svg";
import "./ResetPasswordComponent.css";
import {Tooltip} from 'antd';
import PageNotFoundComponent from '../../PageNotFoundComponent/PageNotFoundComponent';
const CssTextField = withStyles({
    root: {
      backgroundColor: "#F2F2F2",
      borderRadius: "5px",
      "& .MuiInput-underline:after": {
        borderBottomColor: "#14193A",
      },
      "& .MuiOutlinedInput-root": {
        "& fieldset": {
          borderColor: "#E2E5F2",
          borderWidth: 0,
        },
  
        "&.Mui-focused fieldset": {
          borderColor: "#14193A",
          borderWidth: 0,
        },
      },
    },
  })(TextField);

class ResetPasswordComponent extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            hidden:true,
            Chidden:true,
            notValidUser:false,
            username:"",
            password:"",
            userId:"",
            confirmPassword:"",
            formError:{
                password:"",
                confirmPassword:""
            }
            
         }
         this.on_Password_Change_Handler=this.on_Password_Change_Handler.bind(this);
         this.on_Reset_Password_Handler_Cancel=this.on_Reset_Password_Handler_Cancel.bind(this);
         this.on_Reset_Password_Handler=this.on_Reset_Password_Handler.bind(this);
    }
    /**
     * To fetch the user name brefore rendering the page
     * based on {userid} from url params
     */
    componentWillMount(){
        const { params }= this.props.match;
        let { id } = params;
       if(params && id){
           this.setState({
               userId:id
           });
            AxiosInstance((process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa")
            ? 4002
            : "clinical-login","application/json")
            .get(`/employee/${id}`)
              .then((resp) => {
                  var response=resp && resp.data;
                  if(response && (response.status === "200" || response.status === 200)){
                    this.setState({
                        username:response.data.name
                    })  
                  }else if(response && (response.status === 400 || response.status === "400" || response.status === 404 || response.status === "404")){
                    this.setState({
                      notValidUser:true
                    })
                  }
              }).catch(err=>{
                  console.log("Username not found")
            })
        }
    }
    /**
     * @author Benakesh
     * @param {target element} e 
     * To set the state on form field change
     */
    on_Password_Change_Handler=(e)=>{
        var {name,value}=e.target;
        var {formError}=this.state;
        switch(name){
            case 'password': 
                            if(value.trim().length>0){
                                formError.password=""
                            }
                            break;
            case 'confirmPassword': 
                            if(value.trim().length>0){
                                formError.confirmPassword="";
                            }
                            break;
            default: break;
        }
        this.setState({
            [name]:value,
            formError
        })
    }

    /**
     * @author Benakesh
     * @param {target element} e 
     * To call reset password api
     * if no error in the form
     */
    on_Reset_Password_Handler=(e)=>{
        e.preventDefault();
        var {formError,confirmPassword,password,userId}=this.state;
        if(confirmPassword.trim().length>0 && !validator.equals(password.trim(),confirmPassword.trim())){
            formError.confirmPassword="Confirmed password must match your new password";
        }else if(password.trim() === ""){
            formError.password="Please enter new password";
        }else if(confirmPassword.trim() === ""){
            formError.confirmPassword="Please confirm your password";
        }else{
            formError.confirmPassword="";
            formError.password=""
        }

        this.setState({
             formError
        },()=>{
            if(formError.password === "" && formError.confirmPassword === "" 
                && (confirmPassword !== "" && password !== "" && userId !== "")){
                    AxiosInstance( (process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa")
              ? 4002
              : "clinical-login","application/json")
                    .put("/update/password",{
                            userId:validator.escape(userId).trim(),
                            password:validator.escape(confirmPassword).trim()})
                    .then(resp=>{
                        var response=resp && resp.data;
                        if(response && (response.status === "204" || response.status === 204)){
                            this.setState({
                                password:"",
                                confirmPassword:"",
                                formError:{
                                    password:"",
                                    confirmPassword:""
                                }
                            })
                            var props = {
                                message: response.msg,
                                icon: (
                                  <img src={iconNotificationUpdate} alt="success" />
                                ),
                                type: "success",
                                placement: "bottomRight",
                                duration: 1.5,
                                top: 200,
                                className: "task_notification update-notify",
                              };
                              NotificationComponent(props);
                              localStorage.clear();
                              setTimeout(()=>{
                                this.props.history.replace("/")
                              },2500)
                        }else{
                            var props = {
                                message: response.msg,
                                icon: (
                                  <img src={iconNotificationError} alt="success" />
                                ),
                                type: "error",
                                placement: "bottomRight",
                                duration: 2,
                                top: 200,
                                className: "task_notification error-notify",
                              };
                              NotificationComponent(props);
                        }
                    })
                    .catch(err=>{
                        var props = {
                            message: "Internal Server Error",
                            icon: (
                              <img src={iconNotificationError} alt="success" />
                            ),
                            type: "error",
                            placement: "bottomRight",
                            duration: 2,
                            top: 200,
                            className: "task_notification error-notify",
                          };
                          NotificationComponent(props);
                    })
                }
        })
       
    }

    /**
     * @author Benakesh
     * To go to login form if user clicked home button
     */
    on_Reset_Password_Handler_Cancel=()=>{
       this.props.history.replace("/")
    }
   
    render() { 
        const {formError,hidden,username,password,confirmPassword, Chidden }=this.state;
        if(this.state.notValidUser){
            return <PageNotFoundComponent/>;
        }else{
        return (
            <div className="signup-body-bg-color signup-section">

            <Container fixed className="signup-body">
                <Card className="reset_password_section signup-root">
                    <Grid container>
                        <Grid item xs={12} className="card-header">
                            <div className="header-content">
                                <div className="signup-logo">
                                    <img src={logo3} alt="Sales-Icon" height="auto" />
                                </div>
                                <p className="signup-name">
                                    Hi {this.state.username}
                                </p>
                                <h3 className="pragraph-text">
                                    Welcome to Clinical C2. Fill in to reset your password.
                                </h3>
                            </div>
                        </Grid>
                    </Grid>
                    <Grid container className="header-content">
                        <Grid item xs={12}>
                            <form onSubmit={this.on_Reset_Password_Handler} autoComplete="off">
                                <div className="form_input_field">
                                <InputLabel  className="loginLabel">
                                    Enter New Password
                                </InputLabel>
                                <CssTextField  
                                    id="outlined-basic-password" 
                                    type={
                                            hidden ? "password" : "text"
                                        } 
                                    name="password" 
                                    placeholder="Enter a password" 
                                    className="inputHeight" 
                                    variant="outlined" 
                                    onChange={this.on_Password_Change_Handler}  
                                    value={password} 
                                    InputProps={{   
                                        endAdornment: 
                                            <InputAdornment 
                                                position="end" 
                                                style={{cursor:'pointer'}} 
                                                onClick={
                                                    () => this.setState(
                                                            { 
                                                                hidden: !this.state.hidden 
                                                            })
                                                    }
                                                >
                                                    
                                                <Tooltip title={this.state.hidden?"Show Password":"Hide Password"} placement="bottom">
                                                    <img src={this.state.hidden ? eyeIcon:eyeCloseIcon } alt="eye"/>
                                                </Tooltip>
                                            </InputAdornment>,
                                    }}
                                />
                                  {formError.password !== ""?
                                    <span className="error">* {formError.password}</span>:""}
                                    </div>
                                    <div className="form_input_field">
                                 <InputLabel  className="loginLabel">
                                    Confirm Password
                                </InputLabel>
                                <CssTextField  
                                    id="outlined-basic-password" 
                                    type={
                                        Chidden ? "password" : "text"
                                    } 
                                    name="confirmPassword" 
                                    placeholder="Confirm password" 
                                    className="inputHeight" 
                                    variant="outlined" 
                                    onChange={this.on_Password_Change_Handler}  
                                    value={confirmPassword} 
                                    InputProps={{   
                                        endAdornment: 
                                            <InputAdornment 
                                                position="end" 
                                                style={{cursor:'pointer'}} 
                                                onClick={
                                                    () => this.setState(
                                                            { 
                                                                Chidden: !this.state.Chidden 
                                                            })
                                                    }
                                                >
                                              
                                            <Tooltip title={this.state.Chidden?"Show Password":"Hide Password"} placement="bottom">      
                                               <img src={this.state.Chidden ? eyeIcon:eyeCloseIcon } alt="eye"/>
                                            </Tooltip>
                                            </InputAdornment>,
                                    }}
                                    />
                
                                    <span className="error">{formError.confirmPassword}</span>
                                    </div>
                                <div className="buttonGroup">
                                    <Button 
                                        className="cancelBtn cancel-button go-home-btn white-btn" 
                                        variant="contained" 
                                        color="primary"
                                        onClick={this.on_Reset_Password_Handler_Cancel} 
                                    >
                                       Cancel 
                                    </Button>
                                    <Button 
                                        className="signupBtn success-button join_btn" 
                                        type="submit" 
                                        variant="contained" 
                                        color="primary"
                                    >
                                        Update Password
                                    </Button>

                                </div>
                            </form>
                        </Grid>
                    </Grid>
                </Card>
            </Container>
        </div>
        );
    }
    }
}
 
ResetPasswordComponent.propTypes={
    password:PropTypes.string,
    confirmPassword:PropTypes.string,
    formError:PropTypes.shape({
        password:PropTypes.string,
        confirmPassword:PropTypes.string
    }) 
}
export default withRouter(ResetPasswordComponent);