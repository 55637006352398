import React, { Component } from "react";
import {
    Button,
    TextField,
    Dialog,
    DialogContent,
    MenuItem,
    DialogTitle,
    Select,
    FormControl,
    CircularProgress,
} from "@material-ui/core";
import { withRouter } from "react-router-dom";
import IconButton from "@material-ui/core/IconButton";
import { withStyles } from "@material-ui/core/styles";
import {  Alert } from "@material-ui/lab";
import "./AddUserModel.css";
import { AxiosInstance } from "../../../Utilities";
import validator, * as Validator from "validator";
import addUserIcon from "../../../Assets/images/add_user_icon.svg";
import closeBtn from "../../../Assets/images/close_btn.svg";
import { Transition } from "../../../SharedComponents/CommonUtilities";
import momentTimeZone from 'moment-timezone';
const CssTextField = withStyles({
    root: {
        backgroundColor: "#F2F2F2",
        borderRadius: "5px",
        marginBottom: "15px",
        "& .MuiInput-underline:after": {
            borderBottomColor: "#14193A",
        },
        "& .MuiOutlinedInput-root": {
            "& fieldset": {
                borderColor: "#E2E5F2",
                borderWidth: 0,
            },

            "&.Mui-focused fieldset": {
                borderColor: "#14193A",
                borderWidth: 0,
            },
        },
    },
})(TextField);
const CssAutocomplete = withStyles({
    root: {
        backgroundColor: "#F2F2F2",
        borderRadius: "5px",
        marginBottom: "15px",
        "& .MuiInput-underline:after": {
            borderBottomColor: "#14193A",
        },
        "& .MuiOutlinedInput-root": {
            "& fieldset": {
                borderColor: "#E2E5F2",
                borderWidth: 0,
            },

            "&.Mui-focused fieldset": {
                borderColor: "#14193A",
                borderWidth: 0,
            },
        },
    },
})(FormControl);
class AddUserModel extends Component {
    constructor(props) {
        super(props);
        var userData = localStorage.getItem("userdata")
            ? JSON.parse(localStorage.getItem("userdata"))
            : "";
            var zoneName = momentTimeZone.tz.guess();
        var roles = userData.role;
        this.state = {
            timeZoneName: localStorage.getItem("timeZone")?localStorage.getItem("timeZone"):zoneName,
            responseMsg: "",
            alertType: "",
            loggedInRole: userData.role,
            createdBy: userData.username,
            companyName: "a",
            companyId:
                roles.toLowerCase() != "super admin" && userData.companyId


                    ? userData.companyId
                    : "",
            role: "",
            level: "",
            levelId: "",
            levelName: "",
            email: "",
            phone: "",
            userName: "",
            isLoading: false,
            selectedRoleSuperAdmin: false,
            formError: {
                companyId: "",
                levelId: "",
                phone: "",
                email: "",
                userName: "",
                role: "",
                level: "",
                createdBy: "",
                departmentError: "",
            },
            dialog: false,
            mainDialog: this.props.openModel,
            division: [],
            area: [],
            region: [],
            territory: [],

            division_id: "",
            area_id: "",
            region_id: "",
            territory_id: "",
            levelError: "",
            departmentId: "",
            departmentList: [],
            isSubmit: false,
        };
    }
    componentDidMount = () => {
        AxiosInstance( (process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa")
              ? 4003
              : "clinical-user", "application/x-www-form-urlencoded; charset=UTF-8")
            .get("/departmentList")
            .then((resp) => {
                if (resp.data.status === 200) {
                    this.setState({ departmentList: resp.data.data })
                } else {
                }
            });
    }
    onChangeHandler = (e) => {
        var phoneno = /^(\+\d{1,2}\s?)?1?\-?\.?\s?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;
        var userData = localStorage.getItem("userdata")
            ? JSON.parse(localStorage.getItem("userdata"))
            : "";
        var { name, value } = e.target;
        var { level, levelId, levelName, area, region, division, territory } = this.state;
        if (name === "role" && value !== "Scheduler") {
            this.setState({

                departmentId: "",
            });
        }
        this.setState({ [name]: value });
        var { formError } = this.state;

        switch (name) {
            case "companyId":
                validator.isEmpty(value)
                    ? (formError.companyId = "* Select Company")
                    : (formError.companyId = "");
                break;
            case "email":
                validator.isEmpty(value)
                    ? (formError.email = "* Enter Email id")
                    : !validator.isEmail(value)
                        ? (formError.email = "* Please check the email address is in valid format e.g. name@domain.com")
                        : (formError.email = "");
                break;
            case "phone":
                validator.isEmpty(value)
                    ? (formError.phone = "* Please enter phone number")
                    : !phoneno.test(value)
                        ? (formError.phone = "* Please enter the valid phone number e.g.: 800-555-5555 ")
                        : (formError.phone = "");
                break;
            case "userName":
                validator.isEmpty(value)
                    ? (formError.userName = "* Please enter the Name of the person")
                    :!/\S\w*/.test(value)
              ? (formError.userName = "* Please enter the Name of the person")
                    : !/^[a-zA-Z,'. ]*$/.test(value)
                        ? (formError.userName = "* Special characters are not allowed")
                        : !validator.isLength(value, { min: 2, max: 50 })
                            ? (formError.userName = "* Name length should be min 2 and max of 50 characters")
                            : (formError.userName = "");
                break;
            case "role":
                validator.isEmpty(value)
                    ? (formError.role = "* Please select role")
                    : (formError.role = "");
                break;
            case "departmentId":
                validator.isEmpty(value)
                    ? (formError.departmentError = "* Please select a department")
                    : (formError.departmentError = "");
                break;
            default:
                break;
        }
        this.setState({
            formError,
        });
    };
    onSelectHandler = (e) => {
        var name = e.target.value;
        var { companyOption, formError } = this.state;
        var data = companyOption.filter((item) => {
            return item.name === name;
        });
        data.map((companyData) => {
            this.setState({
                companyId: companyData.id,
                companyName: companyData.name,
                [formError.companyId]: "",
            });
        });
    };
    addUserHandler = (e) => {
        e.preventDefault();
        var {
            phone,
            email,
            userName,
            role,
            formError,
            departmentId,
            role
        } = this.state;
        if (role === "") {
            formError.role = "* Please select user role";
        }

        if (email === "") {
            formError.email = "* Please enter email address";
        }

        if (phone === "") {
            formError.phone = "* Please enter phone number";
        }
        if (userName === "") {
            formError.userName = "* Please enter username";
        }
        if (role === "Scheduler") {
            if (departmentId === "") {
                formError.departmentError = "* Please select a department";
            }
        }
        this.setState({ formError }, () => {
            var userData = {
                name: userName,
                role,
                email,
                phone,
                departmentId,
                timezone:this.state.timeZoneName
            };
            const isEmpty = Object.values(formError).every(
                (x) => x === null || x === ""
            );
            if (formError.phone === "" && formError.email === ""
                && formError.userName === "" && formError.role === "") {
                this.setState({ isLoading: true });
                AxiosInstance( (process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa")
              ? 4003
              : "clinical-user", "application/x-www-form-urlencoded; charset=UTF-8")
                    .post("/addUser", userData)
                    .then((res) => {
                        var response = res.data;
                        if (response.status === 403) {
                            this.setState({
                                isSubmit: false,
                                responseMsg: response.msg,
                                alertType: "error",
                                isLoading: false,
                            });
                        } else if (response.status === 201) {
                            this.setState({
                                isSubmit: true,
                                responseMsg: "Employee registered Successfully",
                                alertType: "success",
                                isLoading: false,
                            });
                            setTimeout(() => {
                                window.location.href = "/user";
                            }, 2000);
                        } else if (response.status === 400) {
                            this.setState({
                                isSubmit: false,
                                responseMsg: response.msg,
                                alertType: "error",
                                isLoading: false,
                            });
                        }
                    })
                    .catch((err) => {
                        this.setState({
                            isLoading: false,
                        });
                    });
            }
        });
    };

    clearForm = () => {
        var {
            role,
            formError,
            level,
            levelError,
            levelId,
            responseMsg,
            area,
            region,
            division,
            userName,
            phone,
            email,
            role,
            territory
        } = this.state;
        var error = {
            companyId: "",
            levelId: "",
            phone: "",
            email: "",
            userName: "",
            role: "",
            level: "",
            departmentId: "",
            createdBy: "",
        };
        this.setState(
            {
                role: "",
                userName: "",
                email: "",
                departmentId: "",
                level: "",
                levelError: "",
                phone: "",
                levelId: "",
                responseMsg: "",
                formError: error,
                area: [],
                region: [],
                division: [],
                territory: [],
                isSubmit: false,
                alertType: "",
            }
        );
    };
    render() {
        var { formError } = this.state;
        const inputProps = {
            step: 500,
        };
        var options = [{ key: "1", value: "Select role" }];
        options.push(
            { key: "2", value: "Account Admin" },
            { key: "3", value: "Master Scheduler" },
            { key: "4", value: "Scheduler" },
        );
        return (
            <div>
                <Dialog
                    onExit={this.clearForm}
                    open={this.props.openModel}
                    {...this.props}
                    aria-labelledby="form-dialog-title"
                    maxWidth="md"
                    className="add_company_dialog add_user_modal"
                    TransitionComponent={Transition} 
                    transitionDuration={600}
                >
                    <DialogTitle id="form-dialog-title" className="add_user_header">
                        <img className="add-company_img" src={addUserIcon}></img>
                        <span>Add User</span>
                        <IconButton
                            onClick={this.props.onClose}
                            className="closeBtn"
                            aria-label="show 17 new notifications"
                            color="inherit"
                        >
                            <img src={closeBtn}></img>
                        </IconButton>
                    </DialogTitle>
                    <DialogContent>
                        {this.state.responseMsg != "" ? (
                        <Alert severity={this.state.alertType}>
                            {this.state.responseMsg}
                        </Alert>
                         ) : null} 
                        <form onSubmit={this.onAddUserSubmit} className="adduserform">
                            <div style={{ display: this.state.isLoading ? "block" : "none" }}>
                                <CircularProgress
                                    variant={this.state.isLoading ? "indeterminate" : "static"}
                                />
                            </div>
                            <div className="form_input_field">
                            <label className="label">Name<span className="labelAsterisk"> *</span></label>
                            <CssTextField
                                id="outlined-basic"
                                name="userName"
                                variant="outlined"

                                type="text"
                                fullWidth
                                placeholder="Enter name"
                                onChange={this.onChangeHandler}
                            />
                            <span className="error"> {formError.userName}</span>
                            </div>
                            <div className="form_input_field">
                            <label className="label">Role<span className="labelAsterisk"> *</span> </label>
                            <CssAutocomplete variant="outlined" fullWidth>
                                <Select
                                    className="customSelect"
                                    name="role"
                                    fullWidth
                                    onChange={this.onChangeHandler}
                                >
                                    {options.map((mitem) => (
                                        <MenuItem
                                            key={mitem.key}
                                            value={
                                                mitem.value.toLowerCase() === "select role"
                                                    ? ""
                                                    : mitem.value
                                            }
                                        >
                                            {mitem.value}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </CssAutocomplete>
                            <span className="error"> {formError.role}</span>
                            </div>
                            {this.state.role === "Scheduler" ?
                                <div className="form_input_field">
                                    <label className="label">Select department</label>
                                    <CssAutocomplete variant="outlined" fullWidth>
                                        <Select
                                            className="customSelect"
                                            name="departmentId"
                                            fullWidth
                                            onChange={this.onChangeHandler}
                                        >
                                            {this.state.departmentList.map((mitem) => (
                                                <MenuItem key={mitem._id} value={mitem._id}>
                                                    {mitem.name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </CssAutocomplete>
                                    <span className="error"> {formError.departmentError}</span>

                                </div>
                                : ''}
                                <div className="form_input_field">
                            <label className="label">Email<span className="labelAsterisk"> *</span></label>
                            <CssTextField
                                id="outlined-basic"
                                name="email"
                                variant="outlined"
                                type="text"
                                fullWidth
                                placeholder="Enter email"
                                onChange={this.onChangeHandler}
                            />
                            <span className="error"> {formError.email}</span>
                            </div>
                            <div className="form_input_field">
                            <label className="label">Phone<span className="labelAsterisk"> *</span></label>
                            <CssTextField
                                id="outlined-basic"
                                name="phone"
                                variant="outlined"

                                type="text"
                                fullWidth
                                placeholder="Please enter phone number"
                                onChange={this.onChangeHandler}
                            />
                            <span className="error"> {formError.phone}</span>
                                </div>
                            <div className="add_company_action">
                                <Button
                                    className="cancel_btn"
                                    onClick={this.props.onClose}
                                    variant="outlined"
                                >
                                    Cancel
                                </Button>
                                <Button disabled={this.state.isSubmit}
                                    className="ok_btn"
                                    onClick={this.addUserHandler}
                                    variant="contained"
                                >
                                    {this.state.isSubmit ? <CircularProgress size={20} /> : ''}     Add User
                                </Button>
                            </div>
                        </form>
                    </DialogContent>
                </Dialog>
            </div>
        );
    }
}
export default withRouter(AddUserModel);
