import React, { Component } from "react";
import {
  Button,
  TextField,
  Dialog,
  DialogContent,
  MenuItem,
  DialogTitle,
  Select,
  FormControl,
} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import InputAdornment from "@material-ui/core/InputAdornment";
import addCompanyIcon from "../../Assets/images/add_company_icon.svg";
import closeBtn from "../../Assets/images/close_btn.svg";
import carret_down from "../../Assets/images/carret_down.svg";
import { Alert } from "@material-ui/lab";
import AutoComplete from "react-google-autocomplete";
import { GoogleMapsAPI } from "../../client-config";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Scrollbars } from "react-custom-scrollbars";
import { Transition } from "../../SharedComponents/CommonUtilities/CommonUtilities";
import { DatePicker } from "antd";
import moment from "moment";

const { RangePicker } = DatePicker;
const CssTextField = withStyles({
  root: {
    "& .MuiInput-underline:after": {
      borderBottomColor: "#14193A",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#E2E5F2",
        borderWidth: 1,
      },
      "&.Mui-focused fieldset": {
        borderColor: "#14193A",
        borderWidth: 1,
      },
    },
  },
})(TextField, Select);
export class AddCompanyModal extends Component {
  constructor(props) {
    super(props);
    this.dialogContent = React.createRef()
    this.state = {
      companyName: "",
      companyCode: "",
      companyLogo: null,
      file: null,
      companyPhone: "",
      companyEmail: "",
      companyAddress: "",
      companyWebsite: "",
      companySize: "",
      companyDescription: "",
      responseMsg: "",
      alertType: "",
      formError: {
        companyName: "",
        companyCode: "",
        companyLogo: "",
        companyPhone: "",
        companyEmail: "",
        companyAddress: "",
        companyWebsite: "",
        companySize: "",
        companyDescription: "",
      },
      isValid: !this.props.isValid,
      open: false,
      vertical: "top",
      horizontal: "center",
      errorMsg: "",
    };
  }
  componentDidMount() {
    this.setState({ isValid: false });
  }
  onclickBtn = () => {
    // this.dialogContent.scrollIntoView({
    //   behavior: 'smooth',
    //   block: 'start'
    // },
    //   () => 
    //     this.props.onclickBtn()
    // );
    this.props.onclickBtn()
  }

  render() {
    const inputProps = {
      step: 500,
    };

    return (
      <div className="add_company_modal">
        <Dialog
          {...this.props}
          aria-labelledby="form-dialog-title"
          maxWidth="md"
          className="add_company_dialog"
          TransitionComponent={Transition}
          transitionDuration={600}
          onExit={this.props.onDialogClose}
        >
          <DialogTitle id="form-dialog-title" className="add_user_header">
            <img
              className="add-company_img"
              src={addCompanyIcon}
              alt="addCompany"
            />
            <span>Add Company</span>
            <IconButton
              onClick={this.props.onClose}
              className="closeBtn"
              aria-label="show 17 new notifications"
              color="inherit"
            >
              <img src={closeBtn} alt="closeicon" />
            </IconButton>
          </DialogTitle>
          <Scrollbars
            className="custom_scrollbar"
            style={{ width: "auto", height: 680 }}
          >
            <DialogContent ref={node => {
              this.dialogContent = node;
            }}>
              <form onSubmit={this.props.Addcompanyfn}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={8}>
                    <label className="label bl-label">
                      Name<span className="labelAsterisk"> *</span>
                    </label>
                    <CssTextField
                      id="outlined-basic"
                      defaultValue={this.props.modeldata.companyName}
                      margin="dense"
                      name="companyName"
                      variant="outlined"
                      autoFocus
                      type="text"
                      fullWidth
                      placeholder="Enter Company name"
                      onChange={this.props.handleChange}
                      error={
                        this.props.modeldata.companyName === "" &&
                          this.props.modeldata.isValid
                          ? true
                          : false
                      }
                      onBlur={this.props.checkCompanyId}
                    />
                    <span className="error">
                      {/* {this.props.modeldata.companyName === "" &&
                      this.props.modeldata.isValid
                        ? "* Please enter company name"
                        : ""} */}
                      {this.props.modeldata.formError.companyName
                        ? this.props.modeldata.formError.companyName
                        : ""}
                    </span>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <label className="label bl-label">
                      Company Code<span className="labelAsterisk"> *</span>{" "}
                    </label>
                    <CssTextField
                      id="outlined-basic"
                      variant="outlined"
                      margin="dense"
                      type="text"
                      name="companyCode"
                      fullWidth
                      placeholder="Enter Company Code"
                      value={this.props.modeldata.companyCode}
                      onChange={this.props.handleChange}
                      error={
                        this.props.modeldata.companyCode === "" &&
                          this.props.modeldata.isValid
                          ? true
                          : false
                      }
                      onBlur={this.props.checkCompanyId}
                    />
                    <span className="error">
                      {/* {this.props.modeldata.companyCode === "" &&
                      this.props.modeldata.isValid
                        ? "* Please enter the company code"
                        : ""} */}
                      {this.props.modeldata.formError.companyCode
                        ? this.props.modeldata.formError.companyCode
                        : ""}
                    </span>
                  </Grid>
                </Grid>
                <label className="label bl-label">Add Logo</label>
                <div className="custom_upload">
                  <div>
                    <input
                      style={{ cursor: "pointer" }}
                      className="input_one"
                      type="file"
                      name="companyLogo"
                      onChange={this.props.onFileuploadHandler}
                    />
                    <div className="input_two">
                      Upload logo file in jpg, png, svg format
                    </div>{" "}
                  </div>
                  <Button
                    disabled={this.props.modeldata.isuplode}
                    className="upload_btn primary-btn"
                    variant="contained"
                    onClick={this.props.isuplode}
                  >
                    Upload
                  </Button>
                </div>
                <span className="error">
                  {this.props.modeldata.formError.companyLogo
                    ? this.props.modeldata.formError.companyLogo
                    : ""}
                </span>
                {this.props.modeldata.file !== null ? (
                  <div>
                    <img
                      src={this.props.modeldata.file}
                      width="100px"
                      height="auto"
                      alt="modal file"
                    />
                  </div>
                ) : (
                    " "
                  )}
                <label className="label bl-label">
                  Phone
                  {/* <span className="labelAsterisk"> *</span> */}
                </label>
                <CssTextField
                  id="outlined-basic"
                  defaultValue={this.props.modeldata.companyPhone}
                  name="companyPhone"
                  variant="outlined"
                  margin="dense"
                  type="text"
                  fullWidth
                  placeholder="Enter Phone Number"
                  onChange={this.props.handleChange}
                  error={
                    this.props.modeldata.companyPhone === "" &&
                      this.props.modeldata.isValid
                      ? true
                      : false
                  }
                />
                <span className="error">
                  {/* {this.props.modeldata.companyPhone === "" &&
                  this.props.modeldata.isValid
                    ? "* Please enter the company phone"
                    : ""} */}
                  {this.props.modeldata.formError.companyPhone
                    ? this.props.modeldata.formError.companyPhone
                    : ""}
                </span>
                <label className="label bl-label">
                  Email<span className="labelAsterisk"> *</span>
                </label>
                <CssTextField
                  defaultValue={this.props.modeldata.companyEmail}
                  id="outlined-basic"
                  name="companyEmail"
                  variant="outlined"
                  margin="dense"
                  type="email"
                  fullWidth
                  placeholder="Enter Email"
                  onChange={this.props.handleChange}
                  error={
                    this.props.modeldata.companyEmail === "" &&
                      this.props.modeldata.isValid
                      ? true
                      : false
                  }
                />
                <span className="error">
                  {/* {this.props.modeldata.companyEmail === "" &&
                  this.props.modeldata.isValid
                    ? "* Please enter the company email"
                    : ""} */}
                  {this.props.modeldata.formError.companyEmail
                    ? this.props.modeldata.formError.companyEmail
                    : ""}
                </span>
                <label className="label bl-label">
                  Address
                </label>
                <AutoComplete
                  style={{
                    width: "100%",
                    height: "38px",
                    paddingLeft: "16px",
                    marginTop: "2px",
                    marginBottom: "4px",
                  }}
                  name="address"
                  margin="dense"
                  apiKey={GoogleMapsAPI}
                  types={[]}
                  onPlaceSelected={this.props.onGooglePlaceSelect}
                  onChange={this.props.handleChange}
                />
                <span className="error">
                  {" "}
                  {this.props.modeldata.formError.companyAddress ? (
                    <span className="error">
                      {this.props.modeldata.formError.companyAddress}
                    </span>
                  ) : (
                      ""
                    )}
                </span>
                <label className="label bl-label">
                  Website
                </label>
                <CssTextField
                  id="outlined-basic"
                  name="companyWebsite"
                  variant="outlined"
                  margin="dense"
                  type="text"
                  fullWidth
                  placeholder="Enter Website"
                  onChange={this.props.handleChange}
                  error={
                    this.props.modeldata.companyWebsite === "" &&
                      this.props.modeldata.isValid
                      ? true
                      : false
                  }
                />
                <span className="error">
                  {/* {this.props.modeldata.companyWebsite === "" &&
                  this.props.modeldata.isValid
                    ? "* Please enter the company website"
                    : ""} */}
                  {this.props.modeldata.formError.companyWebsite
                    ? this.props.modeldata.formError.companyWebsite
                    : ""}
                </span>
                <label className="label bl-label">
                  Size<span className="labelAsterisk"> *</span>
                </label>
                <FormControl variant="outlined" fullWidth margin="dense">
                  <Select
                    margin="dense"
                    className="customSelect"
                    placeholder="Select size"
                    labelId="demo-simple-select-outlined-label"
                    name="companySize"
                    id="demo-simple-select-outlined"
                    fullWidth
                    onChange={this.props.handleChange}
                    error={
                      this.props.modeldata.companySize === "" &&
                        this.props.modeldata.isValid
                        ? true
                        : false
                    }
                  >
                    <MenuItem value="">
                      <em>Select your company size</em>
                    </MenuItem>
                    <MenuItem value="small">Small</MenuItem>
                    <MenuItem value="medium">Medium</MenuItem>
                    <MenuItem value="large">Large</MenuItem>
                  </Select>
                </FormControl>
                <span className="error">
                  {this.props.modeldata.formError.companySize
                    ? this.props.modeldata.formError.companySize
                    : ""}
                </span>

                <label className="label bl-label">
                  Billing type<span className="labelAsterisk"> *</span>
                </label>
                <FormControl variant="outlined" fullWidth margin="dense">
                  <Select
                    margin="dense"
                    className="customSelect"
                    placeholder="Select billing type"
                    name="billingConfigType"
                    fullWidth
                    // value={this.props.modeldata.billingConfigType}
                    onChange={this.props.handleChange}
                    defaultValue="1"
                  >
                    <MenuItem value="1">
                      Organizational
                    </MenuItem>
                    <MenuItem value="2">Team</MenuItem>
                    <MenuItem value="3">Individual</MenuItem>
                  </Select>
                </FormControl>
                <span className="error">
                  {this.props.modeldata.formError.billingConfigType === ""
                    ? ""
                    : `* ${this.props.modeldata.formError.billingConfigType}`}
                </span>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={7} className="trail_field_left">
                    <label className="label bl-label">
                      Trial Ends on<span className="labelAsterisk"> *</span>
                    </label>
                    <div className="trail_field">
                      <RangePicker
                        className="filter_date_range "
                        name="datepicker"
                        format="MM/DD/YYYY"
                        allowClear={false}
                        inputReadOnly={true}
                        separator="to"
                        value={[
                          moment(this.props.billingTrialEnds[0], "MM/DD/YYYY"),
                          moment(this.props.billingTrialEnds[1], "MM/DD/YYYY"),
                        ]}
                        disabledDate={this.props.valid}
                        placeholder={["MM/DD/YYYY", "MM/DD/YYYY"]}
                        suffixIcon={
                          <img
                            className="carret"
                            alt="carret"
                            src={carret_down}
                          />
                        }
                        getPopupContainer={(node) => node.parentNode}
                        onChange={this.props.onDateRangeChanage}
                      />
                      <span className="remaining_days">{this.props.billingTrialDays} days</span>
                    </div>
                    <span className="error"></span>
                  </Grid>
                  <Grid item xs={12} md={5} className="trail_field_right">
                    <label className="label bl-label">
                      Pricing/User<span className="labelAsterisk"> *</span>
                    </label>
                    <CssTextField
                      variant="outlined"
                      margin="dense"
                      fullWidth
                      className="pricing_field"
                      placeholder=""
                      name="billingPrice"
                      onChange={this.props.handleChange}
                      value={this.props.modeldata.billingPrice ? this.props.modeldata.billingPrice : ""}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start" className="dollar_symbol">$</InputAdornment>
                        ),
                      }}
                    />
                    <span className="error">
                      {this.props.modeldata.formError.billingPrice === ""
                        ? ""
                        : `* ${this.props.modeldata.formError.billingPrice}`}
                    </span>
                  </Grid>
                </Grid>
                <label className="label bl-label">Description</label>
                <CssTextField
                  id="outlined-basic"
                  name="companyDescription"
                  variant="outlined"
                  margin="dense"
                  multiline
                  rowsMax={3}
                  className="text_area"
                  type="text"
                  fullWidth
                  inputProps={inputProps}
                  placeholder="Enter Description"
                  onChange={this.props.handleChange}
                />
                {this.props.modeldata.responseMsg !== "" ? (
                  <Alert severity={this.props.modeldata.alertType}>
                    {this.props.modeldata.responseMsg}
                  </Alert>
                ) : null}
                <div className="add_company_action">
                  <Button
                    className="cancel_btn white-btn"
                    onClick={this.props.onClose}
                    variant="outlined"
                  >
                    Cancel
                  </Button>
                  <Button
                    className="ok_btn green-btn"
                    type="submit"
                    variant="contained"
                    onClick={this.onclickBtn}
                    disabled={this.props.modeldata.isSubmit}
                  >
                    {this.props.modeldata.isSubmit ? (
                      <CircularProgress size={20} />
                    ) : (
                        ""
                      )}
                    Add Company
                  </Button>
                </div>
              </form>
            </DialogContent>
          </Scrollbars>
        </Dialog>
      </div>
    );
  }
}
