import React from 'react';
import Grid from "@material-ui/core/Grid";
import Loader from "react-loader-spinner";
import {
    Button,
    Dialog,
    CircularProgress,
    TextField, 
    Select,
    FormControl,
    MenuItem
  } from "@material-ui/core";
import { withStyles, IconButton } from "@material-ui/core";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import { Alert } from "@material-ui/lab";
import { TransitionGrow } from '../../../SharedComponents/CommonUtilities/CommonUtilities';
import closeBtn from "../../../Assets/images/close_btn.svg";
import editIcon from "../../../Assets/images/view_edit_icon.svg";
import Delete from "../../../Assets/images/delete.svg";
import './ServiceComponent.css';

const CssTextField = withStyles({
    root: {
      "& .MuiInput-underline:after": {
        borderBottomColor: "#14193A",
      },
      "& .MuiOutlinedInput-root": {
        "& fieldset": {
          borderColor: "",
          borderWidth: 0,
        },
        "&.Mui-focused fieldset": {
          borderColor: "",
          borderWidth: 0,
        },
      },
    },
  })(TextField, Select);

const ServiceComponent = (props) => {
    return (
        <div>
            <Dialog open={props.data?.showdelete} className="deleteServicesDialog" TransitionComponent={TransitionGrow} transitionDuration={600}>
          {props.data?.responseMsg !== "" ? (
            <Alert severity={props.data?.alertType}>
              {props.data?.responseMsg}
            </Alert>
          ) : null}
          <DialogTitle>
            {" "}
            <center>Delete Service</center>{" "}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <center>
                {" "}
                Are you sure you want to delete this Service? This action cannot be undone.
              </center>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              className="cancel"
              onClick={props.handleClose}
              color="primary"
            >
              Cancel
            </Button>
            <Button
              disabled={props.data?.disable}
              className="delete"
              onClick={() =>
                props.deleteService(props.data?.deleteId)
              }
              autoFocus
            >
              {props.data?.disable ? <CircularProgress size={20} /> : ""} Delete
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={props.data?.showAdd || props.data?.showEdit}
          onClose={props.handleMenuClose}
          id="primary-search-account-menu"
          className="add_company_dialog"
          maxWidth='md' 
        >
          <DialogTitle className="add_user_header add_service_header">
            <span>{props.data?.showAdd ? 'Add Service' : 'Edit Service'}</span>
            <IconButton
              onClick={props.handleMenuClose}
              className="closeBtn"
              color="inherit"
            >
              <img src={closeBtn} alt=""></img>
            </IconButton>
          </DialogTitle>
          <DialogContent className="add_service_content">
          <div className="label bl-label">Service Type</div>
          <FormControl variant="outlined" fullWidth className="ms-b-10" margin="dense">
          <Select
            className="customSelect select-service"
            name="serviceType"
            fullWidth
            onChange={props.selectServiceType}
            margin="dense"
            defaultValue={props.data?.serviceType}
           >
            {props.data?.serviceTypeList && props.data?.serviceTypeList.length>0 ?
             props.data?.serviceTypeList.map((serviceType) => ( 
             <MenuItem key={serviceType._id} value={serviceType._id}>
                {serviceType.serviceType}
             </MenuItem>)) : <MenuItem value="">No Data</MenuItem> }
            </Select>
            </FormControl>                                   
            <div className="label bl-label">Service Name</div>
            <CssTextField
              name="serviceName"
              value={props.data?.serviceName}
              className="txt_field"
              placeholder="Enter Service name"
              onChange={props.handleChange}
              fullWidth 
              margin="dense"
              variant="outlined"
            />
            <div>
              {props.data?.serviceFormError?.serviceName !== "" ? (
                <span className="modal-err-msg">
                  {props.data?.serviceFormError?.serviceName}
                </span>
              ) : (
                ""
              )}
            </div>
            <div className="add_company_action">
            <Button
              className="cancel_btn white-btn"
              onClick={props.handleMenuClose}
              variant="outlined"
            >
              Cancel
            </Button>
            <Button
              disabled={props.data?.serviceName === "" ? true : false}
              className={`ok_btn green-btn ${
                props.data?.serviceName === "" ? "disabled-btn" : ""
              }`}
              variant="contained"
              onClick={()=> props.data?.showAdd ? props.addService(): props.editService()}
            >
             {props.data?.showAdd ? "Add" : "Update" }
            </Button>
          </div>
          </DialogContent>
        </Dialog>
 
            <div className="header-dept">
                    <div className="dept_title">Services</div>
                    <div
                      variant="contained"
                      className="add_dept_name"
                      style={{ boxShadow: "none", cursor: "pointer" }}
                      onClick={props.setMenu}
                    >
                      +Add Service
                  </div>
                    
                  </div>
                  <Grid container>
                    {props.data?.loading ? (
                      <div style={{ textAlign: "center", width: "100%" }}>
                        <Loader
                          type="ThreeDots"
                          color="#00BFFF"
                          height={50}
                          width={50}
                          visible={props.data?.loading}
                        />
                      </div>
                    ) : props.data?.services?.length !== 0 ? (
                      props.data?.services.map((row) => (
                        <>
                          <Grid item xs={12} sm={4}>
                            <div
                              className="dept_name_grid"
                              onMouseEnter={() => props.hideButton(row._id)}
                            >
                              <div className="dept_name_text">
                                  <span href={"service:" + row.service} rel="noopener noreferrer">
                                    {row.service}
                                  </span>
                                </div>
                              <div className="dept_name_edit_del"></div>
                              {props.data?.hideId === row._id ? (
                                <div className="dept-options">
                                  <img
                                    className="dept_edit_icon"
                                    src={editIcon}
                                    alt=""
                                    style={{ cursor: "pointer" }}
                                    onClick={(e) =>
                                      props.updateMenu(e ,row._id, row)
                                    }
                                  ></img>
                                  <img
                                    className="dept_del_icon"
                                    style={{ cursor: "pointer" }}
                                    src={Delete}
                                    alt=""
                                    onClick={() =>
                                      props.onDeleteHandleClick(row._id)
                                    }
                                  ></img>
                                </div>
                              ) : (
                                  ""
                                )}
                            </div>
                           
                          </Grid>
                        </>
                      ))
                    ) : (
                          <div style={{ textAlign: "center", width: "100%" }}>
                            No data found
                          </div>
                        )}
                  </Grid>
        </div>
    );
}

export default ServiceComponent;
