import React, { Component, Suspense } from "react";
import { withStyles } from '@material-ui/core/styles';
import HeaderComponent from "../../SharedComponents/HeaderComponent/HeaderComponent";
import { NotificationComponent } from "../../SharedComponents/NotificationComponent/NotificationComponent";
import { Dialog, CircularProgress } from '@material-ui/core';
import iconNotificationSuccess from "../../Assets/images/icon-notify-success.svg";
import iconNotificationError from "../../Assets/images/icon-notify-error.svg";
import Grid from '@material-ui/core/Grid';
import './DepartmentsComponent.css';
import editIcon from "../../Assets/images/view_edit_icon.svg";
import Delete from "../../Assets/images/delete.svg";
import { AxiosInstance } from "../../Utilities";
import Loader from "react-loader-spinner";
import validator from "validator";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import { Alert } from "@material-ui/lab";
import {
    Typography,
    Button,
    Select,
    TextField,
    InputAdornment,
    Tooltip
} from "@material-ui/core";
import AddDepartmentModel from "./AddDepartmentModel/AddDepartmentModel";
import EditDepartmentModel from "./EditDepartmentModel/EditDepartmentModel";
import searchIcon from "../../Assets/images/search_icon.svg";
import { TransitionGrow } from "../../SharedComponents/CommonUtilities";
import { isSafari } from "react-device-detect";
const CssTextField = withStyles({
    root: {
        '& .MuiInput-underline:after': {
            borderBottomColor: '#14193A',
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: '',
                borderWidth: 0
            },
            '&.Mui-focused fieldset': {
                borderColor: '',
                borderWidth: 0
            },
        },
    },
})(TextField, Select);
const LightTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: "black",
        color: "white",
        boxShadow: theme.shadows[1],
        fontSize: 13,
    },
}))(Tooltip);
function serchingFor(searchText) {
    return function (x) {
        return x.name.toLowerCase().includes(searchText.toLowerCase()) || !searchText;
    }
}
class DepartmentsComponent extends Component {
    constructor(props) {
        super(props);
        var userData = localStorage.getItem("userdata")
            ? JSON.parse(localStorage.getItem("userdata"))
            : "";
        this.state = {
            userRole: userData.role.toLowerCase(),
            departments: [],
            deptName: '',
            editId: '',
            editName: '',
            deptSerch: "",
            hideId: '',
            loading: true,
            searchText: "",
            openModel: false,
            showEditModal: false,
            isSubmit: false,
            responseMsg: "",
            alertType: "",
            responseMsg: "",
            formError: {
                editName: '',
                deptName: '',
            },
            tooltipprops:{}
        };
    }
    UNSAFE_componentWillMount(){
        let obj = {}
        if(isSafari){
          obj.open = false;
          this.setState({tooltipprops:obj})
        }
      }
    componentDidMount = () => {
        this.setState({ loading: true }, () => {
            AxiosInstance((process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa")
                ? 4005
                : "clinical-department", "application/x-www-form-urlencoded; charset=UTF-8")
                .post("/get_department")
                .then((resp) => {
                    if (resp.status === 200) {
                        let response = resp.data.data;
                        this.setState({
                            departments: response,
                        })
                    } else {

                    }
                });
        });
        setTimeout(() => {
            this.setState({ loading: false });
        }, 2500);
    }
    handleChange = (event) => {
        let name = event.target.name;
        let value = event.target.value;
        const {formError}=this.state;
        if (!validator.isLength(value, { max: 100 })) {
           formError.editName = "*Department Name should not exceed 100 characters.";
        }else if (!validator.trim(value)) {
            formError.editName = "*Please enter a valid Department Name.";
        } else {
           formError.editName = "";
        }
        this.setState({
            editName: value,
            formError
        });
    };
    handleAddChange = (event) => {
        let name = event.target.name;
        let value = event.target.value;
        if (!validator.isLength(value, { max: 100 })) {
            this.state.formError.deptName = "*Department Name should not exceed 100 characters.";
        }
        else if (!validator.trim(value)) {
            this.state.formError.deptName = "*Please enter a valid Department Name.";
        }
        else {
            this.state.formError.deptName = "";
        }
        this.setState({
            deptName: value,
        });
    };
    setMenu = (e) => {
        this.setState({
            deptName: '',
            formError: {
                deptName: ""
            },
            openModel: true
        })
    }
    closeMenu = (e, id, name) => {
        this.setState({
            editId: id,
            editName: name,
            formError: {
                editName: ""
            },
            showEditModal: true
        })
    }
    hideButton = (id) => {
        this.setState({
            hideId: id
        })
    }
    onCloseModal = () => {
        this.setState({
            openModel: false,
            showEditModal: false,
            responseMsg: ""
        });
    };
    addDept = () => {
        const { formError } = this.state;
        let data = {
            name: this.state.deptName,
        };
        if (formError.deptName === "") {
            AxiosInstance((process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa")
                ? 4005
                : "clinical-department", "application/x-www-form-urlencoded; charset=UTF-8")
                .post("/add_department", data)
                .then((resp) => {
                    let response = resp.data.data;
                    if (response && (resp.data.status === "200" || resp.data.status === 200)) {
                        this.setState({
                            openModel: false,
                            responseMsg: ""
                        })
                        var props = {
                            message: "Department added successfully.",
                            icon: <img src={iconNotificationSuccess} alt="success" />,
                            type: "success",
                            placement: "bottomRight",
                            duration: 4,
                            top: 200,
                            className: "task_notification success-notify level-error",
                        };
                        NotificationComponent(props);
                        this.componentDidMount()
                    } else {
                        this.setState({
                            responseMsg: resp.data.msg,
                            alertType: "error",
                            isSubmit: false,
                        });
                        NotificationComponent(props);
                    }
                })
                .catch((err) => {
                    var error = err.response;
                    if (error && (error.status === "403" || error.status === 403)) {
                        localStorage.clear();
                        setTimeout(() => {
                            window.location.href = "/";
                        }, 500);
                    } else {
                        console.log("Internal Server Error");
                    }
                });
        }
    }
    editDept = (e, name, id) => {
        const { formError } = this.state;
        let data = {
            id: id,
            name: this.state.editName,
        }
        if (formError.editName === "") {
            AxiosInstance((process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa")
                ? 4005
                : "clinical-department", "application/x-www-form-urlencoded; charset=UTF-8")
                .post("/update_department", data)
                .then((resp) => {
                    let response = resp.data.data;
                    if (response && (resp.data.status === "200" || resp.data.status === 200)) {
                        this.setState({
                            showEditModal: false
                        })
                        var props = {
                            message: "Department updated successfully.",
                            icon: <img src={iconNotificationSuccess} alt="success" />,
                            type: "success",
                            placement: "bottomRight",
                            duration: 4,
                            top: 200,
                            className: "task_notification success-notify level-error",
                        };
                        NotificationComponent(props);
                        this.componentDidMount()
                    } else {
                        this.setState({
                            responseMsg: resp.data.msg,
                            alertType: "error",
                            isSubmit: false,
                        });
                        NotificationComponent(props);
                    }
                })
                .catch((err) => {
                    var error = err.response;
                    if (error && (error.status === "403" || error.status === 403)) {
                        localStorage.clear();
                        setTimeout(() => {
                            window.location.href = "/";
                        }, 500);
                    } else {
                        console.log("Internal Server Error");
                    }
                });
        }
    }
    handleClose = () => {
        this.setState({
            responseMsg: "",
            showdelete: false,
            disable: false,
        });
    };
    onDeleteHandleClick = (id) => {
        this.setState({ showdelete: true, deleteId: id });
    };
    delDept = (e, id) => {
        let data = {
            id: id
        }
        AxiosInstance((process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa")
            ? 4005
            : "clinical-department", "application/x-www-form-urlencoded; charset=UTF-8")
            .post("/delete_department", data)
            .then((resp) => {
                let response = resp.data.data;
                if (response && (resp.data.status === "200" || resp.data.status === 200)) {
                    this.setState({
                        showdelete: false,
                        disable: false
                    })
                    var props = {
                        message: "Department deleted successfully.",
                        icon: <img src={iconNotificationSuccess} alt="success" />,
                        type: "success",
                        placement: "bottomRight",
                        duration: 4,
                        top: 200,
                        className: "task_notification success-notify level-error",
                    };
                    NotificationComponent(props);
                    this.componentDidMount()
                } else {
                    this.setState({
                        responseMsg: resp.data.msg,
                        alertType: "error",
                        isSubmit: false,
                    });
                }
            })
            .catch((err) => {
                var error = err.response;
                if (error && (error.status === "403" || error.status === 403)) {
                    localStorage.clear();
                    setTimeout(() => {
                        window.location.href = "/";
                    }, 500);
                } else {
                    console.log("Internal Server Error");
                }
            });
    };
    onKeyDown = (event, e, id, name) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            event.stopPropagation();
            this.editDept(e, name, id);
        }
    }
    deptSearch = (e) => {
        let name = e.target.value
        this.setState({ searchText: name },
        )
    }
    render() {
        const { userRole } = this.state;
        let handleClose = () => this.setState({ disable: false, showdelete: false });
        return (
            <>
                <Suspense fallback={<div></div>}>
                    <AddDepartmentModel
                        openModel={this.state.openModel}
                        onCloseModal={this.onCloseModal}
                        modaldata={this.state}
                        handleAddChange={this.handleAddChange}
                        addDept={this.addDept}
                    />
                </Suspense>
                <Suspense fallback={<div></div>}>
                    <EditDepartmentModel
                        showEditModal={this.state.showEditModal}
                        onCloseModal={this.onCloseModal}
                        modaldata={this.state}
                        handleChange={this.handleChange}
                        editDept={this.editDept}
                        onKeyDown={this.onKeyDown}
                    />
                </Suspense>
                <Dialog open={this.state.showdelete} className="deleteServiceDialog" TransitionComponent={TransitionGrow} transitionDuration={600}>
                    {this.state.responseMsg !== "" ? (
                        <Alert severity={this.state.alertType}>
                            {this.state.responseMsg}
                        </Alert>
                    ) : null}
                    <DialogTitle>
                        {" "}
                        <center>Delete Department</center>{" "}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            <center>
                                {" "}
                Are you sure you want to delete this Department? Your action cannot be undone
              </center>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            className="cancel"
                            onClick={this.handleClose}
                            color="primary"
                        >
                            Cancel
            </Button>
                        <Button disabled={this.state.disable}
                            className="delete red-btn"
                            onClick={(e) => this.delDept(e, this.state.deleteId)}
                            style={{ color: "red" }}
                            autoFocus
                        >
                            {this.state.disable ? <CircularProgress size={20} /> : ''}   Delete
            </Button>
                    </DialogActions>
                </Dialog>
                <div className="body-bg-color">
                    <HeaderComponent />
                    <div className="dept_flex">
                        <div className="header-add-btn">
                            <Typography variant="h5" className="tableTitle" component="div">Departments</Typography>
                            <CssTextField id="outlined-basic" name='deptSearch' variant="outlined" type="text" placeholder="Search Here" className="searchbar" style={{ color: '#929292', fontFamily: 'Roboto', backgroundColor: '#F3F3F3', opacity: 1 }} InputProps={{ startAdornment: <InputAdornment position="start" style={{ cursor: 'pointer' }}><img src={searchIcon} /></InputAdornment> }} onChange={this.deptSearch} />
                        </div>
                        <div className="dept-layout">
                            <div className="header-dept ">
                                <div className="dept_title">Departments list</div>
                                {userRole === 'account admin' ?
                                    <div variant="contained" className="add_dept_name" style={{ boxShadow: 'none', cursor: 'pointer' }} onClick={this.setMenu}
                                    >+Add Department</div> : ''}
                            </div>
                            <Grid container>
                                {this.state.loading ? <div style={{ textAlign: "center", width: '100%' }}><Loader
                                    type="ThreeDots"
                                    color="#00BFFF"
                                    height={50}
                                    width={50}
                                    visible={this.state.loading}
                                />
                                </div> :
                                    this.state.departments.filter(serchingFor(this.state.searchText)).length !== 0 ? this.state.departments.filter(serchingFor(this.state.searchText)).map((row) => (
                                        <Grid item xs={12} md={4}>
                                            <div className="dept_name_grid" onMouseEnter={() => this.hideButton(row._id)}>
                                                <div className="dept_name_text" >
                                                    <LightTooltip
                                                        title={row.name}
                                                        placement="bottom-start"
                                                        {...this.state.tooltipprops}
                                                        arrow
                                                    >
                                                        <span href={"department:" + row.name} rel="noopener noreferrer">
                                                            {row.name}
                                                        </span>
                                                    </LightTooltip></div>
                                                <div className="dept_name_edit_del">
                                                    {userRole === 'account admin' && this.state.hideId === row._id ?
                                                        <div>
                                                            <LightTooltip title="Edit" {...this.state.tooltipprops} arrow>
                                                                <img className="dept_edit_icon" src={editIcon} style={{ cursor: "pointer" }} onClick={(e) => this.closeMenu(e, row._id, row.name)} ></img>
                                                            </LightTooltip>
                                                            <LightTooltip title="Delete" {...this.state.tooltipprops} arrow>
                                                                <img className="dept_del_icon" src={Delete} alt="add" onClick={() => this.onDeleteHandleClick(row._id)} style={{ color: "red", cursor: "pointer" }} autoFocus></img>
                                                            </LightTooltip>



                                                        </div>
                                                        : ''}
                                                </div>
                                            </div>
                                        </Grid>
                                    )) : <div style={{ textAlign: 'center', width: '100%' }}>No data found</div>}
                            </Grid>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}
export default DepartmentsComponent;