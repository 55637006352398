import React, { Component, Suspense } from "react";
import { Container, Card, TextField, Button } from "@material-ui/core";
import email from "../../Assets/images/Icon simple-email.svg";
import phone from "../../Assets/images/Icon awesome-phone-alt.svg";
// import division from "../../Assets/images/Icon material-device-hub1.svg";
import editprofile from "../../Assets/images/profile-pic-edit.svg";
import editIcon from "../../Assets/images/view_edit_icon.svg";
import DefaultProfile from "../../Assets/images/dummy-profile-image.png";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { AxiosInstance } from "../../Utilities";
import { Alert } from "@material-ui/lab";
import "./ProfileComponent.css";
import { withStyles } from "@material-ui/core/styles";
import "antd/dist/antd.css";
import close_icon_red from "../../Assets/img/close_icon_red.svg";
import Loader from "react-loader-spinner";
import HeaderComponent from "../../SharedComponents/HeaderComponent/HeaderComponent";
const CssTextField = withStyles({
    root: {
      "& .MuiInput-underline:after": {
        borderBottomColor: "#14193A",
      },
      "& .MuiOutlinedInput-root": {
        "& fieldset": {
          borderColor: "#E2E5F2",
          borderWidth: 1,
        },
  
        "&.Mui-focused fieldset": {
          borderColor: "#14193A",
          borderWidth: 1,
        },
      },
    },
  })(TextField);
  class ProfileComponent extends Component {
    constructor(props) {
        super(props);
        var userData = localStorage.getItem("userdata")
          ? JSON.parse(localStorage.getItem("userdata"))
          : "";
          this.state = {
            role: userData.role,
            profileData: {},
            // loading: true,
            image: "",
            mnumber: false,
            eprofile: false,
            formError: "",
            editphone: "",
            hidePro: "showPro",
            hidePhone: "showPhone",
            phone: "",
            isPhone:true,
            disablePhone:true,
            profilePic: "",
            file: null,
            responseMsg: "",
            alertType: "",
            invalidImage: "",
            isValid: !this.props.isValid,
            profilePath: "",
            loading: false,
            department_details:[]
          };
        }
        componentDidMount = () => {
            this.setState({ isValid: false });
            AxiosInstance( (process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa")
              ? 4003
              : "clinical-user", "application/x-www-form-urlencoded; charset=UTF-8")
              .get("/profile")
              .then((resp) => {
                let response = resp.data.data;

                if (response && (resp.data.status === "200" || resp.data.status === 200)) {
                  this.setState({
                    profileData: resp.data.data,
                    phone: resp.data.data.phone,
                    profilePath: resp.data.data.profilePath,
                  });
                  if(this.state.role === "Scheduler"){
                    this.setState({
                    department_details:resp.data.data.department_details[0].name
                   })
                  }
                }
              })
              .catch((err) => {
                var error = err.response;
                if (error && (error.status === "403" || error.status === 403)) {
                  localStorage.clear();
                  setTimeout(() => {
                    window.location.href = "/";
                  }, 500);
                } else {
                }
              });
          };
          onProSubmit = (e) => {
            const { profilePic } = this.state;
            let data = "";
            if (profilePic !== null) {
              data = new FormData();
              data.append("profilePhoto", profilePic);
            }
            if (this.state.invalidImage === "") {
              AxiosInstance( (process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa")
              ? 4003
              : "clinical-user", "application/x-www-form-urlencoded; charset=UTF-8")
                .put("/profile", data)
                .then((resp) => {
                  if (resp.data.status === 200) {
                    this.setState({
                      eprofile: false,
                      responseMsg: "Updated successfully",
                      alertType: "success",
                      profilePath: resp.data.profilePath,
                    });
                    var userData = localStorage.getItem("userdata")
                      ? JSON.parse(localStorage.getItem("userdata"))
                      : "";
                    userData.profilePath = resp.data.profilePath;
                    var jsonData = JSON.stringify(userData);
                    localStorage.setItem("userdata", jsonData);
                    setTimeout(() => {
                      this.setState({ responseMsg: "" });
                    }, 2000);
                  } else {
                    this.setState({ responseMsg: resp.data.msg, alertType: "error" });
                  }
                })
                .catch((err) => {
                  var error = err.response;
                  if (error && (error.status === "403" || error.status === 403)) {
                    localStorage.clear();
                    setTimeout(() => {
                      window.location.href = "/";
                    }, 500);
                  } else {
                  }
                });
            }
            setTimeout(() => {
              this.setState({ loading: false });
            }, 1000);
          };
          uplodProf = (e) => {
            this.setState({ loading: false });
            const proimage = e.target.files[0];
        
            if (!proimage.name.match(/\.(jpg|jpeg|png|gif)$/)) {
              this.setState({
                responseMsg: "Upload valid image in jpg, jpeg, png or gif format",
                alertType: "error",
                loading:false
              });
              return false;
            } else {
              this.setState(
                { profilePic: proimage, responseMsg: "", alertType: "" },
                this.onProSubmit
              );
            }
          };
          handlepPhoneChange = (e) => {
            this.setState({ editphone: e.target.value, disablePhone:false });
            var phoneno = /^(\+\d{1}\s?)?1?\-?\.?\s?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;
            if (e.target.value.length === 0) {
              this.setState({ formError: "*Please enter phone no." });
            } else if (e.target.value.match(phoneno)) {
              this.setState({ formError: "" });
            } else {
              this.setState({
                formError:
                  "*Please enter the valid phone number e.g.: 800-555-5555 ",
              });
              return false;
            }
          };
          onSubmit = (e) => {
            e.preventDefault();
            var profile = {
              phone: this.state.editphone,
            };
            if (this.state.formError === "") {
              AxiosInstance( (process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa")
              ? 4003
              : "clinical-user", "application/x-www-form-urlencoded; charset=UTF-8")
                .put("/profile", profile)
                .then((resp) => {
                  if (resp.data.status === 200) {
                    this.setState({
                      formError: "",
                      responseMsg: "Updated successfully",
                      alertType: "success",
                      phone: this.state.editphone,
                      mnumber: false,
                      isPhone:true,
                      disablePhone:true
                    });
                    setTimeout(() => {
                      this.setState({ responseMsg: "" });
                    }, 2000);
                  } else {
                    this.setState({
                      formError: "",
                      responseMsg: resp.data.msg,
                      alertType: "error"
                    });
                  }
                })
                .catch((err) => {
                  var error = err.response;
                  if (error && (error.status === "403" || error.status === 403)) {
                    localStorage.clear();
                    setTimeout(() => {
                      window.location.href = "/";
                    }, 500);
                  } else {
                  }
                });
            }
          };
          hideMobEdit = () => {
            this.setState({ isValid: false, mnumber: false, formError: "",isPhone:true });
          };
          showMobEdit = () => {
            this.setState({
              responseMsg: "",
              alertType: "",
              invalidImage: "",
              editphone: this.state.phone,
              mnumber: true,
              isPhone:false,
            });
          };
          hideProEdit = () => {
            this.setState({ invalidImage: "" });
          };
          showProEdit = () => {
            this.setState({
              responseMsg: "",
              alertType: "",
              eprofile: true,
            });
          };
          showButton = (showvalue) => {
            switch (showvalue) {
              case "hidePro":
                this.setState({ hidePro: "" })
                break;
              case "hidePhone":
                this.setState({ hidePhone: "" })
                break;
              default:
                break;
            }
          };
          hideButton = (hidevalue) => {
            switch (hidevalue) {
              case "hidePro":
                this.setState({ hidePro: "showPro" })
                break;
              case "hidePhone":
                this.setState({ hidePhone: "showPhone" })
                break;
              default:
                break;
            }
          }
    render() {
        let { loading } = this.state;
    let show = "";
    let hidden = "";
    if (this.state.mnumber === true) {
      hidden = "hidden";
    } else if (this.state.mnumber === false) {
      show = "hidden";
    }
    let proshow = "";
    let prohidden = "";
    if (this.state.eprofile === true) {
      prohidden = "hidden";
    } else if (this.state.eprofile === false) {
      proshow = "hidden";
    }
    let profileForm = (
        <div className="signup-body-bg-color">
            <HeaderComponent profile={this.state.profilePath} />
            <div className="manage-accoutn-container">
          <Container fixed className="signup-body">
          <Card className="signup-root">
            <div className="card-header pro_box_ht">
              <div className="profile-header-content ">
                <div className={prohidden}>
                  {this.state.profilePath === "" ||
                    this.state.profilePath === undefined ? (
                      <div className="prof_avtar">
                        <img
                          className="avatar_img"
                          alt={this.state.profileData.name}
                          src={DefaultProfile}
                        />
                        <span className="avatar_input_file_container">
                        <input
                          className="avatar_edit_img avatar_input_file"
                          type="file"
                          name="profilePic"
                          onChange={this.uplodProf}
                        />
                        </span>
                        <img
                         className={this.state.hidePro + " avatar_edit_img"}
                          src={editprofile}
                          style={{ cursor: "pointer" }}
                        />
                        <p style={{ color: "red", fontSize: "14px" }}>
                          {this.state.invalidImage}{" "}
                        </p>
                      </div>
                    ) : (
                        <div className="prof_avtar" >
                        <img
                          className="avatar_img"
                          alt={this.state.profileData.name}
                          src={
                            "https://salesc2userprofile.s3.amazonaws.com/" +
                            this.state.profilePath
                          }
                        />
                        <span className="avatar_input_file_container">
                        <input
                          className="avatar_edit_img avatar_input_file"
                          type="file"
                          name="profilePic"
                          onChange={this.uplodProf}
                        />
                        </span>
                        <img
                          className=" avatar_edit_img"
                          src={editprofile}
                          style={{ cursor: "pointer" }}
                        />
                        <p style={{ color: "red", fontSize: "14px" }}>
                          {this.state.invalidImage}{" "}
                        </p>
                      </div>
                    )}{" "}
                      <Loader
                    className="pro_loding"
                    type="ThreeDots"
                    color="#00BFFF"
                    height={50}
                    width={50}
                    visible={loading}
                  />
                </div>
                <div className="prof_name">
                  {" "}
                  <h2>{this.state.profileData.name}</h2>{" "}
                  <p>
                    {this.state.profileData.role
                      ? this.state.profileData.role.charAt(0).toUpperCase() +
                      this.state.profileData.role.slice(1)
                      : "-"}
                  </p>
                </div>
              </div>
            </div>
            {this.state.responseMsg != "" ? (
              <Alert severity={this.state.alertType}>
                {this.state.responseMsg}
              </Alert>
            ) : null}
            <div className="profile-header-content profile-header-content-two">
              <div className="key_name">Email</div>
              <div className="profile_value">
                <img src={email} />
                <span className="profile_value_span">
                  {" "}
                  {this.state.profileData.email}
                </span>
              </div>
              <div className="key_name">Phone Number</div>
               <div className="profile_value" onMouseEnter={() => this.showButton("hidePhone")} onMouseLeave={() => this.hideButton("hidePhone")} >
                <img src={phone} />
                {this.state.isPhone && (
                <div className={hidden}>
                  <span className="profile_value_span">
                    {" "}
                    {this.state.phone}
                  </span>
                  <img
                    className={this.state.hidePhone + " edit_icon"}
                    src={editIcon}
                    style={{ cursor: "pointer" }}
                    onClick={this.showMobEdit}
                  />
                </div>
                )}
                {!this.state.isPhone && (
                <div className="info-card-detail-left " className={show}>
                  <span className="edit-input add_company_dialog">
                      <CssTextField
                        id="outlined-basic"
                        name="editphone"
                        variant="outlined"
                        type="text"
                        placeholder="Please enter phone number"
                        value={this.state.editphone}
                        onChange={this.handlepPhoneChange}
                      />
                      <Button
                        className="add_more_btn"
                        disabled={this.state.disablePhone}
                        onClick={this.onSubmit}
                      >
                        Save
                      </Button>
                    <img
                      className={show}
                      src={close_icon_red}
                      style={{ cursor: "pointer" }}
                      onClick={this.hideMobEdit}
                    />
                  </span>
                </div>
                 )}
              </div>
              <div className="profile_ErrorMassage">
                <span className="modal-err-msg">{this.state.formError}</span>
                </div>
                {this.state.role !== "Scheduler" ? (
                  ""
                ) : (
                  <>
                    <div className="key_name">Team</div>
                    <div className="profile_value">
                      {/* <img className="division_img" src={division} /> */}
                      <div>
                        {this.state.department_details === null || this.state.department_details === "" ? (
                          ""
                        ) : (
                            <div className="division_value">
                              <span className="profile_value_span profile_value_span_key">
                                Department
                          </span>
                              <span className="profile_value_seperator profile_value_span">
                                :
                          </span>
                              <span className="profile_value_two profile_value_span">
                                {this.state.department_details}
                              </span>
                            </div>
                          )}
                        </div>
                    </div>
                  </>
                )}
            </div>
          </Card>
        </Container>
      </div>
      </div>
      );
    return <div>{profileForm}</div>;
  }
}
  export default connect()(withRouter(ProfileComponent));
