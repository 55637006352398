import React, { Component, Suspense } from "react";

import * as action from "../../Store/Actions/index";
import { withStyles } from '@material-ui/core/styles';
import HeaderComponent from "../../SharedComponents/HeaderComponent/HeaderComponent";
import {
    Typography,
    Button,
    FormControl,
    InputLabel,
    Select,
    TextField,
    InputAdornment,
    MenuItem, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TableFooter, TablePagination, Tooltip,
} from "@material-ui/core";
import searchIcon from "../../Assets/images/search_icon.svg";
import { connect } from "react-redux";
import { withRouter, NavLink } from "react-router-dom";
import jwt from "jsonwebtoken";
import OpenMenu from "../../Assets/images/open-menu.svg";
import { AxiosInstance } from "../../Utilities";
import { Autocomplete, Alert } from "@material-ui/lab";
import 'antd/dist/antd.css';
import { DatePicker } from 'antd';
import Loader from "react-loader-spinner";
import InviteCompanyModal from "./InviteCompanyModal";
import { AddCompanyModal } from "./AddCompanyModal";
import validator from "validator";
import momentTimeZone from 'moment-timezone';
import CustomPaginationComponent from "../../SharedComponents/CustomPaginationComponent/CustomPaginationComponent";
import { isSafari } from "react-device-detect";
import {
    emailRegx,
    phoneRegx,
  } from "../../SharedComponents/Validation/formValidation";
import {
    getGooglePlaceObjectValues,
    getCity,
    getArea,
    getState,
    getZipcode,
  } from "../../SharedComponents/CommonUtilities/CommonUtilities";
const { RangePicker } = DatePicker;
const moment = require("moment");
const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(even)': {
            backgroundColor: "#F9F9F9",
        },
    },
}))(TableRow);
const LightTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: "black",
        color: "white",
        boxShadow: theme.shadows[1],
        fontSize: 13,
    },
}))(Tooltip);
const CssTextField = withStyles({
    root: {
        '& .MuiInput-underline:after': {
            borderBottomColor: '#14193A',
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: '',
                borderWidth: 0
            },
            '&.Mui-focused fieldset': {
                borderColor: '',
                borderWidth: 0
            },
        },
    },
})(TextField, Select);
class CompanyComponent extends Component {
    constructor(props) {
        super(props);
        var userData = localStorage.getItem("userdata")
            ? JSON.parse(localStorage.getItem("userdata"))
            : "";
        var roles = userData.role;
        var zoneName = momentTimeZone.tz.guess();
        this.state = {
            auth_username: userData.username,
            role: userData.role,
            zoneName: zoneName,
            timeZoneName: localStorage.getItem("timeZone") ? localStorage.getItem("timeZone") : zoneName,
            auth_email: userData.email,
            auth_companyId: userData.companyId,
            showaddModal: false,
            showEditModal: false,
            modalRecord: {},
            deleteRecord: {},
            rowsPerPage: '10',
            nextpage: 1,
            pagesize: 10,
            loading: true,
            searchText: "",
            openModel: false,
            responseMsg: "",
            alertType: "",
            createdBy: userData.username,
            companyName: "",
            companyId: "",
            email: "",
            phone: "",
            firstName: "",
            lastName: "",
            isLoading: false,
            disable: true,
            companySize: "",
            companyOption: [
                {
                    id: "",
                    name: "",
                },
            ],
            divisionOption: [
                {
                    id: "",
                    name: "",
                },
            ],
            divisionId: "",
            formError: {
                companyId: "",
                phone: "",
                email: "",
                compName: "",
                firstname: "",
                lastname: "",
                createdBy: "",
                divisionError: "",
                divError: ""
            },
            companyNameError: "",
            division: [],
            division_id: "",
            divisionName: "",
            divisioninvite: "",
            companyList: [],
            isSubmit: false,
            ishide : true,
            pageNumber:1,
            ishide: true,
            pageNumber: 1,
            tooltipprops:{},
            //new states
            file : null,
            companyLogo:"",
            billingPrice: "", 
            isValid: false,
            billingConfigType: '1',
            billingTrialEnds: [
                momentTimeZone(new Date()).tz(localStorage.getItem("timeZone") ? localStorage.getItem("timeZone") : zoneName),
                momentTimeZone(new Date()).tz(localStorage.getItem("timeZone") ? localStorage.getItem("timeZone") : zoneName).add(14, 'days')
            ],
            billingTrialDays: 14,
            billingPrice: 29.99,
            companyAddress: "",
            formError: {
                companyName: "",
                companyCode: "",
                companyPhone: "",
                companyEmail: "",
                companyAddress: "",
                companyWebsite: "",
                companySize: "",
                billingConfigType: "",
                billingPrice: ""
            }
        };
    }
    UNSAFE_componentWillMount(){
        let obj = {}
        if(isSafari){
          obj.open = false;
          this.setState({tooltipprops:obj})
        }
      }
    componentDidMount = () => {
        this.setState({ loading: true }, () => {
            this.props.onPagination(this.state.pageNumber - 1, this.state.pagesize);
        })
        console.log(this.props);
        setTimeout(() => {
            this.setState({ loading: false });
        }, 3000);
    }
    onCompanyDetailHandler = (taskId) => {
        console.log("clicked");
        //this.props.history.push(`task/${taskId}`)
    }
    // handleChangePage = (event, nextpage) => {
    //     this.setState({ loading: true });
    //     this.setState({ nextpage: nextpage + 1 },
    //         this.filter
    //     )
    // };
    handleCustomPaginagtionChange = (event, value) => {
        this.setState({ loading: true });
        this.setState({ pageNumber: value },
            this.filter
        )
    }
    handleChangeRowsPerPage = (event) => {
        this.setState({ loading: true });
        this.setState({ pagesize: event.target.value },
            this.filter
        );
    };
    onDateChanage = (date, dateString) => {
        this.setState({ dateFilter: { from: dateString[0], to: dateString[1], } },
            this.filter
        );
    }
    handleStatusSelect = (event) => {
        this.setState({ [event.target.name]: event.target.value },
            this.filter
        );
    }


    taskSearch = (e) => {
        this.setState({ loading: true });
        let name = e.target.value
        this.setState({ searchText: name },
            this.filter
        )
    }
    filter = () => {

        var { pagesize, pageNumber } = this.state
        let data = {
            searchText: this.state.searchText,
            pagesize: pagesize,
            nextpage: pageNumber,
        }
        this.props.onSearchBy(data);
        setTimeout(() => {
            this.setState({ loading: false });
        }, 2000);
    }
    onCloseModal = () => {
        this.setState({
            openModel: false,
            responseMsg: "",
            companyName: "",
            divisionName: "",
            divisioninvite: "",
            companyId: "",
            divisionId: "",
            email: "",
            phone: "",
            firstName: "",
            lastName: "",
            companyNameError: "",
            ishide: true,
            companySize: "small",
            formError: {
                companyId: "",
                phone: "",
                email: "",
                compName: "",
                firstname: "",
                lastname: "",
                createdBy: "",
                divisionError: "",
                divError: "",
                disable: false
            },
        });
    };

    closeaddModal = () => {
        this.props.history.replace("" + "/company", null);
        this.setState({ 
            showaddModal: false,
            file : null,
            companyLogo:"",
            billingPrice: "", 
            isValid: false,
            billingConfigType: '1',
            billingTrialEnds: [
                momentTimeZone(new Date()).tz(localStorage.getItem("timeZone") ? localStorage.getItem("timeZone") : this.state.zoneName),
                momentTimeZone(new Date()).tz(localStorage.getItem("timeZone") ? localStorage.getItem("timeZone") : this.state.zoneName).add(14, 'days')
            ],
            billingTrialDays: 14,
            billingPrice: 29.99,
            companyAddress: "",
            companyName: "",
            companyCode: "",
            companyPhone: "",
            companyEmail: "",
            companyWebsite: "",
            companySize: "",
            formError: {
                companyName: "",
                companyCode: "",
                companyPhone: "",
                companyEmail: "",
                companyAddress: "",
                companyWebsite: "",
                companySize: "",
                billingConfigType: "",
                billingPrice: ""
            }
         })
    }
    
    onChangeHandler = (e) => {
        var phoneno = /^(\+\d{1,2}\s?)?1?\-?\.?\s?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;
        var userData = localStorage.getItem("userdata")
            ? JSON.parse(localStorage.getItem("userdata"))
            : "";
        var { name, value } = e.target;
        this.setState({ [name]: value });
        var { formError } = this.state;

        switch (name) {
            case "email":
                validator.isEmpty(value)
                    ? (formError.email = "* Enter Email id")
                    : !validator.trim(value)
                        ? (formError.email = "*Please enter a valid Email")
                        : !validator.isEmail(value)
                            ? (formError.email = "* Please check the email address is in valid format e.g. name@domain.com")
                            : (formError.email = "");
                break;
            case "phone":
                validator.isEmpty(value)
                    ? (formError.phone = "")
                    : !phoneno.test(value)
                        ? (formError.phone = "* Please enter the valid phone number e.g.: 800-555-5555 ")
                        : (formError.phone = "");
                break;
            case "companyName":
                validator.isEmpty(value)
                    ? (formError.compName = "* Please select a company")
                    : (formError.compName = "");
                break;
            case "firstName":
                validator.isEmpty(value)
                    ? (formError.firstname = "* Please enter the First name")
                    : !validator.trim(value)
                        ? (formError.firstname = "*Please enter a valid First name")
                        : !/^[a-zA-Z,'. ]*$/.test(value)
                            ? (formError.firstname = "* Special characters are not allowed")
                            : !validator.isLength(value, { min: 2, max: 50 })
                                ? (formError.firstname = "* First Name should be min 2 and max 50 character")
                                : (formError.firstname = "");
                break;
            case "lastName":
                validator.isEmpty(value)
                    ? (formError.lastname = "* Please enter the Last name")
                    : !validator.trim(value)
                        ? (formError.lastname = "*Please enter a valid Last name")
                        : !/^[a-zA-Z,'. ]*$/.test(value)
                            ? (formError.lastname = "* Special characters are not allowed")
                            : !validator.isLength(value, { min: 2, max: 50 })
                                ? (formError.lastname = "* Last Name min 2 and max 50 character")
                                : (formError.lastname = "");
                break;
            case "divisionName":
                validator.isEmpty(value)
                    ? (formError.divisionError = "* Please Select division")
                    : (formError.divisionError = "");
                break;
            case "divisioninvite":
                validator.isEmpty(value)
                    ? (formError.divError = "")
                    : !validator.trim(value)
                        ? (formError.divError = "*Please enter a valid Division")
                        : !validator.isLength(value, { max: 100 })
                            ? (formError.divError = "* Division name should not exceed 100 characters")
                            : (formError.divError = "");
            default:
                break;
        }
        this.setState({
            formError,
        });
    };

    onFileuploadHandler = (event) => {
        const { formError } = this.state;
        var x = document.getElementsByClassName("input_two");
        x[0].innerHTML = "Upload logo file in jpg, png, svg format";
        if (!event.target.files[0].name.match(/\.(jpg|jpeg|png|svg)$/)) {
          formError.companyLogo = "* Upload valid image";
          this.setState({
            companyLogo: "",
            file: null,
            loaded: 0,
            isuplode: true,
          });
        } else {
          formError.companyLogo = "";
          var y = document.getElementsByClassName("input_two");
          y[0].innerHTML = event.target.files[0].name;
          this.setState({
            companyLogo: event.target.files[0],
            file: URL.createObjectURL(event.target.files[0]),
            loaded: 0,
            isuplode: false,
          });
        }
        this.setState({ formError });
      };

      isuplode = () => {
        this.setState({ isuplode: true,
          companyLogo:this.state.companyLogo   })
      }

    inviteHandler = (e) => {
        e.preventDefault();
        var {
            phone,
            email,
            companyName,
            firstName,
            lastName,
            formError,
            divisionName,
            divisioninvite,
            companyNameError
        } = this.state;
        if (companyName === "") {
            companyNameError = "* Please select a company";
        }
        if (firstName === "") {
            formError.firstname = "* Please enter first name";
        }
        if (lastName === "") {
            formError.lastname = "* Please enter last name";
        }
        // if (divisionName === "") {
        //     formError.divisionError = "* Please enter division";
        // }
        if (email === "") {
            formError.email = "* Please enter email";
        }
        // if (phone === "") {
        //     formError.phone = "* Please enter phone";
        // }
        this.setState({ formError, companyNameError }, () => {
            var userData = {
                firstName,
                lastName,
                companyName,
                email,
                phone,
                divisionName,
                divisioninvite
            };
            const isEmpty = Object.values(formError).every(
                (x) => x === null || x === ""
            );
            if (companyNameError === "" && formError.firstname === "" && formError.lastname === "" && formError.email === "" && formError.phone === "" && formError.divError === "") {
                this.setState({ isLoading: true });
                AxiosInstance((process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa")
                    ? 4006
                    : "clinical-company", "application/x-www-form-urlencoded; charset=UTF-8")
                    .post("/company/invite", userData)
                    .then((res) => {
                        var response = res.data;
                        if (response && (response.status === "403" || response.status === 403)) {
                            this.setState({
                                isSubmit: false,
                                responseMsg: response.msg,
                                alertType: "error",
                                isLoading: false,
                            });
                        } else if (response && (response.status === "200" || response.status === 200)) {
                            this.setState({
                                isSubmit: true,
                                responseMsg: response.msg,
                                alertType: "success",
                                isLoading: false,
                            });
                            setTimeout(() => {
                                this.onCloseModal()
                            }, 1000);
                        } else if (response && (response.status === "400" || response.status === 400)) {
                            this.setState({
                                isSubmit: false,
                                responseMsg: response.msg,
                                alertType: "error",
                                isLoading: false,
                            });
                        }
                    })
                    .catch((err) => {
                        var error = err.response;
                        if (error && (error.status === "403" || error.status === 403)) {
                            localStorage.clear();
                            setTimeout(() => {
                                window.location.href = "/";
                            }, 500);
                        } else {
                            console.log("Internal Server Error");
                        }
                        this.setState({
                            isLoading: false,
                        });
                    });
            }
        });
    };
    newInviteHandler = (e) => {
        e.preventDefault();
        var {
            companyName,
            formError,
            divisionName,
            companyNameError
        } = this.state;
        if (companyName === "") {
            companyNameError = "* Please select a company";
        }
        if (divisionName === "") {
            formError.divisionError = "* Please select division";
        }
        this.setState({ formError, companyNameError }, () => {
            let userData = "";
            let isvalid = false;
            if (this.state.companySize === "large") {
                if (this.state.companyId !== "" && this.state.divisionId !== "") {
                    isvalid = true;
                }
                userData = {
                    companyId: this.state.companyId,
                    divisionId: this.state.divisionId
                };
            } else {
                if (this.state.companyId !== "") {
                    isvalid = true;
                }
                userData = {
                    companyId: this.state.companyId
                };
            }
            const isEmpty = Object.values(formError).every(
                (x) => x === null || x === ""
            );
            if (isvalid) {
                this.setState({ isLoading: true });
                AxiosInstance((process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa")
                    ? 4006
                    : "clinical-company", "application/x-www-form-urlencoded; charset=UTF-8")
                    .post("/team", userData)
                    .then((res) => {
                        var response = res.data;
                        if (response && (response.status === "403" || response.status === 403)) {
                            this.setState({
                                isSubmit: false,
                                responseMsg: response.msg,
                                alertType: "error",
                                isLoading: false,
                            });
                        } else if (response && (response.status === "200" || response.status === 200)) {
                            this.setState({
                                isSubmit: true,
                                responseMsg: response.msg,
                                alertType: "success",
                                isLoading: false,
                            });
                            setTimeout(() => {
                                this.onCloseModal()
                            }, 1000);
                        } else if (response && (response.status === "400" || response.status === 400)) {
                            this.setState({
                                isSubmit: false,
                                responseMsg: response.msg,
                                alertType: "error",
                                isLoading: false,
                            });
                        }
                    })
                    .catch((err) => {
                        var error = err.response;
                        if (error && (error.status === "403" || error.status === 403)) {
                            localStorage.clear();
                            setTimeout(() => {
                                window.location.href = "/";
                            }, 500);
                        } else {
                            console.log("Internal Server Error");
                        }
                        this.setState({
                            isLoading: false,
                        });
                    });
            }
        });
    };
    companyListHandler = async (e) => {
        var name = e.target.value;
        let value = e.target.value;
        this.setState({ companyName: name, })
        if (!validator.trim(value)) {
            this.state.companyNameError = "*Please enter a valid company Name";
        }
        var data = {
            companyName: name,
        };
        this.setState({

            companyNameError: this.state.companyNameError,
        })
        AxiosInstance((process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa")
            ? 4006
            : "clinical-company", "application/x-www-form-urlencoded; charset=UTF-8")
            .post("/companylist", data).then((res) => {
                if (res.data.status == "200") {
                    this.setState({
                        companyOption: res.data.finalCompany,
                        companyNameError: "",
                    });
                } else {
                    this.setState({
                        companyOption: [
                            {
                                id: "",
                                name: name,
                            },
                        ],
                        ishide: false
                    });
                }
            })
            .catch((err) => {
                var error = err.response;
                if (error && (error.status === "403" || error.status === 403)) {
                    localStorage.clear();
                    setTimeout(() => {
                        window.location.href = "/";
                    }, 500);
                } else {
                    console.log("Internal Server Error");
                }
            });
    };
    onSelectHandler = (e) => {
        if (!e.target.value) {
            this.companyListHandler(e);
        } else {
            var name = e.target.value;
            var { companyOption } = this.state;
            var data = companyOption.filter((item) => {
                return item.name === name;
            });
            data.map((companyData) => {
                this.setState({
                    companyId: companyData.id,
                    companyName: companyData.name,
                    companySize: companyData.size,
                    disable: false,
                    companyNameError: ""
                });
            });
        }
    };
    compAutoClose = (e) => {
        var name = e.target.value;
        if (typeof name === "undefined") {
            this.setState({ companyId: "" });
        }
    }
    divisionListHandler = async (e) => {
        var data = {
            companyId: this.state.companyId,
        };
        AxiosInstance((process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa")
            ? 4006
            : "clinical-company", "application/x-www-form-urlencoded; charset=UTF-8")
            .post("/divisionlist", data).then((res) => {
                if (res.data.status == "200") {
                    this.setState({
                        divisionOption: res.data.finalDivision,
                        formError: {
                            divisionError: ""
                        }
                    });
                } else {
                    this.setState({
                        divisionOption: [
                            {
                                id: "one",
                                name: "No data",
                            },
                        ],

                    });
                }
            })
            .catch((err) => {
                var error = err.response;
                if (error && (error.status === "403" || error.status === 403)) {
                    localStorage.clear();
                    setTimeout(() => {
                        window.location.href = "/";
                    }, 500);
                } else {
                    console.log("Internal Server Error");
                }
            });
    };
    onDivisionSelectHandler = (e) => {
        if (!e.target.value) {
            this.divisionListHandler(e);
        } else {
            var name = e.target.value;

            var { divisionOption } = this.state;
            var data = divisionOption.filter((item) => {
                return item.name === name;
            });
            data.map((divisionData) => {
                this.setState({
                    divisionId: divisionData.id, divisionName: divisionData.name,
                });
            });
        }
    };
    divisionAutoClose = (e) => {
        var name = e.target.value;
        if (typeof name === "undefined") {
            this.setState({ divisionId: "" });
        }
    }
    navigateDetail = (row) => {
        this.props.history.push('/company/' + row.id);
    }
    handleChange = (event) => {
        const { formError, } = this.state;
        let identifier = event.target.name;
        let value = event.target.value;
        console.log("value",value)
        switch (identifier) {
          case "billingPrice":
            validator.isEmpty(value)
              ? formError.billingPrice = "Please enter a billing price"
              : !/^(?!0+$)\d{1,6}(\.\d{1,2})?$/.test(value)
                ? formError.billingPrice = "Please enter the valid price. e.g: $2,500"
                : formError.billingPrice = "";
            break;
          case "companyName":
            value.trim() === "" ?
              formError.companyName = "* Please enter the company name"
              : !validator.isLength(value.trim(), { max: 50 }) ?
                formError.companyName = "* Company Name should not exceed 50 characters."
                : formError.companyName = "";
            break;
          case "address":
            if (!validator.isLength(value.trim(), { max: 100 })) {
              formError.companyAddress = "* Address should not exceed 100 characters";
            } else {
              formError.companyAddress = "";
            }
            break;
          case "companyCode":
            if (!validator.isLength(value.trim(), { max: 4 })) {
              formError.companyCode = "* Code should not exceed 4 characters";
            } else {
              formError.companyCode = "";
            }
            break;
          case "companyEmail":
            if (!emailRegx.test(value.trim()) && value.trim().length > 0) {
              formError.companyEmail =
                "* Please check the email address is in valid format e.g. name@domain.com";
            } else if (!validator.isLength(value, { max: 100 })) {
              formError.companyEmail = "* Email should not exceed 100 characters";
            } else {
              formError.companyEmail = "";
            }
            break;
          case "companyPhone":
            if (value.trim() === 0) {
              formError.companyPhone = "";
            }
            else if (!phoneRegx.test(value.trim()) && value.trim().length > 0) {
              formError.companyPhone = "* Please enter the valid phone number e.g.: 800-555-5555";
            } else {
              formError.companyPhone = "";
            }
            break;
          case "companyLogo":
            if (!phoneRegx.test(value.trim()) && value.trim().length > 0) {
              formError.companyLogo = "* Upload a valid image";
            } else {
              formError.companyLogo = "";
            }
            break;
          case "companyWebsite":
            var expression = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi;
            var regex = new RegExp(expression);
            if (value.trim() === 0) {
              formError.companyWebsite = "";
            } else if (!regex.test(value.trim()) && value.trim().length > 0) {
              formError.companyWebsite = "* Enter valid website url";
            } else {
              formError.companyWebsite = "";
            }
            break;
          case "companySize":
            if (value.trim() === "") {
              formError.companySize = "*Please select the company size";
            } else {
              formError.companySize = "";
            }
            break;
          default:
            break;
        }
        console.log(
            event.target.name, event.target.value
        )
        this.setState({
          [event.target.name]: event.target.value,
          formError,
        });
      };

      onGooglePlaceSelect = (place) => {
        var addressArray = place.address_components;
        var formattedPlace = {
          ...getGooglePlaceObjectValues(place),
          city: getCity(addressArray),
          area: getArea(addressArray),
          state: getState(addressArray),
          zipcode: getZipcode(addressArray),
        };
        this.setState({
          eventAddress: formattedPlace.address,
          Address: formattedPlace.address,
          eventLocation: [formattedPlace.lng, formattedPlace.lat],
        });
      };


      onclickBtn = (e) => {
        //e.preventDefault();
        const {
          companyName,
          companyCode,
          companyPhone,
          companyEmail,
          Address,
          companySize,
          billingPrice,
          companyWebsite,
        } = this.state;
        if (
          // companyWebsite === "" ||
          companyName === "" ||
          companyCode === "" ||
          // companyPhone === "" ||
          companyEmail === "" ||
           // Address === "" ||
          companySize === "" ||
          billingPrice === ""
        ) {
          this.setState({ isValid: true });
        } else {
          this.setState({ isValid: false });
        }
      };

      Addcompanyfn = async (e) => {
        e.preventDefault();
        console.log("this.state.Address",this.state.Address)
        const { formError } = this.state;
        if (this.state.companyName?.trim() === "") {
          formError.companyName = "*Please enter a company name";
        } else {
          formError.companyName = "";
        }
        if (this.state.companyEmail?.trim() === "") {
          formError.companyEmail = "*Please enter the company email";
        } else {
          formError.companyEmail = "";
        }
        if (this.state.companySize?.trim() === "") {
          formError.companySize = "*Please select the company size";
        } else {
          formError.companySize = "";
        }
    
        if (this.state.billingPrice === "") {
          formError.billingPrice = "Please enter a billing price";
        } else {
          formError.billingPrice = "";
        }
    
        this.setState({
          formError
        }, () => {
          const isEmpty = Object.values(formError).every(
            (x) => x === null || x === ""
          );
          if (isEmpty) {
    
            this.setState({ isSubmit: true, responseMsg: "" });
            console.log(this.state.billingTrialEnds[0],this.state.billingTrialEnds[1])
            let trialStart = momentTimeZone(this.state.billingTrialEnds[0]).tz(this.state.timeZoneName).utc(); //.format('MM-DD-YYYY hh:mm A');
            let trialEnd = momentTimeZone(this.state.billingTrialEnds[1]).tz(this.state.timeZoneName).utc();//.format('MM-DD-YYYY hh:mm A');
            let Filedata = new FormData();
            Filedata.append("companyName", this.state.companyName);
            Filedata.append("companyId", this.state.companyCode);
            Filedata.append("address", this.state.Address  || "");
            Filedata.append("website", this.state.companyWebsite || "");
            Filedata.append("email", this.state.companyEmail);
            Filedata.append("phone", this.state.companyPhone || "");
            Filedata.append("companyLogo", this.state.companyLogo || "");
            Filedata.append("size", this.state.companySize);
            Filedata.append("description", this.state.companyDescription || "");
            Filedata.append("billingType", this.state.billingConfigType);
            Filedata.append("pricePerUser", parseFloat(this.state.billingPrice));
            // Filedata.append("billingTrialDays",this.state.billingTrialDays);
            Filedata.append("trialStart", trialStart);//new Date(trialStart).toUTCString());
            Filedata.append("trialEnd", trialEnd);//new Date(trialEnd).toUTCString());
            if (!this.state.isValid) {
              AxiosInstance((process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa") ? 4006 : 'company', "application/x-www-form-urlencoded; charset=UTF-8")
                .post("/addCompany", Filedata)
                .then((resp) => {
                  if (resp.data.status === 201) {
                    this.setState(
                      {
                        responseMsg: "Company details added successfully",
                        alertType: "success",
                      },
                      () => {
                        setTimeout(() => {
                          this.closeaddModal();
                          this.componentDidMount();
                          this.setState({ showaddModal: false, isSubmit: false });
                        }, 2000);
                      }
                    );
                  } else {
                    this.setState({
                      responseMsg: resp.data.msg,
                      alertType: "error",
                      isSubmit: false,
                    });
                  }
                })
                .catch((err) => {
                  var error = err.response;
                  if (error && (error.status === "403" || error.status === 403)) {
                    localStorage.clear();
                    setTimeout(() => {
                      window.location.href = "/";
                    }, 500);
                  } else {
                    console.log("Internal Server Error");
                  }
                  this.setState({
                    responseMsg: "Something went wrong, Please try again later",
                    alertType: "error",
                    isSubmit: false,
                  });
                });
            } else {
              this.setState({ isSubmit: false });
            }
          }
        });
      };

    checkCompanyId = () => {
        let data = {
          companyName: this.state.companyName,
          companyId: this.state.companyCode,
        };
        AxiosInstance((process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "qa") ? 4006 : 'company', "application/x-www-form-urlencoded; charset=UTF-8")
          .post("/company/checkCompanyId", data)
          .then((resp) => {
            if (resp.data.status === 200 || resp.status === 200) {
              this.setState({ companyCode: resp.data.companyId });
            } else {
              this.setState({ responseMsg: resp.data.message, alertType: "error" });
              this.setState({ companyCode: resp.data.companyId });
            }
          })
          .catch((err) => {
            var error = err.response;
            if (error && (error.status === "403" || error.status === 403)) {
              localStorage.clear();
              setTimeout(() => {
                window.location.href = "/";
              }, 500);
            } else {
              console.log("Internal Server Error");
            }
          });
      };

    render() {
        let page = 0;
        let { loading } = this.state;
        let TaskPage = (
            <div className="body-bg-color">
                <HeaderComponent />
                <Suspense fallback={<div></div>}>
                    <InviteCompanyModal
                        openModel={this.state.openModel}
                        onClose={this.onCloseModal}
                        onChangeHandler={this.onChangeHandler}
                        inviteHandler={this.inviteHandler}
                        companyListHandler={this.companyListHandler}
                        newInviteHandler={this.newInviteHandler}
                        onSelectHandler={this.onSelectHandler}
                        compAutoClose={this.compAutoClose}
                        modaldata={this.state}
                        divisionListHandler={this.divisionListHandler}
                        onDivisionSelectHandler={this.onDivisionSelectHandler}
                        divisionAutoClose={this.divisionAutoClose}
                    />
                    <AddCompanyModal
                        open={this.state.showaddModal}
                        onClose={this.closeaddModal}
                        modeldata={this.state}
                        isValid={this.state.showaddModal}
                        onFileuploadHandler={this.onFileuploadHandler}
                        handleChange={this.handleChange}
                        checkCompanyId={this.checkCompanyId}
                        Addcompanyfn={this.Addcompanyfn}
                        onclickBtn={this.onclickBtn}
                        isuplode={this.isuplode}
                        onGooglePlaceSelect={this.onGooglePlaceSelect}
                        valid={this.valid}
                        onDateRangeChanage={this.onDateRangeChanage}
                        onDialogClose={this.onDialogClose}
                        billingTrialDays={this.state.billingTrialDays}
                        billingTrialEnds={this.state.billingTrialEnds}
                    />
                </Suspense>
                <div className="header-add-btn" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Typography variant="h5" className="tableTitle" component="div">Companies</Typography>
                <div>
                    <Button variant="contained" className="tableaddBtn" color="default" style={{ boxShadow: 'none', marginRight: '10px' }} onClick={() => this.setState({ openModel: true })}>Invite Company</Button>
                    {this.state.role && this.state.role.toLowerCase() === "account admin" && 
                    <Button variant="contained" className="tableaddBtn" color="default" style={{ boxShadow: 'none' }} onClick={() => this.setState({ showaddModal: true })}>Add Company</Button>
                    }
                </div>
            </div>
                <div className='Acc-Table' >
                    <div className="filterSearch">

                        <CssTextField id="outlined-basic" name='taskSerch' variant="outlined" type="text" placeholder="Search Here" style={{ color: '#303960', fontFamily: 'Roboto', backgroundColor: '#F9F9F9', opacity: 1 }} InputProps={{ startAdornment: <InputAdornment position="start" style={{ cursor: 'pointer' }}><img src={searchIcon} /></InputAdornment> }} onChange={this.taskSearch} />
                    </div>
                    <div className="table_container">
                        <TableContainer >
                            <Table aria-label="customized table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell >#</TableCell>
                                        <TableCell >NAME</TableCell>
                                        <TableCell >DIVISION</TableCell>
                                        <TableCell >TERRITORY MANAGER</TableCell>
                                        <TableCell >PHONE</TableCell>
                                        <TableCell >EMAIL</TableCell>
                                        <TableCell >ACTION</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody className="tablecell_container">
                                    {loading ?
                                        <StyledTableRow>
                                            <TableCell colSpan={9} style={{ textAlign: "center" }}>
                                                <Loader
                                                    type="ThreeDots"
                                                    color="#00BFFF"
                                                    height={50}
                                                    width={50}
                                                    visible={loading}
                                                />
                                            </TableCell>
                                        </StyledTableRow> :
                                        !this.props.companyList || this.props.companyList.length === 0 ?
                                            <StyledTableRow><TableCell colSpan={9} style={{ textAlign: 'center' }}>No data found</TableCell></StyledTableRow> : (this.state.rowsPerPage > 0
                                                ? this.props.companyList.slice()
                                                : this.props.companyList).map((row, index) => (
                                                    <StyledTableRow key={row._id}>
                                                        <TableCell onClick={() => this.navigateDetail(row)}> {!this.props.pager ? 0 : (this.props.pager.currentPage - 1) * this.state.pagesize + (index + 1)}</TableCell>
                                                        <TableCell onClick={() => this.navigateDetail(row)}>
                                                            <LightTooltip title={row.name} {...this.state.tooltipprops} arrow>
                                                                <span>{row.name ? row.name : ""}</span>
                                                            </LightTooltip>
                                                        </TableCell>
                                                        <TableCell onClick={() => this.navigateDetail(row)} >

                                                            <LightTooltip title={row.division ? row.division.charAt(0).toUpperCase() + row.division.slice(1) : ''} {...this.state.tooltipprops} arrow>
                                                                <span>{row.division ? row.division.charAt(0).toUpperCase() + row.division.slice(1) : '--'}</span>
                                                            </LightTooltip>


                                                        </TableCell>
                                                        <TableCell onClick={() => this.navigateDetail(row)}>

                                                            <LightTooltip title={row.manager ? row.manager.charAt(0).toUpperCase() + row.manager.slice(1) : ''}
                                                                {...this.state.tooltipprops} arrow>
                                                                <span> {row.manager ? row.manager.charAt(0).toUpperCase() + row.manager.slice(1) : '--'}
                                                                </span>
                                                            </LightTooltip>

                            

                                                        </TableCell>
                                                        <TableCell >
                                                            <LightTooltip title={row.phone ? row.phone : ""} {...this.state.tooltipprops} arrow>
                                                                <a href={"tel:" + row.phone} rel="noopener noreferrer">{row.phone ? row.phone : "--"}</a>
                                                            </LightTooltip>
                                                        </TableCell>
                                                        <TableCell >
                                                            <LightTooltip title={row.email ? row.email : ""} {...this.state.tooltipprops} arrow>
                                                                <a href={"mailto:" + row.email} rel="noopener noreferrer">{row.email ? row.email : "--"}</a>
                                                            </LightTooltip>

                                                        </TableCell>
                                                        <TableCell ><div className="table_action_btns" style={{ display: "flex", alignItems: "center", justifyContent: "left", }}>
                                                            <div>
                                                                <LightTooltip title="View Details" {...this.state.tooltipprops} arrow>
                                                                    <NavLink to={'/company/' + row.id} className="navbars">
                                                                        <img style={{ cursor: 'pointer' }} src={OpenMenu} alt="openmenu"></img>
                                                                    </NavLink>
                                                                </LightTooltip>
                                                            </div>
                                                        </div></TableCell>
                                                    </StyledTableRow>
                                                ))}
                                </TableBody>
                                <TableFooter>
                                    {/* <TableRow>
                                        <TablePagination
                                            rowsPerPageOptions={[5, 10, 25,]}
                                            colSpan={9}
                                            count={!this.props.pager ? 0 : this.props.pager.totalItems}
                                            rowsPerPage={this.state.pagesize}
                                            page={!this.props.pager ? 0 : this.props.pager.currentPage - 1}
                                            labelDisplayedRows={({ from, to, count }) => `Showing ${!to ? 0 : to} of ${count === undefined ? 0 : count} entries`}
                                            SelectProps={{
                                                inputProps: { 'aria-label': 'rows per page' }
                                            }}
                                            onChangePage={this.handleChangePage}
                                            onChangeRowsPerPage={this.handleChangeRowsPerPage}
                                        />
                                    </TableRow> */}
                                    {this.props.companyList && this.props.companyList.length > 0 ?
                                        <TableRow>
                                            <td colSpan={20} className="pagination_container">
                                                <CustomPaginationComponent
                                                    count={this.props.pager.endPage}
                                                    rowsPerPage={this.state.pagesize}
                                                    page={this.state.pageNumber}
                                                    onPageChange={this.handleCustomPaginagtionChange}
                                                    onChangeRowsPerPage={this.handleChangeRowsPerPage}
                                                />
                                            </td>
                                        </TableRow>
                                        : null}
                                </TableFooter>
                            </Table>
                        </TableContainer>
                    </div>
                </div>
            </div >
        )
        return <div>{TaskPage}</div>;
    }
}
const mapStateToProps = (state) => {
    return {
        // isAuthenticated: jwt.decode(localStorage.getItem("token")) ? false : true,
        loginError: state.auth.error,
        pager: state.pagination.pager,
        companyList: state.pagination.pageOfItems,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        onPagination: (pageNo, pagerole,) => dispatch(action.companyPagination(pageNo, pagerole)),
        onSearchBy: (value) => dispatch(action.companySearch(value)),
        // onSearchByAssigend: (value) => dispatch(action.onSearchByAssigend(value)),
        // onSearchByDate: (value) => dispatch(action.onSearchByDate(value)),
        // onSearchByAccount: (value) => dispatch(action.onSearchByAccount(value)),
        // onSearchByCompany: (value) => dispatch(action.onSearchByCompany(value)),
    };
};
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(CompanyComponent));
